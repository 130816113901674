import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_info } from 'components/authentication/actions';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportNhanSuReport } from 'redux/saga/cong-doan/thu-chi/report/exportNhanSuReport';

function NhanSuReportPage() {
    const { control, getValues, watch, setValue, trigger } = useForm();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const theme = useTheme();

    const watches = watch();
    const values = getValues();

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const exportAnnualReport = () => {
        dispatch(loading());
        const academicYear = get(values, 'academicYear.value.name');
        callApiExportNhanSuReport(congDoanId, academicYear)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Báo cáo nhân sự ${get(congDoanInfo, 'congDoanName', '')} năm học ${academicYear}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo nhân sự</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            {watches.academicYear && (
                                <div className="col-md-3">
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={exportAnnualReport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
        </>
    );
}

export default NhanSuReportPage;
