import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CongDoanUserChangePassword from './CongDoanUserChangePassword';
import { callApiChangePAsswordUser } from 'redux/saga/trung-uong/cong-doan/changePasswordUser';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { loaded, loading } from 'components/mam-non-page/actions';

const EmptyRowsView = () => {
    const message = 'Chưa có cộng đoàn nào thuộc sứ vụ này';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function CongDoanUserList() {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableHeight, setTableHeight] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [open, setOpen] = useState(false);
    const congDoanUsers = useSelector((state) => state.commonReducer.congDoanUsers, []);
    const dispatch = useDispatch();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        congDoanUsers.forEach((item, index) => {
            rows.push({
                index: index + 1,
                congDoanName: item.congDoanName,
                city: item.city,
                principalName: item.principalName,
                username: item.username,
                congDoan: item,
            });
        });
        setRows(rows);
    }, [congDoanUsers]);

    useEffect(() => {
        const columns = [
            { key: 'index', name: 'STT', editable: false, frozen: true, width: 50 },
            { key: 'congDoanName', name: 'Tên cộng đoàn', editable: false, frozen: true, width: 250 },
            { key: 'city', name: 'Thành phố', editable: false, width: 250 },
            { key: 'principalName', name: 'Tên hiệu trưởng', editable: true, frozen: true, width: 250 },
            { key: 'username', name: 'Tên tài khoản', width: 150 },
            { key: 'actions', name: '', width: 80 },
        ];
        setColumns(columns);
    }, []);

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <VpnKeyIcon />,
                callback: () => {
                    setSelectedUser(row.congDoan);
                    openDialog();
                },
            },
        ];
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onChangePassword = (username, password) => {
        dispatch(loading());
        callApiChangePAsswordUser(username, { newPassword: password, confirmPassword: password })
            .then((res) => {
                toastHelper.success(`Thay đổi mật khẩu cho người dùng ${username} thành công!`);
                closeDialog();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                headerRowHeight={60}
                emptyRowsView={EmptyRowsView}
                getCellActions={getCellActions}
            />
            {selectedUser && <CongDoanUserChangePassword open={open} closeDialog={closeDialog} onChangePassword={onChangePassword} user={selectedUser} />}
        </div>
    );
}

export default CongDoanUserList;
