import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_MONTH_YEAR_FORMAT, FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { callApiExportNoiTruReportForAllCongDoanMonthly } from 'redux/saga/trung-uong/report/noitru/exportNoiTruMonthReport';

function TWNoiTruReportMonthPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const theme = useTheme();

    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const exportReportPDF = useCallback(() => {
        dispatch(loading());
        callApiExportNoiTruReportForAllCongDoanMonthly(dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT), true)
            .then((res) => {
                const pdfURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                setPdfFile(pdfURL);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.monthYear]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            if (userInfo.congDoanId) {
                setPdfFile(null);
                exportReportPDF();
            }
        }
    }, [values, watches, prevValues, exportReportPDF, userInfo.congDoanId]);

    const onSubmit = () => {
        if (userInfo.congDoanId && values.monthYear) {
            exportNoiTruReport();
        }
    };

    const exportNoiTruReport = () => {
        dispatch(loading());
        callApiExportNoiTruReportForAllCongDoanMonthly(dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT), false)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Báo cáo thu chi tất cả nội trú tháng ${dayjs(values.monthYear).format(FMI_MONTH_YEAR_FORMAT)}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo thu chi nội trú theo tháng</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onSubmit}>
                                        Xuất báo cáo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {pdfFile && (
                <div className="align-pdf">
                    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={index} pageNumber={index + 1}></Page>
                        ))}
                    </Document>
                </div>
            )}
        </>
    );
}

export default TWNoiTruReportMonthPage;
