import { Divider } from '@material-ui/core';
import { LOAI_THUCHI_DAUNAM } from 'config/constants/danhMucThuConstant';
import { ACADEMIC_YEAR_DELIMITER, FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get, groupBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Chưa có mục chi nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

const ThuChiDauNamHeader = (props) => {
    return (
        <div>
            {props.index === 0 ? (
                <>
                    <div
                        style={{
                            width: (props.itemWidth - 4) * props.itemsInGroup,
                            zIndex: 1,
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}
                    >
                        <div style={{ left: 152 }}>{props.type}</div>
                    </div>
                </>
            ) : (
                <div style={{ opacity: 0 }}>{props.type}</div>
            )}
            <Divider />
            <div className="text-center">{props.column.name}</div>
        </div>
    );
};

function ThuChiDauNamList({ academicYear, thuchiDauNams, dmThuChiDauNams, data, setData }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
        const newData = new Map(data);
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (get(newRows[i], 'dm.id', false) || updated[cellKey] !== '') {
                newData.set(rows[i].monthYear, newRows[i]);
            }
        }
        setRows(newRows);
        setData(newData);
    };

    const buildDynamicColumns = useCallback(
        (label, key = '', itemWidth = 80) => {
            if (key === LOAI_THUCHI_DAUNAM.TONG_TON) {
                return [
                    { key: LOAI_THUCHI_DAUNAM.TIEN_MAT, label: 'Tiền mặt' },
                    { key: LOAI_THUCHI_DAUNAM.NGAN_HANG, label: 'Ngân hàng' },
                    { key: LOAI_THUCHI_DAUNAM.TONG_TON, label: 'Tổng' },
                ].map((item, index) => {
                    return {
                        key: item.key,
                        name: item.label,
                        width: 100,
                        headerRenderer: <ThuChiDauNamHeader index={index} type={'Tổng tồn'} itemsInGroup={3} itemWidth={100} />,
                        type: key,
                    };
                });
            }
            return dmThuChiDauNams.map((dm, index) => {
                return {
                    key: key + '_' + dm.id,
                    name: dm.name,
                    width: itemWidth,
                    headerRenderer: <ThuChiDauNamHeader index={index} type={label} itemsInGroup={dmThuChiDauNams.length} itemWidth={itemWidth} />,
                    type: key,
                };
            });
        },
        [dmThuChiDauNams]
    );

    const buildDynamicColumnsForALLType = useCallback(() => {
        return [
            ...buildDynamicColumns('Thu', LOAI_THUCHI_DAUNAM.THU),
            ...buildDynamicColumns('Chi', LOAI_THUCHI_DAUNAM.CHI),
            ...buildDynamicColumns('Tồn cuối', LOAI_THUCHI_DAUNAM.TON),
            ...buildDynamicColumns('Tổng tồn', LOAI_THUCHI_DAUNAM.TONG_TON),
            {
                key: LOAI_THUCHI_DAUNAM.NOTE,
                name: '',
                width: 200,
                type: LOAI_THUCHI_DAUNAM.NOTE,
                headerRenderer: <ThuChiDauNamHeader index={0} type={'Ghi chú'} itemsInGroup={1} itemWidth={200} />,
            },
        ].map((item) => {
            return { ...item, ...{ editable: true } };
        });
    }, [buildDynamicColumns]);

    const buildColumns = useCallback(() => {
        const defaultColumns = [{ key: 'month', name: 'TH', editable: false, frozen: true, width: 60 }];
        return [...defaultColumns, ...buildDynamicColumnsForALLType()];
    }, [buildDynamicColumnsForALLType]);

    useEffect(() => {
        const columns = buildColumns();
        setColumns(columns);
    }, [buildColumns]);

    const buildRow = useCallback((thuchis) => {
        const row = { idMap: new Map() };
        if (thuchis) {
            thuchis.forEach((thuchi) => {
                const loaiThuChi = thuchi.loaiThuChiDauNam;
                const danhmucId = thuchi.danhMucThuDauNamId;
                const amount = thuchi.amount;
                const note = thuchi.note;
                const id = thuchi.id;
                if (danhmucId !== null) {
                    row[`${loaiThuChi}_${danhmucId}`] = amount;
                } else {
                    row[`${loaiThuChi}`] = loaiThuChi === LOAI_THUCHI_DAUNAM.NOTE ? note : amount;
                }
                if (id) {
                    if (danhmucId !== null) {
                        row.idMap.set(`${loaiThuChi}_${danhmucId}`, id);
                    } else {
                        row.idMap.set(`${loaiThuChi}`, id);
                    }
                }
            });
        }
        return row;
    }, []);

    const buildRows = useCallback(() => {
        const yearSplitted = academicYear.name.split(ACADEMIC_YEAR_DELIMITER);
        const rows = dateHelper.getMonthYearArrayByAcademicYear(academicYear).map((month) => {
            return {
                month: +yearSplitted[0] === dayjs(month).year() ? dayjs(month).format('MM') : dayjs(month).format('MM/YY'),
                monthYear: dayjs(month).format(FMI_YEAR_MONTH_FORMAT),
            };
        });
        const thuchiGroupedByMonth = groupBy(thuchiDauNams, 'month');
        rows.forEach((row, index) => {
            rows[index] = { ...rows[index], ...buildRow(thuchiGroupedByMonth[row.monthYear]), ...{ monthYear: row.monthYear } };
        });
        return rows;
    }, [thuchiDauNams, buildRow, academicYear]);

    useEffect(() => {
        if (thuchiDauNams) {
            setRows(buildRows());
        }
    }, [buildRows, thuchiDauNams]);

    const checkEditable = (props) => {
        if (props.column.type === LOAI_THUCHI_DAUNAM.TON) {
            return false;
        }
        if (props.column.type === LOAI_THUCHI_DAUNAM.TONG_TON && (props.column.key === LOAI_THUCHI_DAUNAM.TIEN_MAT || props.column.key === LOAI_THUCHI_DAUNAM.TONG_TON))
            return false;
        return props.column.editable;
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                headerRowHeight={60}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
                onCheckCellIsEditable={checkEditable}
            />
        </div>
    );
}

export default ThuChiDauNamList;
