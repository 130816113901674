import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getListOfCongDoanDanhMucThuCongDoan(payload) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetListOfCongDoanDanhMucThuCongDoan, payload.payload.isInternal);
        yield put({
            type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.CONGDOAN_CALL_FAIL,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfCongDoanDanhMucThuCongDoan(isInternal = false) {
    return transport.get(`/congdoan/thuchi/thucongdoan/danhmuc`, { params: { isInternal } });
}
