import { GENDERS } from 'config/constants/genderConstant';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Không có học sinh vắng';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function AbsencesList({ absences, setData }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        if (absences) {
            absences.forEach((item, index) => {
                const row = {
                    index: index + 1,
                    firstName: get(item, 'student.firstName'),
                    lastName: get(item, 'student.lastName'),
                    gender: GENDERS[get(item, 'student.gender')],
                    dateOfBirth: dateHelper.formatDate(get(item, 'student.dateOfBirth')),
                    className: get(item, 'clazz.className'),
                    reason: get(item, 'reason'),
                    description: get(item, 'description'),
                    id: get(item, 'uuid'),
                };
                rows.push(row);
            });
            setRows(rows);
        }
    }, [absences]);

    useEffect(() => {
        // const readOnly = dateHelper.isAfterNextMonth10thDate(monthYear);
        const columns = [
            { key: 'index', name: 'STT', editable: false, width: 60 },
            { key: 'lastName', name: 'Họ', width: 200 },
            { key: 'firstName', name: 'Tên', editable: false, width: 100 },
            { key: 'gender', name: 'Giới tính', width: 100 },
            { key: 'dateOfBirth', name: 'Ngày sinh', width: 150 },
            { key: 'className', name: 'Lớp', width: 200 },
            { key: 'reason', name: 'K/P', width: 80 },
            { key: 'description', name: 'Lí do', width: 300, editable: true },
        ].map((item) => {
            return {
                ...item,
                ...{
                    resizable: true,
                },
            };
        });
        setColumns(columns);
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
        setData(newRows);
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                onGridRowsUpdated={onGridRowsUpdated}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default AbsencesList;
