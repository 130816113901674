import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Chưa có mục chi nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function DuChiCongDoanTable({ academicYear, danhmucchicongdoans, duchicongdoans, data, setData }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const numOfMonthForYear = get(academicYear, 'congDoanNumOfMonth', 12);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        if (danhmucchicongdoans) {
            danhmucchicongdoans
                .sort((a, b) => a.code - b.code)
                .forEach((dm) => {
                    const matchedDuChiCongDoan = duchicongdoans ? duchicongdoans.find((duthu) => dm.code === duthu.danhMucChiCongDoanCode) : {};
                    const row = {
                        dmId: dm.id,
                        code: dm.code,
                        name: dm.name,
                        id: get(matchedDuChiCongDoan, 'id'),
                        danhMucChiCongDoanCode: get(matchedDuChiCongDoan, 'danhMucChiCongDoanCode'),
                        junAmount: get(matchedDuChiCongDoan, 'junAmount', '') ? get(matchedDuChiCongDoan, 'junAmount') : '',
                        julAmount: get(matchedDuChiCongDoan, 'julAmount', '') ? get(matchedDuChiCongDoan, 'julAmount') : '',
                        augAmount: get(matchedDuChiCongDoan, 'augAmount', '') ? get(matchedDuChiCongDoan, 'augAmount') : '',
                        sepAmount: get(matchedDuChiCongDoan, 'sepAmount', '') ? get(matchedDuChiCongDoan, 'sepAmount') : '',
                        octAmount: get(matchedDuChiCongDoan, 'octAmount', '') ? get(matchedDuChiCongDoan, 'octAmount') : '',
                        novAmount: get(matchedDuChiCongDoan, 'novAmount', '') ? get(matchedDuChiCongDoan, 'novAmount') : '',
                        decAmount: get(matchedDuChiCongDoan, 'decAmount', '') ? get(matchedDuChiCongDoan, 'decAmount') : '',
                        janAmount: get(matchedDuChiCongDoan, 'janAmount', '') ? get(matchedDuChiCongDoan, 'janAmount') : '',
                        febAmount: get(matchedDuChiCongDoan, 'febAmount', '') ? get(matchedDuChiCongDoan, 'febAmount') : '',
                        marAmount: get(matchedDuChiCongDoan, 'marAmount', '') ? get(matchedDuChiCongDoan, 'marAmount') : '',
                        aprAmount: get(matchedDuChiCongDoan, 'aprAmount', '') ? get(matchedDuChiCongDoan, 'aprAmount') : '',
                        mayAmount: get(matchedDuChiCongDoan, 'mayAmount', '') ? get(matchedDuChiCongDoan, 'mayAmount') : '',
                        extraAmount1: get(matchedDuChiCongDoan, 'extraAmount1', '') ? get(matchedDuChiCongDoan, 'extraAmount1', '') : '',
                        extraAmount2: get(matchedDuChiCongDoan, 'extraAmount2', '') ? get(matchedDuChiCongDoan, 'extraAmount2', '') : '',
                        duchi: matchedDuChiCongDoan,
                    };
                    rows.push(row);
                });
            setRows(rows);
        }
    }, [duchicongdoans, danhmucchicongdoans]);

    const buildDynamicColumnBaseOnYear = useCallback(() => {
        if (numOfMonthForYear === 14) {
            return [
                { key: 'aprAmount', name: 'Tháng 4', editable: true, width: 80 },
                { key: 'mayAmount', name: 'Tháng 5', editable: true, width: 80 },
                { key: 'junAmount', name: 'Tháng 6', editable: true, width: 80 },
                { key: 'julAmount', name: 'Tháng 7', editable: true, width: 80 },
                { key: 'augAmount', name: 'Tháng 8', editable: true, width: 80 },
                { key: 'sepAmount', name: 'Tháng 9', editable: true, width: 80 },
                { key: 'octAmount', name: 'Tháng 10', editable: true, width: 80 },
                { key: 'novAmount', name: 'Tháng 11', editable: true, width: 80 },
                { key: 'decAmount', name: 'Tháng 12', editable: true, width: 80 },
                { key: 'janAmount', name: 'Tháng 1', editable: true, width: 80 },
                { key: 'febAmount', name: 'Tháng 2', editable: true, width: 80 },
                { key: 'marAmount', name: 'Tháng 3', editable: true, width: 80 },
                { key: 'extraAmount1', name: 'Tháng 4', editable: true, width: 80 },
                { key: 'extraAmount2', name: 'Tháng 5', editable: true, width: 80 },
            ];
        } else if (numOfMonthForYear === 10) {
            return [
                { key: 'junAmount', name: 'Tháng 6', editable: true, width: 80 },
                { key: 'julAmount', name: 'Tháng 7', editable: true, width: 80 },
                { key: 'augAmount', name: 'Tháng 8', editable: true, width: 80 },
                { key: 'sepAmount', name: 'Tháng 9', editable: true, width: 80 },
                { key: 'octAmount', name: 'Tháng 10', editable: true, width: 80 },
                { key: 'novAmount', name: 'Tháng 11', editable: true, width: 80 },
                { key: 'decAmount', name: 'Tháng 12', editable: true, width: 80 },
                { key: 'janAmount', name: 'Tháng 1', editable: true, width: 80 },
                { key: 'febAmount', name: 'Tháng 2', editable: true, width: 80 },
                { key: 'marAmount', name: 'Tháng 3', editable: true, width: 80 },
            ];
        } else {
            return [
                { key: 'junAmount', name: 'Tháng 6', editable: true, width: 80 },
                { key: 'julAmount', name: 'Tháng 7', editable: true, width: 80 },
                { key: 'augAmount', name: 'Tháng 8', editable: true, width: 80 },
                { key: 'sepAmount', name: 'Tháng 9', editable: true, width: 80 },
                { key: 'octAmount', name: 'Tháng 10', editable: true, width: 80 },
                { key: 'novAmount', name: 'Tháng 11', editable: true, width: 80 },
                { key: 'decAmount', name: 'Tháng 12', editable: true, width: 80 },
                { key: 'janAmount', name: 'Tháng 1', editable: true, width: 80 },
                { key: 'febAmount', name: 'Tháng 2', editable: true, width: 80 },
                { key: 'marAmount', name: 'Tháng 3', editable: true, width: 80 },
                { key: 'aprAmount', name: 'Tháng 4', editable: true, width: 80 },
                { key: 'mayAmount', name: 'Tháng 5', editable: true, width: 80 },
            ];
        }
    }, [numOfMonthForYear]);

    useEffect(() => {
        const commonColumns = [
            { key: 'code', name: 'Mã số', editable: false, width: 60 },
            { key: 'name', name: 'Diễn giải', editable: false, width: 360 },
        ];
        const dynamicColumns = buildDynamicColumnBaseOnYear();
        const columns = [...commonColumns, ...dynamicColumns].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
        setColumns(columns);
    }, [buildDynamicColumnBaseOnYear]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
        const newData = new Map(data);
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (get(newRows[i], 'dm.id', false) || updated[cellKey] !== '') {
                newData.set(i, newRows[i]);
            }
        }
        setRows(newRows);
        setData(newRows);
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default DuChiCongDoanTable;
