import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import './styles.scss';

function HolidayTable({ month, onChangeHoliday, holidays }) {
    const { register, getValues } = useForm();

    const daysInMonth = () => {
        const selectedMonth = dayjs(new Date(dayjs().year(), month - 1, 1));
        return [...Array.from(Array(dayjs(selectedMonth).daysInMonth()).keys(), (x) => x + 1)];
    };

    const handleChange = () => {
        onChangeHoliday(
            Object.values(getValues()['withIndex'])
                .filter((d) => Boolean(d) !== false)
                .join(',')
        );
    };

    return (
        <fieldset>
            <div className="holidays">
                {daysInMonth().map((c, i) => (
                    <div className="holiday" key={c}>
                        <label>
                            <input
                                className="mr-1"
                                type="checkbox"
                                value={c}
                                checked={holidays.map((holiday) => Number(holiday)).includes(Number(c))}
                                name={'withIndex.' + i}
                                onChange={handleChange}
                                ref={register}
                                readOnly
                            />
                            {c}
                        </label>
                    </div>
                ))}
            </div>
        </fieldset>
    );
}

export default HolidayTable;
