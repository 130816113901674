export const ASSET_TYPE = {
    DATDAI: 'DATDAI',
    XE: 'XE',
    DESKTOP: 'DESKTOP',
    LAPTOP: 'LAPTOP',
    PHONE: 'PHONE',
    PRINTER: 'PRINTER',
    PROJECTOR: 'PROJECTOR',
    PHOTOCOPY: 'PHOTOCOPY',
    TIVI: 'TIVI',
    WASHINGMACHINE: 'WASHINGMACHINE',
    SQUEEZER: 'SQUEEZER',
    HOUSEHOLD: 'HOUSEHOLD',
    KITCHEN: 'KITCHEN',
    CHURCH: 'CHURCH',
    GUESTROOM: 'GUESTROOM',
    OFFICE: 'OFFICE',
    PLAYGROUND: 'PLAYGROUND',
    FESTIVAL: 'FESTIVAL',
    SPORTS: 'SPORTS',
    SCHOOL: 'SCHOOL',
    CLASS: 'CLASS',
    BANKACCOUNT: 'BANKACCOUNT',
    BANKCARD: 'BANKCARD',
};

export const ASSET_SOURCE = {
    CONGDOAN: 'CONGDOAN',
    MAMNON: 'MAMNON',
    NOITRU: 'NOITRU',
};

const ALL_SOURCES = [ASSET_SOURCE.CONGDOAN, ASSET_SOURCE.MAMNON, ASSET_SOURCE.NOITRU];

export const ASSET_TYPE_OPTIONS = [
    { key: ASSET_TYPE.DATDAI, label: 'Đất đai', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.XE, label: 'Xe', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.DESKTOP, label: 'Máy tính bàn', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.LAPTOP, label: 'Máy laptop', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.PHONE, label: 'Điện thoại', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.PRINTER, label: 'Máy in', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.PROJECTOR, label: 'Máy chiếu', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.PHOTOCOPY, label: 'Máy photo', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.TIVI, label: 'Tivi', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.WASHINGMACHINE, label: 'Máy giặt', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.SQUEEZER, label: 'Máy vắt', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.HOUSEHOLD, label: 'Vật dụng trong nhà', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.KITCHEN, label: 'Vật dụng nhà bếp', assetSources: ALL_SOURCES },
    { key: ASSET_TYPE.CHURCH, label: 'Vật dụng nhà nguyện', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.GUESTROOM, label: 'Vật dụng nhà khách', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.OFFICE, label: 'Vật dụng văn phòng', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.PLAYGROUND, label: 'Vật dụng sân chơi', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.FESTIVAL, label: 'Vật dụng lễ hội', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.SPORTS, label: 'Vật dụng thể dục/thể thao', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.SCHOOL, label: 'Vật dụng học tập', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.CLASS, label: 'Vật dụng trong lớp', assetSources: [ASSET_SOURCE.MAMNON, ASSET_SOURCE.MAMNON] },
    { key: ASSET_TYPE.BANKACCOUNT, label: 'Tài khoản NH', assetSources: [ASSET_SOURCE.CONGDOAN] },
    { key: ASSET_TYPE.BANKCARD, label: 'Thẻ NH', assetSources: [ASSET_SOURCE.CONGDOAN] },
];

export const BANK_ACCOUNT_TYPE = {
    CARD: 'CARD',
    ACCOUNT: 'ACCOUNT',
};
