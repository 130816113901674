import numeral from 'numeral';

const { FMI_NUMBER_FORMAT } = require('config/constants/numberConstant');

function formatNumber(number, format = FMI_NUMBER_FORMAT) {
    return numeral(number).format(format);
}

export const numberHelper = {
    formatNumber,
};
