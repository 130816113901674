import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { numberHelper } from 'helpers/numberHelper';
import { get, groupBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { CustomHeader } from '../ThuTienTable';

const EmptyRowsView = () => {
    const message = 'Chưa có học sinh nào đóng tiền';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function TraCuuThuTienTable({ paymentSummaries }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        paymentSummaries.classPaidSummaries.forEach((item, index) => {
            const row = buildRow(item, index + 1);
            rows.push(row);
        });
        rows.push(buildRow(paymentSummaries.totalSummary, ''));
        setRows(rows);
    }, [paymentSummaries]);

    const buildRow = (summary, index) => {
        const row = {
            index,
            className: get(summary, 'name', ''),
            numberOfPaidStudents: get(summary, 'numberOfPaidStudents', ''),
            totalPaidAmount: numberHelper.formatNumber(get(summary, 'totalPaidAmount', '')),
            totalRemainingAmount: numberHelper.formatNumber(get(summary, 'totalRemainingAmount', '')),
        };
        const tuitionsGrouped = groupBy(summary.tuitions, 'danhMucThuId');
        Object.entries(tuitionsGrouped).forEach(([k, v]) => {
            row[`tuition-${k}`] = numberHelper.formatNumber(v[0].amount);
        });
        return row;
    };

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'className', name: 'Lớp', frozen: true, width: 200 },
            { key: 'totalPaidAmount', name: 'Tổng tiền đã đóng', frozen: true, width: 100, headerRenderer: <CustomHeader /> },
            { key: 'totalRemainingAmount', name: 'Tổng tiền còn lại', frozen: true, width: 100, headerRenderer: <CustomHeader /> },
            { key: 'numberOfPaidStudents', name: 'Số học sinh đã trả', width: 80, headerRenderer: <CustomHeader /> },
        ];
        const allTuitions = get(paymentSummaries, 'totalSummary.tuitions', []);
        const tuiTionColumns = allTuitions.map((tuition) => {
            return { key: `tuition-${tuition.danhMucThuId}`, name: tuition.name, width: 110, headerRenderer: <CustomHeader /> };
        });
        return [...fixedColumns, ...tuiTionColumns].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, [paymentSummaries]);

    useEffect(() => {
        if (paymentSummaries.totalSummary) {
            setColumns(buildColumns());
        }
    }, [buildColumns, paymentSummaries]);

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                enableCellSelect={true}
                minHeight={tableHeight}
                emptyRowsView={EmptyRowsView}
                headerRowHeight={110}
            />
        </div>
    );
}

export default TraCuuThuTienTable;
