import { Box, Divider, makeStyles, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useTheme } from '@material-ui/core';
import TabPanel from 'components/shared/TabPanel';
import { FMI_DATE_FORMAT, FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { trunguong__get_cong_doan_overview } from '../actions';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        maxHeight: 600,
    },
});

function CongDoanReportOverviewPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const [finishedTab, setFinishedTab] = React.useState(0);
    const classes = useStyles();

    const handleChangeFinishedTab = (event, newValue) => {
        setFinishedTab(newValue);
    };

    const theme = useTheme();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const congDoanReportOverview = useSelector((state) => state.commonReducer.congDoanReportOverview, null);
    const percent = congDoanReportOverview ? Math.round((congDoanReportOverview.finished.length / congDoanReportOverview.totalCongDoan) * 100) / 100 : 0;
    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            dispatch(trunguong__get_cong_doan_overview({ yearMonth: dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT) }));
        }
    }, [values, watches, prevValues, dispatch]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Tổng quan báo cáo cộng đoàn</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {congDoanReportOverview && (
                <>
                    <div className="row mx-0 mt-3">
                        <div className="col-md-3 pr-0 d-flex justify-content-center align-items-center" style={{ minHeight: 500 }}>
                            <div style={{ width: 200, height: 200 }}>
                                <CircularProgressbar value={percent} text={`${percent * 100}%`} maxValue={1} />
                            </div>
                        </div>
                        <div className="col-md-9 pr-0">
                            <Tabs value={finishedTab} onChange={handleChangeFinishedTab} aria-label="simple tabs example">
                                <Tab label="Đã hoàn thành" />
                                <Tab label="Chưa hoàn thành" />
                            </Tabs>
                            <TabPanel value={finishedTab} index={0}>
                                <TableContainer component={Paper} className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>STT</TableCell>
                                                <TableCell>Cộng đoàn</TableCell>
                                                <TableCell>Ngày gửi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {congDoanReportOverview.finished.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>{get(row, 'congDoan.congDoanName', '')}</TableCell>
                                                    <TableCell>{dayjs(get(row, 'updatedDate', '')).format(FMI_DATE_FORMAT)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={finishedTab} index={1}>
                                <TableContainer component={Paper} className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>STT</TableCell>
                                                <TableCell>Cộng đoàn</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {congDoanReportOverview.notYetFinished.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>{get(row, 'name', '')}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CongDoanReportOverviewPage;
