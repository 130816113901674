import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from 'helpers/usePrevious';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { ASSET_SOURCE, ASSET_TYPE, ASSET_TYPE_OPTIONS } from 'config/constants/assetConstant';
import { get } from 'lodash';
import { messageHelper } from 'helpers/messageHelper';
import { callApiGetAssets } from 'redux/saga/quan-li-tai-san/getAssets';
import AssetTable from './AssetTable';
import { toastHelper } from 'helpers/toastHelper';
import AssetDialog from './asset-dialog/AssetDialog';
import { callApiGenerateAssets } from 'redux/saga/quan-li-tai-san/generateAssets';
import { callApiExportQuanLiTaiSan } from 'redux/saga/quan-li-tai-san/exportAssets';

function QuanLiTaiSanPage({ assetSource }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { control, setValue, getValues, watch } = useForm();
    const [assets, setAssets] = useState(null);
    const [asset, setAsset] = useState(null);
    const [open, setOpen] = useState(false);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanId = useSelector((state) => state.authenticationReducer.userInfo.congDoanId, null);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const getAssets = useCallback(() => {
        const academicYear = get(values, 'academicYear.value');
        const assetType = get(values, 'assetType.value');
        setAssets(null);
        dispatch(loading());
        if (academicYear && assetType && congDoanId) {
            callApiGetAssets(congDoanId, academicYear, assetSource, assetType === ASSET_TYPE.BANKCARD ? ASSET_TYPE.BANKACCOUNT : assetType)
                .then((res) => {
                    let resAssets = res.data;
                    if (assetType === ASSET_TYPE.BANKCARD) {
                        resAssets = resAssets.filter((item) => item.accountType === 'CARD');
                    } else if (assetType === ASSET_TYPE.BANKACCOUNT) {
                        resAssets = resAssets.filter((item) => item.accountType === 'ACCOUNT');
                    }
                    setAssets(resAssets);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    }, [dispatch, values, assetSource, congDoanId]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value');
        const assetType = get(values, 'assetType.value');
        if (isDiff && academicYear && assetType) {
            // getReport();
            getAssets();
        }
    }, [values, watches, prevValues, getAssets]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
    };

    const onAssetTypeChange = (selectedOption) => {
        setValue('assetType', selectedOption);
    };

    const assetTypeOptions = ASSET_TYPE_OPTIONS.filter((item) => item.assetSources.includes(assetSource)).map((item) => {
        return { value: item.key, label: item.label };
    });

    const onSaveSuccess = () => {
        toastHelper.success('Lưu tài sản thành công!');
        getAssets();
        closeDialog();
    };

    const onDeleteSuccess = () => {
        toastHelper.success('Xoá tài sản thành công!');
        getAssets();
    };

    const getAssetSource = () => {
        switch (assetSource) {
            case ASSET_SOURCE.CONGDOAN:
                return 'Cộng đoàn';
            case ASSET_SOURCE.MAMNON:
                return 'Mầm non';
            case ASSET_SOURCE.NOITRU:
                return 'Nội trú';
            default:
                return '';
        }
    };

    const addAsset = () => {
        openDialog();
        setAsset({});
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setAsset(null);
        setOpen(false);
    };

    const generateAssets = () => {
        const academicYear = get(values, 'academicYear.value');
        const assetType = get(values, 'assetType.value');
        dispatch(loading());
        if (academicYear && assetType && congDoanId) {
            callApiGenerateAssets(congDoanId, academicYear, assetSource, assetType === ASSET_TYPE.BANKCARD ? ASSET_TYPE.BANKACCOUNT : assetType)
                .then((res) => {
                    getAssets();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onExport = () => {
        if (values.academicYear) {
            dispatch(loading());
            callApiExportQuanLiTaiSan(congDoanId, values.academicYear.value, assetSource)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Sổ kê khai tài sản năm học ${values.academicYear.value}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Quản lí tài sản {getAssetSource()}</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="academicYear"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập khối để lấy danh sách dự thu!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        options={academicYearsOptions}
                                                        id="academicYear"
                                                        onChange={onAcademicYearChange}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    ></Select>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label">Loại tài sản:</label>
                                    <div className="col-md-6 p-0">
                                        <Controller
                                            name="assetType"
                                            control={control}
                                            defaultValue=""
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onAssetTypeChange}
                                                        options={assetTypeOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {values.academicYear && (
                                <>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-3">
                                        <div className="row mr-0 d-flex justify-content-end mb-1">
                                            <div className="col-md-12 p-0 d-flex justify-content-end">
                                                <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onExport} title="Xuất dữ liệu">
                                                    Xuất dữ liệu
                                                </button>
                                            </div>
                                        </div>
                                        {values.assetType && (
                                            <>
                                                {assets && assets.length === 0 && (
                                                    <div className="row mr-0 d-flex justify-content-end mb-1">
                                                        <div className="col-md-12 p-0 d-flex justify-content-end">
                                                            <button
                                                                type="button"
                                                                className="export-button btn btn-primary fmi-toolbar-button"
                                                                onClick={generateAssets}
                                                                title="Chuyển dữ liệu từ năm học trước"
                                                            >
                                                                Chuyển dữ liệu từ năm học trước
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mr-0 d-flex justify-content-end">
                                                    <div className="col-md-12 p-0 d-flex justify-content-end">
                                                        <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addAsset} title="Thêm tài sản">
                                                            <AddIcon />
                                                            Thêm tài sản
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {assets && (
                <AssetTable
                    assetType={values.assetType.value}
                    assets={assets}
                    onDeleteSuccess={onDeleteSuccess}
                    academicYear={get(values, 'academicYear.value')}
                    onSaveSuccess={onSaveSuccess}
                    assetSource={assetSource}
                />
            )}
            {asset && (
                <AssetDialog
                    open={open}
                    onSaveAssetSuccess={onSaveSuccess}
                    assetType={values.assetType.value}
                    asset={asset}
                    closeDialog={closeDialog}
                    assetSource={assetSource}
                    academicYear={get(values, 'academicYear.value')}
                />
            )}
        </>
    );
}

export default QuanLiTaiSanPage;
