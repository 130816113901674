import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useSelector } from 'react-redux';
import KhanDongDialog from './KhanDongDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { callApiDeleteKhanDong } from 'redux/saga/cong-doan/info/khandong/deleteKhanDong';
import { FMI_YEAR_FORMAT } from 'config/constants/dateConstants';
import { alert } from 'helpers/alertHelper';
import dayjs from 'dayjs';

const EmptyRowsView = () => {
    const message = 'Chưa có BHYT nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function KhanDongList({ khanDongs, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedKhanDong, setSelectedKhanDong] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedKhanDong(null);
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        khanDongs.forEach((khanDong, index) => {
            const row = {
                index: index + 1,
                id: khanDong.id,
                nunName: khanDong.nunName,
                nunDateOfBirth: khanDong.nunDateOfBirth ? dateHelper.formatDate(khanDong.nunDateOfBirth) : '',
                giaoXu: khanDong.giaoXu,
                giaoPhan: khanDong.giaoPhan,
                namTienKhan: dayjs(khanDong.namTienKhan).isValid() ? dateHelper.formatDate(khanDong.namTienKhan, FMI_YEAR_FORMAT) : '',
                namVinhKhan: dayjs(khanDong.namVinhKhan).isValid() ? dateHelper.formatDate(khanDong.namVinhKhan, FMI_YEAR_FORMAT) : '',
                ngayDenCongDoan: dayjs(khanDong.ngayDenCongDoan).isValid() ? dateHelper.formatDate(khanDong.ngayDenCongDoan) : '',
                congViec: khanDong.congViec,
                khanDong,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [khanDongs]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', width: 50 },
            { key: 'nunName', name: 'Tên sơ', width: 220 },
            { key: 'nunDateOfBirth', name: 'Ngày sinh', width: 100 },
            { key: 'giaoXu', name: 'Giáo xứ', width: 150 },
            { key: 'giaoPhan', name: 'Giáo phận', width: 150 },
            { key: 'namTienKhan', name: 'Năm tiền khấn', width: 150 },
            { key: 'namVinhKhan', name: 'Năm vinh khấn', width: 150 },
            { key: 'ngayDenCongDoan', name: 'Ngày đến công đoàn', width: 180 },
            { key: 'congViec', name: 'Công việc', width: 150 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onEditKhanDongSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedKhanDong(row.khanDong);
                    showConfirmDialog(row.khanDong);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedKhanDong(row.khanDong);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá khấn dòng này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                callApiDeleteKhanDong(userInfo.congDoanId, selectedItem.nunId, selectedItem.id)
                    .then((res) => {
                        onDeleteSuccess();
                        alert({ icon: 'success', title: 'Xoá khấn dòng thành công', showConfirmButton: false });
                    })
                    .catch((error) => {
                        alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá khấn dòng! Vui lòng thử lại sau.' });
                    });
            }
        });
    };
    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedKhanDong && <KhanDongDialog khanDong={selectedKhanDong} open={open} closeDialog={closeDialog} onSuccess={onEditKhanDongSuccess} />}
        </div>
    );
}

export default KhanDongList;
