import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading, nun__get_list } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { callApiGetListOfDanhMucDuChiCaNhan } from 'redux/saga/cong-doan/thu-chi/chi-cong-doan/getAllDanhMucDuChiCaNhan';
import { callApiGetDuChiCaNhan } from 'redux/saga/cong-doan/thu-chi/du-chi-ca-nhan/getDuChiCaNhan';
import { callApiSaveDuChiCaNhan } from 'redux/saga/cong-doan/thu-chi/du-chi-ca-nhan/saveDuChiCaNhan';
import DuChiCaNhanTable from './DuChiCaNhanTable';

function DuChiCaNhanPage() {
    const theme = useTheme();

    const { control, setValue, getValues, trigger, watch } = useForm();

    const dispatch = useDispatch();
    const [duchicanhans, setDuchicanhans] = useState(null);
    const [data, setData] = useState(null);
    const [danhMucDuChiCaNhan, setDanhMucDuChiCaNhan] = useState(null);
    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const nunOptions = commonReducer.nuns
        ? commonReducer.nuns.map((item) => {
              return { value: item, label: item.fullName };
          })
        : [];

    const getDuChiCaNhan = useCallback((congDoanId, academicYear, nunId) => {
        setDuchicanhans(null);
        setData(null);
        callApiGetDuChiCaNhan(congDoanId, nunId, academicYear)
            .then((res) => {
                setDuchicanhans(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            });
    }, []);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    useEffect(() => {
        if (congDoanId) {
            dispatch(nun__get_list({ congDoanId, isActive: true }));
        }
    }, [dispatch, congDoanId]);

    useEffect(() => {
        dispatch(loading());
        callApiGetListOfDanhMucDuChiCaNhan()
            .then((res) => {
                setDanhMucDuChiCaNhan(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const nunId = get(values, 'nun.value.id');
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear && nunId && congDoanId) {
            getDuChiCaNhan(congDoanId, academicYear, nunId);
        }
    }, [values, watches, prevValues, dispatch, getDuChiCaNhan, congDoanId]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const onNunChange = (selectedOption) => {
        if (!get(selectedOption, 'value.id')) {
            setDuchicanhans(null);
        }
        setValue('nun', selectedOption);
        trigger('nun');
    };

    const onSubmit = () => {
        const body = buildBody();
        dispatch(loading());
        callApiSaveDuChiCaNhan(congDoanId, body)
            .then((res) => {
                toastHelper.success('Lưu dự chi cá nhân thành công!');
                if (congDoanId) {
                    dispatch(nun__get_list({ congDoanId }));
                }
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const buildBody = () => {
        const resData = [...data];
        resData.forEach((item) => {
            item.junAmount = isNaN(Number(item.junAmount)) ? undefined : Number(item.junAmount);
            item.julAmount = isNaN(Number(item.julAmount)) ? undefined : Number(item.julAmount);
            item.augAmount = isNaN(Number(item.augAmount)) ? undefined : Number(item.augAmount);
            item.sepAmount = isNaN(Number(item.sepAmount)) ? undefined : Number(item.sepAmount);
            item.octAmount = isNaN(Number(item.octAmount)) ? undefined : Number(item.octAmount);
            item.novAmount = isNaN(Number(item.novAmount)) ? undefined : Number(item.novAmount);
            item.decAmount = isNaN(Number(item.decAmount)) ? undefined : Number(item.decAmount);
            item.janAmount = isNaN(Number(item.janAmount)) ? undefined : Number(item.janAmount);
            item.febAmount = isNaN(Number(item.febAmount)) ? undefined : Number(item.febAmount);
            item.marAmount = isNaN(Number(item.marAmount)) ? undefined : Number(item.marAmount);
            item.aprAmount = isNaN(Number(item.aprAmount)) ? undefined : Number(item.aprAmount);
            item.mayAmount = isNaN(Number(item.mayAmount)) ? undefined : Number(item.mayAmount);
            item.extraAmount1 = isNaN(Number(item.extraAmount1)) ? undefined : Number(item.extraAmount1);
            item.extraAmount2 = isNaN(Number(item.extraAmount2)) ? undefined : Number(item.extraAmount2);
        });
        const nunInfoId = get(values, 'nun.value.id');
        return {
            nunInfo: nunInfoId ? { fullName: get(values, 'nun.value.fullName'), id: nunInfoId } : { fullName: values.nun.value },
            academicYear: values.academicYear.value.name,
            duChiCaNhanInfos: resData.map((item) => {
                return { ...item, ...{ danhMucChiCongDoanCode: item.code } };
            }),
        };
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Dự chi cá nhân</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Sơ:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="nun"
                                            name="nun"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng chọn sơ để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <CreatableSelect
                                                        isClearable
                                                        options={nunOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                        onChange={onNunChange}
                                                        formatCreateLabel={(inputValue) => {
                                                            return 'Tạo sơ ' + inputValue;
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onSubmit}>
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.nun && values.academicYear && (
                <DuChiCaNhanTable academicYear={values.academicYear.value} duchicanhans={duchicanhans} danhmucduchicanhans={danhMucDuChiCaNhan} data={data} setData={setData} />
            )}
        </>
    );
}

export default DuChiCaNhanPage;
