import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiGetListOfChiMamNon } from 'redux/saga/mam-non/thu-chi/chi-mam-non/getAllChiMamNon';
import { callApiSaveChiMamNon } from 'redux/saga/mam-non/thu-chi/chi-mam-non/saveAllChiMamNon';
import { congdoan_get_list_danh_muc_chi_mam_non, loaded, loading } from '../actions';
import ChiMamNonList from './ChiMamNonList';
import './styles.scss';
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi);

function MamNonChiMamNonPage() {
    const theme = useTheme();

    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dmChiMamNons = useSelector((state) => state.commonReducer.dmChiMamNons, []);

    const [chiMamNons, setChiMamNons] = useState([]);
    const [data, setData] = useState(null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(congdoan_get_list_danh_muc_chi_mam_non());
    }, [dispatch]);

    const getChiMamNons = useCallback(
        (month, year) => {
            setChiMamNons([]);
            setData(null);
            if (userInfo.congDoanId) {
                callApiGetListOfChiMamNon(userInfo.congDoanId, month, year)
                    .then((res) => {
                        setChiMamNons(res.data);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            }
        },
        [userInfo]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            const month = dayjs(values.monthYear).month() + 1;
            const year = dayjs(values.monthYear).year();
            getChiMamNons(month, year);
        }
    }, [values, watches, prevValues, getChiMamNons]);

    const onSubmit = () => {
        if (data && userInfo.congDoanId && values.monthYear) {
            const body = buildBody(data);
            saveAllChiMamNon(body);
        }
    };

    const buildBody = (data) => {
        return [...data.values()].map((item) => {
            return {
                id: get(item, 'dm.id', undefined),
                amount: Number(item.amount),
                notes: item.notes,
                code: item.code,
                danhMucChiMamNonId: item.dmTMNId,
            };
        });
    };

    const saveAllChiMamNon = (body) => {
        const month = dayjs(values.monthYear).month() + 1;
        const year = dayjs(values.monthYear).year();
        dispatch(loading());
        callApiSaveChiMamNon(userInfo.congDoanId, month, year, body)
            .then((res) => {
                toastHelper.success('Lưu chi mầm non thành công!');
                getChiMamNons(month, year);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Chi mầm non</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })}
                                        disabled={data === null}
                                        onClick={onSubmit}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.monthYear && <ChiMamNonList chiMamNons={chiMamNons} dmChiMamNons={dmChiMamNons} setData={setData} monthYear={values.monthYear} data={data} />}
        </>
    );
}

export default MamNonChiMamNonPage;
