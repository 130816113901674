import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

function CongDoanUserChangePassword({ open, closeDialog, onChangePassword, user }) {
    const { register, handleSubmit, errors, setError } = useForm();

    const onSubmit = (data) => {
        if (data.newPassword !== data.confirmPassword) {
            setError('confirmPassword', { message: 'Nhập lại mật khẩu không khớp với mật khẩu mới. Vui lòng nhập lại!' });
        } else {
            onChangePassword(user.username, data.newPassword);
        }
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">Đổi mật khẩu cho cộng đoàn {user.congDoanName}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="newPassword">Mật khẩu mới</label>
                            <input
                                type="password"
                                className={clsx('form-control', errors.newPassword && 'border-danger')}
                                placeholder="Mật khẩu mới"
                                id="newPassword"
                                name="newPassword"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập mật khẩu mới!',
                                    },
                                })}
                            />
                            {errors.newPassword && <div className="text-danger pt-2">{errors.newPassword.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="confirmPassword">Nhập lại mật khẩu mới</label>
                            <input
                                type="password"
                                className={clsx('form-control', errors.confirmPassword && 'border-danger')}
                                placeholder="Nhập lại mật khẩu mới"
                                id="confirmPassword"
                                name="confirmPassword"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập lại mật khẩu mới!',
                                    },
                                })}
                            />
                            {errors.confirmPassword && <div className="text-danger pt-2">{errors.confirmPassword.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CongDoanUserChangePassword;
