import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import { LOAI_THUCHI_DAUNAM_NHIEMKY } from 'config/constants/danhMucThuConstant';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { split } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import './styles.scss';

const EmptyRowsView = () => {
    const message = 'Chưa có danh mục công tác phục vụ nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

const ThuChiCTPVHeader = (props) => {
    return (
        <div>
            {props.index === 0 ? (
                <>
                    <div style={{ width: 146 * props.columns, zIndex: 1, display: 'flex', position: 'relative', justifyContent: 'center', backgroundColor: 'white' }}>
                        <div style={{ left: 230 }}>{props.type}</div>
                    </div>
                </>
            ) : (
                <div style={{ opacity: 0 }}>{props.type}</div>
            )}
            <Divider />
            <div className="text-center">{props.column.name}</div>
        </div>
    );
};

const ThuChiValue = (props) => {
    return (
        <div
            className={clsx({
                'blue': props.type === LOAI_THUCHI_DAUNAM_NHIEMKY.THU,
                'orange': props.type === LOAI_THUCHI_DAUNAM_NHIEMKY.CHI,
                'green': props.type === LOAI_THUCHI_DAUNAM_NHIEMKY.TON,
                'text-right': true,
            })}
        >
            {props.value}
        </div>
    );
};

function MamNonThuDauNamNhiemKyList({ loaiThuChi, thuchis, totalThuChis, danhMucs, data, setData, academicYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        let newData = data ? [...data] : [];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (data === null) {
                newData = [newRows[i]];
            } else {
                const index = newData.findIndex((item) => item.month === newRows[i].month);
                if (index >= 0) {
                    newData[index] = newRows[i];
                } else {
                    newData = [...data, ...[newRows[i]]];
                }
            }
        }
        setRows(newRows);
        setData(newData);
    };

    const columnTotal = useCallback(() => {
        return { key: LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL, name: 'Tổng cộng', editable: true, width: 150 };
    }, []);

    // Just only the TON is dont have column with summable
    // For CHI, need to add additional rows for refund danhmuc
    const buildColumnsByType = useCallback(
        (type) => {
            const result = danhMucs
                .filter((item) => (type === LOAI_THUCHI_DAUNAM_NHIEMKY.TON || type === LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM ? item.summable : true))
                .map((dm) => {
                    const res = [{ key: `${dm.id}_${type}`, name: dm.name, editable: true, width: 150 }];
                    if (type === LOAI_THUCHI_DAUNAM_NHIEMKY.CHI && dm.refund) {
                        res.push({ key: `${dm.id}_${type}_hoantra`, name: dm.name + '(HT)', editable: true, width: 150 });
                    }
                    return res.flat(Infinity);
                });
            return result.flat(Infinity).map((item) => {
                return { ...item, ...{ formatter: <ThuChiValue type={type} /> } };
            });
        },
        [danhMucs]
    );

    const getNumberOfColumnsByType = useCallback(
        (type) => {
            switch (type) {
                case LOAI_THUCHI_DAUNAM_NHIEMKY.THU:
                    return danhMucs.length;
                case LOAI_THUCHI_DAUNAM_NHIEMKY.CHI:
                    return danhMucs.length + danhMucs.filter((dm) => dm.refund).length;
                default:
                    return danhMucs.filter((dm) => dm.summable).length;
            }
        },
        [danhMucs]
    );

    const buildDynamicColumnsForALLType = useCallback(() => {
        return [
            ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.THU).map((item, index) => {
                return { ...item, ...{ headerRenderer: <ThuChiCTPVHeader index={index} type={'Thu'} columns={getNumberOfColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.THU)} /> } };
            }),
            ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.CHI).map((item, index) => {
                return { ...item, ...{ headerRenderer: <ThuChiCTPVHeader index={index} type={'Chi'} columns={getNumberOfColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.CHI)} /> } };
            }),
            ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.TON).map((item, index) => {
                return { ...item, ...{ headerRenderer: <ThuChiCTPVHeader index={index} type={'Tồn'} columns={getNumberOfColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.TON)} /> } };
            }),
        ].map((item) => {
            return { ...item, ...{ editable: false } };
        });
    }, [buildColumnsByType, getNumberOfColumnsByType]);

    const buildColumns = useCallback(() => {
        const defaultColumns = [{ key: 'month', name: 'Tháng', editable: false, frozen: true, width: 80 }];
        switch (loaiThuChi) {
            case LOAI_THUCHI_DAUNAM_NHIEMKY.THU:
                return [...defaultColumns, ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.THU), columnTotal()];
            case LOAI_THUCHI_DAUNAM_NHIEMKY.CHI:
                return [...defaultColumns, ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.CHI), columnTotal()];
            case LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM:
                return [...defaultColumns, ...buildColumnsByType(LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM), columnTotal()];
            default:
                return [...defaultColumns, ...buildDynamicColumnsForALLType(), columnTotal()];
        }
    }, [buildDynamicColumnsForALLType, loaiThuChi, buildColumnsByType, columnTotal]);

    const getTotalValue = useCallback((thuchis, type = LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL) => {
        const row = {};
        const total = thuchis.find((item) => item.type === type);
        row[LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL] = total ? total.value : '';
        return row;
    }, []);

    const buildRowByType = useCallback(
        (thuchis, type) => {
            const row = {};
            const thuList = thuchis ? thuchis.filter((item) => item.type === type) : [];
            danhMucs.forEach((dm) => {
                const matchedThuChi = thuList.find((item) => item.danhMucId === dm.id && !item.refund);
                row[`${dm.id}_${type}`] = matchedThuChi ? matchedThuChi.value : '';
                if (type === LOAI_THUCHI_DAUNAM_NHIEMKY.CHI && dm.refund) {
                    const matchedChiHoanTra = thuList.find((item) => item.danhMucId === dm.id && item.refund);
                    row[`${dm.id}_${type}_hoantra`] = matchedChiHoanTra ? matchedChiHoanTra.value : '';
                }
            });
            return row;
        },
        [danhMucs]
    );

    const buildRowForAll = useCallback(
        (thuchis) => {
            const tonValues = buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TON);
            const chiValues = buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.CHI);
            const thuValues = buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.THU);
            return { ...tonValues, ...thuValues, ...chiValues };
        },
        [buildRowByType]
    );

    const buildTotalRowByType = useCallback(
        (thuchis, type) => {
            const row = {};
            const thuChisByType = thuchis ? thuchis.filter((item) => item.type === type) : [];
            danhMucs.forEach((dm) => {
                if (dm.summable) {
                    const splittedKey = split(type, '_', 2);
                    const matchedThuChi = thuChisByType.find((item) => item.danhMucId === dm.id);
                    row[`${dm.id}_${splittedKey[1]}`] = matchedThuChi ? matchedThuChi.value : '';
                    if (type === LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL_CHI && dm.refund) {
                        const matchedThuChi = thuChisByType.find((item) => item.danhMucId === dm.id && item.refund);
                        row[`${dm.id}_${splittedKey[1]}_hoantra`] = matchedThuChi ? matchedThuChi.value : '';
                    }
                }
            });
            return row;
        },
        [danhMucs]
    );

    const buildTotalRowForAll = useCallback(
        (thuchis) => {
            const tonValues = buildTotalRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL_TON);
            const chiValues = buildTotalRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL_CHI);
            const thuValues = buildTotalRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL_THU);
            return { ...tonValues, ...thuValues, ...chiValues };
        },
        [buildTotalRowByType]
    );

    const buildTotalRow = useCallback(
        (thuchis) => {
            const commonValue = {
                month: 'Tổng cộng',
            };
            const row = buildTotalRowForAll(thuchis);
            return {
                ...commonValue,
                ...row,
                ...getTotalValue(thuchis),
            };
        },
        [buildTotalRowForAll, getTotalValue]
    );

    const buildRow = useCallback(
        (month, thuchis) => {
            const commonValues = { month };
            let row = {};
            switch (loaiThuChi) {
                case LOAI_THUCHI_DAUNAM_NHIEMKY.THU:
                    row = { ...buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.THU) };
                    break;
                case LOAI_THUCHI_DAUNAM_NHIEMKY.CHI:
                    row = { ...buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.CHI) };
                    break;
                case LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM:
                    row = { ...buildRowByType(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM) };
                    break;
                default:
                    row = { ...buildRowForAll(thuchis) };
                    break;
            }
            return { ...commonValues, ...row, ...getTotalValue(thuchis) };
        },
        [loaiThuChi, buildRowForAll, buildRowByType, getTotalValue]
    );

    const buildTonDauNamRowForAll = useCallback(
        (thuchis) => {
            const tempThuChis = [...thuchis];
            return {
                month: 'Tồn đầu năm',
                ...buildRowByType(
                    tempThuChis.map((item) => {
                        return { ...item, ...{ type: LOAI_THUCHI_DAUNAM_NHIEMKY.TON } };
                    }),
                    LOAI_THUCHI_DAUNAM_NHIEMKY.TON
                ),
            };
        },
        [buildRowByType]
    );

    const buildRows = useCallback(() => {
        const months = dateHelper.getMonthYearArrayByAcademicYear(academicYear);
        let rows = [];

        if (loaiThuChi === LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM) {
            rows.push(buildRow(months[0], thuchis ? thuchis.filter((item) => item.yearMonth === months[0]) : []));
        } else {
            if (loaiThuChi === LOAI_THUCHI_DAUNAM_NHIEMKY.ALL) {
                rows.push({
                    ...buildTonDauNamRowForAll(thuchis ? thuchis.filter((item) => item.type === LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM) : []),
                    ...getTotalValue(thuchis, LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL_TONDAUNAM),
                });
            }
            const commonRows = months.map((month) => {
                return buildRow(month, thuchis ? thuchis.filter((item) => item.yearMonth === month) : []);
            });
            rows = [...rows, ...commonRows];
        }

        if (loaiThuChi !== LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM) {
            rows.push(buildTotalRow(totalThuChis));
        }
        return rows;
    }, [academicYear, thuchis, buildRow, buildTotalRow, totalThuChis, loaiThuChi, buildTonDauNamRowForAll, getTotalValue]);

    useEffect(() => {
        setRows([...buildRows()]);
    }, [buildRows, thuchis, totalThuChis]);

    useEffect(() => {
        setColumns([...buildColumns()]);
    }, [buildColumns, thuchis, totalThuChis]);

    const checkEditable = (props) => {
        if (props.column.key === LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL || props.row.month === 'Tổng cộng') {
            return false;
        }
        return props.column.editable;
    };

    return (
        <div id="rdg" className="tcdn-nhiemky-table">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => {
                    return rows[i];
                }}
                rowsCount={rows.length}
                minHeight={tableHeight}
                onGridRowsUpdated={onGridRowsUpdated}
                enableCellSelect={true}
                headerRowHeight={60}
                emptyRowsView={EmptyRowsView}
                onCheckCellIsEditable={checkEditable}
                css
            />
        </div>
    );
}

export default MamNonThuDauNamNhiemKyList;
