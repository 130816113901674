export const congDoanType = {
    TRUNG_UONG: 'TRUNGUONG',
    NOI_TRU: 'NOITRU',
    MAM_NON: 'MAMNON',
    HUAN_LUYEN: 'HUANLUYEN',
    CONG_DOAN_ONLY: 'CONGDOANONLY',
    NOI_TRU_MAM_NON: 'NOITRUMAMNON',
    VUNG_ADMIN: 'VUNGADMIN',
};

export const suVus = [
    { value: congDoanType.NOI_TRU, label: 'Nội Trú' },
    { value: congDoanType.MAM_NON, label: 'Mầm Non' },
    { value: congDoanType.HUAN_LUYEN, label: 'Huấn luyện' },
    { value: congDoanType.NOI_TRU_MAM_NON, label: 'Mầm non - Nội trú ' },
    { value: congDoanType.CONG_DOAN_ONLY, label: 'Không có sứ vụ GD' },
];
