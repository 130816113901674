import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getCongDoanReportOverview(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetCongDoanReportOverview, action.payload.data.yearMonth);
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_OVERVIEW_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__CALL_FAILED,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetCongDoanReportOverview(yearMonth) {
    return transport.get(`/trunguong/cong-doan-report`, { params: { yearMonth } });
}
