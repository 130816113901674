import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_list_danh_muc_thu_mam_non } from 'components/mam-non-page/actions';
import { CALCULATION_TYPES } from 'config/constants/danhMucThuConstant';
import { errorHelper } from 'content/message/errorMessages';
import { toastHelper } from 'helpers/toastHelper';
import { get } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiCreateDanhMucThu } from 'redux/saga/mam-non/info/danh-muc-thu/createDanhMucThu';
import { callApiUpdateDanhMucThu } from 'redux/saga/mam-non/info/danh-muc-thu/updateDanhMucThu';

function DanhMucThuInfoDialog({ danhMucThu, danhMucThus, open, closeDialog, onSuccess }) {
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const thuMamNons = useSelector((state) => state.commonReducer.dmThuMamNons, []);

    const calculationTypeOptions = CALCULATION_TYPES.map((type) => {
        return { value: type.key, label: type.label };
    });

    const parentDMTOptions = danhMucThus
        .filter((dm) => dm.id !== danhMucThu.id && dm.parentId === null)
        .map((dm) => {
            return { value: dm.id, label: dm.name };
        });

    const thuMamNonOptions = thuMamNons
        ? thuMamNons.map((tmn) => {
              return {
                  value: tmn,
                  label: tmn.name,
              };
          })
        : [];

    const getDefaultParent = () => {
        const matchedOptions = parentDMTOptions.filter((option) => option.value === danhMucThu.parentId);
        return matchedOptions.length > 0 ? matchedOptions[0] : '';
    };

    const getDefaultDMTMamNon = () => {
        if (danhMucThu.dmThuMamNon) {
            const matchedOptions = thuMamNonOptions.filter((option) => option.value.code === danhMucThu.dmThuMamNon.code);
            return matchedOptions.length > 0 ? matchedOptions[0] : '';
        }
        return '';
    };

    const getDefaultCalculationType = () => {
        return danhMucThu.calculationType ? calculationTypeOptions.filter((option) => option.value === danhMucThu.calculationType)[0] : calculationTypeOptions[0];
    };

    const defaultValues = {
        name: danhMucThu.name,
        isCurrentUse: danhMucThu.isCurrentUse,
        isMandantory: danhMucThu.isMandantory,
        isAbsentCount: danhMucThu.isAbsentCount,
        calculationType: getDefaultCalculationType(),
        parent: getDefaultParent(),
        dmThuMamNon: getDefaultDMTMamNon(),
    };

    const { register, handleSubmit, errors, control, setValue, watch } = useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo.congDoanId) {
            dispatch(congdoan_get_list_danh_muc_thu_mam_non({ mappingFromMamNon: true }));
        }
    }, [dispatch, userInfo]);

    useEffect(() => {
        setValue('dmThuMamNon', defaultValues.dmThuMamNon);
    }, [setValue, defaultValues.dmThuMamNon]);

    const watchParent = watch('parent');

    const onSubmit = (data) => {
        const body = {
            ...data,
            ...{ calculationType: data.calculationType.value, parentId: get(data, 'parent.value', undefined), dmThuMamNon: get(data, 'dmThuMamNon.value', undefined) },
        };
        if (userInfo) {
            if (danhMucThu.id) {
                editDMThu(body);
            } else {
                addDMThu(body);
            }
        }
    };

    const addDMThu = (data) => {
        callApiCreateDanhMucThu(userInfo.congDoanId, data).then((res) => {
            onSuccess(res.data);
            closeDialog();
        });
    };

    const editDMThu = (data) => {
        callApiUpdateDanhMucThu(userInfo.congDoanId, danhMucThu.id, data)
            .then((res) => {
                onSuccess(res.data);
                closeDialog();
            })
            .catch((err) => {
                const messageKey = get(err, 'response.data.messageKey', null);
                toastHelper.error(errorHelper.getErrorMessageByKey(messageKey));
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{danhMucThu.id ? 'Chỉnh sửa danh mục thu' : 'Thêm danh mục'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="name">Tên danh mục</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Tên danh mục"
                                defaultValue={defaultValues.name}
                                id="name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên danh mục!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="isCurrentUse">
                                <input className="mr-2" type="checkbox" defaultChecked={defaultValues.isCurrentUse} name="isCurrentUse" id="isCurrentUse" ref={register} />
                                Đang sử dụng
                            </label>
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="isMandantory">
                                <input className="mr-2" type="checkbox" defaultChecked={defaultValues.isMandantory} name="isMandantory" id="isMandantory" ref={register} />
                                Bắt buộc
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="isAbsentCount">
                                <input className="mr-2" type="checkbox" defaultChecked={defaultValues.isAbsentCount} name="isAbsentCount" id="isAbsentCount" ref={register} />
                                Trừ tiền khi nghỉ
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="calculationType">Hình thức thu</label>
                            <Controller
                                as={
                                    <Select
                                        styles={{
                                            menu: (provided) => ({ ...provided, zIndex: 9999, position: 'fixed', top: 'unset', width: 260 }),
                                            control: (provided) => ({ ...provided, borderColor: errors && errors.calculationType ? 'red' : provided.borderColor }),
                                        }}
                                    />
                                }
                                name="calculationType"
                                defaultValue={defaultValues.calculationType}
                                options={calculationTypeOptions}
                                isClearable
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Vui lòng chọn hình thức thu',
                                    },
                                }}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="parent">Danh mục miễn giảm</label>
                            <Controller
                                as={
                                    <Select
                                        styles={{
                                            menu: (provided) => ({ ...provided, zIndex: 9999, position: 'fixed', top: 'unset', width: 260, maxHeight: 160 }),
                                            menuList: (provided) => ({ ...provided, maxHeight: 160 }),
                                        }}
                                    />
                                }
                                defaultValue={defaultValues.parent}
                                options={parentDMTOptions}
                                name="parent"
                                isClearable
                                control={control}
                            />
                        </div>
                    </div>
                    {(watchParent === '' || watchParent === null || watchParent === undefined) && (
                        <div className={clsx('row')}>
                            <div className="col-md-6 form-group">
                                <label htmlFor="dmThuMamNon">Mục thu mầm non</label>
                                <Controller
                                    as={
                                        <Select
                                            styles={{
                                                menu: (provided) => ({ ...provided, zIndex: 9999, position: 'fixed', top: 'unset', width: 260, maxHeight: 160 }),
                                                menuList: (provided) => ({ ...provided, maxHeight: 160 }),
                                            }}
                                        />
                                    }
                                    defaultValue={defaultValues.dmThuMamNon.value}
                                    options={thuMamNonOptions}
                                    name="dmThuMamNon"
                                    isClearable
                                    control={control}
                                />
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default DanhMucThuInfoDialog;
