import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { get } from 'lodash';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { callApiUpdateDangKyMonHoc } from 'redux/saga/mam-non/info/dang-ki-mon-hoc/updateDangKiMonHoc';

function UpdateDangKyMonHocjDialog({ student, registrations, open, closeDialog, onSuccess, clazzId }) {
    const { handleSubmit, control } = useForm();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);

    const onSubmit = (data) => {
        const body = [];
        Object.entries(data).forEach(([k, v]) => {
            body.push({ duThuId: k.split('_')[1], ngayDangKy: v ? dateHelper.formatDate(new Date(v), FMI_SERVER_DATE_FORMAT) : null });
        });

        const congDoanId = get(authenticationReducer, 'userInfo.congDoanId', undefined);

        if (congDoanId && clazzId) {
            callApiUpdateDangKyMonHoc(authenticationReducer.userInfo.congDoanId, clazzId, student.id, body)
                .then((res) => {
                    onSuccess();
                    toastHelper.success('Chỉnh sửa đăng ký thành công!');
                })
                .catch((err) => {
                    messageHelper.showError(err);
                });
        }
    };
    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    Chỉnh sửa ngày đăng ký môn học của học sinh {student.lastName} {student.firstName}
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">Tên môn học</div>
                        <div className="col-md-6 form-group">Ngày đăng ký</div>
                    </div>
                    {registrations?.map((registration) => (
                        <div className="row my-3">
                            <div className="col-md-6">{registration.subjectName}</div>
                            <div className="col-md-6">
                                <Controller
                                    control={control}
                                    id={`subject_${registration.subjectId}`}
                                    name={`subject_${registration.subjectId}`}
                                    defaultValue={registration.ngayDangKy}
                                    render={(props) => {
                                        return (
                                            <ReactDatePicker
                                                placeholderText="Chọn ngày"
                                                className="form-control"
                                                onChange={(e) => props.onChange(e)}
                                                dateFormat="dd.MM.yyyy"
                                                value={new Date(registration.ngayDangKy)}
                                                locale="vi"
                                                selected={props.value ? new Date(props.value) : null}
                                                popperProps={{
                                                    positionFixed: true,
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default UpdateDangKyMonHocjDialog;
