import FMI_ACTIONS from '../actions';

const commonReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case FMI_ACTIONS.ACADEMIC_YEAR__GET_LIST_SUCCESS: {
            return {
                ...state,
                ...{ academicYears: payload.data },
            };
        }
        case FMI_ACTIONS.ACADEMIC_YEAR__GET_FIRST_MONTH_SUCCESS: {
            return {
                ...state,
                ...{ academicYearFirstMonth: payload.data },
            };
        }
        case FMI_ACTIONS.NUN__GET_LIST_SUCCESS: {
            return {
                ...state,
                ...{ nuns: payload.data },
            };
        }
        case FMI_ACTIONS.NUN__GET_NUMBER_OF_NUNS_SUCCESS: {
            return {
                ...state,
                ...{ numberOfNuns: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_PAYMENT_INFO_SUCCESS: {
            return {
                ...state,
                ...{ paymentInfo: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_MAM_NON_SUCCESS: {
            return {
                ...state,
                ...{ dmThuMamNons: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_MAM_NON_SUCCESS: {
            return {
                ...state,
                ...{ dmChiMamNons: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN_SUCCESS: {
            return {
                ...state,
                ...{ dmChiCongDoans: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN_SUCCESS: {
            return {
                ...state,
                ...{ dmThuCongDoans: payload.data },
            };
        }
        case FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_CHI_SUCCESS: {
            return {
                ...state,
                ...{ dmChiVungs: payload.data },
            };
        }
        case FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_THU_SUCCESS: {
            return {
                ...state,
                ...{ dmThuVungs: payload.data },
            };
        }
        case FMI_ACTIONS.NOITRU_GET_DANH_MUC_CHI_SUCCESS: {
            return {
                ...state,
                ...{ dmChiNoiTrus: payload.data },
            };
        }
        case FMI_ACTIONS.NOITRU_GET_DANH_MUC_THU_SUCCESS: {
            return {
                ...state,
                ...{ dmThuNoiTrus: payload.data },
            };
        }
        case FMI_ACTIONS.NOITRU__GET_NUMBER_OF_STUDENTS_SUCCESS: {
            return {
                ...state,
                ...{ noiTruNumberOfStudents: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM_SUCCESS: {
            return {
                ...state,
                ...{ dmThuChiDauNams: payload.data },
            };
        }
        case FMI_ACTIONS.CONGDOAN_GET_INFO_SUCCESS: {
            return {
                ...state,
                ...{ congDoanInfo: payload.data },
            };
        }
        case FMI_ACTIONS.TRUNG_UONG__GET_LIST_CONG_DOAN_USERS_SUCCESS: {
            return {
                ...state,
                ...{ congDoanUsers: payload.data },
            };
        }
        case FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_OVERVIEW_SUCCESS: {
            return {
                ...state,
                ...{ congDoanReportOverview: payload.data },
            };
        }
        case FMI_ACTIONS.TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW_SUCCESS: {
            return {
                ...state,
                ...{ duThuChiCongDoanReportOverview: payload.data },
            };
        }
        case FMI_ACTIONS.TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI_SUCCESS: {
            return {
                ...state,
                ...{ mamNonBaoCaoSai: payload.data },
            };
        }
        case FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI_SUCCESS: {
            return {
                ...state,
                ...{ congDoanBaoCaoSai: payload.data },
            };
        }
        case FMI_ACTIONS.LOADING_STATUS__LOADING: {
            return {
                ...state,
                ...{ loading: true },
            };
        }
        case FMI_ACTIONS.LOADING_STATUS__LOADED: {
            return {
                ...state,
                ...{ loading: false },
            };
        }
        default:
            return state;
    }
};

export default commonReducer;
