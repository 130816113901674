import FMI_ACTIONS from '../actions';

const mamnonReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case FMI_ACTIONS.ACADEMIC_YEAR__GET_LIST_SUCCESS: {
            return {
                ...state,
                ...{ academicYears: payload.data },
            };
        }
        case FMI_ACTIONS.MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY_SUCCESS: {
            return {
                ...state,
                ...{ danhMucThuChiDauNamNhiemKys: payload.data },
            };
        }
        default:
            return state;
    }
};

export default mamnonReducer;
