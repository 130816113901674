import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { monthsOfYear } from 'config/constants/monthsConstants';
import { dateHelper } from 'helpers/dateHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { callApiGetClazzHolidays } from 'redux/saga/mam-non/info/class/getHolidaysOfClass';
import { loaded, loading } from 'components/mam-non-page/actions';
import Holidays from '../holiday/holidays/Holidays';
import { callApiSaveHolidaysOfClass } from 'redux/saga/mam-non/info/class/saveHolidaysOfClass';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';

function ClazzHolidayDialog({ clazz, open, closeDialog, academicYear }) {
    const { control, setValue, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const [holidaysSubmitted, setHolidaySubmitted] = useState('');
    const [holidays, setHolidays] = useState(null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.month) {
            getHolidayForClazz(clazz.id, values.month);
        }
    }, [values, watches, prevValues, clazz.id]);

    const getHolidayForClazz = (clazzId, month) => {
        setHolidays(null);
        callApiGetClazzHolidays(clazzId, month)
            .then((res) => {
                setHolidays(res.data);
                setHolidaySubmitted(res.data.join(','));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const monthOptions = monthsOfYear.map((item) => {
        return { value: item, label: 'Tháng ' + item };
    });

    const onMonthChange = (selectedOption) => {
        setValue('month', selectedOption.value);
    };

    const onChangeHoliday = (holidaysAsString) => {
        setHolidaySubmitted(holidaysAsString);
    };

    const saveClazzHolidays = () => {
        dispatch(loading());
        callApiSaveHolidaysOfClass(clazz.id, values.month, holidaysSubmitted)
            .then((res) => {
                toastHelper.success(`Lưu ngày nghỉ cho lớp ${clazz.name} thành công`);
                closeDialog();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const holidayStyles = {
        holidayFontSize: 20,
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <DialogTitle id="form-dialog-title">
                <h3>Cập nhật ngày nghỉ lớp {clazz.className}</h3>
            </DialogTitle>
            <DialogContent id="form-dialog-content">
                <form>
                    <div className="row mx-0 mb-2">
                        <div className="col-md-6">
                            <label className="form-label font-weight-bold" htmlFor="month">
                                Tháng:
                            </label>
                            <Controller
                                name="month"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                    },
                                }}
                                render={() => {
                                    return (
                                        <Select
                                            onChange={onMonthChange}
                                            options={monthOptions}
                                            menuPortalTarget={document.querySelector('body')}
                                            // menuIsOpen={true}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 13 }),
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                </form>
                {holidays && values.month && (
                    <div className="mt-4 clazz-holiday">
                        <Holidays
                            holidays={holidays}
                            month={getValues()['month']}
                            year={dateHelper.getYearFromAcademicYearAndMonth(academicYear, getValues()['month'])}
                            onChangeHoliday={onChangeHoliday}
                            styles={holidayStyles}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                    Đóng
                </button>
                <button type="button" className="btn btn-primary" onClick={saveClazzHolidays}>
                    Lưu
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default ClazzHolidayDialog;
