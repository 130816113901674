import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { monthsOfYear } from 'config/constants/monthsConstants';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { callApiGetInvoicePayments } from 'redux/saga/mam-non/thu-chi/thu-tien/getInvoicePayment';
import { academic_year__get_list, loaded, loading } from '../actions';
import ThuTienTable from './ThuTienTable';

function MamNonThuTienPage() {
    const theme = useTheme();
    const { control, errors, setValue, getValues, trigger, watch } = useForm();

    const dispatch = useDispatch();
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [academicYear, setAcademicYear] = useState(null);
    const [clazzes, setClazzes] = useState([]);
    const [classInvoice, setClassInvoice] = useState(null);
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getYear = useCallback(() => {
        const yearAsArray = academicYear.split('-');
        if (values.month > 5) {
            return yearAsArray[0];
        } else {
            return yearAsArray[1];
        }
    }, [values.month, academicYear]);

    const getInvoice = useCallback(() => {
        setClassInvoice(null);
        dispatch(loading());
        callApiGetInvoicePayments(authenticationReducer.userInfo.congDoanId, values.clazz.id, values.month, getYear())
            .then((res) => {
                setClassInvoice(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [authenticationReducer.userInfo, values.clazz, values.month, getYear, dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.month && values.clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            getInvoice();
        }
    }, [values, watches, prevValues, authenticationReducer, dispatch, getInvoice]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setAcademicYear(selectedOption.value);
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    const onClazzChange = (selectedOption) => {
        setValue('clazz', selectedOption.value);
        trigger('clazz');
    };

    const monthOptions = monthsOfYear.map((item) => {
        return { value: item, label: 'Tháng ' + item };
    });

    const onMonthChange = (selectedOption) => {
        setValue('month', selectedOption.value);
        trigger('month');
    };

    const onPaidSuccess = () => {
        getInvoice();
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Thu tiền</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="month">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="month"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={() => {
                                                return <Select onChange={onMonthChange} options={monthOptions} classNamePrefix="fmi-react-select" styles={customSelectStyles} />;
                                            }}
                                        />
                                        {errors.month && <span className="text-danger">{errors.month.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="academicYear">
                                        Khối:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={grades.map((item) => {
                                                return { value: item, label: item.name };
                                            })}
                                            id="academicYear"
                                            onChange={onGradeChange}
                                            value={grade}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label">Lớp:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="clazz"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onClazzChange}
                                                        options={clazzes.map((item) => {
                                                            return { value: item, label: item.className };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {getValues()['month'] && academicYear && classInvoice && (
                <ThuTienTable
                    invoices={classInvoice}
                    classId={values.clazz.id}
                    onPaidSuccess={onPaidSuccess}
                    monthYear={dayjs(`${getYear()}-${getValues()['month']}-01`, 'YYYY-M-DD')}
                ></ThuTienTable>
            )}
        </>
    );
}

export default MamNonThuTienPage;
