import { Box, Button, Checkbox, Container, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from '@material-ui/core';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ClazzQuery from './ClazzQuery';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { blue, green } from '@material-ui/core/colors';
import { callApiTransferStudents } from 'redux/saga/mam-non/info/class/student/transferStudents';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { useDispatch } from 'react-redux';
import { loaded, loading } from 'components/mam-non-page/actions';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [theme.breakpoints.up(1280 + theme.spacing(2) * 2)]: {
            width: '75%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        width: 400,
        height: 400,
        overflow: 'auto',
        marginTop: 10,
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    secondaryBtn: {
        color: blue[500],
        backgroundColor: '#ffee58',
    },
    saveBtn: {
        'color': 'white',
        'backgroundColor': green[400],
        '&:hover': {
            backgroundColor: green[400],
        },
    },
    listItem: {
        height: 50,
    },
}));

function not(a, b) {
    if (!a || !b) {
        return [];
    }
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    if (!a || !b) {
        return [];
    }
    return a.filter((value) => b.indexOf(value) !== -1);
}

function MamNonClazzUpgradePage() {
    const classes = useStyles();
    const theme = useTheme();
    const [fromClazz, setFromClazz] = useState(null);
    const [toClazz, setToClazz] = useState(null);
    const [checked, setChecked] = useState([]);
    const [dbFromStudents, setDbFromStudents] = useState(null);
    const [fromStudents, setFromStudents] = useState(null);
    const [toStudents, setToStudents] = useState(null);
    const [transferStudents, setTransferStudents] = useState(null);
    const [forceRender, setForceRender] = useState(0);
    const dispatch = useDispatch();

    const fromChecked = intersection(checked, fromStudents);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const transferFromCheckedStudents = (list) => {
        const convertedList = list
            .sort((a, b) => a.stt - b.stt)
            .map((item, index) => {
                return { ...item, ...{ stt: toStudents.length + index + 1 } };
            });
        return convertedList;
    };

    const handleAllRight = () => {
        setToStudents(toStudents.concat(transferFromCheckedStudents(fromStudents)));
        setTransferStudents(dbFromStudents);
        setFromStudents([]);
    };

    const handleCheckedRight = () => {
        setToStudents(toStudents.concat(transferFromCheckedStudents(fromChecked)));
        setFromStudents(not(fromStudents, fromChecked));
        if (transferStudents) {
            setTransferStudents([...transferStudents, ...fromChecked]);
        } else {
            setTransferStudents(fromChecked);
        }
        setChecked([]);
    };

    const onGetFromStudentsSuccess = (data) => {
        setFromStudents(data);
        setDbFromStudents(data);
    };

    const onReset = () => {
        setTransferStudents(null);
        setForceRender(forceRender + 1);
    };

    const onSave = () => {
        if (fromClazz && toClazz && transferStudents) {
            dispatch(loading());
            callApiTransferStudents(fromClazz.id, { studentIds: transferStudents.map((item) => item.id) }, toClazz.id)
                .then((res) => {
                    toastHelper.success('Chuyển học sinh thành công!');
                    onReset();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const customList = (items, enableSelect = true) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.id}-label`;
                    return (
                        <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} className={classes.listItem}>
                            {enableSelect && (
                                <ListItemIcon>
                                    <Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemIcon>
                            )}
                            <Grid container id={labelId}>
                                <Grid item xs={1}>
                                    <ListItemText primary={value.stt} />
                                </Grid>
                                <Grid item xs={8}>
                                    <ListItemText primary={value.lastName} />
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText primary={value.firstName} />
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Container maxWidth="md">
            <Box p={theme.spacing(1, 0)}>
                <Typography variant="h6">Lên lớp - Xếp lớp</Typography>
            </Box>
            <Grid container spacing={2} justifycontent="center" alignItems="center" className={classes.root}>
                <Grid item>
                    <ClazzQuery students={fromStudents} setStudents={onGetFromStudentsSuccess} setClazz={setFromClazz} forceRender={forceRender} />
                    {toStudents && fromStudents && customList(fromStudents)}
                </Grid>
                <Grid item>
                    {toStudents && fromStudents && (
                        <Grid container direction="column" alignItems="center">
                            <Button variant="contained" color="primary" title="Chuyển tất cả" size="small" className={classes.button} onClick={handleAllRight} aria-label="move all right">
                                <DoubleArrowIcon />
                            </Button>
                            <Button variant="contained" color="primary" title="Chuyển" size="small" className={classes.button} onClick={handleCheckedRight} aria-label="move selected right">
                                <ChevronRightIcon />
                            </Button>
                            <Button variant="contained" title="Khởi tạo lại" size="small" className={clsx(classes.button, classes.secondaryBtn)} onClick={onReset} aria-label="reset">
                                <RotateLeftIcon />
                            </Button>
                            <Button variant="contained" title="Lưu" size="small" className={clsx(classes.button, classes.saveBtn)} onClick={onSave} aria-label="reset">
                                <SaveIcon />
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    <ClazzQuery students={toStudents} setStudents={setToStudents} setClazz={setToClazz} forceRender={forceRender} />
                    {toStudents && fromStudents && customList(toStudents, false)}
                </Grid>
            </Grid>
        </Container>
    );
}

export default MamNonClazzUpgradePage;
