import FMI_ACTIONS from 'redux/actions';

export const noitru_get_list_danh_muc_chi_noi_tru = () => {
    return {
        type: FMI_ACTIONS.NOITRU_GET_DANH_MUC_CHI,
    };
};

export const noitru_get_list_danh_muc_thu_noi_tru = () => {
    return {
        type: FMI_ACTIONS.NOITRU_GET_DANH_MUC_THU,
    };
};

export const noitru__get_number_of_students = (data) => {
    return { type: FMI_ACTIONS.NOITRU__GET_NUMBER_OF_STUDENTS, payload: { data } };
};
