import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Chưa có năm học nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ExchangeRateList({ academicYears, setData }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        academicYears.forEach((academicYear) => {
            const row = {
                academicYearName: academicYear.name,
                usdExchangeRate: academicYear.usdExchangeRate,
                academicYear,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [academicYears]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'academicYearName', name: 'Năm học', width: 200 },
            { key: 'usdExchangeRate', name: 'USD -> VND', editable: true, width: 200 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
        setData(newRows);
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                emptyRowsView={EmptyRowsView}
                onGridRowsUpdated={onGridRowsUpdated}
                enableCellSelect={true}
            />
        </div>
    );
}

export default ExchangeRateList;
