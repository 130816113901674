import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactDataGrid from 'react-data-grid';
import DanhMucThuInfoDialog from './DanhMucThuInfoDialog';
import { callApiDeleteDanhMucThu } from 'redux/saga/mam-non/info/danh-muc-thu/deleteDanhMucThu';
import CheckBoxFormatter from 'components/shared/react-data-grid/CheckBoxFormatter';
import './styles.scss';
import { alert } from 'helpers/alertHelper';
import { useSelector } from 'react-redux';
import { CALCULATION_TYPES } from 'config/constants/danhMucThuConstant';
import { get } from 'lodash';
import { RDG_CHECK_BOX_COLUMN_WIDTH, RDG_HEADER_ROW_HEIGHT } from 'config/constants/reactDataGridConstant';
import { CustomHeader } from 'components/mam-non-page/thu-tien/ThuTienTable';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';

const EmptyRowsView = () => {
    const message = 'Chưa có danh mục thu nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function DanhMucThuList({ danhMucThus, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedDanhMucThu, setSelectedDanhMucThu] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedDanhMucThu(null);
        setOpen(false);
    };

    const getParentName = useCallback(
        (parentId) => {
            const matchedDMs = danhMucThus.filter((dm) => dm.id === parentId);
            return matchedDMs.length > 0 ? matchedDMs[0].name : '';
        },
        [danhMucThus]
    );

    const getCalculationTypeLabel = useCallback((calculationType) => {
        const matchedType = CALCULATION_TYPES.filter((item) => item.key === calculationType);
        return matchedType.length > 0 ? matchedType[0].label : '';
    }, []);

    useEffect(() => {
        const rows = [];
        danhMucThus.forEach((dm, index) => {
            const row = {
                index: index + 1,
                id: dm.id,
                name: dm.name,
                currentUse: dm.isCurrentUse,
                mandatory: dm.isMandantory,
                isAbsentCount: dm.isAbsentCount,
                calculationType: getCalculationTypeLabel(dm.calculationType),
                parent: getParentName(dm.parentId),
                dmThuMamNon: get(dm, 'dmThuMamNon.name', ''),
                danhMucThu: dm,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [danhMucThus, getParentName, getCalculationTypeLabel]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', width: 50 },
            { key: 'name', name: 'Tên danh mục', width: 250 },
            { key: 'currentUse', name: 'Đang sử dụng', formatter: CheckBoxFormatter, width: RDG_CHECK_BOX_COLUMN_WIDTH, headerRenderer: <CustomHeader /> },
            { key: 'mandatory', name: 'Bắt buộc', formatter: CheckBoxFormatter, width: RDG_CHECK_BOX_COLUMN_WIDTH, headerRenderer: <CustomHeader /> },
            { key: 'isAbsentCount', name: 'Trừ tiền khi nghỉ', formatter: CheckBoxFormatter, width: RDG_CHECK_BOX_COLUMN_WIDTH, headerRenderer: <CustomHeader /> },
            { key: 'calculationType', name: 'Hình thức thu', width: 150 },
            { key: 'parent', name: 'Danh mục miễn giảm', width: 250 },
            { key: 'dmThuMamNon', name: 'Mục thu', width: 250 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onEditDanhMucThuSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedDanhMucThu(row.danhMucThu);
                    showConfirmDialog(row.danhMucThu, row.index - 1);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedDanhMucThu(row.danhMucThu);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem, index) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá danh mục thu này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                if (userInfo.congDoanId && selectedItem.id) {
                    callApiDeleteDanhMucThu(userInfo.congDoanId, selectedItem.id)
                        .then((res) => {
                            onDeleteSuccess(index);
                            alert({ icon: 'success', title: 'Xoá danh mục thu thành công', showConfirmButton: false });
                        })
                        .catch((error) => {
                            if (error.response.status === 400) {
                                alert({
                                    icon: 'error',
                                    title: 'Không thể xoá danh mục',
                                    text: 'Danh mục thu này đã được sử dụng bởi một dự thu. Vui lòng xoá dự thu trước khi tiếp tục thao tác.',
                                });
                            } else {
                                alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá danh mục thu! Vui lòng thử lại sau.' });
                            }
                        });
                }
            }
        });
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                headerRowHeight={RDG_HEADER_ROW_HEIGHT}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedDanhMucThu && (
                <DanhMucThuInfoDialog danhMucThus={danhMucThus} danhMucThu={selectedDanhMucThu} open={open} closeDialog={closeDialog} onSuccess={onEditDanhMucThuSuccess} />
            )}
        </div>
    );
}

export default DanhMucThuList;
