import { academic_year__get_first_month } from 'components/authentication/actions';
import { TON_THANG_TRUOC_NOI_TRU_CODE } from 'config/constants/danhMucThuConstant';
import { FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';

const EmptyRowsView = () => {
    const message = 'Chưa có mục thu nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ThuNoiTruList({ danhMucThuNoiTrus, thuNoiTrus, data, setData, monthYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    const dispatch = useDispatch();

    const academicYearFirstMonth = useSelector((state) => state.commonReducer.academicYearFirstMonth, {});
    useEffect(() => {
        dispatch(academic_year__get_first_month({ yearMonth: dayjs(monthYear).format(FMI_YEAR_MONTH_FORMAT) }));
    }, [dispatch, monthYear]);

    const isFirstMonthOfSemester = academicYearFirstMonth ? dayjs(monthYear).isSame(dayjs(academicYearFirstMonth)) : false;

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        if (danhMucThuNoiTrus) {
            danhMucThuNoiTrus.forEach((dm) => {
                const matchedThuNoiTru = thuNoiTrus.find((chiNoiTru) => chiNoiTru.code === dm.code);
                const row = {
                    dmId: dm.id,
                    code: dm.code,
                    name: dm.name,
                    amount: matchedThuNoiTru ? matchedThuNoiTru.amount : '',
                    usdAmount: matchedThuNoiTru ? matchedThuNoiTru.usdAmount : '',
                    eurAmount: matchedThuNoiTru ? matchedThuNoiTru.eurAmount : '',
                    ngoaiTeAmount: matchedThuNoiTru ? matchedThuNoiTru.ngoaiTeAmount : '',
                    notes: matchedThuNoiTru ? matchedThuNoiTru.notes : '',
                    collectDate: matchedThuNoiTru ? dateHelper.formatDate(matchedThuNoiTru.collectDate) : '',
                    dm: matchedThuNoiTru,
                };
                rows.push(row);
            });
            setRows(rows);
        }
    }, [danhMucThuNoiTrus, thuNoiTrus]);

    useEffect(() => {
        // const readOnly = dateHelper.isAfterNextMonth10thDate(monthYear);
        const columns = [
            { key: 'code', name: 'Mã số', editable: false, width: 60 },
            { key: 'name', name: 'Diễn giải', editable: false, width: 360 },
            { key: 'amount', name: 'VND (ĐV: 1000đ)', editable: true, width: 180 },
            { key: 'usdAmount', name: 'USD', editable: true, width: 80 },
            { key: 'eurAmount', name: 'EUR', editable: true, width: 80 },
            { key: 'ngoaiTeAmount', name: 'Ngoại tệ khác', editable: true, width: 120 },
            { key: 'notes', name: 'Ghi chú', editable: true, width: 200 },
            { key: 'collectDate', name: 'Ngày ghi', editable: false, width: 200 },
        ];
        setColumns(columns);
    }, [monthYear]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
        const newData = new Map(data);
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (get(newRows[i], 'dm.id', false) || updated[cellKey] !== '') {
                newData.set(i, newRows[i]);
            }
        }
        setRows(newRows);
        setData(newData);
    };

    const checkEditable = (props) => {
        return props.column.editable && (isFirstMonthOfSemester || !TON_THANG_TRUOC_NOI_TRU_CODE.includes(props.row.code));
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onCheckCellIsEditable={checkEditable}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default ThuNoiTruList;
