import clsx from 'clsx';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import CheckBoxFormatter from 'components/shared/react-data-grid/CheckBoxFormatter';
import { RDG_CHECK_BOX_COLUMN_WIDTH } from 'config/constants/reactDataGridConstant';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { isNumber } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Editors } from 'react-data-grid-addons';
import { callApiAddOffOneMonthForStudent } from 'redux/saga/mam-non/attendance/absence/addOffOneMonthForStudent';
import { callApiDeleteOffOneMonthForStudent } from 'redux/saga/mam-non/attendance/absence/deleteOffOneMonthForStudent';
import './styles.scss';
import { useSelector } from 'react-redux';

const { DropDownEditor } = Editors;
const reasons = [
    { id: 'K', value: 'K' },
    { id: 'P', value: 'P' },
    { id: '+', value: '+' },
];
const AttendanceReasonEditor = <DropDownEditor options={reasons} />;

const AttendanceValue = (props) => {
    const weekDayIndex = dayjs(new Date(props.year, props.selectedMonth - 1, props.dayIndex)).day();
    return (
        <div
            className={clsx({
                'text-center': true,
                'saturday': weekDayIndex === 6,
                'sunday': weekDayIndex === 0,
                'empty-cell': props.value === '',
            })}
        >
            {props.value}
        </div>
    );
};

const DayOffHeader = (props) => {
    const day = new Date(Number(props.year), props.selectedMonth - 1, props.column.name);
    return (
        <div className="d-flex align-items-center h-100">
            <div className="day-off-header">
                <div>{props.column.name}</div>
                <div className="weekday">{dateHelper.getWeekDayOfDateAsString(day)}</div>
            </div>
        </div>
    );
};

const FixedHeader = (props) => {
    return (
        <div className="frozen-header">
            <span>{props.column.name}</span>
        </div>
    );
};

export const CustomHeader = (props) => {
    return <div style={{ whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center', height: '100%' }}>{props.column.name}</div>;
};

const EmptyRowsView = () => {
    const message = 'Chưa có học sinh nào trong lớp';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

const RowRenderer = ({ renderBaseRow, ...props }) => {
    const isActive = props.row.offOneMonth === null;
    return <div style={{ opacity: isActive ? 1 : 0.4 }}>{renderBaseRow(props)}</div>;
};

function AttendanceForm({ attendanceInfo, selectedMonth = 1, setAttendanceData, academicYear, clazzId, onChangeOffOneMonthSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableHeight, setTableHeight] = useState();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const year = dateHelper.isCongDoanSaiGonAndSpecialCaseFor2022(authenticationReducer.userInfo.congDoanId, academicYear, selectedMonth)
        ? 2022
        : selectedMonth > 5
        ? academicYear.split('-')[0]
        : academicYear.split('-')[1];

    console.log(year);

    const daysInMonth = useCallback(() => {
        const month = dayjs(new Date(Number(year), selectedMonth - 1, 1));
        return [...Array.from(Array(dayjs(month).daysInMonth()).keys(), (x) => x + 1)];
    }, [selectedMonth, year]);

    useEffect(() => {
        const rows = [];
        const students = attendanceInfo.students;
        const holidays = attendanceInfo.holidays;
        const month = dayjs(new Date(Number(year), selectedMonth - 1, 1));
        const daysInMonth = [...Array.from(Array(dayjs(month).daysInMonth()).keys(), (x) => x + 1)];
        dayjs.extend(duration);
        students.forEach((student, index) => {
            const row = {
                index: student.stt,
                firstName: student.firstName,
                lastName: student.lastName,
                entryDate: dateHelper.formatDate(student.entryDate),
                studentId: student.id,
                offOneMonth: student.offOneMonth,
            };
            const absentDaysByDefault = holidays.map((val) => Number(val));
            const weekendDays = daysInMonth.filter((day) => dateHelper.isWeekend(new Date(Number(year), selectedMonth - 1, day)));
            weekendDays.forEach((weekendDay) => {
                if (dateHelper.isSaturday(new Date(Number(year), selectedMonth - 1, weekendDay)) && !absentDaysByDefault.includes(weekendDay)) {
                    row[weekendDay] = '+';
                } else {
                    row[weekendDay] = '';
                }
                if (!absentDaysByDefault.includes(weekendDay)) {
                    absentDaysByDefault.push(weekendDay);
                }
            });
            const remainingDays = daysInMonth.filter((value) => !absentDaysByDefault.includes(value));
            remainingDays.forEach((day) => {
                row[day] = '+';
            });
            const studentDayOffs = student.dayOffs;
            studentDayOffs.forEach((dayOff) => {
                row[dayOff.date] = dayOff.reason;
            });
            var numberDaysInSelectedMonth = dayjs(month).daysInMonth();
            var startDay = dayjs(month).startOf('month');
            var diffDays = dayjs(student.entryDate, FMI_SERVER_DATE_FORMAT).diff(startDay, 'days');
            if (diffDays > 0) {
                var days = [...Array.from(Array(diffDays > numberDaysInSelectedMonth ? numberDaysInSelectedMonth : diffDays).keys(), (x) => x + 1)];
                days.forEach((day) => {
                    row[day] = '';
                });
            }
            if (student.offOneMonth) {
                daysInMonth.forEach((date) => (row[date] = ''));
            }
            rows.push(row);
        });
        setRows(rows);
    }, [attendanceInfo, selectedMonth, year]);

    const onLeaveWholeMonth = useCallback(
        (rowIndex, column) => {
            const selectedRow = rows[rowIndex];
            if (selectedRow.offOneMonth) {
                callApiDeleteOffOneMonthForStudent(clazzId, selectedRow.studentId, selectedMonth)
                    .then((res) => {
                        onChangeOffOneMonthSuccess();
                        toastHelper.success(`Đã huỷ đánh dấu học sinh ${selectedRow.lastName} ${selectedRow.firstName} nghỉ nguyên tháng ${selectedMonth}!`);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            } else {
                callApiAddOffOneMonthForStudent(clazzId, selectedRow.studentId, selectedMonth)
                    .then((res) => {
                        onChangeOffOneMonthSuccess();
                        toastHelper.success(`Đã đánh dấu học sinh ${selectedRow.lastName} ${selectedRow.firstName} nghỉ nguyên tháng ${selectedMonth}!`);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            }
        },
        [rows, clazzId, selectedMonth, onChangeOffOneMonthSuccess]
    );

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', editable: false, frozen: true, width: 50 },
            { key: 'lastName', name: 'Họ đệm', editable: false, frozen: true, width: 250 },
            { key: 'firstName', name: 'Tên', editable: false, frozen: true },
            { key: 'entryDate', name: 'Ngày vào lớp', editable: false, frozen: true, width: 130 },
        ].map((item) => {
            return { ...item, ...{ headerRenderer: <FixedHeader /> } };
        });
        fixedColumns.push({
            key: 'offOneMonth',
            name: 'Nghỉ cả tháng',
            frozen: true,
            width: RDG_CHECK_BOX_COLUMN_WIDTH,
            formatter: CheckBoxFormatter,
            headerRenderer: <CustomHeader />,
            events: {
                onDoubleClick: function (e, arg) {
                    onLeaveWholeMonth(arg.rowIdx, arg.column);
                },
            },
        });
        const dayOffsColumns = daysInMonth().map((dayIndex) => {
            return {
                key: dayIndex,
                name: dayIndex,
                width: 50,
                editor: AttendanceReasonEditor,
                formatter: <AttendanceValue selectedMonth={selectedMonth} dayIndex={dayIndex} year={year} />,
                headerRenderer: <DayOffHeader selectedMonth={selectedMonth} year={year} />,
            };
        });
        return [...fixedColumns, ...dayOffsColumns].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, [daysInMonth, selectedMonth, year, onLeaveWholeMonth]);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns, daysInMonth, selectedMonth]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
        setAttendanceData(newRows);
    };

    const checkEditable = (props) => {
        if (props.column.key === 'offOneMonth') {
            return true;
        }
        if (isNumber(props.column.key) && props.row.offOneMonth) {
            return false;
        }
        return true;
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onCheckCellIsEditable={checkEditable}
                onGridRowsUpdated={onGridRowsUpdated}
                headerRowHeight={110}
                rowRenderer={RowRenderer}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default AttendanceForm;
