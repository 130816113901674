import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { suVus } from 'config/constants/suVuConstant';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { trunguong__get_list_cong_doan_users_by_su_vu } from '../actions';
import CongDoanUserList from './CongDoanUserList';

function DanhSachCongDoanTrungUongPage() {
    const theme = useTheme();

    const { control, getValues, watch, setValue, trigger } = useForm();
    const dispatch = useDispatch();
    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const congDoanUsers = useSelector((state) => state.commonReducer.congDoanUsers, []);

    const suVuOptions = suVus;

    const onSuVuChange = (selectedOption) => {
        setValue('suVu', selectedOption);
        trigger('suVu');
    };

    const getCongDoanUsers = useCallback(
        (suVu) => {
            dispatch(trunguong__get_list_cong_doan_users_by_su_vu(suVu));
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const suVu = get(values, 'suVu.value');
        if (isDiff && suVu) {
            getCongDoanUsers(suVu);
        }
    }, [values, watches, prevValues, dispatch, getCongDoanUsers]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Danh sách cộng đoàn theo sứ vụ</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Sứ vụ:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="suVu"
                                            name="suVu"
                                            control={control}
                                            defaultValue={null}
                                            render={() => {
                                                return <Select onChange={onSuVuChange} options={suVuOptions} classNamePrefix="fmi-react-select" styles={customSelectStyles} />;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {congDoanUsers && <CongDoanUserList />}
        </>
    );
}

export default DanhSachCongDoanTrungUongPage;
