const FMI_ACTIONS = {
    /* AUTHENTICATION */
    AUTHENTICATION__SIGN_IN: 'AUTHENTICATION__SIGN_IN',
    AUTHENTICATION__SIGN_OUT: 'AUTHENTICATION__SIGN_OUT',
    AUTHENTICATION__AUTHENTICATE_SUCCESS: 'AUTHENTICATION__AUTHENTICATE_SUCCESS',
    AUTHENTICATION__SIGN_OUT_SUCCESS: 'AUTHENTICATION__SIGN_OUT_SUCCESS',
    AUTHENTICATION__FAIL: 'AUTHENTICATION__FAIL',
    /* LOADING_STATUS */
    LOADING_STATUS__LOADING: 'LOADING_STATUS__LOADING',
    LOADING_STATUS__LOADED: 'LOADING_STATUS__LOADED',
    /* ACADEMIC YEAR */
    ACADEMIC_YEAR__GET_LIST: 'ACADEMIC_YEAR_GET_LIST',
    ACADEMIC_YEAR__GET_LIST_SUCCESS: 'ACADEMIC_YEAR__GET_LIST_SUCCESS',
    ACADEMIC_YEAR__GET_FIRST_MONTH: 'ACADEMIC_YEAR__GET_FIRST_MONTH',
    ACADEMIC_YEAR__GET_FIRST_MONTH_SUCCESS: 'ACADEMIC_YEAR__GET_FIRST_MONTH_SUCCESS',
    ACADEMIC_YEAR__CALL_FAIL: 'ACADEMIC_YEAR__CALL_FAIL',
    /* GRADE */
    GRADE__GET_LIST: 'GRADE__GET_LIST',
    GRADE__GET_LIST_SUCCESS: 'GRADE__GET_LIST_SUCCESS',
    GRADE__CALL_FAIL: 'GRADE__CALL_FAIL',
    /* CLASS */
    CLASS__GET_LIST: 'CLASS__GET_LIST',
    CLASS__GET_LIST_SUCCESS: 'CLASS__GET_LIST_SUCCESS',
    CLASS__CALL_FAIL: 'CLASS__CALL_FAIL',
    /** ATTENDANCE */
    ATTENDANCE__GET_INFO_OF_CLASS: 'ATTENDANCE__GET_INFO_OF_CLASS',
    ATTENDANCE__GET_INFO_OF_CLASS_SUCCESS: 'ATTENDANCE__GET_INFO_OF_CLASS_SUCCESS',
    ATTENDANCE__CHECK_ATTENDANCE: 'ATTENDANCE__CHECK_ATTENDANCE',
    ATTENDANCE__CHECK_ATTENDANCE_SUCCESS: 'ATTENDANCE__CHECK_ATTENDANCE_SUCCESS',
    ATTENDANCE__CHECK_ATTENDANCE_FAIL: 'ATTENDANCE__CHECK_ATTENDANCE_FAIL',
    /** NUN */
    NUN__GET_LIST: 'NUN__GET_LIST',
    NUN__GET_LIST_SUCCESS: 'NUN__GET_LIST_SUCCESS',
    NUN__CALL_FAIL: 'NUN__CALL_FAIL',
    NUN__GET_NUMBER_OF_NUNS: 'NUN__GET_NUMBER_OF_NUNS',
    NUN__GET_NUMBER_OF_NUNS_SUCCESS: 'NUN__GET_NUMBER_OF_NUNS_SUCCESS',
    /** CONG DOAN */
    CONGDOAN_GET_PAYMENT_INFO: 'CONGDOAN_GET_PAYMENT_INFO',
    CONGDOAN_GET_PAYMENT_INFO_SUCCESS: 'CONGDOAN_GET_PAYMENT_INFO_SUCCESS',
    CONGDOAN_GET_INFO: 'CONGDOAN_GET_INFO',
    CONGDOAN_GET_INFO_SUCCESS: 'CONGDOAN_GET_INFO_SUCCESS',
    CONGDOAN_GET_DANH_MUC_THU_MAM_NON: 'CONGDOAN_GET_THU_MAM_NON',
    CONGDOAN_GET_DANH_MUC_THU_MAM_NON_SUCCESS: 'CONGDOAN_GET_THU_MAM_NON_SUCCESS',
    CONGDOAN_GET_DANH_MUC_CHI_MAM_NON: 'CONGDOAN_GET_DANH_MUC_CHI_MAM_NON',
    CONGDOAN_GET_DANH_MUC_CHI_MAM_NON_SUCCESS: 'CONGDOAN_GET_DANH_MUC_CHI_MAM_NON_SUCCESS',
    CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN: 'CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN',
    CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN_SUCCESS: 'CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN_SUCCESS',
    CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN: 'CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN',
    CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN_SUCCESS: 'CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN_SUCCESS',
    CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM: 'CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM',
    CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM_SUCCESS: 'CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM_SUCCESS',
    CONGDOAN__GET_ALL_KHAN_DONG: 'CONGDOAN__GET_ALL_KHAN_DONG',
    CONGDOAN__GET_ALL_KHAN_DONG_SUCCESS: 'CONGDOAN__GET_ALL_KHAN_DONG_SUCCESS',
    CONGDOAN_CALL_FAIL: 'CONGDOAN_CALL_FAIL',
    /**
     * NOI TRU
     */
    NOITRU_GET_DANH_MUC_THU: 'NOITRU_GET_DANH_MUC_THU',
    NOITRU_GET_DANH_MUC_THU_SUCCESS: 'NOITRU_GET_DANH_MUC_THU_SUCCESS',
    NOITRU_GET_DANH_MUC_CHI: 'NOITRU_GET_DANH_MUC_CHI',
    NOITRU_GET_DANH_MUC_CHI_SUCCESS: 'NOITRU_GET_DANH_MUC_CHI_SUCCESS',
    NOITRU__GET_NUMBER_OF_STUDENTS: 'NOITRU__GET_NUMBER_OF_STUDENTS',
    NOITRU__GET_NUMBER_OF_STUDENTS_SUCCESS: 'NOITRU__GET_NUMBER_OF_STUDENTS_SUCCESS',
    NOITRU__CALL_FAIL: 'NOITRU__CALL_FAIL',
    /**
     * CTPV
     */
    CTPV_GET_LIST_DANH_MUC: 'CTPV_GET_LIST_DANH_MUC',
    CTPV_GET_LIST_DANH_MUC_ACTIVE: 'CTPV_GET_LIST_DANH_MUC_ACTIVE',
    CTPV_GET_LIST_DANH_MUC_SUCCESS: 'CTPV_GET_LIST_DANH_MUC_SUCCESS',
    CTPV_CALL_FAIL: 'CTPV_CALL_FAIL',
    /**
     * TRUNG UONG
     */
    TRUNG_UONG__GET_LIST_CONG_DOAN_USERS: 'TRUNG_UONG__GET_LIST_CONG_DOAN_USERS',
    TRUNG_UONG__GET_LIST_CONG_DOAN_USERS_SUCCESS: 'TRUNG_UONG__GET_LIST_CONG_DOAN_USERS_SUCCESS',
    TRUNG_UONG__CALL_FAILED: 'TRUNG_UONG__CALL_FAILED',
    TRUNG_UONG__GET_CONG_DOAN_OVERVIEW: 'TRUNG_UONG__GET_CONG_DOAN_OVERVIEW',
    TRUNG_UONG__GET_CONG_DOAN_OVERVIEW_SUCCESS: 'TRUNG_UONG__GET_CONG_DOAN_OVERVIEW_SUCCESS',
    TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW: 'TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW',
    TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW_SUCCESS: 'TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW_SUCCESS',
    TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI: 'TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI',
    TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI_SUCCESS: 'TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI_SUCCESS',
    TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI: 'TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI',
    TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI_SUCCESS: 'TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI_SUCCESS',
    /**
     * VUNG
     */
    VUNG__GET_LIST_DANH_MUC_THU: 'VUNG__GET_LIST_DANH_MUC_THU',
    VUNG__GET_LIST_DANH_MUC_THU_SUCCESS: 'VUNG__GET_LIST_DANH_MUC_THU_SUCCESS',
    VUNG__GET_LIST_DANH_MUC_CHI: 'VUNG__GET_LIST_DANH_MUC_CHI',
    VUNG__GET_LIST_DANH_MUC_CHI_SUCCESS: 'VUNG__GET_LIST_DANH_MUC_CHI_SUCCESS',
    VUNG__CALL_FAIL: 'VUNG__CALL_FAIL',
    /**
     * MAMNON
     */
    MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY: 'MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY',
    MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY_SUCCESS: 'MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY_SUCCESS',
    MAMNON__CALL_FAILED: 'MAMNON__CALL_FAILED',
};

export default FMI_ACTIONS;
