import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { messageHelper } from 'helpers/messageHelper';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiGetSubjects } from 'redux/saga/mam-non/attendance/invoice/getSubjects';
import { loaded, loading } from '../actions';

function SoThuSelectSubjectsDialog({ open, closeDialog, onExport, clazz, month }) {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const [subjects, setSubjects] = useState([]);

    const authenticationReducer = useSelector((state) => state.authenticationReducer);

    useEffect(() => {
        dispatch(loading());
        callApiGetSubjects(authenticationReducer.userInfo.congDoanId, clazz.id, month)
            .then((res) => {
                setSubjects(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [authenticationReducer.userInfo.congDoanId, clazz.id, month, dispatch]);

    const onSubmit = (data) => {
        const selectedEntries = Object.entries(data).filter(([k, v]) => {
            return v;
        });
        const selectedDuThuIds = selectedEntries.length === 0 || selectedEntries.length === subjects.length ? [] : selectedEntries.map(([k, v]) => +k);
        onExport(selectedDuThuIds);
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3>
                        Chọn môn học dùng để xuất sổ thu cho lớp {clazz.className} tháng {month}
                    </h3>
                </DialogTitle>
                <DialogContent>
                    {subjects.map((item) => {
                        return (
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor={item.duthuId}>
                                        <input className="mr-2" type="checkbox" defaultChecked={true} name={item.duthuId.toString()} id={item.duthuId} ref={register} />
                                        {item.name}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Xuất sổ thu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default SoThuSelectSubjectsDialog;
