import FMI_ACTIONS from 'redux/actions';

export const trunguong__get_list_cong_doan_users_by_su_vu = (data) => {
    return {
        type: FMI_ACTIONS.TRUNG_UONG__GET_LIST_CONG_DOAN_USERS,
        payload: { data },
    };
};

export const trunguong__get_cong_doan_overview = (data) => {
    return {
        type: FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_OVERVIEW,
        payload: { data },
    };
};

export const trunguong__get_du_thu_chi_congdoan_overview = (data) => {
    return {
        type: FMI_ACTIONS.TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW,
        payload: { data },
    };
};

export const trunguong__get_mam_non_bao_cao_sai = (data) => {
    return {
        type: FMI_ACTIONS.TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI,
        payload: { data },
    };
};

export const trunguong__get_cong_doan_bao_cao_sai = (data) => {
    return {
        type: FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI,
        payload: { data },
    };
};
