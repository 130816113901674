import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getListOfCongDoanDanhMucThuCongDoanVung(payload) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetListOfCongDoanDanhMucThuCongDoanVung, payload.payload.isInternal);
        yield put({
            type: FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_THU_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.VUNG__CALL_FAIL,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfCongDoanDanhMucThuCongDoanVung(isInternal = false) {
    return transport.get(`/congdoanvung/thuchi/thucongdoanvung/danhmuc`, { params: { isInternal } });
}
