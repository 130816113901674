import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportCongDoanVungAnnually } from 'redux/saga/trung-uong/report/vung/exportCongDoanVungReportAnnually';

function TWCongDoanVungAnnuallyReportPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const exportReportPDF = useCallback(() => {
        const academicYear = get(values, 'academicYear.value.name');

        dispatch(loading());
        callApiExportCongDoanVungAnnually(academicYear, true)
            .then((res) => {
                const pdfURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                setPdfFile(pdfURL);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');

        if (isDiff && academicYear) {
            if (userInfo.congDoanId) {
                setPdfFile(null);
                exportReportPDF();
            }
        }
    }, [values, watches, prevValues, exportReportPDF, userInfo.congDoanId]);

    const onSubmit = () => {
        if (userInfo.congDoanId && values.academicYear) {
            exportCongDoanReport();
        }
    };

    const exportCongDoanReport = () => {
        dispatch(loading());
        const academicYear = get(values, 'academicYear.value.name');
        callApiExportCongDoanVungAnnually(academicYear, false)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Báo cáo thu chi vùng năm học ${academicYear}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo thu chi vùng theo năm</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy báo cáo!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onSubmit}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>

            {pdfFile && (
                <div className="align-pdf">
                    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={index} pageNumber={index + 1}></Page>
                        ))}
                    </Document>
                </div>
            )}
        </>
    );
}

export default TWCongDoanVungAnnuallyReportPage;
