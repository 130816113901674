import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import FMI_ACTIONS from '../actions';
import signIn from './authentication/signIn';
import signOut from './authentication/signOut';
import getListOfCongDoanDanhMucChiMamNon from './mam-non/thu-chi/chi-mam-non/getAllDanhMucChiMamNon';
import checkAttendance from './mam-non/info/class/checkAttendance';
import getCongDoanPaymentInfo from './common/getCongDoanPaymentInfo';
import getListOfAcademicYears from './common/getListOfAcademicYears';
import getListOfCongDoanDanhMucThuMamNon from './mam-non/thu-chi/thu-mam-non/getDanhMucThuMamNon';
import getListOfCongDoanDanhMucChiCongDoan from './cong-doan/thu-chi/chi-cong-doan/getAllDanhMucChiCongDoan';
import getListOfCongDoanDanhMucThuCongDoan from './cong-doan/thu-chi/thu-cong-doan/getAllDanhMucThuCongDoan';
import getListOfCongDoanDanhMucChiNoiTru from './noi-tru/thu-chi/chi-noi-tru/getAllDanhMucChiNoiTru';
import getListOfCongDoanDanhMucThuNoiTru from './noi-tru/thu-chi/thu-noi-tru/getAllDanhMucThuNoiTru';
import getCongDoanInfo from './cong-doan/info/getCongDoanInfo';
import getListNuns from './common/getListNuns';
import getListDanhMucCTPVOfCongDoan from './ctpv/danhmuc/getListDanhMucCTPVOfCongDoan';
import getListDanhMucCTPVActiveOfCongDoan from './ctpv/danhmuc/getActiveDanhMucCTPV';
import getNumberOfNuns from './common/getNumberOfNuns';
import getNoiTruNumberOfStudent from './common/getNoiTruNumberOfStudent';
import getListOfCongDoanDanhMucThuChiDauNam from './mam-non/thu-chi/thu-chi-dau-nam/getDanhMucThuChiDauNam';
import getListOfCongDoanUsersBySuVu from './trung-uong/cong-doan/getCongDoanUserBySuVu';
import getCongDoanReportOverview from './trung-uong/cong-doan/getCongDoanReportOverview';
import getListOfCongDoanDanhMucThuCongDoanVung from './vung/thu/getListOfDanhMucThuCongDoanVung';
import getListOfCongDoanDanhMucChiCongDoanVung from './vung/chi/getListOfDanhMucChiCongDoanVung';
import getAcademicYearFirstMonth from './common/getAcademicYearFirstMonth';
import getAllKhanDong from './cong-doan/info/khandong/getAllKhanDong';
import getDuThuChiCongDoanReportOverview from './trung-uong/duthuchi/getDuThuChiCongDoanOverview';
import getMamNonBaoCaoSai from './trung-uong/bao-cao-sai/getMamNonBaoCaoSai';
import getCongDoanBaoCaoSai from './trung-uong/bao-cao-sai/getCongDoanBaoCaoSai';
import getListDanhMucThuChiDauNamNhiemKy from './mam-non/thu-chi/thu-chi-dau-nam-nhiem-ky/getDanhMucThuChiDauNamNhiemKy';

function* sagas() {
    yield all([
        takeEvery(FMI_ACTIONS.AUTHENTICATION__SIGN_IN, signIn),
        takeEvery(FMI_ACTIONS.AUTHENTICATION__SIGN_OUT, signOut),
        takeEvery(FMI_ACTIONS.ACADEMIC_YEAR__GET_LIST, getListOfAcademicYears),
        takeEvery(FMI_ACTIONS.NUN__GET_LIST, getListNuns),
        takeEvery(FMI_ACTIONS.ATTENDANCE__CHECK_ATTENDANCE, checkAttendance),
        takeEvery(FMI_ACTIONS.CONGDOAN_GET_PAYMENT_INFO, getCongDoanPaymentInfo),
        takeEvery(FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_MAM_NON, getListOfCongDoanDanhMucThuMamNon),
        takeEvery(FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_MAM_NON, getListOfCongDoanDanhMucChiMamNon),
        takeEvery(FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN, getListOfCongDoanDanhMucChiCongDoan),
        takeEvery(FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN, getListOfCongDoanDanhMucThuCongDoan),
        takeEvery(FMI_ACTIONS.NOITRU_GET_DANH_MUC_CHI, getListOfCongDoanDanhMucChiNoiTru),
        takeEvery(FMI_ACTIONS.NOITRU_GET_DANH_MUC_THU, getListOfCongDoanDanhMucThuNoiTru),
        takeLatest(FMI_ACTIONS.CONGDOAN_GET_INFO, getCongDoanInfo),
        takeLatest(FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC, getListDanhMucCTPVOfCongDoan),
        takeLatest(FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC_ACTIVE, getListDanhMucCTPVActiveOfCongDoan),
        takeLatest(FMI_ACTIONS.NUN__GET_NUMBER_OF_NUNS, getNumberOfNuns),
        takeLatest(FMI_ACTIONS.NOITRU__GET_NUMBER_OF_STUDENTS, getNoiTruNumberOfStudent),
        takeLatest(FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM, getListOfCongDoanDanhMucThuChiDauNam),
        takeLatest(FMI_ACTIONS.TRUNG_UONG__GET_LIST_CONG_DOAN_USERS, getListOfCongDoanUsersBySuVu),
        takeLatest(FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_OVERVIEW, getCongDoanReportOverview),
        takeLatest(FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_THU, getListOfCongDoanDanhMucThuCongDoanVung),
        takeLatest(FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_CHI, getListOfCongDoanDanhMucChiCongDoanVung),
        takeLatest(FMI_ACTIONS.ACADEMIC_YEAR__GET_FIRST_MONTH, getAcademicYearFirstMonth),
        takeLatest(FMI_ACTIONS.CONGDOAN__GET_ALL_KHAN_DONG, getAllKhanDong),
        takeLatest(FMI_ACTIONS.TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW, getDuThuChiCongDoanReportOverview),
        takeLatest(FMI_ACTIONS.TRUNG_UONG__GET_MAM_NON_BAO_CAO_SAI, getMamNonBaoCaoSai),
        takeLatest(FMI_ACTIONS.TRUNG_UONG__GET_CONG_DOAN_BAO_CAO_SAI, getCongDoanBaoCaoSai),
        takeLatest(FMI_ACTIONS.MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY, getListDanhMucThuChiDauNamNhiemKy),
    ]);
}

export default sagas;
