import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { LOAI_CTPV, LOAI_CTPVS } from 'config/constants/danhMucThuConstant';
import { FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Select from 'react-select';
import { callApiGetThuChiCTPV } from 'redux/saga/ctpv/thuchi/getThuChiCTPV';
import { ctpv__get_list_danh_muc_active } from '../actions';
import { callApiSaveThuChiCTPV } from 'redux/saga/ctpv/thuchi/saveThuChiCTPV';
import CTPVThuChiList from './CTPVThuChiList';
import { toastHelper } from 'helpers/toastHelper';
import { callApiExportThuChiCTPV } from 'redux/saga/ctpv/thuchi/exportThuChiCTPV';
import dayjs from 'dayjs';

function CTPVThuChiPage() {
    const theme = useTheme();
    const { control, setValue, getValues, watch } = useForm({
        defaultValues: {
            loaiCTPV: { value: LOAI_CTPV.ALL, label: 'Tất cả' },
        },
    });
    const dispatch = useDispatch();
    const [thuchis, setThuChis] = useState([]);
    const [data, setData] = useState(null);
    const [optionClassName, setOptionClassName] = useState('has-default');
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const danhMucs = useSelector((state) => state.ctpvReducer.danhMucs, []);
    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const loaiCTPVOptions = LOAI_CTPVS.map((type) => {
        return { value: type.key, label: type.label };
    });

    const onOptionChange = (selectedOption) => {
        setValue('loaiCTPV', selectedOption);
        if (optionClassName !== '') {
            setOptionClassName('');
        }
    };

    const buildBody = () => {
        const body = data.map((item) => {
            return {
                id: get(item, 'thuchi.id'),
                danhMucCongTacPhucVuId: get(item, 'dm.id') ? +get(item, 'dm.id') : undefined,
                tienMat: +get(item, 'tienMat', 0),
                nganHang: +get(item, 'nganHang', 0),
                usd: +get(item, 'usd', 0),
                euro: +get(item, 'eur', 0),
                loaiCTPV: values.loaiCTPV.value,
            };
        });
        return body;
    };

    const onSave = () => {
        if (values.loaiCTPV && userInfo.congDoanId && values.monthYear) {
            const body = buildBody();
            dispatch(loading());
            callApiSaveThuChiCTPV(userInfo.congDoanId, dateHelper.formatDate(values.monthYear, FMI_YEAR_MONTH_FORMAT), body)
                .then((data) => {
                    toastHelper.success('Lưu thành công!');
                    getThuChis(userInfo.congDoanId, dateHelper.formatDate(values.monthYear, FMI_YEAR_MONTH_FORMAT), values.loaiCTPV.value);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    useEffect(() => {
        dispatch(ctpv__get_list_danh_muc_active());
    }, [dispatch]);

    const getThuChis = useCallback(
        (congDoanId, monthYear, loaiCTPV) => {
            dispatch(loading());
            setData(null);
            setThuChis(null);
            callApiGetThuChiCTPV(congDoanId, monthYear, loaiCTPV)
                .then((res) => {
                    setThuChis(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            if (userInfo.congDoanId) {
                getThuChis(userInfo.congDoanId, dateHelper.formatDate(values.monthYear, FMI_YEAR_MONTH_FORMAT), values.loaiCTPV.value);
            }
        }
    }, [values, watches, prevValues, getThuChis, userInfo.congDoanId]);

    const onExport = () => {
        if (userInfo.congDoanId) {
            dispatch(loading());
            const month = dayjs(values.monthYear).month() + 1;
            const year = dayjs(values.monthYear).year();
            callApiExportThuChiCTPV(userInfo.congDoanId, year, month)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo thu chi CTPV tháng ${month} năm ${year}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Thu chi công tác phục vụ</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row mr-0">
                                    <label htmlFor="loaiCTPV" className="mb-0 col-md-4 fmi-label pr-0">
                                        Loại nhập
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="loaiCTPV"
                                            control={control}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        options={loaiCTPVOptions}
                                                        value={value}
                                                        onChange={onOptionChange}
                                                        id="loaiCTPV"
                                                        classNamePrefix="fmi-react-select"
                                                        className={optionClassName}
                                                        styles={customSelectStyles}
                                                    ></Select>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-3 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true })}
                                        disabled={data === null}
                                        onClick={onSave}
                                        title="Lưu"
                                    >
                                        Lưu
                                    </button>
                                </div>
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': values.monthYear === undefined })}
                                        onClick={onExport}
                                        title="Xuất báo cáo"
                                    >
                                        Xuất báo cáo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.monthYear && thuchis && (
                <CTPVThuChiList loaiCTPV={values.loaiCTPV} thuchis={thuchis} danhMucs={danhMucs} data={data} setData={setData} monthYear={values.monthYear} />
            )}
        </>
    );
}

export default CTPVThuChiPage;
