import { Divider } from '@material-ui/core';
import { academic_year__get_first_month } from 'components/authentication/actions';
import { LOAI_CTPV } from 'config/constants/danhMucThuConstant';
import { FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';

const EmptyRowsView = () => {
    const message = 'Chưa có danh mục công tác phục vụ nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

const ThuChiCTPVHeader = (props) => {
    return (
        <div>
            {props.index === 0 ? (
                <>
                    <div style={{ width: 584, zIndex: 1, display: 'flex', position: 'relative', justifyContent: 'center', backgroundColor: 'white' }}>
                        <div style={{ left: 230 }}>{props.type}</div>
                    </div>
                </>
            ) : (
                <div style={{ opacity: 0 }}>{props.type}</div>
            )}
            <Divider />
            <div className="text-center">{props.column.name}</div>
        </div>
    );
};

function CTPVThuChiList({ loaiCTPV, thuchis, danhMucs, data, setData, monthYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    const dispatch = useDispatch();

    const academicYearFirstMonth = useSelector((state) => state.commonReducer.academicYearFirstMonth, {});
    useEffect(() => {
        dispatch(academic_year__get_first_month({ yearMonth: dayjs(monthYear).format(FMI_YEAR_MONTH_FORMAT) }));
    }, [dispatch, monthYear]);

    const isFirstMonthOfSemester = academicYearFirstMonth ? dayjs(monthYear).isSame(dayjs(academicYearFirstMonth)) : false;

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        let newData = data ? [...data] : [];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (data === null) {
                newData = [newRows[i]];
            } else {
                const index = newData.findIndex((item) => item.dm.id === newRows[i].dm.id);
                if (index >= 0) {
                    newData[index] = newRows[i];
                } else {
                    newData = [...data, ...[newRows[i]]];
                }
            }
        }
        setRows(newRows);
        setData(newData);
    };

    const buildDynamicColumns = useCallback(
        (label, key = '') => {
            const isEditable = loaiCTPV.value === LOAI_CTPV.TONDAUTHANG && !isFirstMonthOfSemester ? false : true;
            return [
                { key: 'tienMat' + key, name: 'Tiền mặt (VNĐ)', editable: isEditable, width: 150, headerRenderer: <ThuChiCTPVHeader index={0} type={label} /> },
                { key: 'nganHang' + key, name: 'Ngân hàng (VNĐ)', editable: isEditable, width: 150, headerRenderer: <ThuChiCTPVHeader index={1} type={label} /> },
                { key: 'usd' + key, name: 'USD', editable: isEditable, width: 150, headerRenderer: <ThuChiCTPVHeader index={2} type={label} /> },
                { key: 'eur' + key, name: 'EURO', editable: isEditable, width: 150, headerRenderer: <ThuChiCTPVHeader index={3} type={label} /> },
            ];
        },
        [loaiCTPV, isFirstMonthOfSemester]
    );

    const buildDynamicColumnsForALLType = useCallback(() => {
        return [
            ...buildDynamicColumns('Tồn đầu', LOAI_CTPV.TONDAUTHANG),
            ...buildDynamicColumns('Thu', LOAI_CTPV.THU),
            ...buildDynamicColumns('Chi', LOAI_CTPV.CHI),
            ...buildDynamicColumns('Tồn cuối', LOAI_CTPV.TONCUOI),
        ].map((item) => {
            return { ...item, ...{ editable: false } };
        });
    }, [buildDynamicColumns]);

    const buildColumns = useCallback(() => {
        const defaultColumns = [
            { key: 'index', name: 'STT', editable: false, frozen: true, width: 50 },
            { key: 'name', name: 'Các công tác', editable: false, frozen: true, width: 250 },
        ];

        switch (loaiCTPV.value) {
            case LOAI_CTPV.CHI:
                return [...defaultColumns, ...buildDynamicColumns('Chi')];
            case LOAI_CTPV.TONDAUTHANG:
                return [...defaultColumns, ...buildDynamicColumns('Tồn đầu tháng')];
            case LOAI_CTPV.THU:
                return [...defaultColumns, ...buildDynamicColumns('Thu')];
            default:
                return [...defaultColumns, ...buildDynamicColumnsForALLType()];
        }
    }, [buildDynamicColumnsForALLType, loaiCTPV, buildDynamicColumns]);

    useEffect(() => {
        const columns = buildColumns();
        setColumns([...columns]);
    }, [buildColumns]);

    const buildRowByTypeALL = useCallback(
        (dm, loaiCTPV) => {
            const matchedThuchi = thuchis.find((item) => item.danhMucCongTacPhucVuId === dm.id && item.loaiCTPV === loaiCTPV);
            switch (loaiCTPV) {
                case LOAI_CTPV.THU:
                    return {
                        tienMatTHU: matchedThuchi ? matchedThuchi.tienMat : '',
                        nganHangTHU: matchedThuchi ? matchedThuchi.nganHang : '',
                        usdTHU: matchedThuchi ? matchedThuchi.usd : '',
                        eurTHU: matchedThuchi ? matchedThuchi.euro : '',
                        thuchi: matchedThuchi,
                    };
                case LOAI_CTPV.CHI:
                    return {
                        tienMatCHI: matchedThuchi ? matchedThuchi.tienMat : '',
                        nganHangCHI: matchedThuchi ? matchedThuchi.nganHang : '',
                        usdCHI: matchedThuchi ? matchedThuchi.usd : '',
                        eurCHI: matchedThuchi ? matchedThuchi.euro : '',
                        thuchi: matchedThuchi,
                    };
                case LOAI_CTPV.TONDAUTHANG:
                    return {
                        tienMatTONDAUTHANG: matchedThuchi ? matchedThuchi.tienMat : '',
                        nganHangTONDAUTHANG: matchedThuchi ? matchedThuchi.nganHang : '',
                        usdTONDAUTHANG: matchedThuchi ? matchedThuchi.usd : '',
                        eurTONDAUTHANG: matchedThuchi ? matchedThuchi.euro : '',
                        thuchi: matchedThuchi,
                    };
                case LOAI_CTPV.TONCUOI:
                    return {
                        tienMatTONCUOI: matchedThuchi ? matchedThuchi.tienMat : '',
                        nganHangTONCUOI: matchedThuchi ? matchedThuchi.nganHang : '',
                        usdTONCUOI: matchedThuchi ? matchedThuchi.usd : '',
                        eurTONCUOI: matchedThuchi ? matchedThuchi.euro : '',
                        thuchi: matchedThuchi,
                    };
                default:
                    break;
            }
        },
        [thuchis]
    );

    const buildRowBySingleType = useCallback(
        (dm, loaiCTPV) => {
            const matchedThuchi = thuchis.find((item) => item.danhMucCongTacPhucVuId === dm.id && item.loaiCTPV === loaiCTPV);
            return {
                tienMat: matchedThuchi ? matchedThuchi.tienMat : '',
                nganHang: matchedThuchi ? matchedThuchi.nganHang : '',
                usd: matchedThuchi ? matchedThuchi.usd : '',
                eur: matchedThuchi ? matchedThuchi.euro : '',
                thuchi: matchedThuchi,
            };
        },
        [thuchis]
    );

    const buildRow = useCallback(
        (dm, index) => {
            const defaultValue = {
                index: index + 1,
                name: dm.name,
                dm,
            };
            if (loaiCTPV.value === LOAI_CTPV.ALL) {
                return {
                    ...defaultValue,
                    ...buildRowByTypeALL(dm, LOAI_CTPV.THU),
                    ...buildRowByTypeALL(dm, LOAI_CTPV.CHI),
                    ...buildRowByTypeALL(dm, LOAI_CTPV.TONDAUTHANG),
                    ...buildRowByTypeALL(dm, LOAI_CTPV.TONCUOI),
                };
            } else {
                return {
                    ...defaultValue,
                    ...buildRowBySingleType(dm, loaiCTPV.value),
                };
            }
        },
        [buildRowByTypeALL, buildRowBySingleType, loaiCTPV.value]
    );

    useEffect(() => {
        const rows = [];
        danhMucs
            .filter((item) => item.active === true)
            .forEach((dm, index) => {
                const row = buildRow(dm, index);
                rows.push(row);
            });
        setRows(rows);
    }, [danhMucs, buildRow]);

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                onGridRowsUpdated={onGridRowsUpdated}
                enableCellSelect={true}
                headerRowHeight={60}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default CTPVThuChiList;
