export const CALCULATION_TYPES = [
    { key: 'MONTHLY', label: 'Thu hàng tháng' },
    { key: 'DAILY', label: 'Thu hàng ngày' },
];

export const TON_THANG_TRUOC_CONG_DOAN_CODE = 1000;
export const TON_THANG_TRUOC_NOI_TRU_CODE = [1000, 1001];

export const LOAI_CTPV = {
    ALL: 'ALL',
    THU: 'THU',
    CHI: 'CHI',
    TONDAUTHANG: 'TONDAUTHANG',
    TONCUOI: 'TONCUOI',
};

export const LOAI_CTPVS = [
    { key: LOAI_CTPV.ALL, label: 'Tất cả' },
    { key: LOAI_CTPV.THU, label: 'Thu' },
    { key: LOAI_CTPV.CHI, label: 'Chi' },
    { key: LOAI_CTPV.TONDAUTHANG, label: 'Tồn đầu tháng' },
];

export const LOAI_THUCHI_DAUNAM_NHIEMKY = {
    ALL: 'ALL',
    THU: 'THU',
    CHI: 'CHI',
    TON: 'TON',
    TONDAUNAM: 'TONDAUNAM',
    TONG_TON: 'TONG_TON',
    TOTAL: 'TOTAL',
    TOTAL_THU: 'TOTAL_THU',
    TOTAL_CHI: 'TOTAL_CHI',
    TOTAL_TON: 'TOTAL_TON',
    TOTAL_TONDAUNAM: 'TOTAL_TONDAUNAM',
};

export const THUCHI_DAUNAM_NHIEMKY_OPTIONS = [
    { key: LOAI_THUCHI_DAUNAM_NHIEMKY.ALL, label: 'Tất cả' },
    { key: LOAI_THUCHI_DAUNAM_NHIEMKY.THU, label: 'Thu' },
    { key: LOAI_THUCHI_DAUNAM_NHIEMKY.CHI, label: 'Chi' },
    { key: LOAI_THUCHI_DAUNAM_NHIEMKY.TONDAUNAM, label: 'Tồn đầu năm' },
];

export const LOAI_THUCHI_DAUNAM = {
    THU: 'THU',
    CHI: 'CHI',
    TON: 'TON',
    TIEN_MAT: 'TIEN_MAT',
    NGAN_HANG: 'NGAN_HANG',
    TONG_TON: 'TONG_TON',
    NOTE: 'NOTE',
};

export const LOAI_THUCHI_DAUNAMS = [
    { key: LOAI_THUCHI_DAUNAM.THU, label: 'Thu' },
    { key: LOAI_THUCHI_DAUNAM.CHI, label: 'Chi' },
    { key: LOAI_THUCHI_DAUNAM.TON, label: 'Tồn' },
    { key: LOAI_THUCHI_DAUNAM.TIEN_MAT, label: 'Tiền mặt' },
    { key: LOAI_THUCHI_DAUNAM.NGAN_HANG, label: 'Ngân hàng' },
];
