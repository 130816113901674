import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_list_danh_muc_thu_cong_doan, loaded, loading } from 'components/mam-non-page/actions';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiGetListOfThuCongDoan } from 'redux/saga/cong-doan/thu-chi/thu-cong-doan/getAllThuCongDoan';
import { callApiSaveThuCongDoan } from 'redux/saga/cong-doan/thu-chi/thu-cong-doan/saveAllThuCongDoan';
import TonThangTruocList from './TonThangTruocList';

function CongDoanTonThangTruocPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dmThuCongDoans = useSelector((state) => state.commonReducer.dmThuCongDoans, []);

    const theme = useTheme();
    const [data, setData] = useState(null);
    const [thuCongDoans, setThuCongDoans] = useState([]);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(congdoan_get_list_danh_muc_thu_cong_doan({ isInternal: true }));
    }, [dispatch]);

    const getThuCongDoans = useCallback(
        (month, year) => {
            setThuCongDoans([]);
            setData(null);
            if (userInfo.congDoanId) {
                callApiGetListOfThuCongDoan(userInfo.congDoanId, month, year, true)
                    .then((res) => {
                        setThuCongDoans(res.data);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            }
        },
        [userInfo]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            const month = dayjs(values.monthYear).month() + 1;
            const year = dayjs(values.monthYear).year();
            getThuCongDoans(month, year);
        }
    }, [values, watches, prevValues, getThuCongDoans]);

    const onSubmit = () => {
        if (data && userInfo.congDoanId && values.monthYear) {
            const body = buildBody(data);
            saveAllTonThangTruoc(body);
        }
    };

    const buildBody = (data) => {
        return [...data.values()].map((item) => {
            return {
                id: get(item, 'dm.id', undefined),
                amount: Number(item.amount),
                notes: item.notes,
                code: item.code,
                danhMucThuCongDoanId: item.dmId,
                eurAmount: Number(item.eurAmount),
                usdAmount: Number(item.usdAmount),
                audAmount: Number(item.audAmount),
                ngoaiTeAmount: item.ngoaiTeAmount,
            };
        });
    };

    const saveAllTonThangTruoc = (body) => {
        const month = dayjs(values.monthYear).month() + 1;
        const year = dayjs(values.monthYear).year();
        dispatch(loading());
        callApiSaveThuCongDoan(userInfo.congDoanId, month, year, body)
            .then((res) => {
                toastHelper.success('Lưu giao dịch tài khoản thành công!');
                getThuCongDoans(month, year);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Giao dịch tài khoản</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })}
                                        disabled={data === null}
                                        onClick={onSubmit}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0 justify-content-end">
                            <div className="alert alert-warning mb-0 p-2" role="alert">
                                Chỉ có <strong>Tiền gửi ngân hàng/vàng hoặc ngoại tệ khác</strong> có thể nhập phần <strong>Ngoại tệ</strong>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.monthYear && <TonThangTruocList dmThuCongDoans={dmThuCongDoans} thuCongDoans={thuCongDoans} monthYear={values.monthYear} data={data} setData={setData} />}
        </>
    );
}

export default CongDoanTonThangTruocPage;
