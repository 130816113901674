import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_info } from 'components/authentication/actions';
import { FMI_MONTH_YEAR_FORMAT, FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiExportAbsencesSummary } from 'redux/saga/mam-non/attendance/absence/getAbsencesSummaryReport';
import { callApiGetSchoolAbsences } from 'redux/saga/mam-non/attendance/attendance/getSchoolAbsences';
import { loaded, loading } from '../actions';
import AbsenceSchoolReportList from './AbsenceSchoolReportList';

function MamNonTongHopDiemDanhPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();

    const theme = useTheme();
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const [absences, setAbsences] = useState(null);

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    const getAbsences = useCallback(
        (month, year) => {
            setAbsences(null);
            dispatch(loading());
            callApiGetSchoolAbsences(month, year)
                .then((res) => {
                    setAbsences(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            const month = dayjs(values.monthYear).month() + 1;
            const year = dayjs(values.monthYear).year();
            getAbsences(month, year);
        }
    }, [values, watches, prevValues, getAbsences]);

    const onExportReport = () => {
        if (values.monthYear) {
            exportMamNonReport();
        }
    };

    const exportMamNonReport = () => {
        dispatch(loading());
        callApiExportAbsencesSummary(dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT))
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute(
                    'download',
                    `Báo cáo điểm danh tổng hợp ${get(congDoanInfo, 'congDoanName', '')} tháng ${dayjs(values.monthYear).format(FMI_MONTH_YEAR_FORMAT)}.xlsx`
                ); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Mầm non tổng hợp điểm danh</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pr-0"></div>
                            <div className="col-md-3 pr-0"></div>
                            {watches.monthYear && (
                                <div className="col-md-3">
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExportReport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.monthYear && absences && <AbsenceSchoolReportList absences={absences} monthYear={values.monthYear} />}
        </>
    );
}

export default MamNonTongHopDiemDanhPage;
