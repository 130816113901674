import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getAllKhanDong(payload) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetAllKhanDong, payload.payload.data.congdoanId);
        yield put({
            type: FMI_ACTIONS.CONGDOAN__GET_ALL_KHAN_DONG_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.CONGDOAN_CALL_FAIL,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetAllKhanDong(congdoanId) {
    return transport.get(`/congdoan/${congdoanId}/khandong`);
}
