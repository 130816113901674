import { makeStyles, Paper } from '@material-ui/core';
import { academic_year__get_list } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiGetListStudent } from 'redux/saga/mam-non/info/class/student/getListStudents';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 400,
        padding: theme.spacing(1),
    },
}));

function ClazzQuery({ students, setStudents, setClazz, forceRender }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [clazzes, setClazzes] = useState([]);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const prevForceRender = usePrevious(forceRender);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getStudents = useCallback(
        async (clazzId) => {
            setStudents(null);
            const data = await callApiGetListStudent(clazzId, true);
            setStudents(data.data);
        },
        [setStudents]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues) || prevForceRender !== forceRender;
        if (values.clazz && values.clazz.id && isDiff) {
            getStudents(values.clazz.id);
        }
    }, [values, watches, prevValues, getStudents, forceRender, prevForceRender]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        resetValue();
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        resetValue();
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    const onClazzChange = (selectedOption) => {
        setClazz(selectedOption.value);
        setValue('clazz', selectedOption.value);
        trigger('clazz');
    };

    const resetValue = () => {
        setClazz(null);
        setStudents(null);
    };

    return (
        <Paper className={classes.paper} elevation={3}>
            <form>
                <div className="row mx-0 my-2 align-items-center">
                    <label className="mb-0 col-md-4 fmi-label px-0" htmlFor="academicYear">
                        Năm học:
                    </label>
                    <div className="col-md-8 p-0">
                        <Select options={academicYearsOptions} id="academicYear" classNamePrefix="fmi-react-select" onChange={onAcademicYearChange} styles={customSelectStyles}></Select>
                    </div>
                </div>
                <div className="row mx-0 my-2 align-items-center">
                    <label className="mb-0 col-md-4 fmi-label px-0" htmlFor="grade">
                        Khối học:
                    </label>
                    <div className="col-md-8 p-0">
                        <Select
                            options={grades.map((item) => {
                                return { value: item, label: item.name };
                            })}
                            id="grade"
                            onChange={onGradeChange}
                            value={grade}
                            classNamePrefix="fmi-react-select"
                            styles={customSelectStyles}
                        ></Select>
                    </div>
                </div>
                <div className="row mx-0 my-2 align-items-center">
                    <label className="mb-0 col-md-4 fmi-label px-0" htmlFor="clazz">
                        Lớp học:
                    </label>
                    <div className="col-md-8 p-0">
                        <Controller
                            id="clazz"
                            name="clazz"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Vui lòng nhập lớp để lấy danh sách học sinh!',
                                },
                            }}
                            render={({ value }) => {
                                return (
                                    <Select
                                        onChange={onClazzChange}
                                        options={clazzes.map((item) => {
                                            return { value: item, label: item.className };
                                        })}
                                        value={value !== '' ? value.label : ''}
                                        styles={customSelectStyles}
                                        classNamePrefix="fmi-react-select"
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
                {students && (
                    <div className="row mx-0 my-2 align-items-center">
                        <label className="mb-0 col-md-4 fmi-label px-0" htmlFor="numberOfStudents">
                            Sĩ số:
                        </label>
                        <span id="numberOfStudents">{students.length}</span>
                    </div>
                )}
            </form>
        </Paper>
    );
}

export default ClazzQuery;
