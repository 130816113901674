import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportBaoCaoDuChiCongDoan } from 'redux/saga/cong-doan/thu-chi/du-chi-cong-doan/exportBaoCaoChiCongDoan';
import { callApiGetDuChiCongDoan } from 'redux/saga/cong-doan/thu-chi/du-chi-cong-doan/getDuChiCongDoan';
import { callApiGetListOfDanhMucDuChiCongDoan } from 'redux/saga/cong-doan/thu-chi/du-chi-cong-doan/getListDanhMucChiCongDoan';
import { callApiSaveDuChiCongDoan } from 'redux/saga/cong-doan/thu-chi/du-chi-cong-doan/saveDuChiCongDoan';
import DuChiCongDoanReportExportDialog from './DuChiCongDoanReportExportDialog';
import DuChiCongDoanTable from './DuChiCongDoanTable';

function DuChiCongDoanPage() {
    const theme = useTheme();

    const { control, setValue, getValues, trigger, watch } = useForm();
    const dispatch = useDispatch();
    const [duchicongdoans, setDuchicongdoans] = useState(null);
    const [data, setData] = useState(null);
    const [danhMucDuChiCongDoan, setDanhMucDuChiCongDoan] = useState(null);
    const [exporting, setExporting] = useState(false);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const openDialog = () => {
        setExporting(true);
    };

    const closeDialog = () => {
        setExporting(false);
    };

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getDuChiCongDoan = useCallback(
        (congDoanId, academicYear) => {
            setDuchicongdoans(null);
            setData(null);
            dispatch(loading());
            callApiGetDuChiCongDoan(congDoanId, academicYear)
                .then((res) => {
                    setDuchicongdoans(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(loading());
        callApiGetListOfDanhMucDuChiCongDoan()
            .then((res) => {
                setDanhMucDuChiCongDoan(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear && congDoanId) {
            getDuChiCongDoan(congDoanId, academicYear);
        }
    }, [values, watches, prevValues, dispatch, getDuChiCongDoan, congDoanId]);

    const onSubmit = () => {
        const academicYear = get(values, 'academicYear.value.name');
        const body = buildBody();
        if (academicYear) {
            dispatch(loading());
            callApiSaveDuChiCongDoan(congDoanId, body, academicYear)
                .then((res) => {
                    toastHelper.success('Lưu dự chi cộng đoàn thành công!');
                    getDuChiCongDoan(congDoanId, academicYear);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const buildBody = () => {
        const resData = [...data];
        resData.forEach((item) => {
            item.junAmount = item.junAmount !== '' ? Number(item.junAmount) : undefined;
            item.julAmount = item.julAmount !== '' ? Number(item.julAmount) : undefined;
            item.augAmount = item.augAmount !== '' ? Number(item.augAmount) : undefined;
            item.sepAmount = item.sepAmount !== '' ? Number(item.sepAmount) : undefined;
            item.octAmount = item.octAmount !== '' ? Number(item.octAmount) : undefined;
            item.novAmount = item.novAmount !== '' ? Number(item.novAmount) : undefined;
            item.decAmount = item.decAmount !== '' ? Number(item.decAmount) : undefined;
            item.janAmount = item.janAmount !== '' ? Number(item.janAmount) : undefined;
            item.febAmount = item.febAmount !== '' ? Number(item.febAmount) : undefined;
            item.marAmount = item.marAmount !== '' ? Number(item.marAmount) : undefined;
            item.aprAmount = item.aprAmount !== '' ? Number(item.aprAmount) : undefined;
            item.mayAmount = item.mayAmount !== '' ? Number(item.mayAmount) : undefined;
            item.extraAmount1 = item.extraAmount1 !== '' ? Number(item.extraAmount1) : undefined;
            item.extraAmount2 = item.extraAmount2 !== '' ? Number(item.extraAmount2) : undefined;
        });
        return resData.map((item) => {
            return { ...item, ...{ danhMucChiCongDoanCode: item.code } };
        });
    };

    const onExportDuChi = (submitToTW) => {
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiExportBaoCaoDuChiCongDoan(congDoanId, academicYear, submitToTW)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo dự chi cộng đoàn ${get(congDoanInfo, 'congDoanName', '')}.xls`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Dự chi công đoàn</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-4 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={openDialog}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        disabled={data === null}
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })}
                                        onClick={onSubmit}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.academicYear && (
                <DuChiCongDoanTable
                    academicYear={values.academicYear.value}
                    danhmucchicongdoans={danhMucDuChiCongDoan}
                    duchicongdoans={duchicongdoans}
                    data={data}
                    setData={setData}
                />
            )}
            {exporting && values.academicYear && (
                <DuChiCongDoanReportExportDialog onExport={onExportDuChi} closeDialog={closeDialog} open={exporting} academicYear={get(values, 'academicYear.value.name')} />
            )}
        </>
    );
}

export default DuChiCongDoanPage;
