import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Chưa có mục thu nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ThuMamNonList({ dmThuMamNons, thuMamNons, setData, monthYear, data }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
        const newData = new Map(data);
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (get(newRows[i], 'dm.id', false) || updated[cellKey] !== '') {
                newData.set(i, newRows[i]);
            }
        }
        setRows(newRows);
        setData(newData);
    };

    useEffect(() => {
        const rows = [];
        if (dmThuMamNons) {
            dmThuMamNons.forEach((dm) => {
                const matchedThuMamNon = thuMamNons.find((thuMamNon) => thuMamNon.code === dm.code);
                const row = {
                    dmTMNId: dm.id,
                    code: dm.code,
                    name: dm.name,
                    amount: matchedThuMamNon ? matchedThuMamNon.amount : '',
                    notes: matchedThuMamNon ? matchedThuMamNon.notes : '',
                    collectDate: matchedThuMamNon ? dateHelper.formatDate(matchedThuMamNon.collectDate) : '',
                    dm: matchedThuMamNon,
                };
                rows.push(row);
            });
            setRows(rows);
        }
    }, [dmThuMamNons, thuMamNons]);

    useEffect(() => {
        // const readOnly = dateHelper.isAfterNextMonth10thDate(monthYear);
        const columns = [
            { key: 'code', name: 'Mã số', editable: false, width: 60 },
            { key: 'name', name: 'Diễn giải', editable: false, width: 300 },
            { key: 'amount', name: 'Số tiền (ĐV: 1000đ)', editable: true, width: 250 },
            { key: 'notes', name: 'Ghi chú', editable: true, width: 300 },
            { key: 'collectDate', name: 'Ngày ghi', editable: false, width: 250 },
        ];
        setColumns(columns);
    }, [monthYear]);

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default ThuMamNonList;
