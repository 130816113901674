import { Box, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CTPVDanhMucDialog from './CTPVDanhMucDialog';
import { ctpv__get_list_danh_muc } from '../actions';
import CTPVDanhMucList from './CTPVDanhMucList';
import { toastHelper } from 'helpers/toastHelper';

function CTPVDanhMucPage() {
    const danhMucs = useSelector((state) => state.ctpvReducer.danhMucs, []);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const theme = useTheme();
    const [danhMuc, setDanhMuc] = useState(null);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const addDanhMucThu = () => {
        setDanhMuc({
            name: '',
            active: false,
        });
        openDialog();
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onAddDMThuSuccess = () => {
        dispatch(ctpv__get_list_danh_muc(userInfo.congDoanId, true));
        toastHelper.success('Lưu danh mục thành công!');
    };

    const onEditDMThuSuccess = () => {
        dispatch(ctpv__get_list_danh_muc(userInfo.congDoanId, true));
        toastHelper.success('Lưu danh mục thành công!');
    };

    const onDeleteSuccess = () => {
        dispatch(ctpv__get_list_danh_muc(userInfo.congDoanId, true));
    };

    useEffect(() => {
        dispatch(ctpv__get_list_danh_muc(userInfo.congDoanId, true));
    }, [dispatch, userInfo]);

    return (
        <>
            <div className="d-flex justify-content-between">
                <Box p={theme.spacing(0, 3)} className="d-flex align-items-center">
                    <Typography variant="h6">Danh mục công tác phục vụ</Typography>
                </Box>
                <Box p={theme.spacing(1, 1)}>
                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addDanhMucThu} title="Thêm danh mục">
                        <AddIcon />
                        Thêm danh mục
                    </button>
                </Box>
            </div>
            {danhMuc && <CTPVDanhMucDialog danhMuc={danhMuc} open={open} closeDialog={closeDialog} onSuccess={onAddDMThuSuccess} />}
            {danhMucs && <CTPVDanhMucList danhMucs={danhMucs} onEditSuccess={onEditDMThuSuccess} onDeleteSuccess={onDeleteSuccess} />}
        </>
    );
}

export default CTPVDanhMucPage;
