import httpHandler from './axios';

export const transport = {
  get,
  post,
  put,
  del,
};

function get(url, config = {}) {
  return httpHandler.get(`${url}`, config);
}

function post(url, data, config = {}) {
  return httpHandler.post(`${url}`, data, config);
}

function put(url, data, config = {}) {
  return httpHandler.put(`${url}`, data, config);
}

function del(url, config = {}) {
  return httpHandler.delete(`${url}`, config);
}
