import { transport } from 'config/transport';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getListDanhMucThuChiDauNamNhiemKy() {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetListDanhMucThuChiDauNamNhiemKy);
        yield put({
            type: FMI_ACTIONS.MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.MAMNON__CALL_FAILED,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListDanhMucThuChiDauNamNhiemKy() {
    return transport.get(`/mamnon/danhmucdaunam`);
}
