import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { FMI_DATE_FORMAT, FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiExportAbsences } from 'redux/saga/mam-non/attendance/absence/exportAbsences';
import { callApiGetAbsences } from 'redux/saga/mam-non/attendance/absence/getAbsences';
import { callApiUpdateAbsences } from 'redux/saga/mam-non/attendance/absence/updateAbsence';
import { loaded, loading } from '../actions';
import AbsencesList from './AbsencesList';

function MamNonDanhSachVangPage() {
    const { control, getValues, watch } = useForm();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const theme = useTheme();
    const dispatch = useDispatch();

    const [absences, setAbsences] = useState([]);
    const [data, setData] = useState(null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const getAbsences = useCallback(
        (date) => {
            setAbsences([]);
            setData(null);
            if (userInfo.congDoanId) {
                callApiGetAbsences({ date })
                    .then((res) => {
                        setAbsences(res.data);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            }
        },
        [userInfo.congDoanId]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.date) {
            getAbsences(dateHelper.formatDate(values.date, FMI_SERVER_DATE_FORMAT));
        }
    }, [values, watches, prevValues, getAbsences]);

    const onSave = () => {
        dispatch(loading());
        callApiUpdateAbsences(buildUpdateAbsencesBody())
            .then(() => {
                getAbsences(dateHelper.formatDate(values.date, FMI_SERVER_DATE_FORMAT));
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    const buildUpdateAbsencesBody = () => {
        return data.map((item) => {
            return { id: get(item, 'id'), description: get(item, 'description') };
        });
    };

    const onExport = () => {
        if (userInfo.congDoanId) {
            dispatch(loading());
            callApiExportAbsences(dateHelper.formatDate(values.date, FMI_SERVER_DATE_FORMAT))
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo danh sách vắng ngày ${dateHelper.formatDate(values.date, FMI_DATE_FORMAT)}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Danh sách vắng theo ngày</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="date">
                                        Ngày:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="date"
                                            name="date"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn ngày"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="dd.MM.yyyy"
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3"></div>

                            {absences && absences.length > 0 && (
                                <>
                                    {data ? (
                                        <>
                                            <div className="col-md-3">
                                                <div className="row mx-0 w-100">
                                                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onExport} title="Xuất Excel">
                                                        Xuất excel
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row mr-0 d-flex justify-content-end">
                                                    <div className="col-md-12 p-0 d-flex justify-content-end">
                                                        <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onSave} title="Lưu">
                                                            Lưu
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3">
                                                <div className="row mx-0 w-100">
                                                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onExport} title="Xuất Excel">
                                                        Xuất excel
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            <AbsencesList absences={absences} setData={setData} />
        </>
    );
}

export default MamNonDanhSachVangPage;
