import { Box, Divider, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import { trunguong__get_cong_doan_bao_cao_sai } from 'components/trung-uong-page/actions';
import { FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        maxHeight: 600,
    },
    tableHeader: {
        fontWeight: 600,
    },
});

function CongDoanBaoCaoSaiPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const congDoanBaoCaoSai = useSelector((state) => state.commonReducer.congDoanBaoCaoSai, null);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            dispatch(trunguong__get_cong_doan_bao_cao_sai({ yearMonth: dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT) }));
        }
    }, [values, watches, prevValues, dispatch]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Cộng đoàn báo cáo sai </Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {congDoanBaoCaoSai && (
                <>
                    <div className="row mx-0 mt-3">
                        <div className="col-md-9 pr-0">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeader}>STT</TableCell>
                                            <TableCell className={classes.tableHeader}>Cộng đoàn</TableCell>
                                            <TableCell className={classes.tableHeader}>Địa chỉ</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {congDoanBaoCaoSai.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell>{get(row, 'name', '')}</TableCell>
                                                <TableCell>{get(row, 'address', '') + ', ' + get(row, 'city', '')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default CongDoanBaoCaoSaiPage;
