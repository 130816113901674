import { toast } from 'react-toastify';

const DEFAULT_CONFIG = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: true,
    pauseOnHover: true,
    closeOnClick: true,
};

const success = (message, config = DEFAULT_CONFIG) => {
    toast.success(message, config);
};

const error = (message, config = DEFAULT_CONFIG) => {
    toast.error(message, config);
};

const warning = (message, config = DEFAULT_CONFIG) => {
    toast.warning(message, config);
};

export const toastHelper = {
    success,
    error,
    warning,
};
