export const ROLES = {
    ROLE_MAMNON_ADMIN: 'ROLE_MAMNON_ADMIN',
    ROLE_CONGDOAN_ADMIN: 'ROLE_CONGDOAN_ADMIN',
    ROLE_CTPV_ADMIN: 'ROLE_CTPV_ADMIN',
    ROLE_VIEWER: 'ROLE_VIEWER',
    ROLE_NOITRU_ADMIN: 'ROLE_NOITRU_ADMIN',
    ROLE_TRUNGUONG_ADMIN: 'ROLE_TRUNGUONG_ADMIN',
    ROLE_MAMNON_USER: 'ROLE_MAMNON_USER',
    ROLE_BANNGANH_ADMIN: 'ROLE_BANNGANH_ADMIN',
    ROLE_VUNG_ADMIN: 'ROLE_VUNG_ADMIN',
};
