import BanNganhPage from 'components/ban-nganh/BanNganhPage';
import BanNganhThuChiPage from 'components/ban-nganh/thuchi/BanNganhThuChiPage';
import BaoHiemYTePage from 'components/cong-doan-page/bao-hiem-y-te/BaoHiemYTePage';
import CongDoanPage from 'components/cong-doan-page/CongDoanPage';
import DuChiCaNhanPage from 'components/cong-doan-page/du-thu/du-chi-ca-nhan/DuChiCaNhanPage';
import DuChiCongDoanPage from 'components/cong-doan-page/du-thu/du-chi-cong-doan/DuChiCongDoanPage';
import DuThuCongDoanPage from 'components/cong-doan-page/du-thu/du-thu-cong-doan/DuThuCongDoanPage';
import ThuChiCongDoanPage from 'components/cong-doan-page/du-thu/thu-chi-cong-doan/ThuChiCongDoanPage';
import KhanDongPage from 'components/cong-doan-page/khan-dong/KhanDongPage';
import NunByMonthPage from 'components/cong-doan-page/nun/monthly/NunByMonthPage';
import NunPage from 'components/cong-doan-page/nun/NunPage';
import CongDoanQuanLiTaiSanPage from 'components/cong-doan-page/quan-li-tai-san/CongDoanQuanLiTaiSanPage';
import MonthlyReportPage from 'components/cong-doan-page/report/monthly-report/MonthlyReportPage';
import NhanSuReportPage from 'components/cong-doan-page/report/nhan-su-report/NhanSuReportPage';
import YearlyReportPage from 'components/cong-doan-page/report/yearly-report/YearlyReportPage';
import CongDoanChiCongDoanPage from 'components/cong-doan-page/thu-chi/chi-cong-doan/CongDoanChiCongDoanPage';
import CongDoanThuCongDoanPage from 'components/cong-doan-page/thu-chi/thu-cong-doan/CongDoanThuCongDoanPage';
import CongDoanTonThangTruocPage from 'components/cong-doan-page/thu-chi/ton-thang-truoc/CongDoanTonThangTruocPage';
import CongTacPhucVuPage from 'components/cong-tac-phuc-vu-page/CongTacPhucVuPage';
import CTPVDanhMucPage from 'components/cong-tac-phuc-vu-page/danh-muc/CTPVDanhMucPage';
import CongTacPhucVuBaoCaoNamPage from 'components/cong-tac-phuc-vu-page/report/CongTacPhucVuBaoCaoNamPage';
import CTPVThuChiPage from 'components/cong-tac-phuc-vu-page/thu-chi/CTPVThuChiPage';
import MamNonChiMamNonPage from 'components/mam-non-page/chi-mam-non/MamNonChiMamNonPage';
import MamNonClazzPage from 'components/mam-non-page/clazz/MamNonClazzPage';
import MamNonClazzUpgradePage from 'components/mam-non-page/clazz/upgrade/MamNonClazzUpgradePage';
import MamNonDangKiMonHocPage from 'components/mam-non-page/dang-ki-mon-hoc-tu-chon/MamNonDangKiMonHocPage';
import MamNonDanhMucThuPage from 'components/mam-non-page/danh-muc-thu/MamNonDanhMucThuPage';
import MamNonDanhSachVangPage from 'components/mam-non-page/danh-sach-vang/MamNonDanhSachVangPage';
import MamNonDanhSachPage from 'components/mam-non-page/danh-sach/MamNonDanhSachPage';
import MamNonDiemDanhPage from 'components/mam-non-page/diem-danh/MamNonDiemDanhPage';
import MamNonDuThuPage from 'components/mam-non-page/du-thu/MamNonDuThuPage';
import MamNonHolidayPage from 'components/mam-non-page/holiday/MamNonHolidayPage';
import MamNonPage from 'components/mam-non-page/MamNonPage';
import MamNonUserPage from 'components/mam-non-page/MamNonUserPage';
import MamNonPhieuThuPage from 'components/mam-non-page/phieu-thu/MamNonPhieuThuPage';
import MamNonQuanLiTaiSanPage from 'components/mam-non-page/quan-li-tai-san/MamNonQuanLiTaiSanPage';
import MamNonMonthlyReportPage from 'components/mam-non-page/report/monthly-report/MamNonMonthlyReportPage';
import MamNonYearlyReportPage from 'components/mam-non-page/report/yearly-report/MamNonYearlyReportPage';
import MamNonThuDauNamNhiemKyPage from 'components/mam-non-page/thu-dau-nam-nhiem-ky/MamNonThuDauNamNhiemKyPage';
import MamNonThuChiDauNamPage from 'components/mam-non-page/thu-dau-nam/bao-cao/MamNonThuChiDauNamPage';
import MamNonThuDauNamPage from 'components/mam-non-page/thu-dau-nam/MamNonThuDauNamPage';
import MamNonThuMamNonPage from 'components/mam-non-page/thu-mam-non/MamNonThuMamNonPage';
import MamNonThuTienPage from 'components/mam-non-page/thu-tien/MamNonThuTienPage';
import MamNonTraCuuThuTienMonthlyPage from 'components/mam-non-page/thu-tien/tra-cuu-1-month/MamNonTraCuuThuTienMonthlyPage';
import MamNonTraCuuThuTienPage from 'components/mam-non-page/thu-tien/tra-cuu/MamNonTraCuuThuTienPage';
import MamNonTongHopDiemDanhPage from 'components/mam-non-page/tong-hop-diem-danh/MamNonTongHopDiemDanhPage';
import NoiTruPage from 'components/noi-tru/NoiTruPage';
import NoiTruQuanLiTaiSanPage from 'components/noi-tru/quan-li-tai-san/NoiTruQuanLiTaiSanPage';
import NoiTruNumberOfStudentPage from 'components/noi-tru/students/NoiTruNumberOfStudentPage';
import ChiNoiTruPage from 'components/noi-tru/thu-chi/chi-noi-tru/ChiNoiTruPage';
import NoiTruMonthlyReportPage from 'components/noi-tru/thu-chi/report/monthly-report/NoiTruMonthlyReportPage';
import NoiTruYearlyReportPage from 'components/noi-tru/thu-chi/report/yearly-report/NoitruYearlyReportPage';
import ThuNoiTruPage from 'components/noi-tru/thu-chi/thu-noi-tru/ThuNoiTruPage';
import CongDoanBaoCaoSaiPage from 'components/trung-uong-page/bao-cao-sai/cong-doan/CongDoanBaoCaoSaiPage';
import MamNonBaoCaoSaiPage from 'components/trung-uong-page/bao-cao-sai/mam-non/MamNonBaoCaoSaiPage';
import DanhSachCongDoanTrungUongPage from 'components/trung-uong-page/cong-doan/DanhSachCongDoanTrungUongPage';
import DuThuChiCongDoanReportOverviewPage from 'components/trung-uong-page/duthuchi/report-overview/DuThuChiCongDoanReportOverviewPage';
import CongDoanExchangeRatePage from 'components/trung-uong-page/exchange-rate/CongDoanExchangeRatePage';
import CongDoanReportOverviewPage from 'components/trung-uong-page/report-overview/CongDoanReportOverviewPage';
import TWBanNganhReportPage from 'components/trung-uong-page/report/ban-nganh/TWBanNganhReportPage';
import TrungUongCongDoanAnnualPage from 'components/trung-uong-page/report/congdoan/annual/TrungUongCongDoanAnnualPage';
import TrungUongCongDoanMonthPage from 'components/trung-uong-page/report/congdoan/month/TrungUongCongDoanMonthPage';
import TrungUongCTPVPage from 'components/trung-uong-page/report/ctpv/TrungUongCTPVPage';
import TrungUongDuChiPage from 'components/trung-uong-page/report/duchi/TrungUongDuChiPage';
import TrungUongDuThuPage from 'components/trung-uong-page/report/duthu/TrungUongDuThuPage';
import TWMamNonReportAnnualPage from 'components/trung-uong-page/report/mamnon/annual/TWMamNonReportAnnualPage';
import TWMamNonReportMonthPage from 'components/trung-uong-page/report/mamnon/month/TWMamNonReportMonthPage';
import TWNoiTruReportAnnualPage from 'components/trung-uong-page/report/noitru/annual/TWNoiTruReportAnnualPage';
import TWNoiTruReportMonthPage from 'components/trung-uong-page/report/noitru/month/TWNoiTruReportMonthPage';
import TWCongDoanVungAnnuallyReportPage from 'components/trung-uong-page/report/vung/annual/TWCongDoanVungAnnuallyReportPage';
import TWCongDoanVungMonthReportPage from 'components/trung-uong-page/report/vung/month/TWCongDoanVungMonthReportPage';
import TrungUongPage from 'components/trung-uong-page/TrungUongPage';
import ChiVungPage from 'components/vung-page/chi/ChiVungPage';
import VungAnnuallyReportPage from 'components/vung-page/report/annually/VungAnnuallyReportPage';
import VungMonthlyReportPage from 'components/vung-page/report/monthly/VungMonthlyReportPage';
import ThuVungPage from 'components/vung-page/thu/ThuVungPage';
import VungTonThangTruocPage from 'components/vung-page/ton-thang-truoc/VungTonThangTruocPage';
import VungPage from 'components/vung-page/VungPage';
import Forbidden from '../../components/exception/Forbidden';
import NotFound from '../../components/exception/NotFound';
import HomePage from '../../components/home-page/HomePage';

export const mamNonRoutes = [
    {
        path: '/mam-non',
        component: MamNonPage,
    },
    {
        path: '/mam-non/diem-danh',
        component: MamNonDiemDanhPage,
    },
    {
        path: '/mam-non/danh-sach',
        component: MamNonDanhSachPage,
    },
    {
        path: '/mam-non/ngay-nghi',
        component: MamNonHolidayPage,
    },
    {
        path: '/mam-non/danh-muc-thu',
        component: MamNonDanhMucThuPage,
    },
    {
        path: '/mam-non/dang-ki-mon-hoc',
        component: MamNonDangKiMonHocPage,
    },
    {
        path: '/mam-non/du-thu',
        component: MamNonDuThuPage,
    },
    {
        path: '/mam-non/thu-dau-nam',
        component: MamNonThuDauNamPage,
    },
    {
        path: '/mam-non/phieu-thu',
        component: MamNonPhieuThuPage,
    },
    {
        path: '/mam-non/clazzes',
        component: MamNonClazzPage,
    },
    {
        path: '/mam-non/thu-tien',
        component: MamNonThuTienPage,
    },
    {
        path: '/mam-non/thu-mam-non',
        component: MamNonThuMamNonPage,
    },
    {
        path: '/mam-non/chi-mam-non',
        component: MamNonChiMamNonPage,
    },
    {
        path: '/mam-non/clazzes/upgrade',
        component: MamNonClazzUpgradePage,
    },
    {
        path: '/mam-non/bao-cao/thang',
        component: MamNonMonthlyReportPage,
    },
    {
        path: '/mam-non/bao-cao/nam',
        component: MamNonYearlyReportPage,
    },
    {
        path: '/mam-non/danh-sach-vang',
        component: MamNonDanhSachVangPage,
    },
    {
        path: '/mam-non/tong-hop-diem-danh',
        component: MamNonTongHopDiemDanhPage,
    },
    {
        path: '/mam-non/tra-cuu-thu-tien',
        component: MamNonTraCuuThuTienPage,
    },
    {
        path: '/mam-non/tra-cuu-thu-tien-by-month',
        component: MamNonTraCuuThuTienMonthlyPage,
    },
    {
        path: '/mam-non/bao-cao/thu-dau-nam',
        component: MamNonThuChiDauNamPage,
    },
    {
        path: '/mam-non/thu-dau-nam-nhiem-ky',
        component: MamNonThuDauNamNhiemKyPage,
    },
    {
        path: '/mam-non/quan-li-tai-san',
        component: MamNonQuanLiTaiSanPage,
    },
];

export const mamNonUserRoutes = [
    {
        path: '/mam-non-user',
        component: MamNonUserPage,
    },
    {
        path: '/mam-non/diem-danh',
        component: MamNonDiemDanhPage,
    },
];

const congDoanRoutes = [
    {
        path: '/cong-doan',
        component: CongDoanPage,
    },
    {
        path: '/cong-doan/thu-cong-doan',
        component: CongDoanThuCongDoanPage,
    },
    {
        path: '/cong-doan/chi-cong-doan',
        component: CongDoanChiCongDoanPage,
    },
    {
        path: '/cong-doan/ton-thang-truoc',
        component: CongDoanTonThangTruocPage,
    },
    {
        path: '/cong-doan/bao-cao/thang',
        component: MonthlyReportPage,
    },
    {
        path: '/cong-doan/bao-cao/nam',
        component: YearlyReportPage,
    },
    {
        path: '/cong-doan/du-thu/du-chi-ca-nhan',
        component: DuChiCaNhanPage,
    },
    {
        path: '/cong-doan/du-thu/du-thu-cong-doan',
        component: DuThuCongDoanPage,
    },
    {
        path: '/cong-doan/du-thu/du-chi-cong-doan',
        component: DuChiCongDoanPage,
    },
    {
        path: '/cong-doan/du-thu/thu-chi-cong-doan',
        component: ThuChiCongDoanPage,
    },
    {
        path: '/cong-doan/nun',
        component: NunPage,
    },
    {
        path: '/cong-doan/nun/numbers',
        component: NunByMonthPage,
    },
    {
        path: '/cong-doan/bhyt',
        component: BaoHiemYTePage,
    },
    {
        path: '/cong-doan/khan-dong',
        component: KhanDongPage,
    },
    {
        path: '/cong-doan/bao-cao/nhan-su',
        component: NhanSuReportPage,
    },
    {
        path: '/cong-doan/quan-li-tai-san',
        component: CongDoanQuanLiTaiSanPage,
    },
];

const congTacPhucVuRoutes = [
    {
        path: '/cong-tac-phuc-vu',
        component: CongTacPhucVuPage,
    },
    {
        path: '/ctpv/danh-muc',
        component: CTPVDanhMucPage,
    },
    {
        path: '/ctpv/thu-chi',
        component: CTPVThuChiPage,
    },
    {
        path: '/ctpv/thu-chi/report',
        component: CongTacPhucVuBaoCaoNamPage,
    },
];

export const noiTruRoutes = [
    {
        path: '/noi-tru',
        component: NoiTruPage,
    },
    {
        path: '/noi-tru/thu-chi/chi-noi-tru',
        component: ChiNoiTruPage,
    },
    {
        path: '/noi-tru/thu-chi/thu-noi-tru',
        component: ThuNoiTruPage,
    },
    {
        path: '/noi-tru/bao-cao/thang',
        component: NoiTruMonthlyReportPage,
    },
    {
        path: '/noi-tru/bao-cao/nam',
        component: NoiTruYearlyReportPage,
    },
    {
        path: '/noi-tru/students',
        component: NoiTruNumberOfStudentPage,
    },
    {
        path: '/noi-tru/quan-li-tai-san',
        component: NoiTruQuanLiTaiSanPage,
    },
];

export const trungUongRoutes = [
    {
        path: '/trung-uong',
        component: TrungUongPage,
    },
    {
        path: '/trung-uong/cong-doan',
        component: DanhSachCongDoanTrungUongPage,
    },
    {
        path: '/cong-doan/exchange-rate',
        component: CongDoanExchangeRatePage,
    },
    {
        path: '/cong-doan/report/overview',
        component: CongDoanReportOverviewPage,
    },
    {
        path: '/trung-uong/duthu',
        component: TrungUongDuThuPage,
    },
    {
        path: '/trung-uong/duchi',
        component: TrungUongDuChiPage,
    },
    {
        path: '/trung-uong/ctpv',
        component: TrungUongCTPVPage,
    },
    {
        path: '/trung-uong/congdoan/annual',
        component: TrungUongCongDoanAnnualPage,
    },
    {
        path: '/trung-uong/congdoan/month',
        component: TrungUongCongDoanMonthPage,
    },
    {
        path: '/trung-uong/mamnon/annual',
        component: TWMamNonReportAnnualPage,
    },
    {
        path: '/trung-uong/mamnon/month',
        component: TWMamNonReportMonthPage,
    },
    {
        path: '/trung-uong/noitru/annual',
        component: TWNoiTruReportAnnualPage,
    },
    {
        path: '/trung-uong/noitru/month',
        component: TWNoiTruReportMonthPage,
    },
    {
        path: '/trung-uong/du-thu-chi/overview',
        component: DuThuChiCongDoanReportOverviewPage,
    },
    {
        path: '/trung-uong/vung/annual',
        component: TWCongDoanVungAnnuallyReportPage,
    },
    {
        path: '/trung-uong/vung/month',
        component: TWCongDoanVungMonthReportPage,
    },
    {
        path: '/trung-uong/ban-nganh',
        component: TWBanNganhReportPage,
    },
    {
        path: '/trung-uong/mam-non/bao-cao-sai',
        component: MamNonBaoCaoSaiPage,
    },
    {
        path: '/trung-uong/cong-doan/bao-cao-sai',
        component: CongDoanBaoCaoSaiPage,
    },
];

export const banNganhRoutes = [
    {
        path: '/ban-nganh',
        component: BanNganhPage,
    },
    {
        path: '/ban-nganh/thu-chi',
        component: BanNganhThuChiPage,
    },
];

export const vungRoutes = [
    {
        path: '/vung',
        component: VungPage,
    },
    {
        path: '/vung/thu-vung',
        component: ThuVungPage,
    },
    {
        path: '/vung/chi-vung',
        component: ChiVungPage,
    },
    {
        path: '/vung/ton-dau-thang',
        component: VungTonThangTruocPage,
    },
    {
        path: '/vung/monthly-report',
        component: VungMonthlyReportPage,
    },
    {
        path: '/vung/annually-report',
        component: VungAnnuallyReportPage,
    },
];

export const homeRoutes = [
    {
        path: '/',
        component: HomePage,
        exact: true,
    },
    {
        path: '/home',
        component: HomePage,
        exact: true,
    },
];

const protectedRoutes = [...homeRoutes, ...congDoanRoutes, ...congTacPhucVuRoutes];

export const errorRoutes = [
    {
        path: '/not-found',
        component: NotFound,
    },
    {
        path: '/access-denied',
        component: Forbidden,
    },
    {
        component: NotFound,
    },
];

export default protectedRoutes;
