import React from 'react';
import { Switch, Route } from 'react-router-dom';
import nonProtectedRoutes from '../../../config/routes/nonProtectedRoutes';

function NonProtectedSwitcher() {
    return (
        <Switch>
            {nonProtectedRoutes.map((route, index) => {
                return <Route exact key={index} path={route.path} component={route.component} />;
            })}
        </Switch>
    );
}

export default NonProtectedSwitcher;
