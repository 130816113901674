import { ASSET_TYPE } from 'config/constants/assetConstant';
import { messageHelper } from 'helpers/messageHelper';
import React from 'react';
import { useSelector } from 'react-redux';
import { callApiSaveAsset } from 'redux/saga/quan-li-tai-san/saveAsset';
import BankAccountDialog from './BankAccountDialog';
import BankCardDialog from './BankCardDialog';
import CommonAssetDialog from './CommonAssetDialog';
import DatDaiDialog from './DatDaiDialog';
import XeDialog from './XeDialog';

function AssetDialog({ asset, open, closeDialog, assetType, academicYear, onSaveAssetSuccess, assetSource }) {
    const congDoanId = useSelector((state) => state.authenticationReducer.userInfo.congDoanId, null);

    const getAccountType = () => {
        return assetType === ASSET_TYPE.BANKACCOUNT ? 'ACCOUNT' : 'CARD';
    };

    const onSaveAsset = (body) => {
        if (congDoanId && academicYear) {
            callApiSaveAsset(congDoanId, academicYear, {
                ...body,
                ...{
                    id: asset.id,
                    assetSource,
                    assetType: assetType === ASSET_TYPE.BANKCARD ? ASSET_TYPE.BANKACCOUNT : assetType,
                    accountType: [ASSET_TYPE.BANKACCOUNT, ASSET_TYPE.BANKCARD].includes(assetType) ? getAccountType() : undefined,
                    soLuong: +body.soLuong,
                },
            })
                .then(() => {
                    onSaveAssetSuccess();
                })
                .catch((err) => messageHelper.showError(err));
        }
    };

    switch (assetType) {
        case ASSET_TYPE.DATDAI:
            return <DatDaiDialog open={open} datdai={asset} closeDialog={closeDialog} onSaveAsset={onSaveAsset} />;
        case ASSET_TYPE.XE:
            return <XeDialog open={open} xe={asset} closeDialog={closeDialog} onSaveAsset={onSaveAsset} />;
        case ASSET_TYPE.BANKACCOUNT:
            return <BankAccountDialog open={open} bankAccount={asset} closeDialog={closeDialog} onSaveAsset={onSaveAsset} />;
        case ASSET_TYPE.BANKCARD:
            return <BankCardDialog open={open} bankCard={asset} closeDialog={closeDialog} onSaveAsset={onSaveAsset} />;
        default:
            return <CommonAssetDialog open={open} asset={asset} assetType={assetType} closeDialog={closeDialog} onSaveAsset={onSaveAsset} />;
    }
}

export default AssetDialog;
