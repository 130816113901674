import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import Header from './components/app-navigator/Header';
import Footer from './components/app-navigator/Footer';
import NonProtectedSwitcher from './components/app-navigator/router/NonProtectedSwitcher';
import ProtectedSwitcher from './components/app-navigator/router/ProtectedSwitcher';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-circular-progressbar/dist/styles.css';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { defaultColor } from 'config/constants/colorConstant';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        minHeight: 40,
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
}));

function App() {
    const signedIn = useSelector((state) => state.authenticationReducer.signedIn, false);
    const loading = useSelector((state) => state.commonReducer.loading, false);
    const classes = useStyles();

    return (
        // <StrictMode>
        <Suspense
            fallback={
                /* Do not need to create the component and style separatedly for fasting load */
                <div className="global-loading-status">Loading</div>
            }
        >
            <LoadingOverlay active={loading} spinner={<PacmanLoader color={defaultColor} />}>
                {signedIn ? (
                    <>
                        <Header />
                        <main className={clsx(classes.content)}>
                            <ProtectedSwitcher />
                        </main>
                        <Footer />
                    </>
                ) : (
                    <NonProtectedSwitcher />
                )}
                <ToastContainer />
            </LoadingOverlay>
        </Suspense>
        // </StrictMode>
    );
}

export default App;
