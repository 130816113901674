import React from 'react';

function Forbidden() {
  return (
    <section className='hero is-danger'>
      <div className='hero-body'>
        <div className='container'>
          <h1 className='title'>Access restriction</h1>
          <h2 className='subtitle'>This is the error page</h2>
        </div>
      </div>
    </section>
  );
}

export default Forbidden;
