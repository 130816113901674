import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_info } from 'components/authentication/actions';
import { LOAI_THUCHI_DAUNAM_NHIEMKY, THUCHI_DAUNAM_NHIEMKY_OPTIONS } from 'config/constants/danhMucThuConstant';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get, groupBy, split } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportThuChiDauNamNhiemKy } from 'redux/saga/mam-non/thu-chi/thu-chi-dau-nam-nhiem-ky/exportThuChiDauNamNhiemKy';
import { callApiGetThuChiDauNamNhiemKy } from 'redux/saga/mam-non/thu-chi/thu-chi-dau-nam-nhiem-ky/getThuChiDauNamNhiemKy';
import { callApiSaveThuChiDauNamNhiemKy } from 'redux/saga/mam-non/thu-chi/thu-chi-dau-nam-nhiem-ky/saveThuChiDauNamNhiemKy';
import { academic_year__get_list, loaded, loading, mamnon__get_list_danh_muc_thuchi_daunam_nhiemky } from '../actions';
import MamNonThuDauNamNhiemKyList from './MamNonThuDauNamNhiemKyList';

function MamNonThuDauNamNhiemKyPage() {
    const theme = useTheme();
    const { control, setValue, getValues, watch, trigger } = useForm({
        defaultValues: {
            loaiThuChiDauNam: { value: LOAI_THUCHI_DAUNAM_NHIEMKY.ALL, label: 'Tất cả' },
        },
    });

    const watches = watch();
    const dispatch = useDispatch();

    const [thuchis, setThuChis] = useState([]);
    const [totalThuChis, setTotalThuChis] = useState([]);
    const [data, setData] = useState(null);
    const [optionClassName, setOptionClassName] = useState('has-default');

    const values = getValues();
    const prevValues = usePrevious(values);
    const commonReducer = useSelector((state) => state.commonReducer);
    const danhMucs = useSelector((state) => state.mamnonReducer.danhMucThuChiDauNamNhiemKys, []);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const loaiThuChiDauNamNhiemKyOptions = THUCHI_DAUNAM_NHIEMKY_OPTIONS.map((type) => {
        return { value: type.key, label: type.label };
    });

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    useEffect(() => {
        dispatch(mamnon__get_list_danh_muc_thuchi_daunam_nhiemky());
    }, [dispatch]);

    const getThuChis = useCallback(
        (congDoanId, academicYear, loaiThuChiDauNam) => {
            dispatch(loading());
            setData(null);
            callApiGetThuChiDauNamNhiemKy(congDoanId, academicYear, loaiThuChiDauNam)
                .then((res) => {
                    setThuChis(res.data.thuchis);
                    setTotalThuChis(res.data.total);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            if (userInfo.congDoanId) {
                getThuChis(userInfo.congDoanId, academicYear, values.loaiThuChiDauNam.value);
            }
        }
    }, [values, watches, prevValues, getThuChis, userInfo.congDoanId]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const onOptionChange = (selectedOption) => {
        setValue('loaiThuChiDauNam', selectedOption);
        if (optionClassName !== '') {
            setOptionClassName('');
        }
    };

    const buildBody = () => {
        const res = {};
        Object.entries(groupBy(data, 'month')).forEach(([key, value]) => {
            const thuchiDtos = buildListThuChiDto(key, value);
            if (thuchiDtos.length) {
                res[key] = thuchiDtos;
            }
        });
        return res;
    };

    const buildListThuChiDto = (month, inputList) => {
        const res = [];
        Object.entries(inputList[0]).forEach(([k, v]) => {
            if (k !== 'month' && k !== LOAI_THUCHI_DAUNAM_NHIEMKY.TOTAL) {
                const splittedKey = split(k, '_', 3);
                if (v !== '' || isExistedThuChi(month, splittedKey)) {
                    res.push({ danhMucId: +splittedKey.at(0), type: splittedKey.at(1), value: +v, refund: splittedKey.at(2) !== undefined ? true : false });
                }
            }
        });
        return res;
    };

    const isExistedThuChi = (month, splittedKey) => {
        const matchedThuChi = thuchis.find((thuchi) => thuchi.yearMonth === month && thuchi.danhMucId === +splittedKey[0] && thuchi.type === splittedKey[1]);
        return matchedThuChi !== undefined;
    };

    const onSave = () => {
        const academicYear = get(values, 'academicYear.value.name');
        const loaiThuChiDauNam = get(values, 'loaiThuChiDauNam.value');
        if (userInfo.congDoanId && values.academicYear) {
            const body = buildBody();
            dispatch(loading());
            callApiSaveThuChiDauNamNhiemKy(userInfo.congDoanId, academicYear, body)
                .then((data) => {
                    toastHelper.success('Lưu thành công!');
                    getThuChis(userInfo.congDoanId, academicYear, loaiThuChiDauNam);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onExport = () => {
        const academicYear = get(values, 'academicYear.value.name');
        if (userInfo.congDoanId && academicYear) {
            callApiExportThuChiDauNamNhiemKy(userInfo.congDoanId, academicYear)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Thu chi đầu năm nhiệm kỳ cộng đoàn ${get(congDoanInfo, 'congDoanName', '')} năm học ${academicYear} .xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Thu chi đầu năm nhiệm kỳ</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy thu chi đầu năm!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row mr-0">
                                    <label htmlFor="loaiThuChiDauNam" className="mb-0 col-md-4 fmi-label pr-0">
                                        Loại nhập
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="loaiThuChiDauNam"
                                            control={control}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        options={loaiThuChiDauNamNhiemKyOptions}
                                                        value={value}
                                                        onChange={onOptionChange}
                                                        id="loaiThuChiDauNam"
                                                        classNamePrefix="fmi-react-select"
                                                        className={optionClassName}
                                                        styles={customSelectStyles}
                                                    ></Select>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            {values.academicYear && (
                                <div className="col-md-3 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                                    <div className="row mb-2 mx-0 w-100">
                                        <button
                                            type="button"
                                            className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true })}
                                            onClick={onExport}
                                            title="Xuất excel"
                                        >
                                            Xuất excel
                                        </button>
                                    </div>
                                    <div className="row mx-0 w-100">
                                        <button
                                            type="button"
                                            className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true })}
                                            disabled={data === null}
                                            onClick={onSave}
                                            title="Lưu"
                                        >
                                            Lưu
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.academicYear && (
                <MamNonThuDauNamNhiemKyList
                    academicYear={values.academicYear.value}
                    danhMucs={danhMucs}
                    data={data}
                    loaiThuChi={values.loaiThuChiDauNam.value}
                    setData={setData}
                    thuchis={thuchis}
                    totalThuChis={totalThuChis}
                />
            )}
        </>
    );
}

export default MamNonThuDauNamNhiemKyPage;
