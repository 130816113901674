import history from '../../helpers/history';
import jwtHelpers from '../../helpers/jwtHelpers';
import FMI_ACTIONS from '../actions';

function isSignedIn() {
    if (jwtHelpers.getAccessToken()) {
        return true;
    } else {
        history.push('/');
        return false;
    }
}

const initialState = isSignedIn()
    ? {
          signedIn: true,
          userInfo: jwtHelpers.getUserInfoAsObject(),
      }
    : {
          signedIn: false,
      };

const authenticationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FMI_ACTIONS.AUTHENTICATION__AUTHENTICATE_SUCCESS: {
            const userInfo = {
                congDoanId: payload.congdoanid,
                roles: payload.roles,
                username: payload.username,
            };
            sessionStorage.setItem('accessToken', payload.token);
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            return {
                signedIn: true,
                userInfo: userInfo,
            };
        }
        case FMI_ACTIONS.AUTHENTICATION__SIGN_OUT_SUCCESS:
            return {
                ...state,
                signedIn: false,
                userInfo: undefined,
            };
        case FMI_ACTIONS.AUTHENTICATION__FAIL:
            return {
                error: payload,
            };
        default:
            return state;
    }
};

export default authenticationReducer;
