import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import NunDialog from './NunDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { callApiDeleteNun } from 'redux/saga/cong-doan/info/nun/deleteNun';
import { alert } from 'helpers/alertHelper';
import { dateHelper } from 'helpers/dateHelper';
import { FMI_DATE_FORMAT } from 'config/constants/dateConstants';
import { useSelector } from 'react-redux';

const EmptyRowsView = () => {
    const message = 'Chưa có sơ nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function NunList({ nuns, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedNun, setSelectedNun] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedNun(null);
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        nuns.forEach((nun, index) => {
            const row = {
                index: index + 1,
                id: nun.id,
                fullName: nun.fullName,
                cmnd: nun.cmnd,
                phone: nun.phone,
                noiSinh: nun.noiSinh,
                namSinh: nun.namSinh ? dateHelper.formatDate(nun.namSinh, FMI_DATE_FORMAT) : '',
                active: nun.active,
                nun,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [nuns]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', width: 50 },
            { key: 'fullName', name: 'Tên sơ', width: 250 },
            { key: 'namSinh', name: 'Ngày sinh', width: 150 },
            { key: 'phone', name: 'SĐT', width: 150 },
            { key: 'cmnd', name: 'CMND', width: 150 },
            { key: 'noiSinh', name: 'Nơi Sinh', width: 250 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onEditDanhMucSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedNun(row.nun);
                    showConfirmDialog(row.nun);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedNun(row.nun);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá sơ này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                callApiDeleteNun(userInfo.congDoanId, selectedItem.id)
                    .then((res) => {
                        onDeleteSuccess();
                        alert({ icon: 'success', title: 'Xoá sơ nun thành công', showConfirmButton: false });
                    })
                    .catch((error) => {
                        alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá sơ! Vui lòng thử lại sau.' });
                    });
            }
        });
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedNun && <NunDialog nun={selectedNun} open={open} closeDialog={closeDialog} onSuccess={onEditDanhMucSuccess} />}
        </div>
    );
}

export default NunList;
