import axios from 'axios';
import history from '../helpers/history';
import jwtHelpers from '../helpers/jwtHelpers';
import environmentConstants from './constants/environmentConstants';

const httpHandler = axios.create({
    baseURL: environmentConstants.ROOT_URL,
});

httpHandler.interceptors.request.use(
    function intercept(config) {
        const interceptedConfig = config;
        if (jwtHelpers.getAccessToken()) {
            interceptedConfig.headers = {
                Authorization: `Bearer ${jwtHelpers.getAccessToken()}`,
            };
        }
        return interceptedConfig;
    },
    function interceptError(error) {
        return Promise.reject(error);
    }
);

httpHandler.interceptors.response.use(
    function intercept(response) {
        return response;
    },
    function interceptError(error) {
        // const originalRequest = error.config;

        switch (error.response.status) {
            case 403:
                history.push('/403');
                jwtHelpers.clearSession();
                return Promise.reject(error);
            case 401:
                jwtHelpers.clearSession();
                history.push('/');
                return Promise.reject(error);
            default:
                return Promise.reject(error);
        }
    }
);

export default httpHandler;
