import { GENDERS } from 'config/constants/genderConstant';
import { dateHelper } from 'helpers/dateHelper';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteIcon from '@material-ui/icons/Delete';

import './styles.scss';
import StudentDialog from './StudentDialog';
import { callApiDeleteStudent } from 'redux/saga/mam-non/info/class/student/deleteStudent';
import { callApiRestoreStudent } from 'redux/saga/mam-non/info/class/student/restoreStudent';
import { alert } from 'helpers/alertHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';

const EmptyRowsView = () => {
    const message = 'Chưa có học sinh nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

const RowRenderer = ({ renderBaseRow, ...props }) => {
    const isActive = props.row.isActive;
    return <div style={{ opacity: isActive ? 1 : 0.4 }}>{renderBaseRow(props)}</div>;
};

function StudentList({ students, classId, onEditSuccess, clazz, onDeleteSuccess, onRestoreSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState();
    const [open, setOpen] = useState(false);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        students.forEach((student, index) => {
            const row = {
                index: student.stt,
                firstName: student.firstName,
                lastName: student.lastName,
                gender: GENDERS[student.gender],
                dateOfBirth: dateHelper.formatDate(student.dateOfBirth),
                entryDate: dateHelper.formatDate(student.entryDate),
                fatherName: student.fatherName,
                fatherPhone: student.fatherPhone,
                motherName: student.motherName,
                motherPhone: student.motherPhone,
                studentId: student.id,
                student: student,
                isActive: student.isActive,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [students]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'lastName', name: 'Họ đệm', frozen: true, width: 250 },
            { key: 'firstName', name: 'Tên', frozen: true, width: 100 },
            { key: 'gender', name: 'Giới tính', width: 85 },
            { key: 'dateOfBirth', name: 'Ngày sinh', width: 120 },
            { key: 'entryDate', name: 'Ngày vào lớp', width: 120 },
            { key: 'fatherName', name: 'Họ tên cha', width: 140 },
            { key: 'fatherPhone', name: 'Điện thoại cha', width: 140 },
            { key: 'motherName', name: 'Họ tên mẹ', width: 140 },
            { key: 'motherPhone', name: 'Điện thoại mẹ', width: 140 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const showRestoreConfirmDialog = (selectedItem) => {
        alert({
            title: `Bạn có chắc muốn khôi phục học sinh ${selectedItem.lastName} ${selectedItem.firstName}?`,
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                restoreStudent(selectedItem);
            }
        });
    };

    const restoreStudent = (selectedItem) => {
        if (selectedItem.studentId && classId) {
            callApiRestoreStudent(classId, selectedItem.studentId)
                .then((res) => {
                    alert({ icon: 'success', title: 'Khôi phục học sinh thành công', showConfirmButton: false });
                    onRestoreSuccess();
                })
                .catch((err) => {
                    alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi khôi phục học sinh! Vui lòng thử lại sau.' });
                });
        }
    };

    const showDeleteConfirmDialog = (selectedItem) => {
        alert({
            title: `Bạn có chắc muốn xoá học sinh ${selectedItem.lastName} ${selectedItem.firstName}?`,
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteStudent(selectedItem);
            }
        });
    };

    const deleteStudent = (selectedItem) => {
        if (selectedItem.studentId && classId) {
            callApiDeleteStudent(classId, selectedItem.studentId)
                .then((res) => {
                    alert({ icon: 'success', title: 'Xoá học sinh thành công', showConfirmButton: false });
                    onDeleteSuccess();
                })
                .catch((err) => {
                    alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá học sinh! Vui lòng thử lại sau.' });
                });
        }
    };

    const actionColumnActions = (row) => {
        let actions = [];
        if (row.isActive) {
            actions = [
                {
                    icon: <DeleteIcon />,
                    callback: () => {
                        showDeleteConfirmDialog(row);
                    },
                },
                {
                    icon: <EditIcon />,
                    callback: () => {
                        setSelectedStudent(row.student);
                        openDialog();
                    },
                },
            ];
        } else {
            actions = [
                {
                    icon: <RestoreIcon />,
                    callback: () => {
                        showRestoreConfirmDialog(row);
                    },
                },
            ];
        }
        return actions;
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const onEditStudentSuccess = () => {
        onEditSuccess();
    };

    return (
        <div className="student-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                getCellActions={getCellActions}
                rowRenderer={RowRenderer}
                emptyRowsView={EmptyRowsView}
            />
            {selectedStudent && <StudentDialog student={selectedStudent} students={students} open={open} clazz={clazz} closeDialog={closeDialog} classId={classId} onSuccess={onEditStudentSuccess} />}
        </div>
    );
}

export default StudentList;
