import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authentication__sign_in } from './actions';

function SignInPage() {
    const { register, handleSubmit, errors } = useForm();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(authentication__sign_in(data));
    };

    return (
        <div className="container is-fullheight">
            <div className="row align-items-center justify-content-center is-fullheight">
                <div className="col-md-6">
                    <div className="form-block">
                        <div className="text-center mb-5">
                            <h3>
                                Đăng nhập vào <strong>FMI Phu Xuan Hue</strong>
                            </h3>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group first">
                                <label htmlFor="username">Tên đăng nhập</label>
                                <input
                                    type="text"
                                    className={clsx('form-control', errors.username && 'border-danger')}
                                    placeholder="Nhập tên đăng nhập"
                                    id="username"
                                    name="username"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Vui lòng nhập tên đăng nhập!',
                                        },
                                    })}
                                />
                                {errors.username && <div className="text-danger pt-2">{errors.username.message}</div>}
                            </div>
                            <div className="form-group last mb-3">
                                <label htmlFor="password">Mật khẩu</label>
                                <input
                                    type="password"
                                    className={clsx('form-control', errors.password && 'border-danger')}
                                    placeholder="Nhập mật khẩu"
                                    id="password"
                                    name="password"
                                    ref={register({
                                        required: {
                                            value: true,
                                            message: 'Vui lòng nhập mật khẩu!',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'Mật khẩu phải có ít nhất 6 kí tự!',
                                        },
                                    })}
                                />
                                {errors.password && <div className="text-danger pt-2">{errors.password.message}</div>}
                            </div>
                            {authenticationReducer.error && <div className="text-danger mb-2">{authenticationReducer.error.errorMsg}</div>}

                            <button type="submit" className="btn btn-block btn-primary">
                                Đăng nhập
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInPage;
