import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Table from 'components/shared/react-table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import usePrevious from 'helpers/usePrevious';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { get, groupBy } from 'lodash';
import { messageHelper } from 'helpers/messageHelper';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import Select from 'react-select';
import { numberHelper } from 'helpers/numberHelper';
import { callApiGetCTPVReportForAllCongDoan } from 'redux/saga/trung-uong/report/ctpv/getCTPVReportForAllCongDoan';
import { LOAI_CTPV } from 'config/constants/danhMucThuConstant';
import clsx from 'clsx';
import { callApiExportCTPVReportForAllCongDoan } from 'redux/saga/trung-uong/report/ctpv/exportCTPVReportForAllCongDoan';

function TrungUongCTPVPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getReport = useCallback(() => {
        dispatch(loading());
        callApiGetCTPVReportForAllCongDoan(values.academicYear.value.name)
            .then((res) => {
                setReport(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.academicYear]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            getReport();
        }
    }, [values, watches, prevValues, getReport]);

    const columns = useMemo(() => {
        const academicYear = get(values, 'academicYear.value');
        if (!academicYear) return [];
        const fixedColumns = [
            {
                Header: 'STT',
                columns: [
                    {
                        Header: '  ',
                        columns: [
                            {
                                Header: ' ',
                                accessor: 'stt',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'CÁC CÔNG TÁC',
                columns: [
                    {
                        Header: ' ',
                        columns: [
                            {
                                Header: ' ',
                                accessor: 'danhMucName',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'TỒN ĐẦU NĂM',
                columns: [
                    {
                        Header: 'VND',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tonDauNamTienMat',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'tonDauNamNganHang',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'tonDauNamUSD',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'tonDauNamEURO',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'THU',
                columns: [
                    {
                        Header: 'VND',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'thuTienMat',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'thuNganHang',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'thuUSD',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'thuEURO',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'CHI',
                columns: [
                    {
                        Header: 'VND',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'chiTienMat',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'chiNganHang',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'chiUSD',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'chiEURO',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'TỒN CUỐI NĂM',
                columns: [
                    {
                        Header: 'VND',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tonCuoiNamTienMat',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'tonCuoiNamNganHang',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'tonCuoiNamUSD',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'tonCuoiNamEURO',
                            },
                        ],
                    },
                ],
            },
        ];
        return [...fixedColumns];
    }, [values]);

    const buildData = () => {
        const result = [];
        const reportGrouppedByDanhMucName = groupBy(report, 'danhMucCongTacPhucVuName');
        Object.entries(reportGrouppedByDanhMucName).forEach(([k, v], index) => {
            const element = { stt: index + 1, danhMucName: k };
            const tonDauNam = v.find((i) => i.loaiCTPV === LOAI_CTPV.TONDAUTHANG);
            element['tonDauNamTienMat'] = tonDauNam ? numberHelper.formatNumber(tonDauNam.tienMat) : 0;
            element['tonDauNamNganHang'] = tonDauNam ? numberHelper.formatNumber(tonDauNam.nganHang) : 0;
            element['tonDauNamUSD'] = tonDauNam ? numberHelper.formatNumber(tonDauNam.usd) : 0;
            element['tonDauNamEURO'] = tonDauNam ? numberHelper.formatNumber(tonDauNam.euro) : 0;
            const thu = v.find((i) => i.loaiCTPV === LOAI_CTPV.THU);
            element['thuTienMat'] = thu ? numberHelper.formatNumber(thu.tienMat) : 0;
            element['thuNganHang'] = thu ? numberHelper.formatNumber(thu.nganHang) : 0;
            element['thuUSD'] = thu ? numberHelper.formatNumber(thu.usd) : 0;
            element['thuEURO'] = thu ? numberHelper.formatNumber(thu.euro) : 0;
            const chi = v.find((i) => i.loaiCTPV === LOAI_CTPV.CHI);
            element['chiTienMat'] = chi ? numberHelper.formatNumber(chi.tienMat) : 0;
            element['chiNganHang'] = chi ? numberHelper.formatNumber(chi.nganHang) : 0;
            element['chiUSD'] = chi ? numberHelper.formatNumber(chi.usd) : 0;
            element['chiEURO'] = chi ? numberHelper.formatNumber(chi.euro) : 0;
            const tonCuoiNam = v.find((i) => i.loaiCTPV === LOAI_CTPV.TONCUOI);
            element['tonCuoiNamTienMat'] = tonCuoiNam ? numberHelper.formatNumber(tonCuoiNam.tienMat) : 0;
            element['tonCuoiNamNganHang'] = tonCuoiNam ? numberHelper.formatNumber(tonCuoiNam.nganHang) : 0;
            element['tonCuoiNamUSD'] = tonCuoiNam ? numberHelper.formatNumber(tonCuoiNam.usd) : 0;
            element['tonCuoiNamEURO'] = tonCuoiNam ? numberHelper.formatNumber(tonCuoiNam.euro) : 0;
            result.push(element);
        });
        return result;
    };

    const onExport = () => {
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiExportCTPVReportForAllCongDoan(academicYear)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo thu chi CTPV tất cả cộng đoàn.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo công tác phục vụ</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy báo cáo!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {report && <Table columns={columns} data={buildData()} />}
        </>
    );
}

export default TrungUongCTPVPage;
