import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { ASSET_TYPE_OPTIONS } from 'config/constants/assetConstant';
import React from 'react';
import { useForm } from 'react-hook-form';

function CommonAssetDialog({ asset, open, assetType, closeDialog, onSaveAsset }) {
    const { register, handleSubmit, errors } = useForm();

    const defaultValues = {
        id: asset.id,
        name: asset.name,
        loai: asset.loai,
        percent: asset.percent,
        triGia: asset.triGia,
        nguoiSuDung: asset.nguoiSuDung,
        noiSuDung: asset.noiSuDung,
        firstTest: asset.firstTest,
        secondTest: asset.secondTest,
        note: asset.note,
        soLuong: asset.soLuong,
    };

    const onSubmit = (data) => {
        const body = { ...data };
        onSaveAsset(body);
    };

    const getAssetLabel = () => {
        return ASSET_TYPE_OPTIONS.filter((item) => item.key === assetType)[0].label;
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{asset.id ? `Chỉnh sửa ${getAssetLabel()}` : `Thêm ${getAssetLabel()}`}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="name">Tên tài sản</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Tên tài sản"
                                defaultValue={defaultValues.name}
                                id="name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập Tên tài sản!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="loai">Loại</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.loai && 'border-danger')}
                                placeholder="Loại"
                                defaultValue={defaultValues.loai}
                                id="loai"
                                name="loai"
                                ref={register({})}
                            />
                            {errors.loai && <div className="text-danger pt-2">{errors.loai.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="percent">Đạt %</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.percent && 'border-danger')}
                                placeholder="Đạt %"
                                defaultValue={defaultValues.percent}
                                id="percent"
                                name="percent"
                                ref={register({})}
                            />
                            {errors.percent && <div className="text-danger pt-2">{errors.percent.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="triGia">Trị giá</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.triGia && 'border-danger')}
                                placeholder="Trị giá"
                                defaultValue={defaultValues.triGia}
                                id="triGia"
                                name="triGia"
                                ref={register({})}
                            />
                            {errors.triGia && <div className="text-danger pt-2">{errors.triGia.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="firstTest">{`Kiểm tra 1`}</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.firstTest && 'border-danger')}
                                placeholder="Kiểm tra 1"
                                defaultValue={defaultValues.firstTest}
                                id="firstTest"
                                name="firstTest"
                                ref={register({})}
                            />
                            {errors.firstTest && <div className="text-danger pt-2">{errors.firstTest.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="secondTest">Kiểm tra 2</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.secondTest && 'border-danger')}
                                placeholder="Kiểm tra 2"
                                defaultValue={defaultValues.secondTest}
                                id="secondTest"
                                name="secondTest"
                                ref={register({})}
                            />
                            {errors.secondTest && <div className="text-danger pt-2">{errors.secondTest.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="noiSuDung">Người/nơi sử dụng</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.noiSuDung && 'border-danger')}
                                placeholder="Người/nơi sử dụng"
                                defaultValue={defaultValues.noiSuDung}
                                id="noiSuDung"
                                name="noiSuDung"
                                ref={register({})}
                            />
                            {errors.noiSuDung && <div className="text-danger pt-2">{errors.noiSuDung.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="note">Ghi chú</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.note && 'border-danger')}
                                placeholder="Ghi chú"
                                defaultValue={defaultValues.note}
                                id="note"
                                name="note"
                                ref={register({})}
                            />
                            {errors.note && <div className="text-danger pt-2">{errors.note.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="soLuong">Số lượng</label>
                            <input
                                type="number"
                                className={clsx('form-control', errors.soLuong && 'border-danger')}
                                placeholder="Số lượng"
                                defaultValue={defaultValues.soLuong}
                                id="soLuong"
                                name="soLuong"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập số lượng',
                                    },
                                })}
                            />
                            {errors.soLuong && <div className="text-danger pt-2">{errors.soLuong.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CommonAssetDialog;
