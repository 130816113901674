import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { toastHelper } from 'helpers/toastHelper';
import React from 'react';
import { useForm } from 'react-hook-form';
import { callApiCreateThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/createThuDauNam';
import { callApiUpdateThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/updateThuDauNam';

function ThuDauNamDialog({ thuDauNam, closeDialog, gradeId, open, onSuccess }) {
    const defaultValues = {
        name: thuDauNam.name,
        amount: thuDauNam.amount,
    };

    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = (data) => {
        const body = { ...data };
        if (thuDauNam.id) {
            editThuDauNam(body);
        } else {
            addThuDauNam(body);
        }
    };

    const addThuDauNam = (data) => {
        if (gradeId) {
            callApiCreateThuDauNam(gradeId, data)
                .then((res) => {
                    onSuccess(res.data);
                    reset(defaultValues);
                    toastHelper.success('Thêm mục thu đầu năm thành công.');
                })
                .catch((err) => {
                    toastHelper.error('Gặp lỗi khi thêm thu đầu năm. Vui lòng thử lại sau.');
                });
        }
    };

    const editThuDauNam = (data) => {
        callApiUpdateThuDauNam(thuDauNam.id, data)
            .then((res) => {
                onSuccess(res.data);
                closeDialog();
                toastHelper.success('Chỉnh sửa thu đầu năm thành công.');
            })
            .catch((err) => {
                toastHelper.error('Gặp lỗi khi chỉnh sửa thu đầu năm. Vui lòng thử lại sau.');
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3>{thuDauNam.id ? 'Chỉnh sửa thu đầu năm' : 'Thêm thu đầu năm'}</h3>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="name">Mục thu</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Mục thu"
                                defaultValue={defaultValues.name}
                                id=" name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên mục thu!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="amount">Số tiền</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Số tiền"
                                defaultValue={defaultValues.amount}
                                id="amount"
                                name="amount"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập số tiền!',
                                    },
                                })}
                            />
                            {errors.amount && <div className="text-danger pt-2">{errors.amount.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ThuDauNamDialog;
