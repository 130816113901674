import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import Table from 'components/shared/react-table/Table';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import { numberHelper } from 'helpers/numberHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetReportDuChiForAllCongDoan } from 'redux/saga/trung-uong/report/duchi/getDuChiForAllCongDoan';

function TrungUongDuChiPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getReport = useCallback(() => {
        dispatch(loading());
        callApiGetReportDuChiForAllCongDoan(values.academicYear.value.name)
            .then((res) => {
                setReport(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.academicYear]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            getReport();
        }
    }, [values, watches, prevValues, getReport]);

    const columns = useMemo(() => {
        const academicYear = get(values, 'academicYear.value');
        if (!academicYear) return [];
        const months = dateHelper.getMonthArraysByAcademicYear(academicYear);
        const extraMonths = months.slice(12);
        const normalMonths = months.slice(0, 12);
        const fixedColumns = [
            {
                Header: 'Mã số',
                accessor: 'danhMucChiCongDoanCode',
            },
            {
                Header: 'Danh mục',
                accessor: 'danhMucChiCongDoanName',
            },
        ];
        const columnsWithNormalMonth = normalMonths.map((item) => {
            return {
                Header: `Tháng ${item}`,
                accessor: dateHelper.duThuFieldMappedByMonth().get(item),
            };
        });
        const columnsWithExtraMonths = extraMonths.map((item, index) => {
            return {
                Header: `Tháng ${item}/${dateHelper.getYearsFromAcademicYear(academicYear.name)[1]}`,
                accessor: dateHelper.duThuFieldMappedByMonth().get(index + 1 + 12),
            };
        });
        return [...fixedColumns, ...columnsWithNormalMonth, ...columnsWithExtraMonths];
    }, [values]);

    const buildData = () => {
        return report.map((item) => {
            return {
                ...item,
                ...{
                    junAmount: numberHelper.formatNumber(item.junAmount),
                    julAmount: numberHelper.formatNumber(item.julAmount),
                    augAmount: numberHelper.formatNumber(item.augAmount),
                    sepAmount: numberHelper.formatNumber(item.sepAmount),
                    octAmount: numberHelper.formatNumber(item.octAmount),
                    novAmount: numberHelper.formatNumber(item.novAmount),
                    decAmount: numberHelper.formatNumber(item.decAmount),
                    janAmount: numberHelper.formatNumber(item.janAmount),
                    febAmount: numberHelper.formatNumber(item.febAmount),
                    marAmount: numberHelper.formatNumber(item.marAmount),
                    aprAmount: numberHelper.formatNumber(item.aprAmount),
                    mayAmount: numberHelper.formatNumber(item.mayAmount),
                    extraAmount1: numberHelper.formatNumber(item.extraAmount1),
                    extraAmount2: numberHelper.formatNumber(item.extraAmount2),
                },
            };
        });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo dự chi</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {report && <Table columns={columns} data={buildData()} />}
        </>
    );
}

export default TrungUongDuChiPage;
