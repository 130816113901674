import FMI_ACTIONS from '../actions';

const ctpvReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC_SUCCESS: {
            return {
                ...state,
                ...{ danhMucs: payload.data },
            };
        }
        default:
            return state;
    }
};

export default ctpvReducer;
