import clsx from 'clsx';
import { FMI_PERCENT_FORMAT } from 'config/constants/numberConstant';
import dayjs from 'dayjs';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { numberHelper } from 'helpers/numberHelper';
import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { CustomHeader } from '../thu-tien/ThuTienTable';
import './styles.scss';

const EmptyRowsView = () => {
    const message = 'Không có lớp nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

const DayOffHeader = (props) => {
    return (
        <div className="day-off-header">
            <div>
                {props.column.dateIndex} | {props.column.weekDay}
            </div>
            <div className="weekday">{props.column.type}</div>
        </div>
    );
};

const AbsenceValue = (props) => {
    return (
        <div
            className={clsx({
                'text-center': true,
                // 'cm-column': props.column.type === 'CM',
                // 'vm-column': props.column.type === 'VM',
            })}
        >
            {props.value}
        </div>
    );
};

// const AbsenceRow = ({ row }) => {
//     console.log(row);
//     return <div>1</div>;
// };

function AbsenceSchoolReportList({ absences, monthYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        if (absences) {
            absences.forEach((item, index) => {
                const row = {
                    index: index + 1,
                    className: item.clazz.className,
                    numberOfStudents: item.clazz.numOfStudents,
                    totalAttendance: item.totalAttendances,
                    totalAbsences: item.totalAbsences,
                    diligencePercent:
                        item.diligencePercent < 1
                            ? numberHelper.formatNumber(item.diligencePercent, FMI_PERCENT_FORMAT)
                            : numberHelper.formatNumber(item.diligencePercent / 100, FMI_PERCENT_FORMAT),
                };
                item.details.forEach((item) => {
                    row[`absence-at-${item.date}`] = item.absenceAmount;
                    row[`attendance-at-${item.date}`] = item.attendanceAmount;
                });
                rows.push(row);
            });
            setRows(rows);
        }
    }, [absences]);

    useEffect(() => {
        const columns = [
            { key: 'index', name: 'STT', editable: false, width: 60, frozen: true },
            { key: 'className', name: 'Tên lớp', width: 200, frozen: true },
            { key: 'numberOfStudents', name: 'Sĩ số', width: 60, formatter: <AbsenceValue /> },
            { key: 'totalAttendance', name: 'Tổng số có mặt', editable: false, width: 80, formatter: <AbsenceValue />, headerRenderer: <CustomHeader /> },
            { key: 'totalAbsences', name: 'Tổng số vắng mặt', width: 84, headerRenderer: <CustomHeader />, formatter: <AbsenceValue /> },
            { key: 'diligencePercent', name: 'Tỉ lệ chuyên cần', width: 80, headerRenderer: <CustomHeader />, formatter: <AbsenceValue /> },
        ];
        const dates = dateHelper.getAllDaysOfMonth(monthYear);
        const dateColumns = [];
        dates.forEach((date) => {
            const attendanceColumn = {
                key: `attendance-at-${date}`,
                dateIndex: dayjs(date).date(),
                weekDay: dateHelper.getWeekDayOfDateAsString(date),
                type: 'CM',
                headerRenderer: <DayOffHeader />,
                formatter: <AbsenceValue />,
                width: 55,
            };
            dateColumns.push(attendanceColumn);
            const absenceColumn = {
                key: `absence-at-${date}`,
                dateIndex: dayjs(date).date(),
                weekDay: dateHelper.getWeekDayOfDateAsString(date),
                type: 'VM',
                headerRenderer: <DayOffHeader />,
                formatter: <AbsenceValue />,
                width: 55,
            };
            dateColumns.push(absenceColumn);
        });
        setColumns(
            [...columns, ...dateColumns].map((item) => {
                return { ...item, ...{ resizable: true } };
            })
        );
    }, [monthYear]);

    return (
        <div id="rdg" className="absence-school">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                // rowRenderer={AbsenceRow}
                minHeight={tableHeight}
                emptyRowsView={EmptyRowsView}
                headerRowHeight={90}
            />
        </div>
    );
}

export default AbsenceSchoolReportList;
