import { call, put } from '@redux-saga/core/effects';
import { transport } from 'config/transport';
import FMI_ACTIONS from 'redux/actions';

export default function* getAttendanceInfo(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiGetAttendanceInfo, action);
        yield put({
            type: FMI_ACTIONS.ATTENDANCE__GET_INFO_OF_CLASS_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.ACADEMIC_YEAR__CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetAttendanceInfo(params) {
    return transport.get(`/mamnon/classes/${params.classId}`, { params: { holidayMonth: params.month } });
}
