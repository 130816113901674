import FMI_ACTIONS from 'redux/actions';

export const grade__get_list = (data) => {
    return {
        type: FMI_ACTIONS.GRADE__GET_LIST,
        payload: { data },
    };
};

export const class__get_list = (data) => {
    return {
        type: FMI_ACTIONS.CLASS__GET_LIST,
        payload: { data },
    };
};

export const academic_year__get_list = (data) => {
    return {
        type: FMI_ACTIONS.ACADEMIC_YEAR__GET_LIST,
        payload: { data },
    };
};

export const nun__get_list = (data) => {
    return {
        type: FMI_ACTIONS.NUN__GET_LIST,
        payload: { data },
    };
};

export const attendance__check_attendance = (classId, attendanceData) => {
    return {
        type: FMI_ACTIONS.ATTENDANCE__CHECK_ATTENDANCE,
        payload: { classId, attendanceData },
    };
};

export const congdoan_get_payment_info = () => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_PAYMENT_INFO,
    };
};

export const congdoan_get_list_danh_muc_thu_mam_non = (data) => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_MAM_NON,
        payload: { ...data },
    };
};

export const congdoan_get_list_danh_muc_chi_mam_non = () => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_MAM_NON,
    };
};

export const congdoan_get_list_danh_muc_thu_cong_doan = (data) => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_CONG_DOAN,
        payload: { ...data },
    };
};

export const congdoan_get_list_danh_muc_chi_cong_doan = () => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_CHI_CONG_DOAN,
    };
};

export const congdoan_get_list_danh_muc_thuchi_daunam = () => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THUCHI_DAUNAM,
    };
};

export const mamnon__get_list_danh_muc_thuchi_daunam_nhiemky = () => {
    return {
        type: FMI_ACTIONS.MAMNON__GET_LIST_DANHMUC_THUCHI_DAUNAM_NHIEMKY,
    };
};

export const loading = () => {
    return {
        type: FMI_ACTIONS.LOADING_STATUS__LOADING,
    };
};

export const loaded = () => {
    return {
        type: FMI_ACTIONS.LOADING_STATUS__LOADED,
    };
};
