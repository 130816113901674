import FMI_ACTIONS from 'redux/actions';

export const authentication__sign_in = (data) => {
    return {
        type: FMI_ACTIONS.AUTHENTICATION__SIGN_IN,
        payload: { data },
    };
};

export const authentication_sign_out = (cb) => {
    return {
        type: FMI_ACTIONS.AUTHENTICATION__SIGN_OUT,
        cb,
    };
};

export const congdoan_get_info = () => {
    return {
        type: FMI_ACTIONS.CONGDOAN_GET_INFO,
    };
};

export const academic_year__get_first_month = (data) => {
    return {
        type: FMI_ACTIONS.ACADEMIC_YEAR__GET_FIRST_MONTH,
        payload: { ...data },
    };
};
