import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { callApiDeleteAsset } from 'redux/saga/quan-li-tai-san/deleteAsset';
import { ASSET_TYPE } from 'config/constants/assetConstant';
import ReactDataGrid from 'react-data-grid';
import AssetDialog from './asset-dialog/AssetDialog';
import EditIcon from '@material-ui/icons/Edit';
import { alert } from 'helpers/alertHelper';
import { useSelector } from 'react-redux';

const EmptyRowsView = (props) => {
    const message = 'Chưa có tài sản nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function AssetTable({ assetType, assets, onDeleteSuccess, onSaveSuccess, assetSource, academicYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState();
    const [open, setOpen] = useState(false);
    const [tableHeight, setTableHeight] = useState();
    const congDoanId = useSelector((state) => state.authenticationReducer.userInfo.congDoanId, null);

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const buildRow = useCallback((index, asset) => {
        return {
            id: asset.id,
            index: index + 1,
            soLuongDat: asset.soLuongDat,
            dienTich: asset.dienTich,
            soDo: asset.soDo,
            soHong: asset.soHong,
            triGia: asset.triGia,
            noiCatGiu: asset.noiCatGiu,
            nguoiDungTen: asset.nguoiDungTen,
            nguoiDuocVietDiChuc: asset.nguoiDuocVietDiChuc,
            loai: asset.loai,
            soLuong: asset.soLuong,
            soXe: asset.soXe,
            name: asset.name,
            nguoiSuDung: asset.nguoiSuDung,
            noiSuDung: asset.noiSuDung,
            nguoiGiuGiayTo: asset.nguoiGiuGiayTo,
            percent: asset.percent,
            assetSource: asset.assetSource,
            assetType: asset.assetType,
            firstTest: asset.firstTest,
            secondTest: asset.secondTest,
            note: asset.note,
            createdDate: asset.createdDate,
            updatedDate: asset.updatedDate,
            stk: asset.stk,
            loaiTien: asset.loaiTien,
            tenNganHang: asset.tenNganHang,
            swift: asset.swift,
            diaChiDangKyTK: asset.diaChiDangKyTK,
            tenChuTK: asset.tenChuTK,
            tenUyQuyenTK: asset.tenUyQuyenTK,
            soThe: asset.soThe,
            loaiThe: asset.loaiThe,
            thoiHan: asset.thoiHan,
            lienKetVoiSoTK: asset.lienKetVoiSoTK,
            tenChuThe: asset.tenChuThe,
            nguoiDuocChiaSeMK: asset.nguoiDuocChiaSeMK,
            accountType: asset.accountType,
            asset: asset,
        };
    }, []);

    useEffect(() => {
        const rows = [];
        assets.forEach((asset, index) => {
            const row = buildRow(index, asset);
            rows.push(row);
        });
        setRows(rows);
    }, [assets, buildRow]);

    const buildColumns = useCallback(() => {
        let fixedColumns = {};
        switch (assetType) {
            case ASSET_TYPE.DATDAI:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'soLuongDat', name: 'Số lượng đất', frozen: true, editable: true, width: 200 },
                    { key: 'dienTich', name: 'Diện tích', editable: true, width: 100 },
                    { key: 'soDo', name: 'Sổ đỏ', editable: true, width: 100 },
                    { key: 'soHong', name: 'Sổ hồng', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'noiCatGiu', name: 'Nơi cất giữ', editable: true, width: 100 },
                    { key: 'nguoiDungTen', name: 'Người đứng tên', editable: true, width: 150 },
                    { key: 'nguoiDuocVietDiChuc', name: 'Người được viết di chúc', editable: true, width: 200 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.XE:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'name', name: 'Tên', frozen: true, editable: true, width: 100 },
                    { key: 'loai', name: 'Loại', editable: true, width: 100 },
                    { key: 'soXe', name: 'Số xe', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'nguoiDungTen', name: 'Người đứng tên xe', editable: true, width: 150 },
                    { key: 'nguoiSuDung', name: 'Người sử dụng', editable: true, width: 150 },
                    { key: 'nguoiGiuGiayTo', name: 'Người giữ giấy tờ', editable: true, width: 150 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.BANKACCOUNT:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'stk', name: 'Số tài khoản', frozen: true, editable: true, width: 250 },
                    { key: 'loaiTien', name: 'Loại tiền', editable: true, width: 100 },
                    { key: 'tenNganHang', name: 'Tên ngân hàng', editable: true, width: 130 },
                    { key: 'swift', name: 'Swift', editable: true, width: 100 },
                    { key: 'diaChiDangKyTK', name: 'Địa chỉ đăng ký TK', editable: true, width: 155 },
                    { key: 'tenChuTK', name: 'Tên chủ TK', editable: true, width: 100 },
                    { key: 'tenUyQuyenTK', name: 'Tên uỷ quyền TK', editable: true, width: 150 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.BANKCARD:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'soThe', name: 'Số thẻ', frozen: true, editable: true, width: 200 },
                    { key: 'loaiThe', name: 'Loại thẻ', editable: true, width: 100 },
                    { key: 'tenNganHang', name: 'Tên ngân hàng', editable: true, width: 130 },
                    { key: 'thoiHan', name: 'Thời hạn', editable: true, width: 100 },
                    { key: 'lienKetVoiSoTK', name: 'Liên kết với STK', editable: true, width: 150 },
                    { key: 'tenChuThe', name: 'Tên chủ thẻ', editable: true, width: 180 },
                    { key: 'nguoiDuocChiaSeMK', name: 'Người được chia sẻ MK', editable: true, width: 200 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.HOUSEHOLD:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'name', name: 'Tên vật dụng', frozen: true, editable: true, width: 250 },
                    { key: 'loai', name: 'Chất liệu/Hiệu', editable: true, width: 130 },
                    { key: 'soLuong', name: 'Số lượng', editable: true, width: 80 },
                    { key: 'percent', name: 'Đạt %', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'noiSuDung', name: 'Nơi sử dụng', editable: true, width: 150 },
                    { key: 'firstTest', name: 'Kiểm tra 1', editable: true, width: 100 },
                    { key: 'secondTest', name: 'Kiểm tra 2', editable: true, width: 100 },
                    { key: 'note', name: 'Ghi chú', editable: true, width: 100 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.TIVI:
            case ASSET_TYPE.WASHINGMACHINE:
            case ASSET_TYPE.SQUEEZER:
            case ASSET_TYPE.DESKTOP:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'name', name: 'Tên vật dụng', frozen: true, editable: true, width: 250 },
                    { key: 'loai', name: 'Loại', editable: true, width: 100 },
                    { key: 'soLuong', name: 'Số lượng', editable: true, width: 80 },
                    { key: 'percent', name: 'Đạt %', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'noiSuDung', name: 'Nơi sử dụng', editable: true, width: 150 },
                    { key: 'firstTest', name: 'Kiểm tra 1', editable: true, width: 100 },
                    { key: 'secondTest', name: 'Kiểm tra 2', editable: true, width: 100 },
                    { key: 'note', name: 'Ghi chú', editable: true, width: 100 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
            case ASSET_TYPE.KITCHEN:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'name', name: 'Tên vật dụng', frozen: true, editable: true, width: 250 },
                    { key: 'loai', name: 'Chất liệu/Hiệu', editable: true, width: 150 },
                    { key: 'soLuong', name: 'Số lượng', editable: true, width: 80 },
                    { key: 'percent', name: 'Đạt %', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'noiSuDung', name: 'Tủ cất giữ/sử dụng', editable: true, width: 150 },
                    { key: 'firstTest', name: 'Kiểm tra 1', editable: true, width: 100 },
                    { key: 'secondTest', name: 'Kiểm tra 2', editable: true, width: 100 },
                    { key: 'note', name: 'Ghi chú', editable: true, width: 100 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;

            default:
                fixedColumns = [
                    { key: 'index', name: 'STT', frozen: true, editable: false, width: 50 },
                    { key: 'name', name: 'Tên', frozen: true, editable: true, width: 250 },
                    { key: 'loai', name: 'Loại', editable: true, width: 100 },
                    { key: 'soLuong', name: 'Số lượng', editable: true, width: 80 },
                    { key: 'percent', name: 'Đạt %', editable: true, width: 100 },
                    { key: 'triGia', name: 'Trị giá', editable: true, width: 100 },
                    { key: 'nguoiSuDung', name: 'Người sử dụng', editable: true, width: 120 },
                    { key: 'firstTest', name: 'Kiểm tra 1', editable: true, width: 100 },
                    { key: 'secondTest', name: 'Kiểm tra 2', editable: true, width: 100 },
                    { key: 'note', name: 'Ghi chú', editable: true, width: 100 },
                    { key: 'actions', editable: false, width: 80 },
                ];
                break;
        }

        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true, editable: false } };
        });
    }, [assetType]);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns, assetType]);

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedAsset(row.asset);
                    showConfirmDialog(row);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedAsset(row.asset);
                    openDialog();
                },
            },
        ];
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const getAssetType = () => {
        return assetType === ASSET_TYPE.BANKCARD ? ASSET_TYPE.BANKACCOUNT : assetType;
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá tài sản này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                if (congDoanId && selectedItem.id) {
                    callApiDeleteAsset(congDoanId, selectedItem.id, getAssetType())
                        .then((res) => {
                            onDeleteSuccess(selectedItem.index - 1);
                            alert({ icon: 'success', title: 'Xoá mục tài sản thành công', showConfirmButton: false });
                        })
                        .catch((error) => {
                            alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá mục tài sản! Vui lòng thử lại sau.' });
                        });
                }
            }
        });
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedAsset(null);
        setOpen(false);
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedAsset && (
                <AssetDialog
                    open={open}
                    onSaveAssetSuccess={onSaveSuccess}
                    assetType={assetType}
                    asset={selectedAsset}
                    closeDialog={closeDialog}
                    assetSource={assetSource}
                    academicYear={academicYear}
                />
            )}
        </div>
    );
}

export default AssetTable;
