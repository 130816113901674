import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { messagesConstants } from 'config/constants/messagesConstant';
import { errorHelper, INTERNAL_SERVER_ERROR } from 'content/message/errorMessages';
import { toastHelper } from 'helpers/toastHelper';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { callApiImportStudents } from 'redux/saga/mam-non/info/class/student/importStudents';
import { loaded, loading } from '../../actions';

function ImportStudentDialog({ clazz, closeDialog, open, onSuccess }) {
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.xlsx, .xls',
        onDropAccepted: (files) => setFiles(files),
        onDropRejected: () => toastHelper.warning(messagesConstants.FILES_NOT_ACCEPTED),
    });

    const importStudent = () => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            dispatch(loading());
            callApiImportStudents(clazz.id, formData)
                .then((res) => {
                    setFiles([]);
                    onSuccess();
                    toastHelper.success(`Dữ liệu đã được nhập thành công cho lớp ${clazz.className}!`);
                })
                .catch((err) => {
                    const messageKey = get(err, 'response.data.messageKey', null);
                    if (messageKey) {
                        toastHelper.error(errorHelper.getErrorMessageByKey(messageKey));
                    } else {
                        toastHelper.error(errorHelper.getErrorMessageByKey(INTERNAL_SERVER_ERROR));
                    }
                })
                .finally(() => {
                    dispatch(loaded());
                });
        } else {
            toastHelper.error('Vui lòng chọn file dữ liệu học sinh');
        }
    };

    const onCloseDialog = () => {
        setFiles([]);
        closeDialog();
    };

    return (
        <Dialog open={open} onClose={onCloseDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <DialogTitle id="form-dialog-title">
                <h3>Nhập dữ liệu học sinh lớp: {clazz.className}</h3>
            </DialogTitle>
            <DialogContent>
                <div className="mb-2">
                    Tải mẫu file{' '}
                    <a href="/assets/files/import-student.xlsx" alt="template">
                        tại đây
                    </a>{' '}
                </div>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {files.length > 0 ? (
                        <p>
                            {files[0].path} - {files[0].size} bytes
                        </p>
                    ) : (
                        <>
                            <p>Kéo và thả file vào đây, hoặc click vào để chọn file</p>
                            <em>(Chỉ những file với đuổi .xlsx, .xls được chấp nhận)</em>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-secondary" onClick={onCloseDialog}>
                    Đóng
                </button>
                <button type="button" className="btn btn-primary" onClick={importStudent}>
                    Tải lên
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default ImportStudentDialog;
