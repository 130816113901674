import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { academic_year__get_list } from '../actions';
import { callApiGetListOfDuThu } from 'redux/saga/mam-non/info/du-thu/getAllDuThu';
import DuThuForm from './du-thu-table/DuThuForm';
import { callApiSaveAllDuThu } from 'redux/saga/mam-non/info/du-thu/saveAllDuThu';
import { toastHelper } from 'helpers/toastHelper';
import { callApiGetListOfDanhMucThu } from 'redux/saga/mam-non/info/danh-muc-thu/getListOfDanhMucThu';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';

function MamNonDuThuPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [grades, setGrades] = useState([]);
    const [duThuInfo, setDuThuInfo] = useState(null);
    const [danhMucThus, setDanhMucThus] = useState(null);
    const [duThuData, setDuThuData] = useState(null);
    const { control, errors, setValue, getValues, trigger, watch } = useForm();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getDuThuInfo = useCallback(
        (gradeId) => {
            if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
                setDuThuInfo(null);
                callApiGetListOfDuThu(authenticationReducer.userInfo.congDoanId, gradeId)
                    .then((res) => {
                        setDuThuInfo(res.data);
                    })
                    .catch((err) => {
                        console.warn(err);
                    });
            }
        },
        [authenticationReducer.userInfo]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.grade && values.grade.id) {
            getDuThuInfo(values.grade.id);
        }
    }, [values, prevValues, watches, getDuThuInfo]);

    useEffect(() => {
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfDanhMucThu(authenticationReducer.userInfo.congDoanId)
                .then((res) => {
                    setDanhMucThus(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                });
        }
    }, [dispatch, authenticationReducer.userInfo]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('grade', '');
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('grade', selectedOption.value);
        trigger('grade');
    };

    const onSubmitDuThuForm = () => {
        if (duThuData) {
            const reqBody = duThuData
                .filter((item) => item.amount !== '')
                .map((item) => {
                    return { id: item.duThuId, danhMucThuId: item.danhMucThuId, amount: Number(item.amount), isMandantory: item.isMandatory };
                });
            if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
                callApiSaveAllDuThu(authenticationReducer.userInfo.congDoanId, values.grade.id, reqBody)
                    .then((res) => {
                        toastHelper.success('Lưu dự thu thành công');
                        getDuThuInfo(values.grade.id);
                    })
                    .catch((err) => {
                        toastHelper.error('Có lỗi lúc lưu dự thu!');
                    });
            }
        }
    };

    const onDeleteDTSuccess = () => {
        getDuThuInfo(values.grade.id);
        toastHelper.success('Xoá dự thu thành công!');
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Dự thu</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label">Khối:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="grade"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập khối để lấy danh sách dự thu!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onGradeChange}
                                                        options={grades.map((item) => {
                                                            return { value: item, label: item.name };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5"></div>
                            <div className="col-md-1 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary fmi-toolbar-button': true, 'd-none': duThuInfo === null })}
                                        onClick={onSubmitDuThuForm}
                                        disabled={duThuData === null}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {danhMucThus && duThuInfo && <DuThuForm danhMucThus={danhMucThus} duThuInfo={duThuInfo} setDuThuData={setDuThuData} gradeId={values.grade.id} onDeleteSuccess={onDeleteDTSuccess} />}
        </>
    );
}

export default MamNonDuThuPage;
