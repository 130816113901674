import { Box, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DanhMucThuList from './danh-muc-thu/DanhMucThuList';
import { callApiGetListOfDanhMucThu } from 'redux/saga/mam-non/info/danh-muc-thu/getListOfDanhMucThu';
import { useSelector } from 'react-redux';
import DanhMucThuInfoDialog from './danh-muc-thu/DanhMucThuInfoDialog';
import { toastHelper } from 'helpers/toastHelper';
import { messageHelper } from 'helpers/messageHelper';

const defaultDanhMucThu = {};

function MamNonDanhMucThuPage() {
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const theme = useTheme();
    const [danhMucThus, setDanhMucThus] = useState(null);
    const [danhMucThu, setDanhMucThu] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (userInfo) {
            callApiGetListOfDanhMucThu(userInfo.congDoanId)
                .then((res) => {
                    setDanhMucThus(res.data);
                })
                .catch((err) => messageHelper.showError(err));
        }
    }, [userInfo]);

    const addDanhMucThu = () => {
        setDanhMucThu(defaultDanhMucThu);
        openDialog();
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onAddDMThuSuccess = (data) => {
        const newDMThus = [...danhMucThus];
        newDMThus.push(data);
        setDanhMucThus(newDMThus);
        toastHelper.success('Lưu danh mục thu thành công!');
    };

    const onEditDMThuSuccess = (data, index) => {
        const newDanhMucThus = [...danhMucThus];
        newDanhMucThus[index] = data;
        setDanhMucThus(newDanhMucThus);
        toastHelper.success('Lưu danh mục thu thành công!');
    };

    const onDeleteSuccess = (index) => {
        const newDanhMucThus = [...danhMucThus];
        newDanhMucThus.splice(index, 1);
        setDanhMucThus(newDanhMucThus);
        toastHelper.success('Xoá danh mục thu thành công!');
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <Box p={theme.spacing(0, 3)} className="d-flex align-items-center">
                    <Typography variant="h6">Danh mục thu</Typography>
                </Box>
                <Box p={theme.spacing(1, 1)}>
                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addDanhMucThu} title="Thêm danh mục thu">
                        <AddIcon />
                        Thêm danh mục thu
                    </button>
                </Box>
            </div>
            {danhMucThus && <DanhMucThuList danhMucThus={danhMucThus} onEditSuccess={onEditDMThuSuccess} onDeleteSuccess={onDeleteSuccess} />}
            {danhMucThu && danhMucThus && (
                <DanhMucThuInfoDialog danhMucThus={danhMucThus} danhMucThu={danhMucThu} open={open} closeDialog={closeDialog} onSuccess={onAddDMThuSuccess} />
            )}
        </>
    );
}

export default MamNonDanhMucThuPage;
