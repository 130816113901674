import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useSelector } from 'react-redux';
import { callApiDeleteDuThu } from 'redux/saga/mam-non/info/du-thu/deleteDuThu';
import DeleteIcon from '@material-ui/icons/Delete';
import { alert } from 'helpers/alertHelper';
import CheckBoxFormatter from 'components/shared/react-data-grid/CheckBoxFormatter';
import { CALCULATION_TYPES } from 'config/constants/danhMucThuConstant';
import { RDG_CHECK_BOX_COLUMN_WIDTH } from 'config/constants/reactDataGridConstant';
import { CustomHeader } from 'components/mam-non-page/thu-tien/ThuTienTable';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { messageHelper } from 'helpers/messageHelper';

const EmptyRowsView = () => {
    const message = 'Chưa có học sinh nào trong lớp';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function DuThuForm({ danhMucThus, duThuInfo, setDuThuData, gradeId, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const getCalculationTypeLabel = useCallback((calculationType) => {
        const matchedType = CALCULATION_TYPES.filter((item) => item.key === calculationType);
        return matchedType.length > 0 ? matchedType[0].label : '';
    }, []);

    useEffect(() => {
        const rows = [];
        const currentInUseDanhMucThu = [];
        const notInUseDanhMucThu = [];

        danhMucThus.forEach((dm) => {
            if (dm.isCurrentUse) {
                currentInUseDanhMucThu.push(dm);
            } else {
                notInUseDanhMucThu.push(dm);
            }
        });
        currentInUseDanhMucThu.forEach((dm, idx) => {
            const matchedDuThu = duThuInfo.find((item) => item.danhMucThuId === dm.id);
            const row = {
                index: idx + 1,
                danhMucThuName: dm.name,
                amount: matchedDuThu ? matchedDuThu.amount : '',
                isMandatory: matchedDuThu ? matchedDuThu.isMandantory : dm.isMandantory,
                calculationType: getCalculationTypeLabel(dm.calculationType),
                duThuId: matchedDuThu ? matchedDuThu.id : undefined,
                danhMucThuId: dm.id,
            };
            rows.push(row);
        });
        notInUseDanhMucThu.forEach((dm, idx) => {
            const matchedDuThu = duThuInfo.find((item) => item.danhMucThuId === dm.id);
            if (matchedDuThu) {
                const row = {
                    index: rows.length + 1,
                    danhMucThuName: dm.name,
                    amount: matchedDuThu ? matchedDuThu.amount : '',
                    isMandatory: matchedDuThu ? matchedDuThu.isMandantory : dm.isMandantory,
                    calculationType: getCalculationTypeLabel(dm.calculationType),
                    duThuId: matchedDuThu ? matchedDuThu.id : undefined,
                    danhMucThuId: dm.id,
                };
                rows.push(row);
            }
        });
        setRows(rows);
    }, [danhMucThus, duThuInfo, getCalculationTypeLabel]);

    useEffect(() => {
        const columns = [
            { key: 'index', name: 'STT', editable: false, frozen: true, width: 50 },
            { key: 'danhMucThuName', name: 'Tên danh mục', editable: false, frozen: true, width: 250 },
            { key: 'isMandatory', name: 'Bắt buộc', editable: false, formatter: CheckBoxFormatter, width: RDG_CHECK_BOX_COLUMN_WIDTH, headerRenderer: <CustomHeader /> },
            { key: 'calculationType', name: 'Hình thức thu', editable: false, width: 250 },
            { key: 'amount', name: 'Số tiền phải đóng', editable: true, frozen: true, width: 300 },
            { key: 'actions', width: 80 },
        ];
        setColumns(columns);
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
        setDuThuData(newRows);
    };

    const actionColumnActions = (row) => {
        return [
            {
                icon: row.duThuId ? <DeleteIcon /> : <span></span>,
                callback: () => {
                    if (row.duThuId) {
                        showConfirmDialog(row);
                    }
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc muốn xoá dự thu này?',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                if (userInfo.congDoanId && selectedItem.duThuId) {
                    callApiDeleteDuThu(userInfo.congDoanId, gradeId, selectedItem.duThuId)
                        .then((res) => {
                            alert({ icon: 'success', title: 'Dự thu đã được xoá', showConfirmButton: false });
                            onDeleteSuccess();
                        })
                        .catch((err) => {
                            messageHelper.showError(err);
                        });
                }
            }
        });
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                headerRowHeight={60}
                emptyRowsView={EmptyRowsView}
                getCellActions={getCellActions}
            />
        </div>
    );
}

export default DuThuForm;
