import { Box, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApiUpdateAcademicYears } from 'redux/saga/trung-uong/academicYear/updateAcademicYears';
import ExchangeRateList from './ExchangeRateList';

function CongDoanExchangeRatePage() {
    const academicYears = useSelector((state) => state.commonReducer.academicYears, null);
    const theme = useTheme();

    const [data, setData] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const onSave = () => {
        const body = buildBody();
        dispatch(loading());
        callApiUpdateAcademicYears(body)
            .then((res) => {
                toastHelper.success('Lưu thành công!');
                setData(null);
                dispatch(academic_year__get_list());
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const buildBody = () => {
        return data.map((item) => {
            return { name: item.academicYearName, usdExchangeRate: item.usdExchangeRate };
        });
    };

    return (
        <>
            <div className="p-2">
                <Box p={theme.spacing(0, 3)}>
                    <div className="row m-0">
                        <div className="col-md-6 px-0">
                            <Typography variant="h6">Quản lí tỉ lệ chuyển đổi tiền ngoại tệ</Typography>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                            <div className="row mx-0 w-100">
                                <button
                                    type="button"
                                    className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': data === null })}
                                    onClick={onSave}
                                    title="Lưu"
                                >
                                    Lưu
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>
            {academicYears && <ExchangeRateList academicYears={academicYears} setData={setData} />}
        </>
    );
}

export default CongDoanExchangeRatePage;
