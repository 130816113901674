import { put } from 'redux-saga/effects';
import jwtHelpers from '../../../helpers/jwtHelpers';
import FMI_ACTIONS from '../../actions';

export default function* signOut(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    yield put({ type: FMI_ACTIONS.AUTHENTICATION__SIGN_OUT_SUCCESS });
    yield jwtHelpers.clearSession();
    yield action.cb();
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}
