import { combineReducers } from 'redux';
import authenticationReducer from './authentication';
import commonReducer from './commonReducer';
import ctpvReducer from './ctpvReducer';
import congdoanReducer from './congdoanReducer';
import mamnonReducer from './mamnonReducer';

const reducers = combineReducers({
    authenticationReducer,
    commonReducer,
    ctpvReducer,
    congdoanReducer,
    mamnonReducer,
});

export default reducers;
