import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from '../../actions';
import { transport } from '../../../config/transport';

export default function* getListOfAcademicYears(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiGetListOfAcademicYears);
        yield put({
            type: FMI_ACTIONS.ACADEMIC_YEAR__GET_LIST_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.ACADEMIC_YEAR__CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfAcademicYears() {
    return transport.get('/mamnon/academicYears');
}
