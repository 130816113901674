import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_info } from 'components/authentication/actions';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_MONTH_YEAR_FORMAT, FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiExportPaymentSummariesByMonth } from 'redux/saga/mam-non/thu-chi/thu-tien/exportPaymentSummariesByMonth';
import { callApiGetPaymentSummariesByMonth } from 'redux/saga/mam-non/thu-chi/thu-tien/getPaymenSummariesByMonth';
import TraCuuThuTienTable from '../tra-cuu/TraCuuThuTienTable';

function MamNonTraCuuThuTienMonthlyPage() {
    const { control, getValues, watch } = useForm();
    const theme = useTheme();
    const dispatch = useDispatch();
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const [paymentSummaries, setPaymentSummaries] = useState();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    const getPaymentSummary = useCallback(
        (monthYear) => {
            setPaymentSummaries(null);
            dispatch(loading());
            callApiGetPaymentSummariesByMonth(monthYear)
                .then((res) => {
                    setPaymentSummaries(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            getPaymentSummary(dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT));
        }
    }, [values, watches, prevValues, getPaymentSummary]);

    const exportReport = useCallback(() => {
        dispatch(loading());
        callApiExportPaymentSummariesByMonth(dayjs(values.monthYear).format(FMI_YEAR_MONTH_FORMAT))
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute(
                    'download',
                    `Tổng kết thu tiền cộng đoàn ${get(congDoanInfo, 'congDoanName', '')} tháng ${dayjs(values.monthYear).format(FMI_MONTH_YEAR_FORMAT)}.xlsx`
                ); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.monthYear, congDoanInfo]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Tra cứu tiền thu theo tháng</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            {watches.monthYear && (
                                <div className="col-md-3">
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={exportReport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {paymentSummaries && <TraCuuThuTienTable paymentSummaries={paymentSummaries}></TraCuuThuTienTable>}
        </>
    );
}

export default MamNonTraCuuThuTienMonthlyPage;
