import FMI_ACTIONS from 'redux/actions';

export const ctpv__get_list_danh_muc = (congDoanId, isActive) => {
    return {
        type: FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC,
        payload: { congDoanId, isActive },
    };
};

export const ctpv__get_list_danh_muc_active = () => {
    return {
        type: FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC_ACTIVE,
    };
};
