import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_YEAR_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get, groupBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiSaveNoiTruNumberOfStudents } from 'redux/saga/noi-tru/report/students/saveNumberOfStudent';
import { noitru__get_number_of_students } from '../actions';
import NoiTruNumberOfStudentList from './NoiTruNumberOfStudentList';

function NoiTruNumberOfStudentPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const noiTruNumberOfStudents = useSelector((state) => state.commonReducer.noiTruNumberOfStudents, {});

    const [data, setData] = useState(null);

    const theme = useTheme();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const getNumberOfStudents = useCallback(
        (congDoanId, year) => {
            setData(null);
            dispatch(noitru__get_number_of_students({ congDoanId, year }));
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.year) {
            if (userInfo.congDoanId) {
                getNumberOfStudents(userInfo.congDoanId, dateHelper.formatDate(values.year, FMI_YEAR_FORMAT));
            }
        }
    }, [values, watches, prevValues, getNumberOfStudents, userInfo.congDoanId]);

    const getPropertyNameByMonth = (month) => {
        switch (month) {
            case 1:
                return 'janAmount';
            case 2:
                return 'febAmount';
            case 3:
                return 'marAmount';
            case 4:
                return 'aprAmount';
            case 5:
                return 'mayAmount';
            case 6:
                return 'junAmount';
            case 7:
                return 'julAmount';
            case 8:
                return 'augAmount';
            case 9:
                return 'sepAmount';
            case 10:
                return 'octAmount';
            case 11:
                return 'novAmount';
            case 12:
                return 'decAmount';
            default:
                return '';
        }
    };

    const buildBody = () => {
        const body = {};
        Object.entries(groupBy(data, 'month')).forEach(([k, v]) => {
            body[getPropertyNameByMonth(+k)] = +v[0].numberOfNuns;
        });
        return body;
    };

    const onSave = () => {
        if (userInfo.congDoanId && values.year) {
            const body = { ...buildBody(), ...{ id: get(noiTruNumberOfStudents, 'id', null) } };
            dispatch(loading());
            callApiSaveNoiTruNumberOfStudents(userInfo.congDoanId, dateHelper.formatDate(values.year, FMI_YEAR_FORMAT), body)
                .then((data) => {
                    toastHelper.success('Lưu thành công!');
                    getNumberOfStudents(userInfo.congDoanId, dateHelper.formatDate(values.year, FMI_YEAR_FORMAT));
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Số lượng sơ theo tháng</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="year">
                                        Năm:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="year"
                                            name="year"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn năm"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="yyyy"
                                                    locale="vi"
                                                    showYearPicker
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': data === null })}
                                        onClick={onSave}
                                        title="Lưu"
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.year && <NoiTruNumberOfStudentList noiTruNumberOfStudents={noiTruNumberOfStudents} setData={setData} />}
        </>
    );
}

export default NoiTruNumberOfStudentPage;
