import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import ClazzDialog from './ClazzDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import './styles.scss';
import { callApiDeleteClass } from 'redux/saga/mam-non/info/class/deleteClass';
import { alert } from 'helpers/alertHelper';
import { errorHelper } from 'content/message/errorMessages';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import ClazzHolidayDialog from './ClazzHolidayDialog';

const EmptyRowsView = () => {
    const message = 'Chưa có lớp nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ClazzList({ clazzes, onEditSuccess, onDeleteSuccess, academicYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedClazz, setSelectedClazz] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);
    const [openHoliday, setOpenHoliday] = useState(false);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const openHolidayDialog = () => {
        setOpenHoliday(true);
    };

    const closeHolidayDialog = () => {
        setOpenHoliday(false);
    };

    useEffect(() => {
        const rows = [];
        clazzes.forEach((clazz, index) => {
            const row = {
                index: index + 1,
                name: clazz.className,
                numberOfStudents: clazz.numOfStudents,
                clazz: clazz,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [clazzes]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'name', name: 'Tên lớp', frozen: false, width: 250 },
            { key: 'numberOfStudents', name: 'Sĩ số', frozen: false, width: 60 },
            { key: 'actions', width: 120 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedClazz(row.clazz);
                    setSelectedIndex(row.index - 1);
                    showConfirmDialog(row);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedClazz(row.clazz);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
            {
                icon: <EventBusyIcon />,
                callback: () => {
                    setSelectedClazz(row.clazz);
                    setSelectedIndex(row.index - 1);
                    openHolidayDialog();
                },
            },
        ];
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const onEditClazzSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá lớp này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                if (selectedItem.clazz.id) {
                    callApiDeleteClass(selectedItem.clazz.id)
                        .then((res) => {
                            onDeleteSuccess(selectedItem.index - 1);
                            alert({ icon: 'success', title: 'Xoá lớp thành công', showConfirmButton: false });
                        })
                        .catch((error) => {
                            if (error.response && error.response.status === 400) {
                                alert({ icon: 'error', title: 'Lỗi', text: 'Không thể xoá lớp. ' + errorHelper.getErrorMessageByKey(error.response.data.messageKey) });
                            } else {
                                alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá lớp! Vui lòng thử lại sau.' });
                            }
                        });
                }
            }
        });
    };

    return (
        <div className="class-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedClazz && <ClazzDialog clazz={selectedClazz} open={open} closeDialog={closeDialog} onSuccess={onEditClazzSuccess} />}
            {selectedClazz && <ClazzHolidayDialog clazz={selectedClazz} open={openHoliday} closeDialog={closeHolidayDialog} academicYear={academicYear} />}
        </div>
    );
}

export default ClazzList;
