import SignInPage from '../../components/authentication/SignInPage';
import Forbidden from '../../components/exception/Forbidden';
import NotFound from '../../components/exception/NotFound';

const nonProtectedRoutes = [
  {
    path: '/',
    component: SignInPage,
  },
  {
    path: '/sign-in',
    component: SignInPage,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/access-denied',
    component: Forbidden,
  },
  {
    component: NotFound,
  },
];

export default nonProtectedRoutes;
