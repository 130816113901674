import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiGetListStudent } from 'redux/saga/mam-non/info/class/student/getListStudents';
import { callApiExportDangKiMonHoc } from 'redux/saga/mam-non/info/dang-ki-mon-hoc/exportDangKiMonHoc';
import { callApiGetDangKiMonHocInfo } from 'redux/saga/mam-non/info/dang-ki-mon-hoc/getAllDangKiMonHoc';
import { callApiRegisterSubjects } from 'redux/saga/mam-non/info/dang-ki-mon-hoc/registerSubjects';
import { callApiUnRegisterSubjects } from 'redux/saga/mam-non/info/dang-ki-mon-hoc/unRegisterSubjects';
import { callApiGetAllOptionalDuThu } from 'redux/saga/mam-non/info/du-thu/getAllDuThuOptional';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { academic_year__get_list, loaded, loading } from '../actions';
import DangKiMonHocList from './DangKiMonHocList';

function MamNonDangKiMonHocPage() {
    const theme = useTheme();
    const { control, errors, setValue, getValues, trigger, watch } = useForm();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [students, setStudents] = useState();
    const [clazzes, setClazzes] = useState([]);
    const [duThusOptional, setDuThusOptional] = useState();
    const [dangKyMonHocInfo, setDangKyMonHocInfo] = useState(null);
    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getDuThuOptional = useCallback(() => {
        setDuThusOptional(null);
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetAllOptionalDuThu(authenticationReducer.userInfo.congDoanId, grade.value.id)
                .then((res) => {
                    setDuThusOptional(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                });
        }
    }, [authenticationReducer, grade]);

    const getDangKyMonHocInfo = useCallback(
        (clazzId) => {
            setDangKyMonHocInfo(null);
            if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
                callApiGetDangKiMonHocInfo(authenticationReducer.userInfo.congDoanId, clazzId).then((res) => {
                    setDangKyMonHocInfo(res.data);
                });
            }
        },
        [authenticationReducer]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.clazz && values.clazz.id) {
            getStudents(values.clazz.id);
            getDangKyMonHocInfo(values.clazz.id);
            getDuThuOptional();
        }
    }, [values, watches, prevValues, getDuThuOptional, getDangKyMonHocInfo]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    const onClazzChange = (selectedOption) => {
        setValue('clazz', selectedOption.value);
        trigger('clazz');
    };

    const getStudents = (clazzId) => {
        setStudents(null);
        callApiGetListStudent(clazzId).then((res) => {
            setStudents(res.data);
        });
    };

    const buildDangKyBody = (duThuId, studentIds) => {
        return {
            duThuId,
            studentIds,
        };
    };

    const onRegisterSubject = (duThuId, students) => {
        const congDoanId = get(authenticationReducer, 'userInfo.congDoanId', undefined);
        const clazzId = get(values, 'clazz.id', undefined);
        if (congDoanId && clazzId) {
            const dangKyBody = buildDangKyBody(duThuId, students);
            dispatch(loading());
            callApiRegisterSubjects(congDoanId, clazzId, dangKyBody)
                .then((res) => {
                    getDangKyMonHocInfo(clazzId);
                    toastHelper.success('Lưu đăng kí môn học thành công');
                })
                .catch((err) => messageHelper.showError(err))
                .finally(() => dispatch(loaded()));
        }
    };

    const onUnRegisterSubject = (duThuId, students) => {
        const congDoanId = get(authenticationReducer, 'userInfo.congDoanId', undefined);
        const clazzId = get(values, 'clazz.id', undefined);
        if (congDoanId && clazzId) {
            const dangKyBody = buildDangKyBody(duThuId, students);
            dispatch(loading());
            callApiUnRegisterSubjects(congDoanId, clazzId, dangKyBody)
                .then((res) => {
                    getDangKyMonHocInfo(clazzId);
                    toastHelper.success('Lưu đăng kí môn học thành công');
                })
                .catch((err) => messageHelper.showError(err))
                .finally(() => dispatch(loaded()));
        }
    };

    const onExport = () => {
        const congDoanId = get(authenticationReducer, 'userInfo.congDoanId', undefined);
        const clazzId = get(values, 'clazz.id', undefined);
        const clazzName = get(values, 'clazz.className', undefined);
        if (congDoanId && clazzId) {
            dispatch(loading());
            callApiExportDangKiMonHoc(congDoanId, clazzId)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Danh sách đăng ký môn học lớp ${clazzName}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Đăng kí môn học</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label">Lớp:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="clazz"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onClazzChange}
                                                        options={clazzes.map((item) => {
                                                            return { value: item, label: item.className };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="grade">
                                        Khối:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={grades.map((item) => {
                                                return { value: item, label: item.name };
                                            })}
                                            id="grade"
                                            onChange={onGradeChange}
                                            value={grade}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.clazz && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExport}>
                                            Xuất dữ liệu
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {students && duThusOptional && dangKyMonHocInfo && (
                <DangKiMonHocList
                    students={students}
                    subjects={duThusOptional}
                    registrationInfo={dangKyMonHocInfo}
                    registerSubject={onRegisterSubject}
                    unregisterSubject={onUnRegisterSubject}
                    clazzId={get(values, 'clazz.id', undefined)}
                    refreshList={getDangKyMonHocInfo}
                />
            )}
        </>
    );
}

export default MamNonDangKiMonHocPage;
