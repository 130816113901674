import { call, put } from 'redux-saga/effects';
import { toastHelper } from 'helpers/toastHelper';
import FMI_ACTIONS from 'redux/actions';
import { transport } from 'config/transport';

export default function* getListOfCongDoanUsersBySuVu(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetListOfCongDoanUserBySuVu, action.payload.data);
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__GET_LIST_CONG_DOAN_USERS_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__CALL_FAILED,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfCongDoanUserBySuVu(suVu) {
    return transport.get(`/trunguong/users`, { params: { suVu: suVu } });
}
