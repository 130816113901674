import React from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import ReactDatePicker from 'react-datepicker';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { callApiUpdateStudent } from 'redux/saga/mam-non/info/class/student/editStudent';
import { callApiCreateStudent } from 'redux/saga/mam-non/info/class/student/createStudent';
import { toastHelper } from 'helpers/toastHelper';
import { messageHelper } from 'helpers/messageHelper';
import { useDispatch } from 'react-redux';
import { loaded, loading } from 'components/mam-non-page/actions';

function StudentDialog({ student, closeDialog, open, classId, onSuccess, clazz, students }) {
    const defaultValues = {
        firstName: student.firstName,
        lastName: student.lastName,
        gender: student.gender,
        dateOfBirth: dayjs(student.dateOfBirth, FMI_SERVER_DATE_FORMAT).toDate(),
        entryDate: dayjs(student.entryDate, FMI_SERVER_DATE_FORMAT).toDate(),
        fatherName: student.fatherName,
        fatherPhone: student.fatherPhone,
        motherName: student.motherName,
        motherPhone: student.motherPhone,
    };

    const { register, handleSubmit, errors, control, reset } = useForm();
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        const body = { ...data };
        body.dateOfBirth = dayjs.unix(dayjs(data.dateOfBirth).unix()).format(FMI_SERVER_DATE_FORMAT);
        body.entryDate = dayjs.unix(dayjs(data.entryDate).unix()).format(FMI_SERVER_DATE_FORMAT);
        if (student.id) {
            editStudent(body);
        } else {
            addStudent(body);
        }
    };

    const addStudent = (data) => {
        dispatch(loading());
        callApiCreateStudent(classId, data)
            .then((res) => {
                onSuccess();
                reset(defaultValues);
                toastHelper.success('Thêm học sinh thành công.');
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const editStudent = (data) => {
        dispatch(loading());
        callApiUpdateStudent(classId, student.id, data)
            .then((res) => {
                onSuccess(res.data);
                closeDialog();
                toastHelper.success('Chỉnh sửa học sinh thành công.');
            })
            .catch((err) => {
                messageHelper.showError(err);
                // toastHelper.error('Gặp lỗi khi chỉnh sửa học sinh. Vui lòng thử lại sau.');
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3>{student.id ? 'Chỉnh sửa học sinh' : 'Thêm học sinh'}</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <span className="font-weight-bold">Lớp: </span>
                            <span>{clazz.className}</span>
                        </div>
                        <div className="col-md-6">
                            <span className="font-weight-bold">Số học sinh: </span>
                            <span>{students && students.length}</span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="lastName">Họ đệm</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.lastName && 'border-danger')}
                                placeholder="Họ đệm"
                                defaultValue={defaultValues.lastName}
                                id="lastName"
                                name="lastName"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập Họ đệm!',
                                    },
                                })}
                            />
                            {errors.lastName && <div className="text-danger pt-2">{errors.lastName.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="firstName">Tên</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.firstName && 'border-danger')}
                                placeholder="Tên"
                                defaultValue={defaultValues.firstName}
                                id="firstName"
                                name="firstName"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên!',
                                    },
                                })}
                            />
                            {errors.firstName && <div className="text-danger pt-2">{errors.firstName.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="dateOfBirth">Ngày sinh</label>
                            <Controller
                                control={control}
                                name="dateOfBirth"
                                defaultValue={defaultValues.dateOfBirth}
                                render={(props) => {
                                    return (
                                        <ReactDatePicker
                                            placeholderText="Chọn ngày sinh"
                                            onChange={(e) => props.onChange(e)}
                                            className="form-control"
                                            dateFormat="dd.MM.yyyy"
                                            selected={props.value}
                                            popperProps={{
                                                positionFixed: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="entryDate">Ngày vào lớp</label>
                            <Controller
                                control={control}
                                name="entryDate"
                                defaultValue={defaultValues.entryDate}
                                render={(props) => (
                                    <ReactDatePicker
                                        placeholderText="Chọn ngày vào lớp"
                                        onChange={(e) => props.onChange(e)}
                                        className="form-control"
                                        dateFormat="dd.MM.yyyy"
                                        selected={props.value}
                                        popperProps={{
                                            positionFixed: true,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="fatherName">Họ tên cha</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Họ tên cha"
                                defaultValue={defaultValues.fatherName}
                                id="fatherName"
                                name="fatherName"
                                ref={register}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="fatherPhone">Điện thoại cha</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Điện thoại cha"
                                defaultValue={defaultValues.fatherPhone}
                                id="fatherPhone"
                                name="fatherPhone"
                                ref={register}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="motherName">Họ tên mẹ</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Họ tên mẹ"
                                defaultValue={defaultValues.motherName}
                                id="motherName"
                                name="motherName"
                                ref={register}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="motherPhone">Điện thoại mẹ</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Điện thoại mẹ"
                                defaultValue={defaultValues.motherPhone}
                                id="motherPhone"
                                name="motherPhone"
                                ref={register}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center">
                            <label className="mr-3">Giới tính</label>
                            <Controller
                                as={
                                    <RadioGroup aria-label="gender" className="flex-row">
                                        <FormControlLabel value="FEMALE" control={<Radio />} label="Nữ" />
                                        <FormControlLabel value="MALE" control={<Radio />} label="Nam" />
                                    </RadioGroup>
                                }
                                name="gender"
                                defaultValue={defaultValues.gender}
                                control={control}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default StudentDialog;
