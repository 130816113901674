export const INTERNAL_SERVER_ERROR = 'internalServerError';

export const FMI_API_ERRORS = [
    { key: 'classStillReferencedByStudent', message: 'Lớp hiện tại đã có học sinh.' },
    { key: 'classStillReferencedByHoliday', message: 'Lớp hiện tại đã có ngày nghỉ.' },
    { key: 'importStudentstoClass', message: 'File sai định dạng. Vui lòng chọn file có đúng định dạng!' },
    { key: 'congDoanNotFound', message: 'Không tìm thấy cộng đoàn' },
    { key: INTERNAL_SERVER_ERROR, message: 'Có lỗi xảy ra vui lòng thử lại sau.' },
    { key: 'studentHaveExisted', message: 'Học sinh đã tồn tại. Vui lòng nhập lại thông tin chính xác.' },
    { key: 'DanhMucCTPVNotFound', message: 'Không tìm thấy danh mục CTPV' },
    { key: 'unableToUpdateInvoice', message: 'Không thể thay đổi số tiền của mục này vì học sinh đã đóng tiền.' },
    { key: 'academicYearNotFound', message: 'Không tìm thấy năm học tương ứng' },
    { key: 'accessCodeNotTrue', message: 'Mã điểm danh sai. Vui lòng nhập lại mã điểm danh chính xác cho lớp này' },
    { key: 'studentHaveExisted', message: 'Tồn tại học sinh trùng tên. Vui lòng nhập lại thông tin chính xác' },
    { key: 'KhanDongExisted', message: 'Khấn dòng dành cho sơ này đã tồn tại.' },
    { key: 'unableToDeleteDuThu', message: 'Không thể xoá dự thu vì hiện tại đã có phiếu thu hoặc đăng kí môn học sử dụng nó.' },
    { key: 'previousAcademicYearNotFound', message: 'Không thể tìm thấy năm học trước. Vui lòng thử lại ở năm học khác' },
    { key: 'UserNameNotFound', message: 'Không tìm thấy người dùng.' },
    { key: 'noInvoiceIsGenerated', message: 'Không có học sinh nào khả dụng để tạo phiếu thu. Vui lòng kiểm tra lại đăng kí môn học.' },
    { key: 'danhMucThuDauNamNotExisted', message: 'Không tìm thấy danh mục thu đầu năm. Vui lòng kiểm tra lại.' },
    { key: 'anAssociatedTuitionExisted', message: 'Hiện tại đã có phiếu thu sử dụng danh mục thu này. Không thể cập nhật danh mục thu.' },
];

function getErrorMessageByKey(key) {
    const matchedError = FMI_API_ERRORS.find((item) => item.key === key);
    return matchedError ? matchedError.message : '';
}

export const errorHelper = {
    getErrorMessageByKey,
};
