import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FMI_MONTH_YEAR_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';

function MonthlyReportExportDialog({ onExport, monthYear, open, closeDialog }) {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data) => {
        const submitToTW = get(data, 'submitToTW', false);
        onExport(submitToTW);
        closeDialog();
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3 className="text-center">Bạn có chắc chắn muốn xuất file báo cáo tháng {dayjs(monthYear).format(FMI_MONTH_YEAR_FORMAT)}?</h3>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center">
                        <div className="form-group">
                            <label htmlFor="submitToTW">
                                <input className="mr-2" type="checkbox" defaultChecked={false} name="submitToTW" id="submitToTW" ref={register} />
                                Xuất báo cáo và gửi lên Trung Ương
                            </label>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="d-flex justify-content-center w-100">
                        <button type="button" className="btn btn-secondary mr-1" onClick={closeDialog}>
                            Đóng
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Đồng ý
                        </button>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default MonthlyReportExportDialog;
