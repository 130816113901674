import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getDuThuChiCongDoanReportOverview(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetThuChiCongDoanReportOverview, action.payload.data.academicYear);
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__GET_THU_CHI_CONG_DOAN_OVERVIEW_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.TRUNG_UONG__CALL_FAILED,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetThuChiCongDoanReportOverview(academicYear) {
    return transport.get(`/trunguong/du-thu-chi-report`, { params: { academicYear } });
}
