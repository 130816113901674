import CheckBoxFormatter from 'components/shared/react-data-grid/CheckBoxFormatter';
import { FMI_DATE_FORMAT } from 'config/constants/dateConstants';
import { RDG_CHECK_BOX_COLUMN_WIDTH } from 'config/constants/reactDataGridConstant';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { messageHelper } from 'helpers/messageHelper';
import { numberHelper } from 'helpers/numberHelper';
import { toastHelper } from 'helpers/toastHelper';
import { groupBy, sumBy } from 'lodash';
import numeral from 'numeral';
import { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { callApiSaveInvoicePayments } from 'redux/saga/mam-non/thu-chi/thu-tien/saveInvoicePayment';
import { congdoan_get_list_danh_muc_thu_mam_non } from '../actions';

const EmptyRowsView = () => {
    const message = 'Chưa có phiếu thu cho lớp này. Bạn có thể lập phiếu thu';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

export const CustomHeader = (props) => {
    return <div style={{ whiteSpace: 'break-spaces' }}>{props.column.name}</div>;
};

function ThuTienTable({ invoices, classId, onPaidSuccess, monthYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dmThuMamNons = useSelector((state) => state.commonReducer.dmThuMamNons, []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo.congDoanId) {
            dispatch(congdoan_get_list_danh_muc_thu_mam_non({ mappingFromMamNon: true }));
        }
    }, [dispatch, userInfo]);

    useEffect(() => {
        const rows = [];
        invoices
            .sort((a, b) => (a.stt > b.stt ? 1 : -1))
            .forEach((item) => {
                const row = buildRow(item);
                rows.push(row);
            });
        setRows(rows);
    }, [invoices]);

    const buildRow = (invoice) => {
        const row = {
            index: invoice.stt,
            student: invoice.student,
            lastName: invoice.student.lastName,
            firstName: invoice.student.firstName,
            isPaid: invoice.isPaid,
            note: invoice.note,
            paymentAmount: numberHelper.formatNumber(invoice.paymentAmount),
            paidDate: invoice.paidDate ? dateHelper.formatDate(invoice.paidDate) : '',
            mappingAmount: numberHelper.formatNumber(invoice.truyThuAmount),
            tuitions: invoice.tuitions,
            invoice: invoice,
        };
        const tuitionsGrouped = groupBy(invoice.tuitions, 'code');
        Object.entries(tuitionsGrouped).forEach(([k, v]) => {
            row[`tuition-${k}`] = numberHelper.formatNumber(sumBy(v, 'amount'));
        });
        return row;
    };

    const getTruyThuMonth = (monthYear, paidDate) => {
        const isTruyThu = dateHelper.isAfterNextMonth10thDate(monthYear, paidDate);
        if (isTruyThu) {
            if (dateHelper.isAfterNextMonth10thDate(monthYear, paidDate)) {
                const startDayOfPaidMonth = paidDate.startOf('month');
                if (paidDate.isAfter(startDayOfPaidMonth.add(10, 'day')) && paidDate.isBefore(startDayOfPaidMonth.add(1, 'month').add(10, 'day'))) {
                    return startDayOfPaidMonth;
                } else {
                    return startDayOfPaidMonth.subtract(1, 'month');
                }
            } else {
                return monthYear;
            }
        }
        return null;
    };

    const isDisableThuTien = useCallback(
        (selectedRow) => {
            const paidDate = dayjs(selectedRow.paidDate, FMI_DATE_FORMAT);
            const isTruyThu = dateHelper.isAfterNextMonth10thDate(monthYear, paidDate);
            const truyThuMonth = getTruyThuMonth(monthYear, paidDate);
            if (selectedRow.isPaid && !isTruyThu && dateHelper.isAfterNextMonth10thDate(monthYear)) {
                return true;
            } else if (
                selectedRow.paidDate !== null &&
                isTruyThu &&
                dayjs().format(FMI_DATE_FORMAT) !== selectedRow.paidDate &&
                dateHelper.isAfterNextMonth10thDate(truyThuMonth)
            ) {
                return true;
            }
            return false;
        },
        [monthYear]
    );

    const onPaid = useCallback(
        (rowIndex, column) => {
            const selectedRow = rows[rowIndex];
            dayjs.extend(customParseFormat);
            if (isDisableThuTien(selectedRow)) {
                toastHelper.warning('Không thể xoá thu tiền cho học sinh đã thu tiền tháng trước');
                return;
            }
            callApiSaveInvoicePayments(userInfo.congDoanId, classId, selectedRow.invoice.id, {
                isPaid: !selectedRow.isPaid,
                truyThuAmount: numeral(selectedRow.mappingAmount).value(),
                note: selectedRow.note,
            })
                .then((res) => {
                    onPaidSuccess();
                    toastHelper.success('Lưu thành công!');
                })
                .catch((err) => {
                    messageHelper.showError(err);
                });
        },
        [rows, classId, userInfo.congDoanId, onPaidSuccess, isDisableThuTien]
    );

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'lastName', name: 'Họ đệm', frozen: true, width: 250 },
            { key: 'firstName', name: 'Tên', frozen: true, width: 100 },
            {
                key: 'isPaid',
                name: 'Đã thu tiền',
                frozen: true,
                width: RDG_CHECK_BOX_COLUMN_WIDTH,
                formatter: CheckBoxFormatter,
                headerRenderer: <CustomHeader />,
                events: {
                    onDoubleClick: function (e, arg) {
                        onPaid(arg.rowIdx, arg.column);
                    },
                },
                editable: false,
            },
            {
                key: 'note',
                name: 'Ghi chú',
                frozen: true,
                width: 150,
                editable: true,
            },
            { key: 'paymentAmount', name: 'Tổng tiền', width: 110 },
            { key: 'paidDate', name: 'Ngày đóng', width: 110 },
            { key: 'mappingAmount', name: 'Tổng số tiền chuyển', width: 110, headerRenderer: <CustomHeader /> },
        ];
        const tuiTionColumns = dmThuMamNons.map((dmt) => {
            return { key: `tuition-${dmt.code}`, name: dmt.name, width: 110, headerRenderer: <CustomHeader /> };
        });
        return [...fixedColumns, ...tuiTionColumns].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, [onPaid, dmThuMamNons]);

    useEffect(() => {
        if (dmThuMamNons) {
            setColumns(buildColumns());
        }
    }, [buildColumns, dmThuMamNons]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                enableCellSelect={true}
                minHeight={tableHeight}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
                headerRowHeight={110}
            />
        </div>
    );
}

export default ThuTienTable;
