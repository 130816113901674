import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { loaded, loading, nun__get_list } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import { callApiGetBHYTs } from 'redux/saga/cong-doan/info/bao-hiem-y-te/getAllBHYT';
import { messageHelper } from 'helpers/messageHelper';
import BaoHiemYTeDialog from './BaoHiemYTeDialog';
import { toastHelper } from 'helpers/toastHelper';
import BaoHiemYTeList from './BaoHiemYTeList';

function BaoHiemYTePage() {
    const theme = useTheme();

    const { control, setValue, getValues, trigger, watch } = useForm();

    const dispatch = useDispatch();

    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const [bhyts, setBhyts] = useState(null);
    const [bhyt, setBhyt] = useState(null);
    const [open, setOpen] = useState(false);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        if (congDoanId) {
            dispatch(nun__get_list({ congDoanId, isActive: true }));
        }
    }, [dispatch, congDoanId]);

    const getBaoHiemYTes = useCallback(
        (congDoanId, nunId) => {
            dispatch(loading());
            setBhyts([]);
            callApiGetBHYTs(congDoanId, nunId)
                .then((res) => {
                    setBhyts(res.data);
                })
                .catch((err) => messageHelper.showError(err))
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const nunId = get(values, 'nun.value.id');
        if (isDiff && nunId && congDoanId) {
            getBaoHiemYTes(congDoanId, nunId);
        }
    }, [values, watches, prevValues, dispatch, getBaoHiemYTes, congDoanId]);

    const nunOptions = commonReducer.nuns
        ? commonReducer.nuns.map((item) => {
              return { value: item, label: item.fullName };
          })
        : [];

    const addBHYT = () => {
        const nunId = get(values, 'nun.value.id');
        setBhyt({ maso: '', diaChiThe: '', noiDangKy: '', giaTriSuDung: new Date(), nunId: nunId });
        openDialog();
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onNunChange = (selectedOption) => {
        setValue('nun', selectedOption);
        trigger('nun');
    };

    const onAddBHYTSuccess = () => {
        toastHelper.success('Lưu bhyt thành công!');
        const nunId = get(values, 'nun.value.id');
        if (userInfo.congDoanId && nunId) {
            getBaoHiemYTes(userInfo.congDoanId, nunId);
        }
    };

    const onDeleteSuccess = () => {
        toastHelper.success('Xoá bhyt thành công!');
        const nunId = get(values, 'nun.value.id');
        if (userInfo.congDoanId && nunId) {
            getBaoHiemYTes(userInfo.congDoanId, nunId);
        }
    };

    const onEditSuccess = () => {
        toastHelper.success('Lưu bhyt thành công!');
        const nunId = get(values, 'nun.value.id');

        if (userInfo.congDoanId && nunId) {
            getBaoHiemYTes(userInfo.congDoanId, nunId);
        }
    };

    return (
        <>
            <>
                <Paper elevation={3}>
                    <Box p={theme.spacing(0, 3)}>
                        <Typography variant="h6">Bảo hiểm y tế</Typography>
                    </Box>
                    <Divider></Divider>
                    <Box p={theme.spacing(1, 1)}>
                        <form>
                            <div className="row m-0">
                                <div className="col-md-3 pr-0">
                                    <div className="row mr-0">
                                        <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                            Sơ:
                                        </label>
                                        <div className="col-md-8 p-0">
                                            <Controller
                                                id="nun"
                                                name="nun"
                                                control={control}
                                                defaultValue={null}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Vui lòng chọn sơ để lấy thông tin BHYT!',
                                                    },
                                                }}
                                                render={() => {
                                                    return (
                                                        <ReactSelect
                                                            isClearable
                                                            options={nunOptions}
                                                            classNamePrefix="fmi-react-select"
                                                            styles={customSelectStyles}
                                                            onChange={onNunChange}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3"></div>
                                <div className="col-md-3"></div>
                                <div className="col-md-3">
                                    {values.nun && (
                                        <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addBHYT} title="Thêm BHYT">
                                            <AddIcon />
                                            Thêm BHYT
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </Box>
                </Paper>
                {bhyt && <BaoHiemYTeDialog bhyt={bhyt} closeDialog={closeDialog} open={open} onSuccess={onAddBHYTSuccess} />}
                {bhyts && <BaoHiemYTeList bhyts={bhyts} onDeleteSuccess={onDeleteSuccess} onEditSuccess={onEditSuccess} />}
            </>
        </>
    );
}

export default BaoHiemYTePage;
