import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from '../../actions';
import history from '../../../helpers/history';
import { transport } from '../../../config/transport';

export default function* signIn(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        const response = yield call(callApiSignIn, action.payload.data);
        if (response) {
            yield put({
                type: FMI_ACTIONS.AUTHENTICATION__AUTHENTICATE_SUCCESS,
                payload: response.data,
            });
            yield history.push('/home');
        }
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.AUTHENTICATION__FAIL,
            payload: {
                errorCode: e.response.data.status,
                errorMsg: e.response.data.status === 401 ? 'Tên đăng nhập hoặc mật khẩu chưa đúng' : 'Lỗi hệ thống, xin vui lòng thử lại sau',
            },
        });
    } finally {
        yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
    }
}

export function callApiSignIn(body) {
    return transport.post('/auth/signin', body);
}
