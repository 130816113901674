import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, congdoan_get_list_danh_muc_thuchi_daunam, loaded, loading } from 'components/mam-non-page/actions';
import { LOAI_THUCHI_DAUNAM } from 'config/constants/danhMucThuConstant';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfThuChiMamNon } from 'redux/saga/mam-non/thu-chi/thu-chi-dau-nam/getThuChiDauNam';
import { callApiSaveThuChiDauNamMamNon } from 'redux/saga/mam-non/thu-chi/thu-chi-dau-nam/saveThuChiDauNam';
import ThuChiDauNamList from './ThuChiDauNamList';

function MamNonThuChiDauNamPage() {
    const theme = useTheme();

    const { control, setValue, getValues, trigger, watch } = useForm();

    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [thuchiDauNams, setThuChiDauNams] = useState([]);
    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');
    const dmThuChiDauNams = get(commonReducer, 'dmThuChiDauNams');

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const getThuChiDauNam = useCallback((congDoanId, academicYear) => {
        setThuChiDauNams(null);
        setData(null);
        callApiGetListOfThuChiMamNon(congDoanId, academicYear)
            .then((res) => {
                setThuChiDauNams(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            });
    }, []);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    useEffect(() => {
        dispatch(congdoan_get_list_danh_muc_thuchi_daunam());
    }, [dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear && congDoanId) {
            getThuChiDauNam(congDoanId, academicYear);
        }
    }, [values, watches, prevValues, dispatch, getThuChiDauNam, congDoanId]);

    const onSubmit = () => {
        const body = buildBody();
        const academicYear = get(values, 'academicYear.value.name');
        dispatch(loading());
        callApiSaveThuChiDauNamMamNon(congDoanId, academicYear, body)
            .then((res) => {
                toastHelper.success('Lưu thu chi đầu năm thành công!');
                getThuChiDauNam(congDoanId, academicYear);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const buildBody = () => {
        const result = [];
        const dataMap = new Map(data);
        dataMap.forEach((value, key) => {
            const month = key;
            const idsMap = value.idMap;
            Object.entries(value).forEach(([k, v]) => {
                if (k === LOAI_THUCHI_DAUNAM.NGAN_HANG || k.indexOf(LOAI_THUCHI_DAUNAM.THU) !== -1 || k.indexOf(LOAI_THUCHI_DAUNAM.CHI) !== -1) {
                    if (k === LOAI_THUCHI_DAUNAM.NGAN_HANG) {
                        result.push(buildThuChiDauNamRequest(getIdMap(idsMap, k), LOAI_THUCHI_DAUNAM.NGAN_HANG, v, null, month));
                        return;
                    }
                    const keySplitted = k.split('_');
                    if (keySplitted.length > 1) {
                        result.push(buildThuChiDauNamRequest(getIdMap(idsMap, k), keySplitted[0], v, keySplitted[1], month));
                    }
                }
                if (k === LOAI_THUCHI_DAUNAM.NOTE) {
                    result.push(buildThuChiDauNamRequest(getIdMap(idsMap, k), LOAI_THUCHI_DAUNAM.NOTE, null, null, month, v));
                }
            });
        });
        return result;
    };

    const getIdMap = (idsMap, key) => {
        return idsMap.get(key) ? idsMap.get(key) : null;
    };

    const buildThuChiDauNamRequest = (id, loaiThuChiDauNam, amount, danhMucThuChiDauNamId, month, note) => {
        return {
            id,
            loaiThuChiDauNam,
            amount: +amount,
            danhMucThuChiDauNamId: danhMucThuChiDauNamId ? +danhMucThuChiDauNamId : null,
            month,
            note,
        };
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Thu chi đầu năm</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onSubmit}>
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.academicYear && (
                <ThuChiDauNamList academicYear={values.academicYear.value} thuchiDauNams={thuchiDauNams} dmThuChiDauNams={dmThuChiDauNams} data={data} setData={setData} />
            )}
        </>
    );
}

export default MamNonThuChiDauNamPage;
