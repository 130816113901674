import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { mamNonUserSubMenus, MAM_NON_PACKAGE } from 'components/app-navigator/navMenus';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        paddingTop: '10%',
    },
    container: {
        height: '100%',
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        minHeight: 300,
        minWidth: 300,
        padding: theme.spacing(3),
    },
    listHeader: {
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

function MamNonUserPage() {
    const classes = useStyles();
    const history = useHistory();
    const studentInfoItems = mamNonUserSubMenus.filter((item) => item.package === MAM_NON_PACKAGE.STUDENT_INFO);

    return (
        <div className={classes.wrapper}>
            <Container maxWidth="md" className={classes.container}>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                                        <ListItem className={classes.listHeader}>{MAM_NON_PACKAGE.STUDENT_INFO}</ListItem>
                                        {studentInfoItems.map((menu, index) => (
                                            <ListItem button onClick={() => history.push(menu.path)} key={index}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                                <ListItemText primary={menu.title} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default MamNonUserPage;
