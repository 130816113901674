import EditIcon from '@material-ui/icons/Edit';
import { RDG_HEADER_ROW_HEIGHT } from 'config/constants/reactDataGridConstant';
import { alert } from 'helpers/alertHelper';
import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import UpdateDangKyMonHocjDialog from './UpdateDangKyMonHocjDialog';

const CheckBoxFormatter = ({ value }) => {
    return (
        <>
            <input readOnly type="checkbox" checked={value.registered}></input>
            <span className="ml-1">{value.text}</span>
        </>
    );
};

const EmptyRowsView = () => {
    const message = 'Chưa có học sinh nào trong lớp';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

const fixedColumns = [
    { key: 'index', name: 'STT', editable: false, frozen: true, width: 50 },
    { key: 'lastName', name: 'Họ đệm', editable: false, frozen: true, width: 250 },
    { key: 'firstName', name: 'Tên', editable: false, frozen: true, width: 100 },
];

const actionsColumn = [{ key: 'actions', width: 120 }];

const DangKiMonHocHeader = (props) => {
    const onDoubleClick = () => {
        const isEditableColumns = !fixedColumns
            .map((item) => {
                return item.key;
            })
            .includes(props.column.key);
        if (isEditableColumns) {
            const isAllStudentRegistered = props.rows.every((item) => item[props.column.key]);
            const registerMessage = isAllStudentRegistered ? 'huỷ đăng ký' : 'đăng ký';
            alert({
                title: `Bạn có chắc chắn muốn ${registerMessage} môn ${props.column.name} cho tất cả học sinh trong lớp?`,
                showCancelButton: true,
                confirmButtonText: 'Có',
                cancelButtonText: 'Không',
            }).then((res) => {
                if (res.isConfirmed) {
                    onConfirm();
                }
            });
        }
    };

    const onConfirm = () => {
        const duThuId = props.column.key.split('-')[1];
        const isAllStudentRegistered = props.rows.every((item) => item[props.column.key]);
        const allStudentIds = props.rows.map((item) => {
            return item.studentId;
        });
        if (isAllStudentRegistered) {
            props.unregisterSubject(duThuId, allStudentIds);
        } else {
            props.registerSubject(duThuId, allStudentIds);
        }
    };

    return (
        <div onDoubleClick={onDoubleClick} style={{ whiteSpace: 'break-spaces', cursor: 'pointer' }} title="Nhấn đúp để chọn tất cả">
            {props.column.name}
        </div>
    );
};

function DangKiMonHocList({ students, subjects, registrationInfo, registerSubject, unregisterSubject, clazzId, refreshList }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedRegistration, setSelectedRegistration] = useState();
    const [open, setOpen] = useState(false);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const actionColumnActions = (row) => {
        return [
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedStudent(row.student);
                    setSelectedRegistration(row.registration);
                    openDialog();
                },
            },
        ];
    };

    const onDoubleClickOnCell = useCallback(
        (rowIndex, column) => {
            const duThuId = column.key.split('-')[1];
            const selectedStudentId = rows[rowIndex].studentId;
            let cellValue = rows[rowIndex][column.key];
            if (!cellValue) {
                registerSubject(duThuId, [selectedStudentId]);
            } else {
                unregisterSubject(duThuId, [selectedStudentId]);
            }
        },
        [rows, registerSubject, unregisterSubject]
    );

    const buildColumns = useCallback(() => {
        const subjectColumns = subjects.map((item) => {
            return {
                key: `subject-${item.id}`,
                name: item.name,
                editable: true,
                formatter: CheckBoxFormatter,
                width: 130,
                headerRenderer: <DangKiMonHocHeader rows={rows} unregisterSubject={unregisterSubject} registerSubject={registerSubject} />,
                events: {
                    onDoubleClick: function (e, arg) {
                        onDoubleClickOnCell(arg.rowIdx, arg.column);
                    },
                },
            };
        });
        return [...fixedColumns, ...subjectColumns, ...actionsColumn].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, [subjects, onDoubleClickOnCell, registerSubject, unregisterSubject, rows]);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    useEffect(() => {
        const rows = [];
        students.forEach((student, index) => {
            const row = {
                index: index + 1,
                firstName: student.firstName,
                lastName: student.lastName,
                studentId: student.id,
                student: student,
            };
            subjects.forEach((subject) => {
                row[`subject-${subject.id}`] = false;
            });
            const matchedRegistration = registrationInfo.find((item) => item.studentId === student.id);
            if (matchedRegistration) {
                matchedRegistration.subjects.forEach((subject) => {
                    row[`subject-${subject}`] = true;
                });
                matchedRegistration.registration.forEach((item) => {
                    row[`subject-${item.subjectId}`] = { registered: true, text: dateHelper.formatDate(item.ngayDangKy) };
                });
                row.registration = matchedRegistration.registration;
            }
            rows.push(row);
        });
        setRows(rows);
    }, [students, registrationInfo, subjects]);

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const onUpdateRegistrationSuccess = () => {
        refreshList(clazzId);
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                headerRowHeight={RDG_HEADER_ROW_HEIGHT}
                emptyRowsView={EmptyRowsView}
                getCellActions={getCellActions}
            />
            {selectedStudent && (
                <UpdateDangKyMonHocjDialog
                    student={selectedStudent}
                    registrations={selectedRegistration}
                    open={open}
                    closeDialog={closeDialog}
                    onSuccess={onUpdateRegistrationSuccess}
                    clazzId={clazzId}
                />
            )}
        </div>
    );
}

export default DangKiMonHocList;
