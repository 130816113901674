import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { congdoan_get_info } from 'components/authentication/actions';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportThuChiBanNganh } from 'redux/saga/ban-nganh/exportThuChiBanNganh';
import { callApiGetAllThuChiBanNganh } from 'redux/saga/ban-nganh/getAllThuChiBanNganh';
import { callApiSaveThuChiBanNganh } from 'redux/saga/ban-nganh/saveThuChiBanNganh';
import ThuChiBanNganhTable from './ThuChiBanNganhTable';

function BanNganhThuChiPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const [thuchis, setThuchis] = useState(null);
    const [data, setData] = useState(null);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getThuChis = useCallback(
        (academicYear) => {
            dispatch(loading());
            setThuchis(null);
            setData(null);
            callApiGetAllThuChiBanNganh(academicYear)
                .then((res) => setThuchis(res.data))
                .catch((err) => messageHelper.showError(err))
                .finally(() => dispatch(loaded()));
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            getThuChis(academicYear);
        }
    }, [values, watches, prevValues, getThuChis]);

    const buildBody = () => {
        return data.map((item) => {
            return {
                ...item,
                ...{
                    thuAmount: +item.thuAmount,
                    chiAmount: +item.chiAmount,
                    tonNganHang: +item.tonNganHang,
                    tonTienMat: +item.tonTienMat,
                    academicYear: get(values, 'academicYear.value.name'),
                    id: item.thuChiId,
                },
            };
        });
    };

    const onSave = () => {
        const body = buildBody();
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiSaveThuChiBanNganh(body)
                .then((res) => {
                    toastHelper.success('Lưu thành công');
                    getThuChis(academicYear);
                })
                .catch((err) => messageHelper.showError(err))
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const exportThuChiBanNganh = () => {
        dispatch(loading());
        const academicYear = get(values, 'academicYear.value.name');
        callApiExportThuChiBanNganh(academicYear)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Báo cáo thu chi ban ngành ${get(congDoanInfo, 'congDoanName', '')} năm học ${academicYear}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Thu chi ban ngành</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                            {values.academicYear && (
                                <div className="col-md-3">
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={exportThuChiBanNganh}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                </div>
                            )}
                            {data && (
                                <div className="col-md-3">
                                    <div className="row mr-0 d-flex justify-content-end">
                                        <div className="col-md-12 p-0 d-flex justify-content-end">
                                            <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onSave} title="Lưu">
                                                Lưu
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {thuchis && <ThuChiBanNganhTable thuchis={thuchis} academicYear={values.academicYear.value} setData={setData} />}
        </>
    );
}

export default BanNganhThuChiPage;
