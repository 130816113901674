import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import reportWebVitals from './reportWebVitals';
import reducers from './redux/reducers';
import sagas from './redux/saga';
import history from './helpers/history';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './index.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const reduxSaga = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxSaga)));

reduxSaga.run(sagas);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4dabf5',
            light: '#4dabf5',
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: 'white',
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
