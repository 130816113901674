import { call, put } from '@redux-saga/core/effects';
import { transport } from 'config/transport';
import FMI_ACTIONS from 'redux/actions';

export default function* getListDanhMucCTPVOfCongDoan(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetListDanhMucCTPVOfCongDoan, action.payload.congDoanId, action.payload.isActive);
        yield put({
            type: FMI_ACTIONS.CTPV_GET_LIST_DANH_MUC_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.CTPV_CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListDanhMucCTPVOfCongDoan(congDoanId, isActive) {
    return transport.get(`/ctpv/danhmuc/congDoan/${congDoanId}`, { params: { isActive } });
}
