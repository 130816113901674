import { call, put } from '@redux-saga/core/effects';
import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import FMI_ACTIONS from 'redux/actions';

export default function* checkAttendance(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiCheckAttendance, action.payload.classId, action.payload.attendanceData);
        toastHelper.success('Điểm danh thành công');
        yield put({
            type: FMI_ACTIONS.ATTENDANCE__CHECK_ATTENDANCE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toastHelper.error('Gặp sự cố trong khi điểm danh. Vui lòng thử lại sau');
        yield put({
            type: FMI_ACTIONS.ATTENDANCE__CHECK_ATTENDANCE_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiCheckAttendance(classId, body) {
    return transport.post(`/mamnon/classes/${classId}/attendances`, body);
}
