import { Box, Divider, makeStyles, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useTheme } from '@material-ui/core';
import { trunguong__get_du_thu_chi_congdoan_overview } from 'components/trung-uong-page/actions';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CircularProgressbar } from 'react-circular-progressbar';
import TabPanel from 'components/shared/TabPanel';
import dayjs from 'dayjs';
import { FMI_DATE_FORMAT } from 'config/constants/dateConstants';
import { academic_year__get_list } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        maxHeight: 600,
    },
});

function DuThuChiCongDoanReportOverviewPage() {
    const { control, getValues, watch, setValue, trigger } = useForm();
    const dispatch = useDispatch();
    const [finishedTab, setFinishedTab] = React.useState(0);
    const classes = useStyles();

    const handleChangeFinishedTab = (event, newValue) => {
        setFinishedTab(newValue);
    };

    const theme = useTheme();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);
    const commonReducer = useSelector((state) => state.commonReducer);
    const duThuChiCongDoanReportOverview = useSelector((state) => state.commonReducer.duThuChiCongDoanReportOverview, null);
    const percent = duThuChiCongDoanReportOverview ? Math.round((duThuChiCongDoanReportOverview.finished.length / duThuChiCongDoanReportOverview.totalCongDoan) * 100) / 100 : 0;

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.academicYear) {
            dispatch(trunguong__get_du_thu_chi_congdoan_overview({ academicYear: get(values, 'academicYear.value.name') }));
        }
    }, [values, watches, prevValues, dispatch]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Tổng quan dự thu/chi cộng đoàn</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {duThuChiCongDoanReportOverview && (
                <>
                    <div className="row mx-0 mt-3">
                        <div className="col-md-3 pr-0 d-flex justify-content-center align-items-center" style={{ minHeight: 500 }}>
                            <div style={{ width: 200, height: 200 }}>
                                <CircularProgressbar value={percent} text={`${percent * 100}%`} maxValue={1} />
                            </div>
                        </div>
                        <div className="col-md-9 pr-0">
                            <Tabs value={finishedTab} onChange={handleChangeFinishedTab} aria-label="simple tabs example">
                                <Tab label="Đã hoàn thành" />
                                <Tab label="Chưa hoàn thành" />
                            </Tabs>
                            <TabPanel value={finishedTab} index={0}>
                                <TableContainer component={Paper} className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>STT</TableCell>
                                                <TableCell>Cộng đoàn</TableCell>
                                                <TableCell>Ngày gửi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {duThuChiCongDoanReportOverview.finished.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>{get(row, 'congDoan.name', '')}</TableCell>
                                                    <TableCell>{dayjs(get(row, 'updatedDate', '')).format(FMI_DATE_FORMAT)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={finishedTab} index={1}>
                                <TableContainer component={Paper} className={classes.tableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>STT</TableCell>
                                                <TableCell>Cộng đoàn</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {duThuChiCongDoanReportOverview.notYetFinished.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>{get(row, 'name', '')}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default DuThuChiCongDoanReportOverviewPage;
