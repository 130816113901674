import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { khan_dong__get_list } from '../actions';
import { get } from 'lodash';
import KhanDongList from './KhanDongList';
import KhanDongDialog from './KhanDongDialog';
import { toastHelper } from 'helpers/toastHelper';
function KhanDongPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const congdoanReducer = useSelector((state) => state.congdoanReducer);
    const congDoanId = get(authenticationReducer, 'userInfo.congDoanId');
    const khanDongs = get(congdoanReducer, 'khanDongs');

    const [open, setOpen] = useState(false);
    const [khanDong, setKhanDong] = useState(null);

    useEffect(() => {
        dispatch(khan_dong__get_list({ congdoanId: congDoanId }));
    }, [dispatch, congDoanId]);

    const addKhanDong = () => {
        setKhanDong({ giaoXu: '', giaoPhan: '', congViec: '' });
        openDialog();
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onAddKhanDongSuccess = () => {
        toastHelper.success('Lưu khấn dòng thành công!');
        if (congDoanId) {
            dispatch(khan_dong__get_list({ congdoanId: congDoanId }));
        }
    };

    const onDeleteSuccess = () => {
        toastHelper.success('Xoá khấn dòng thành công!');
        if (congDoanId) {
            dispatch(khan_dong__get_list({ congdoanId: congDoanId }));
        }
    };

    const onEditSuccess = () => {
        toastHelper.success('Lưu khấn dòng thành công!');
        if (congDoanId) {
            dispatch(khan_dong__get_list({ congdoanId: congDoanId }));
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Khấn dòng</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addKhanDong} title="Thêm khấn dòng">
                                    <AddIcon />
                                    Thêm khấn dòng
                                </button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {khanDongs && <KhanDongList khanDongs={khanDongs} onEditSuccess={onEditSuccess} onDeleteSuccess={onDeleteSuccess} />}
            {open && <KhanDongDialog khanDong={khanDong} closeDialog={closeDialog} open={open} onSuccess={onAddKhanDongSuccess} />}
        </>
    );
}

export default KhanDongPage;
