import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from '../../../../actions';
import { transport } from '../../../../../config/transport';
import { toastHelper } from 'helpers/toastHelper';

export default function* getListOfCongDoanDanhMucThuMamNon(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiGetListOfCongDoanDanhMucThuMamNon, action.payload.mappingFromMamNon);
        yield put({
            type: FMI_ACTIONS.CONGDOAN_GET_DANH_MUC_THU_MAM_NON_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.CONGDOAN_CALL_FAIL,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfCongDoanDanhMucThuMamNon(mappingFromMamNon) {
    return transport.get(`/mamnon/thuchi/thumamnon/danhmuc/frommamnon`, { params: { mappingFromMamNon } });
}
