import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ContactsIcon from '@material-ui/icons/Contacts';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useDispatch, useSelector } from 'react-redux';
import { authentication_sign_out } from 'components/authentication/actions';
import './styles.scss';
import { get } from 'lodash';
import { getAuthorizedMenus } from './navMenus';
import UserInfoDialog from 'components/authentication/user/UserInfoDialog';

function Header() {
    const dispatch = useDispatch();
    const history = useHistory();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const username = get(authenticationReducer, 'userInfo.username', '');
    const roles = get(authenticationReducer, 'userInfo.roles', []);
    const [open, setOpen] = useState(false);

    const logout = () => {
        dispatch(authentication_sign_out(() => history.push('/')));
    };

    const authorizedMenus = getAuthorizedMenus(roles);

    const openInfoDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const onOpenGuide = () => {
        window.open('/assets/files/user-guide.pdf');
    };

    return (
        <>
            <nav className="fmi-navbar navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand mr-5" to="/">
                        FMI Phu Xuan Hue
                    </Link>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            {authorizedMenus
                                .filter((item) => !item.disabled)
                                .map(
                                    (item, index) =>
                                        !item.disabled && (
                                            <li className="nav-item nav__menu" key={index}>
                                                <Link className="nav-link" to={item.path}>
                                                    {item.title}
                                                </Link>
                                                {item.subMenus && item.subMenus.length > 0 && (
                                                    <ul className="nav__sub_menu">
                                                        {item.subMenus.map((subMenu, index) => (
                                                            <li className="nav__submenu-item" key={index}>
                                                                <Link to={subMenu.path}>{subMenu.title}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        )
                                )}
                        </ul>
                    </div>
                </div>
                <div className="collapse navbar-collapse navbar-tooltip" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item nav__menu">
                            <span className="nav-link">{username}</span>
                            <ul className="nav__sub_menu">
                                <li className="nav__submenu-item">
                                    <span onClick={onOpenGuide}>
                                        <FindInPageIcon className="mr-2" />
                                        Hướng dẫn sử dụng
                                    </span>
                                </li>
                                <li className="nav__submenu-item">
                                    <span onClick={openInfoDialog}>
                                        <ContactsIcon className="mr-2" />
                                        Thông tin cộng đoàn
                                    </span>
                                </li>
                                <li className="nav__submenu-item">
                                    <span onClick={logout}>
                                        <ExitToAppIcon className="mr-2" />
                                        Đăng xuất
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            {open && <UserInfoDialog open={open} closeDialog={closeDialog} />}
        </>
    );
}

export default Header;
