import React from 'react';

function NotFound() {
    return (
        <div>
            <h1 className="title">Page not found</h1>
            <h2 className="subtitle">This is the error page</h2>
        </div>
    );
}

export default NotFound;
