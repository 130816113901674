import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { monthsOfYear } from 'config/constants/monthsConstants';
import usePrevious from 'helpers/usePrevious';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportAttendanceForm } from 'redux/saga/mam-non/attendance/attendance/exportAttendanceForm';
import { callApiGetAttendanceInfo } from 'redux/saga/mam-non/info/class/getClassAttendanceInfo';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { academic_year__get_list, attendance__check_attendance, loaded, loading } from '../actions';
import './styles.scss';
import AttendanceForm from './attendance-table/AttendanceForm';
import clsx from 'clsx';
import HolidayTable from './holiday-table/HolidayTable';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { get } from 'lodash';
import { ROLES } from 'config/constants/roleConstant';
import { callApiCheckAccessCode } from 'redux/saga/mam-non/attendance/attendance/checkAccessCode';

function MamNonDiemDanhPage() {
    const theme = useTheme();
    const { control, handleSubmit, errors, setValue, getValues, trigger, watch, register } = useForm();
    const dispatch = useDispatch();
    const [academicYear, setAcademicYear] = useState(null);
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [clazzes, setClazzes] = useState([]);
    const [attendanceInfo, setAttendanceInfo] = useState(null);
    const [attendanceData, setAttendanceData] = useState(null);
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const roles = get(authenticationReducer, 'userInfo.roles', []);
    const isMamNonAdmin = roles.indexOf(ROLES.ROLE_MAMNON_ADMIN) >= 0;

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isMamNonAdmin && isDiff && values.month && values.clazz.id) {
            getAttendanceInfo(values.month, values.clazz.id);
        }
    }, [values, watches, prevValues, isMamNonAdmin]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const monthOptions = monthsOfYear.map((item) => {
        return { value: item, label: 'Tháng ' + item };
    });

    const onAcademicYearChange = (selectedOption) => {
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        setAcademicYear(selectedOption.value);
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (!isMamNonAdmin && isDiff) {
            setAttendanceInfo(null);
            setAttendanceData(null);
        }
    }, [isMamNonAdmin, prevValues, values, setValue]);

    const onMonthChange = (selectedOption) => {
        setValue('month', selectedOption.value);
        setValue('accessCode', '');
        trigger('month');
        trigger('accessCode');
    };

    const onSubmit = (data) => {
        callApiExportAttendanceForm({ classId: data.clazz.id, month: data.month }).then((res) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${data.clazz.className}_Bảng điểm danh.xlsx`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };

    const onExportData = () => {
        callApiExportAttendanceForm({ classId: values.clazz.id, month: values.month, includeData: true }).then((res) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${values.clazz.className}_Bảng điểm danh.xlsx`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };

    const onClazzChange = (selectedOption) => {
        setValue('clazz', selectedOption.value);
        trigger('clazz');
        setValue('accessCode', '');
        trigger('accessCode');
    };

    const getAttendanceInfo = (month, clazzId) => {
        setAttendanceInfo(null);
        callApiGetAttendanceInfo({ classId: clazzId, month }).then((res) => {
            setAttendanceInfo(res.data);
            setAttendanceData(res.data);
        });
    };

    const onSubmitAttendanceForm = () => {
        const classId = getValues()['clazz'].id;
        const submitAttendanceData = buildAttendanceData();
        dispatch(attendance__check_attendance(classId, submitAttendanceData));
    };

    const buildAttendanceData = () => {
        const studentAbsents = [];
        for (const data of attendanceData) {
            const absent = {};
            absent.id = data.studentId;
            const dayOffs = [];
            Object.entries(data)
                .filter(([k, v]) => !Number.isNaN(k) && isReasonForDayOff(v))
                .forEach(([k, v]) => {
                    dayOffs.push({ date: Number(k), reason: v });
                });
            absent.dayOffs = dayOffs;
            studentAbsents.push(absent);
        }
        return { month: getValues()['month'], students: studentAbsents };
    };

    const isReasonForDayOff = (value) => {
        return value === 'K' || value === 'P';
    };

    const onGetListAttendance = () => {
        trigger('accessCode');
        if (values.clazz.id && values.accessCode) {
            dispatch(loading());
            callApiCheckAccessCode(values.clazz.id, values.accessCode)
                .then(() => {
                    if (values.month) {
                        getAttendanceInfo(values.month, values.clazz.id);
                    }
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onChangeAccessCode = () => {
        trigger('accessCode');
    };

    const onChangeOffOneMonthSuccess = () => {
        getAttendanceInfo(values.month, values.clazz.id);
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Điểm danh</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="month">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="month"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={() => {
                                                return <Select onChange={onMonthChange} options={monthOptions} classNamePrefix="fmi-react-select" styles={customSelectStyles} />;
                                            }}
                                        />
                                        {errors.month && <span className="text-danger">{errors.month.message}</span>}
                                    </div>
                                </div>
                                {!isMamNonAdmin && (
                                    <div className="row mr-0 mt-2">
                                        <label className="mb-0 col-md-4 fmi-label" htmlFor="accessCode">
                                            Mã:
                                        </label>
                                        <div className="col-md-8 p-0">
                                            <input
                                                className="form-control"
                                                type="text"
                                                style={{ height: 26 }}
                                                ref={register({
                                                    required: {
                                                        value: true,
                                                        message: 'Vui lòng nhập mã để điểm danh!',
                                                    },
                                                })}
                                                onChange={onChangeAccessCode}
                                                maxLength="8"
                                                placeholder="Mã điểm danh"
                                                id="accessCode"
                                                name="accessCode"
                                            />
                                            {errors.accessCode && <span className="text-danger">{errors.accessCode.message}</span>}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="academicYear">
                                        Khối:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={grades.map((item) => {
                                                return { value: item, label: item.name };
                                            })}
                                            id="academicYear"
                                            onChange={onGradeChange}
                                            value={grade}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label">Lớp:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="clazz"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onClazzChange}
                                                        options={clazzes.map((item) => {
                                                            return { value: item, label: item.className };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                                {!isMamNonAdmin && (
                                    <div className="row mr-0 mt-2">
                                        <label className="mb-0 col-md-4 fmi-label" htmlFor=""></label>
                                        <div className="col-md-8 p-0">
                                            <button
                                                type="button"
                                                className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true })}
                                                onClick={onGetListAttendance}
                                                title="Lấy danh sách điểm danh"
                                            >
                                                Lấy DS điểm danh
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-5">
                                {getValues()['month'] > 0 && attendanceInfo && (
                                    <div style={{ width: 450 }}>
                                        <HolidayTable holidays={attendanceInfo.holidays} month={getValues()['month']} onChangeHoliday={() => {}} />
                                    </div>
                                )}
                            </div>
                            <div className="col-md-1 row form-group mb-0 d-flex flex-column justify-content-end align-items-start p-0 w-100 h-100">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': attendanceInfo === null })}
                                        onClick={onSubmitAttendanceForm}
                                        title="Lưu"
                                    >
                                        Lưu
                                    </button>
                                </div>
                                <div className="row mx-0 w-100">
                                    <button
                                        type="submit"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': attendanceInfo === null })}
                                        title="In biểu mẫu"
                                    >
                                        In biểu mẫu
                                    </button>
                                </div>
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button text-small': true, 'd-none': attendanceInfo === null })}
                                        onClick={onExportData}
                                        title="Xuất dữ liệu"
                                    >
                                        Xuất dữ liệu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {getValues()['month'] && values.clazz.id && attendanceInfo && academicYear && (
                <AttendanceForm
                    attendanceInfo={attendanceInfo}
                    selectedMonth={getValues()['month']}
                    setAttendanceData={setAttendanceData}
                    academicYear={academicYear}
                    clazzId={values.clazz.id}
                    onChangeOffOneMonthSuccess={onChangeOffOneMonthSuccess}
                ></AttendanceForm>
            )}
        </>
    );
}

export default MamNonDiemDanhPage;
