import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';

const EmptyRowsView = () => {
    const message = 'Chưa có tháng nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function NoiTruNumberOfStudentList({ noiTruNumberOfStudents, setData }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
        setData(newRows);
    };

    useEffect(() => {
        setColumns([
            { key: 'month', name: 'Tháng', width: 100 },
            { key: 'numberOfNuns', name: 'Số lượng học sinh', editable: true, width: 180 },
        ]);
    }, []);

    const getNumberOfStudents = useCallback(
        (month) => {
            switch (month) {
                case 1:
                    return get(noiTruNumberOfStudents, 'janAmount');
                case 2:
                    return get(noiTruNumberOfStudents, 'febAmount');
                case 3:
                    return get(noiTruNumberOfStudents, 'marAmount');
                case 4:
                    return get(noiTruNumberOfStudents, 'aprAmount');
                case 5:
                    return get(noiTruNumberOfStudents, 'mayAmount');
                case 6:
                    return get(noiTruNumberOfStudents, 'junAmount');
                case 7:
                    return get(noiTruNumberOfStudents, 'julAmount');
                case 8:
                    return get(noiTruNumberOfStudents, 'augAmount');
                case 9:
                    return get(noiTruNumberOfStudents, 'sepAmount');
                case 10:
                    return get(noiTruNumberOfStudents, 'octAmount');
                case 11:
                    return get(noiTruNumberOfStudents, 'novAmount');
                case 12:
                    return get(noiTruNumberOfStudents, 'decAmount');

                default:
                    return '';
            }
        },
        [noiTruNumberOfStudents]
    );

    useEffect(() => {
        const rows = [];
        [...Array(12).keys()].forEach((index) => {
            const row = {
                month: index + 1,
                numberOfNuns: getNumberOfStudents(index + 1),
            };
            rows.push(row);
        });
        setRows(rows);
    }, [getNumberOfStudents]);

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                onGridRowsUpdated={onGridRowsUpdated}
                enableCellSelect={true}
                headerRowHeight={60}
                emptyRowsView={EmptyRowsView}
            />
        </div>
    );
}

export default NoiTruNumberOfStudentList;
