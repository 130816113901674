import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { messageHelper } from 'helpers/messageHelper';
import { numberHelper } from 'helpers/numberHelper';
import { toastHelper } from 'helpers/toastHelper';
import numeral from 'numeral';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { callApiUpdateTuition } from 'redux/saga/mam-non/attendance/invoice/updateTuition';
import { comparatorUtils } from 'utils/comparatorUtils';
import { loaded, loading } from '../actions';

const EmptyRowsView = () => {
    const message = 'Chưa có phiếu thu cho lớp này. Bạn có thể lập phiếu thu';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

const CustomHeader = (props) => {
    return <div style={{ whiteSpace: 'break-spaces' }}>{props.column.name}</div>;
};

function InvoiceTable({ classInvoice, classId, onSetSelectedRows, lackingStudents, selectedRowsPassed }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableHeight, setTableHeight] = useState();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        const rows = [];
        classInvoice.invoices
            .sort((a, b) => (a.stt > b.stt ? 1 : -1))
            .forEach((item) => {
                const row = buildRow(item);
                rows.push(row);
            });
        lackingStudents.forEach((item) => {
            const index = rows.length + 1;
            const row = buildLackingStudentRow(item, index);
            rows.push(row);
        });
        setRows(rows);
    }, [classInvoice, lackingStudents]);

    useEffect(() => {
        setSelectedRows(selectedRowsPassed);
    }, [selectedRowsPassed]);

    const buildRow = (invoice) => {
        const row = {
            index: invoice.stt,
            invoiceId: invoice.id,
            student: invoice.student,
            lastName: invoice.student.lastName,
            firstName: invoice.student.firstName,
            totalAmount: numberHelper.formatNumber(invoice.paymentAmount),
            lastMonthAbsent: invoice.previousAbsentDays,
            remainAmount: numberHelper.formatNumber(invoice.remainAmount),
            tuitions: invoice.tuitions,
        };
        invoice.tuitions.forEach((tuition) => {
            row[`tuition-${tuition.duthuId}`] = numberHelper.formatNumber(tuition.amount);
        });
        return row;
    };

    const buildLackingStudentRow = (student, index) => {
        const row = {
            index,
            student: student,
            lastName: student.lastName,
            firstName: student.firstName,
        };
        return row;
    };

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'lastName', name: 'Họ đệm', frozen: true, width: 250 },
            { key: 'firstName', name: 'Tên', frozen: true, width: 100 },
            { key: 'totalAmount', name: 'Tổng thu', width: 130, editable: false },
            { key: 'lastMonthAbsent', name: 'Ngày vắng tháng trước', width: 60, headerRenderer: <CustomHeader /> },
            { key: 'remainAmount', name: 'Tiền trừ tháng trước', width: 130, headerRenderer: <CustomHeader /> },
        ];
        const tuiTionColumns = classInvoice.tuitions.map((tuition) => {
            return { key: `tuition-${tuition.duthuId}`, name: tuition.name, width: 150, headerRenderer: <CustomHeader />, editable: true };
        });
        return [...fixedColumns, ...tuiTionColumns].map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, [classInvoice]);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        const columnChanged = Object.keys(updated)[0];
        const oldRow = { ...rows[fromRow] };
        const updatedValue = Object.values(updated)[0];
        // if (columnChanged === 'totalAmount') {
        //     updateInvoice(oldRow, columnChanged, updatedValue);
        // } else {
        updateTuitions(oldRow, columnChanged, updatedValue);
        // }
    };

    // This for future purpose if user want to change totalAmount
    // const updateInvoice = (oldRow, columnChanged, updatedValue) => {
    //     if (!comparatorUtils.compare(oldRow[columnChanged], updatedValue) && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
    //         dispatch(loading());
    //         callApiUpdateInvoice(authenticationReducer.userInfo.congDoanId, classId, oldRow.invoiceId, { amount: numeral(updatedValue).value() })
    //             .then((res) => {
    //                 toastHelper.success('Chỉnh sửa phiếu thu thành công.');
    //                 const newRows = [...rows];
    //                 newRows[oldRow.index - 1] = buildRow(res.data);
    //                 setRows(newRows);
    //             })
    //             .catch((err) => {
    //                 toastHelper.error('Có lỗi xảy ra trong quá trình chỉnh sửa phiếu thu. Vui lòng thử lại sau.');
    //             })
    //             .finally(() => {
    //                 dispatch(loaded());
    //             });
    //     }
    // };

    const updateTuitions = (oldRow, columnChanged, updatedValue) => {
        const duthuId = Number(columnChanged.split('-')[1]);
        const tuition = oldRow.tuitions.find((item) => item.duthuId === duthuId);
        if (!comparatorUtils.compare(oldRow[columnChanged], updatedValue) && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId && tuition) {
            dispatch(loading());
            callApiUpdateTuition(authenticationReducer.userInfo.congDoanId, classId, oldRow.invoiceId, tuition.uuid, { amount: numeral(updatedValue).value() })
                .then((res) => {
                    toastHelper.success('Chỉnh sửa mục thu thành công.');
                    const newRows = [...rows];
                    newRows.forEach((item, idx) => {
                        if (item.index === oldRow.index) {
                            newRows[idx] = buildRow(res.data);
                        }
                    });
                    setRows(newRows);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onRowsSelected = (rows) => {
        let newSelectedRows = selectedRows;
        newSelectedRows = newSelectedRows.concat(rows);
        setSelectedRows(newSelectedRows);
        onSetSelectedRows(newSelectedRows);
    };

    const onRowsDeselected = (rows) => {
        let newSelectedRows = selectedRows;
        let rowIndexes = rows.map((r) => r.rowIdx);
        newSelectedRows = newSelectedRows.filter((i) => rowIndexes.indexOf(i.rowIdx) === -1);
        setSelectedRows(newSelectedRows);
        onSetSelectedRows(newSelectedRows);
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                minHeight={tableHeight}
                headerRowHeight={110}
                emptyRowsView={EmptyRowsView}
                rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                        indexes: selectedRows.map((i) => i.rowIdx),
                    },
                }}
            />
        </div>
    );
}

export default InvoiceTable;
