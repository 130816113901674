import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { callApiDeleteBHYT } from 'redux/saga/cong-doan/info/bao-hiem-y-te/deleteBHYT';
import BaoHiemYTeDialog from './BaoHiemYTeDialog';
import { alert } from 'helpers/alertHelper';

const EmptyRowsView = () => {
    const message = 'Chưa có BHYT nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function BaoHiemYTeList({ bhyts, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedBHYT, setSelectedBHYT] = useState();
    const [open, setOpen] = useState(false);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedBHYT(null);
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        bhyts.forEach((bhyt, index) => {
            const row = {
                index: index + 1,
                id: bhyt.id,
                nunName: bhyt.nunName,
                nunDateOfBirth: bhyt.nunDateOfBirth ? dateHelper.formatDate(bhyt.nunDateOfBirth) : '',
                maso: bhyt.maso,
                diaChiThe: bhyt.diaChiThe,
                noiDangKy: bhyt.noiDangKy,
                giaTriSuDung: bhyt.giaTriSuDung ? dateHelper.formatDate(bhyt.giaTriSuDung) : '',
                bhyt,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [bhyts]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', width: 50 },
            { key: 'nunName', name: 'Tên sơ', width: 220 },
            { key: 'nunDateOfBirth', name: 'Ngày sinh', width: 100 },
            { key: 'maso', name: 'Mã số BH', width: 150 },
            { key: 'diaChiThe', name: 'Địa chỉ thẻ', width: 150 },
            { key: 'noiDangKy', name: 'Nơi đăng ký', width: 150 },
            { key: 'giaTriSuDung', name: 'Giá trị sử dụng', width: 150 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onEditBHYTSuccess = (data) => {
        onEditSuccess();
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedBHYT(row.bhyt);
                    showConfirmDialog(row.bhyt);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedBHYT(row.bhyt);
                    openDialog();
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá BHYT này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                callApiDeleteBHYT(userInfo.congDoanId, selectedItem.nunId, selectedItem.id)
                    .then((res) => {
                        onDeleteSuccess();
                        alert({ icon: 'success', title: 'Xoá BHYT thành công', showConfirmButton: false });
                    })
                    .catch((error) => {
                        alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá sơ! Vui lòng thử lại sau.' });
                    });
            }
        });
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedBHYT && <BaoHiemYTeDialog bhyt={selectedBHYT} open={open} closeDialog={closeDialog} onSuccess={onEditBHYTSuccess} />}
        </div>
    );
}

export default BaoHiemYTeList;
