import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiExportPhieuThu } from 'redux/saga/mam-non/attendance/invoice/exportPhieuThu';
import { callApiGetSubjects } from 'redux/saga/mam-non/attendance/invoice/getSubjects';
import { congdoan_get_payment_info, loaded, loading } from '../actions';

function PhieuThuPaymentDayDialog({ open, closeDialog, clazz, month, option, studentIds }) {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const [subjects, setSubjects] = useState([]);

    const authenticationReducer = useSelector((state) => state.authenticationReducer);

    const { paymentInfo } = useSelector((state) => state.commonReducer);

    const defaultValues = {
        paymentDueDate: paymentInfo ? paymentInfo.paymentDueDate : '',
        paymentSentDate: paymentInfo ? paymentInfo.paymentSentDate : '',
    };

    useEffect(() => {
        dispatch(loading());
        callApiGetSubjects(authenticationReducer.userInfo.congDoanId, clazz.id, month)
            .then((res) => {
                setSubjects(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [authenticationReducer.userInfo.congDoanId, clazz.id, month, dispatch]);

    // const onSubmit = (data) => {
    //     const selectedEntries = Object.entries(data).filter(([k, v]) => {
    //         return v;
    //     });
    //     const selectedDuThuIds = selectedEntries.length === 0 || selectedEntries.length === subjects.length ? [] : selectedEntries.map(([k, v]) => +k);
    //     onExport(selectedDuThuIds);
    // };

    const onSubmit = (data) => {
        if (month && clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            const selectedEntries = [];
            Object.entries(data).forEach(([k, v]) => {
                if (k.includes('subject') && v) {
                    selectedEntries.push(+k.replace(`subject_`, ''));
                }
            });
            console.log(selectedEntries);
            dispatch(loading());
            callApiExportPhieuThu(authenticationReducer.userInfo.congDoanId, clazz.id, month, data.paymentDueDate, data.paymentSentDate, studentIds, option.value, selectedEntries)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    window.open(downloadUrl);
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    closeDialog();
                    dispatch(congdoan_get_payment_info());
                    dispatch(loaded());
                });
        }
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3>Thông tin phiếu thu</h3>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="paymentDueDate">Ngày nộp tiền</label>
                            <input
                                type="text"
                                className={'form-control'}
                                placeholder="Ngày nộp tiền"
                                defaultValue={defaultValues.paymentDueDate}
                                id="paymentDueDate"
                                name="paymentDueDate"
                                ref={register()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="paymentSentDate">Ngày gửi phiếu thu</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Ngày gửi phiếu thu"
                                defaultValue={defaultValues.paymentSentDate}
                                id="paymentSentDate"
                                name="paymentSentDate"
                                ref={register()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="paymentSentDate">Chọn môn học</label>
                        </div>
                    </div>
                    {subjects.map((item) => {
                        return (
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor={item.duthuId}>
                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            defaultChecked={true}
                                            name={`subject_${item.duthuId.toString()}`}
                                            id={item.duthuId}
                                            ref={register}
                                        />
                                        {item.name}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Xuất
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default PhieuThuPaymentDayDialog;
