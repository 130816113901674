import { RDG_HEADER_ROW_HEIGHT } from 'config/constants/reactDataGridConstant';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import CTPVDanhMucDialog from './CTPVDanhMucDialog';
import { callApiDeleteDanhMucCTPV } from 'redux/saga/ctpv/danhmuc/deleteDanhMucCTPV';
import { alert } from 'helpers/alertHelper';

const EmptyRowsView = () => {
    const message = 'Chưa có danh mục công tác phục vụ nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function CTPVDanhMucList({ danhMucs, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedDanhMuc, setSelectedDanhMuc] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);

    const [tableHeight, setTableHeight] = useState();

    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setSelectedDanhMuc(null);
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        danhMucs.forEach((dm, index) => {
            const row = {
                index: index + 1,
                id: dm.id,
                name: dm.name,
                active: dm.active,
                dm,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [danhMucs]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', width: 50 },
            { key: 'name', name: 'Tên danh mục', width: 250 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const onEditDanhMucSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedDanhMuc(row.dm);
                    showConfirmDialog(row.dm);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedDanhMuc(row.dm);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
        ];
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá danh mục này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                callApiDeleteDanhMucCTPV(selectedItem.id)
                    .then((res) => {
                        onDeleteSuccess();
                        alert({ icon: 'success', title: 'Xoá danh mục thu thành công', showConfirmButton: false });
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            alert({
                                icon: 'error',
                                title: 'Không thể xoá danh mục',
                                text: 'Danh mục thu này đã được sử dụng bởi một dự thu. Vui lòng xoá dự thu trước khi tiếp tục thao tác.',
                            });
                        } else {
                            alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá danh mục thu! Vui lòng thử lại sau.' });
                        }
                    });
            }
        });
    };

    return (
        <div className="dmthu-data-grid" id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={rows.length > 0 ? tableHeight : 240}
                headerRowHeight={RDG_HEADER_ROW_HEIGHT}
                getCellActions={getCellActions}
                emptyRowsView={EmptyRowsView}
            />
            {selectedDanhMuc && <CTPVDanhMucDialog danhMuc={selectedDanhMuc} open={open} closeDialog={closeDialog} onSuccess={onEditDanhMucSuccess} />}
        </div>
    );
}

export default CTPVDanhMucList;
