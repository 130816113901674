import { call, put } from '@redux-saga/core/effects';
import FMI_ACTIONS from 'redux/actions';
import { callApiNoiTruGetNumberOfStudents } from '../noi-tru/report/students/getNumberOfStudents';

export default function* getNoiTruNumberOfStudent(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiNoiTruGetNumberOfStudents, action.payload.data.congDoanId, action.payload.data.year);
        yield put({
            type: FMI_ACTIONS.NOITRU__GET_NUMBER_OF_STUDENTS_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.NUN__CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}
