import { transport } from 'config/transport';
import { toastHelper } from 'helpers/toastHelper';
import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from 'redux/actions';

export default function* getCongDoanInfo() {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    try {
        let { data } = yield call(callApiGetCongDoanInfo);
        yield put({
            type: FMI_ACTIONS.CONGDOAN_GET_INFO_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.CONGDOAN_CALL_FAIL,
        });
        toastHelper.error(e.message);
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetCongDoanInfo() {
    return transport.get('/congdoan');
}
