import { Box, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { toastHelper } from 'helpers/toastHelper';
import { callApiGetNuns } from 'redux/saga/cong-doan/info/nun/getNuns';
import { messageHelper } from 'helpers/messageHelper';
import { loaded, loading } from 'components/mam-non-page/actions';
import NunDialog from './NunDialog';
import NunList from './NunList';

function NunPage() {
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const theme = useTheme();
    const [nuns, setNuns] = useState([]);
    const [nun, setNun] = useState(null);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const addNun = () => {
        setNun({ name: '' });
        openDialog();
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const getNuns = useCallback(
        (congDoanId, isActive) => {
            dispatch(loading());
            setNuns([]);
            callApiGetNuns(congDoanId, isActive)
                .then((res) => {
                    setNuns(res.data);
                })
                .catch((err) => messageHelper.showError(err))
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        if (userInfo.congDoanId) {
            getNuns(userInfo.congDoanId, true);
        }
    }, [userInfo.congDoanId, getNuns]);

    const onAddNunSuccess = () => {
        toastHelper.success('Lưu sơ thành công!');
        if (userInfo.congDoanId) {
            getNuns(userInfo.congDoanId, true);
        }
    };

    const onEditNunSuccess = () => {
        toastHelper.success('Lưu sơ thành công!');
        if (userInfo.congDoanId) {
            getNuns(userInfo.congDoanId, true);
        }
    };

    const onDeleteSuccess = () => {
        if (userInfo.congDoanId) {
            getNuns(userInfo.congDoanId, true);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <Box p={theme.spacing(0, 3)} className="d-flex align-items-center">
                    <Typography variant="h6">Quán lí sơ</Typography>
                </Box>
                <Box p={theme.spacing(1, 1)}>
                    <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addNun} title="Thêm sơ">
                        <AddIcon />
                        Thêm sơ
                    </button>
                </Box>
            </div>
            {nun && <NunDialog nun={nun} open={open} closeDialog={closeDialog} onSuccess={onAddNunSuccess} />}
            {nuns && <NunList nuns={nuns} onEditSuccess={onEditNunSuccess} onDeleteSuccess={onDeleteSuccess} />}
        </>
    );
}

export default NunPage;
