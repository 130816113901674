import { Box, Divider, IconButton, Menu, MenuItem, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { monthsOfYear } from 'config/constants/monthsConstants';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { alert } from 'helpers/alertHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { callApiExportBienLai } from 'redux/saga/mam-non/attendance/invoice/exportBienLai';
import { callApiExportSoThuThanhToan } from 'redux/saga/mam-non/attendance/invoice/exportSoThu';
import { callApiGenerateInvoice } from 'redux/saga/mam-non/attendance/invoice/generateInvoice';
import { callApiGetInvoice } from 'redux/saga/mam-non/attendance/invoice/getInvoice';
import { academic_year__get_list, congdoan_get_payment_info, loaded, loading } from '../actions';
import InvoiceTable from './InvoiceTable';
import PhieuThuPaymentDayDialog from './PhieuThuPaymentDayDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useCallback } from 'react';
import { callApiExportBienLaiThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/exportBienLaiThuDauNam';
import { callApiExportPhieuThuThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/exportPhieuThuThuDauNam';
import { callApiGetListStudentsLackingInvoice } from 'redux/saga/mam-non/info/class/student/getListStudentsLackingInvoice';
import { dateHelper } from 'helpers/dateHelper';
import dayjs from 'dayjs';
import { FMI_YEAR_MONTH_FORMAT } from 'config/constants/dateConstants';
import { messageHelper } from 'helpers/messageHelper';
import { get } from 'lodash';
import SoThuSelectSubjectsDialog from './SoThuSelectSubjectsDialog';
import BienLaiSelectSubjectsDialog from './BienLaiSelectSubjectsDialog';

function MamNonPhieuThuPage() {
    const theme = useTheme();
    const { control, errors, setValue, getValues, trigger, watch } = useForm({
        defaultValues: {
            option: { value: 'ALL', label: 'Tất cả' },
        },
    });
    const dispatch = useDispatch();
    const [academicYear, setAcademicYear] = useState(null);
    const [grades, setGrades] = useState([]);
    const [grade, setGrade] = useState('');
    const [clazzes, setClazzes] = useState([]);
    const [classInvoice, setClassInvoice] = useState(null);
    const [lackingStudents, setLackingStudents] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSelectSubjectsForSoThu, setOpenSelectSubjectsForSoThu] = useState(false);
    const [openSelectSubjectsForBienLai, setOpenSelectSubjectsForBienLai] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [optionClassName, setOptionClassName] = useState('has-default');
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getInvoice = useCallback(() => {
        callApiGetInvoice(authenticationReducer.userInfo.congDoanId, values.clazz.id, values.month)
            .then((res) => {
                setClassInvoice(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            });
    }, [authenticationReducer.userInfo, values.clazz, values.month]);

    const getStudentsLackingOfInvoice = useCallback(() => {
        const year = dateHelper.getYearFromAcademicYearAndMonth(academicYear, values.month);
        const yearMonth = dayjs()
            .year(Number(year))
            .month(values.month - 1);
        dispatch(loading());
        callApiGetListStudentsLackingInvoice(values.clazz.id, dateHelper.formatDate(yearMonth, FMI_YEAR_MONTH_FORMAT))
            .then((res) => {
                setLackingStudents(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [academicYear, values.month, dispatch, values.clazz]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.month && values.clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            getInvoice();
            getStudentsLackingOfInvoice();
            dispatch(congdoan_get_payment_info());
        }
    }, [values, watches, prevValues, authenticationReducer, dispatch, getInvoice, getStudentsLackingOfInvoice]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const monthOptions = monthsOfYear.map((item) => {
        return { value: item, label: 'Tháng ' + item };
    });

    const onAcademicYearChange = (selectedOption) => {
        setAcademicYear(selectedOption.value);
        setGrade('');
        setClazzes([]);
        setValue('clazz', '');
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('clazz', '');
        setGrade(selectedOption);
        callApiGetListOfClasses(selectedOption.value.id)
            .then((res) => {
                setClazzes(res.data);
            })
            .catch((error) => {
                messageHelper.showError(error);
            });
    };

    const onMonthChange = (selectedOption) => {
        setValue('month', selectedOption.value);
        trigger('month');
    };

    const onClazzChange = (selectedOption) => {
        setSelectedRows([]);
        setValue('clazz', selectedOption.value);
        trigger('clazz');
    };

    const onOptionChange = (selectedOption) => {
        setValue('option', selectedOption);
        if (optionClassName !== '') {
            setOptionClassName('');
        }
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const openSelectSubjectsDialogForSoThu = () => {
        setOpenSelectSubjectsForSoThu(true);
    };

    const closeSelectSubjectDialogForSoThu = () => {
        setOpenSelectSubjectsForSoThu(false);
    };

    const openSelectSubjectsDialogForBienLai = () => {
        setOpenSelectSubjectsForBienLai(true);
    };

    const closeSelectSubjectDialogForBienLai = () => {
        setOpenSelectSubjectsForBienLai(false);
    };

    const onGenerateInvoice = () => {
        const isExistPaidStudent = get(classInvoice, 'invoices', []).some((item) => item.isPaid);
        const alertTitle = getTitle();
        alert({
            title: alertTitle,
            text: isExistPaidStudent ? 'Chú ý: Trong lớp hiện tại có học sinh đã trả tiền. Những học sinh này sẽ không lập phiếu thu lại.' : undefined,
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                generateInvoice();
            }
        });
    };

    const getTitle = () => {
        const isSelectedSomeStudent = classInvoice && selectedRows.length !== 0 && selectedRows.length !== classInvoice.invoices.length;
        if (isSelectedSomeStudent) {
            return `Bạn có chắc muốn lập phiếu thu tháng ${values.month} cho ${selectedRows.length} học sinh trong lớp ${values.clazz.className}?`;
        }
        return `Bạn có chắc lập phiếu thu tháng ${values.month} cho lớp ${values.clazz.className}?`;
    };

    const generateInvoice = () => {
        if (values.month && values.clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            dispatch(loading());
            const studentIds = getStudentIds();
            callApiGenerateInvoice(authenticationReducer.userInfo.congDoanId, values.clazz.id, values.month, studentIds)
                .then((res) => {
                    getInvoice();
                    getStudentsLackingOfInvoice();
                    toastHelper.success('Lập phiếu thu thành công');
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const exportBienLai = (subjectIds) => {
        if (values.month && values.clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            const studentIds = getStudentIds();
            dispatch(loading());
            callApiExportBienLai(authenticationReducer.userInfo.congDoanId, values.clazz.id, values.month, studentIds, values.option.value, subjectIds)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    window.open(downloadUrl);
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onExportBienLai = () => {
        openSelectSubjectsDialogForBienLai();
    };

    const getStudentIds = () => {
        const transferredRows = selectedRows.map((i) => i.row);
        if (transferredRows.length === 0 || (classInvoice && transferredRows.length === classInvoice.invoices.length)) {
            return '';
        }
        return transferredRows.map((item) => item.student.id).join(',');
    };

    const callApiExportSoThu = (subjectIds) => {
        if (values.month && values.clazz.id && authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            dispatch(loading());
            callApiExportSoThuThanhToan(authenticationReducer.userInfo.congDoanId, values.clazz.id, values.month, values.option.value, subjectIds)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `${values.clazz.className}_Sổ thu thanh toán.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onExportSoThu = () => {
        openSelectSubjectsDialogForSoThu();
    };

    const onExportPhieuThu = () => {
        openDialog();
    };

    const onExportBienLaiForThuDauNam = () => {
        if (values.clazz.id) {
            const studentIds = getStudentIds();
            dispatch(loading());
            callApiExportBienLaiThuDauNam(values.clazz.id, studentIds)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    window.open(downloadUrl);
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    const onExportPhieuThuForThuDauNam = () => {
        if (values.clazz.id) {
            const studentIds = getStudentIds();
            dispatch(loading());
            callApiExportPhieuThuThuDauNam(values.clazz.id, studentIds)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                    window.open(downloadUrl);
                })
                .catch((err) => {
                    toastHelper.error('Có lỗi xảy ra. Vui lòng thử lại sau!');
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Phiếu thu</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={academicYearsOptions}
                                            id="academicYear"
                                            onChange={onAcademicYearChange}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="month">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="month"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={() => {
                                                return <Select onChange={onMonthChange} options={monthOptions} classNamePrefix="fmi-react-select" styles={customSelectStyles} />;
                                            }}
                                        />
                                        {errors.month && <span className="text-danger">{errors.month.message}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label" htmlFor="academicYear">
                                        Khối:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Select
                                            options={grades.map((item) => {
                                                return { value: item, label: item.name };
                                            })}
                                            id="academicYear"
                                            onChange={onGradeChange}
                                            value={grade}
                                            classNamePrefix="fmi-react-select"
                                            styles={customSelectStyles}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="row mr-0 mt-2">
                                    <label className="mb-0 col-md-4 fmi-label">Lớp:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="clazz"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onClazzChange}
                                                        options={clazzes.map((item) => {
                                                            return { value: item, label: item.className };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                            {values.month && values.clazz.id && (
                                <>
                                    <div className="col-md-2">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-10">
                                                <Controller
                                                    name="option"
                                                    control={control}
                                                    render={({ value }) => {
                                                        return (
                                                            <Select
                                                                options={[
                                                                    { value: 'ALL', label: 'Tất cả' },
                                                                    { value: 'MANDATORY', label: 'Bắt buộc' },
                                                                    { value: 'OPTIONAL', label: 'Tự chọn' },
                                                                ]}
                                                                value={value}
                                                                onChange={onOptionChange}
                                                                id="option"
                                                                classNamePrefix="fmi-react-select"
                                                                className={optionClassName}
                                                                styles={customSelectStyles}
                                                            ></Select>
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-2 p-0">
                                                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} className="p-1">
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={openMenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'left',
                                                        horizontal: 'center',
                                                    }}
                                                    PaperProps={{
                                                        style: {
                                                            width: '30ch',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem onClick={onExportBienLaiForThuDauNam}>Xuất biên lai cho thu đầu năm</MenuItem>
                                                    <MenuItem onClick={onExportPhieuThuForThuDauNam}>Xuất phiếu thu cho thu đầu năm</MenuItem>
                                                </Menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 px-2">
                                        <div className="row mx-0 w-100">
                                            <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExportBienLai}>
                                                Xuất biên lai
                                            </button>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExportPhieuThu}>
                                                Xuất phiếu thu
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-2 px-2 row form-group mb-0 d-flex flex-column justify-content-end align-items-start py-0 w-100 h-100">
                                        <div className="row mx-0 w-100">
                                            <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onGenerateInvoice}>
                                                Lập phiếu thu
                                            </button>
                                        </div>
                                        <div className="row mx-0 w-100">
                                            <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExportSoThu}>
                                                Xuất sổ thu
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {getValues()['month'] && classInvoice && (
                <InvoiceTable
                    lackingStudents={lackingStudents}
                    classInvoice={classInvoice}
                    classId={values.clazz.id}
                    onSetSelectedRows={setSelectedRows}
                    selectedRowsPassed={selectedRows}
                ></InvoiceTable>
            )}
            {values.month && values.clazz && (
                <PhieuThuPaymentDayDialog clazz={values.clazz} month={values.month} option={values.option} closeDialog={closeDialog} open={open} studentIds={getStudentIds()} />
            )}
            {values.month && values.clazz && openSelectSubjectsForSoThu && (
                <SoThuSelectSubjectsDialog
                    open={openSelectSubjectsForSoThu}
                    closeDialog={closeSelectSubjectDialogForSoThu}
                    clazz={values.clazz}
                    month={values.month}
                    onExport={callApiExportSoThu}
                />
            )}
            {values.month && values.clazz && openSelectSubjectsForBienLai && (
                <BienLaiSelectSubjectsDialog
                    open={openSelectSubjectsForBienLai}
                    closeDialog={closeSelectSubjectDialogForBienLai}
                    clazz={values.clazz}
                    month={values.month}
                    onExport={exportBienLai}
                />
            )}
        </>
    );
}

export default MamNonPhieuThuPage;
