import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import ContactsIcon from '@material-ui/icons/Contacts';
import DescriptionIcon from '@material-ui/icons/Description';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GroupIcon from '@material-ui/icons/Group';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import MoneyIcon from '@material-ui/icons/Money';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PublishIcon from '@material-ui/icons/Publish';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import VoiceOverOffIcon from '@material-ui/icons/VoiceOverOff';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { ROLES } from 'config/constants/roleConstant';

export const PACKAGES = {
    MAM_NON: 'MAM_NON',
    CONG_DOAN: 'CONG_DOAN',
    NOI_TRU: 'NOI_TRU',
    CONG_TAC_PHUC_VU: 'CONG_TAC_PHUC_VU',
    TRUNG_UONG: 'TRUNG_UONG',
    MAM_NON_USER: 'MAM_NON_USER',
    BAN_NGANH: 'BAN_NGANH',
    VUNG: 'VUNG',
};

export const MAM_NON_PACKAGE = {
    STUDENT_INFO: 'Thông tin học sinh',
    ATTENDANCE_AND_INVOICE: 'Phiếu thu',
    PAYMENT: 'Thu chi',
};

export const CONG_DOAN_PACKAGE = {
    PAYMENT: 'Thu chi',
    REPORT: 'Báo cáo',
    DU_THU: 'Dự thu',
};

export const VUNG_PACKAGE = {
    PAYMENT: 'Thu chi',
    REPORT: 'Báo cáo',
};

export const NOI_TRU_PACKAGE = {
    PAYMENT: 'Thu chi',
    INFO: 'Thông tin',
};

export const CTPV_PACKAGE = {
    REPORT: 'Báo cáo',
};

export const TRUNGUONG_PACKAGE = {
    INFO: 'Thông tin',
    REPORT: 'Báo cáo',
};

export const BAN_NGANH_PACKAGE = {
    REPORT: 'Báo cáo',
};

export const congDoanSubMenus = [
    { title: 'Thu cộng đoàn', path: '/cong-doan/thu-cong-doan', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: CONG_DOAN_PACKAGE.PAYMENT },
    { title: 'Chi cộng đoàn', path: '/cong-doan/chi-cong-doan', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.PAYMENT },
    { title: 'Giao dịch tài khoản', path: '/cong-doan/ton-thang-truoc', icon: <MoveToInboxIcon style={{ color: '#90caf9' }} />, package: CONG_DOAN_PACKAGE.PAYMENT },
    { title: 'Số lượng sơ', path: '/cong-doan/nun/numbers', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: CONG_DOAN_PACKAGE.REPORT },
    { title: 'Quản lí tài sản', path: '/cong-doan/quan-li-tai-san', icon: <WebAssetIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.REPORT },
    { title: 'Báo cáo tháng', path: '/cong-doan/bao-cao/thang', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: CONG_DOAN_PACKAGE.REPORT },
    { title: 'Báo cáo năm', path: '/cong-doan/bao-cao/nam', icon: <DescriptionIcon style={{ color: '#dce775' }} />, package: CONG_DOAN_PACKAGE.REPORT },
    { title: 'Báo cáo nhân sự', path: '/cong-doan/bao-cao/nhan-su', icon: <PersonPinIcon style={{ color: '#90caf9' }} />, package: CONG_DOAN_PACKAGE.REPORT },
    { title: 'Quản lí sơ', path: '/cong-doan/nun', icon: <PersonPinIcon style={{ color: '#90caf9' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Bảo hiểm y tế', path: '/cong-doan/bhyt', icon: <MoveToInboxIcon style={{ color: '#1de9b6' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Khấn dòng', path: '/cong-doan/khan-dong', icon: <GroupIcon style={{ color: '#90caf9' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Dự chi cá nhân', path: '/cong-doan/du-thu/du-chi-ca-nhan', icon: <PersonPinIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Dự thu cộng đoàn', path: '/cong-doan/du-thu/du-thu-cong-doan', icon: <GroupIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Dự chi cộng đoàn', path: '/cong-doan/du-thu/du-chi-cong-doan', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
    { title: 'Thu chi cộng đoàn', path: '/cong-doan/du-thu/thu-chi-cong-doan', icon: <MoneyIcon style={{ color: '#ef9a9a' }} />, package: CONG_DOAN_PACKAGE.DU_THU },
];

export const mamNonSubMenus = [
    { title: 'Điểm danh', path: '/mam-non/diem-danh', icon: <PlaylistAddCheckIcon style={{ color: '#1de9b6' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Danh sách HS', path: '/mam-non/danh-sach', icon: <ContactsIcon style={{ color: '#90caf9' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Ngày nghỉ', path: '/mam-non/ngay-nghi', icon: <EventBusyIcon style={{ color: '#ef9a9a' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Danh sách lớp', path: '/mam-non/clazzes', icon: <GroupIcon style={{ color: '#039be5' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Lên lớp / Xếp lớp', path: '/mam-non/clazzes/upgrade', icon: <TransferWithinAStationIcon style={{ color: '#ef9a9a' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Danh sách vắng', path: '/mam-non/danh-sach-vang', icon: <VoiceOverOffIcon style={{ color: '#039be5' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Tổng hợp điểm danh', path: '/mam-non/tong-hop-diem-danh', icon: <AssignmentIcon style={{ color: '#90caf9' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
    { title: 'Danh mục thu', path: '/mam-non/danh-muc-thu', icon: <FormatListBulletedIcon style={{ color: '#66bb6a' }} />, package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE },
    { title: 'Dự thu', path: '/mam-non/du-thu', icon: <FeaturedPlayListIcon style={{ color: '#6d4c41' }} />, package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE },
    { title: 'Đăng kí môn học', path: '/mam-non/dang-ki-mon-hoc', icon: <AssignmentIndIcon style={{ color: '#1de9b6' }} />, package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE },
    { title: 'Thu đầu năm', path: '/mam-non/thu-dau-nam', icon: <ChromeReaderModeIcon style={{ color: '#4db6ac' }} />, package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE },
    {
        title: 'Thu đầu năm nhiệm kỳ',
        path: '/mam-non/thu-dau-nam-nhiem-ky',
        icon: <ChromeReaderModeIcon style={{ color: '#1de9b6' }} />,
        package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE,
    },
    { title: 'Lập phiếu thu', path: '/mam-non/phieu-thu', icon: <LocalAtmIcon style={{ color: '#81d4fa' }} />, package: MAM_NON_PACKAGE.ATTENDANCE_AND_INVOICE },
    { title: 'Thu tiền', path: '/mam-non/thu-tien', icon: <CheckCircleOutlineIcon style={{ color: '#69f0ae' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Tra cứu tiền thu', path: '/mam-non/tra-cuu-thu-tien', icon: <FindInPageIcon style={{ color: '#90caf9' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Tra cứu tiền thu theo tháng', path: '/mam-non/tra-cuu-thu-tien-by-month', icon: <FindInPageIcon style={{ color: '#4db6ac' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Thu mầm non', path: '/mam-non/thu-mam-non', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Chi mầm non', path: '/mam-non/chi-mam-non', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Quản lí tài sản', path: '/mam-non/quan-li-tai-san', icon: <WebAssetIcon style={{ color: '#ef9a9a' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Báo cáo thu/chi/tồn đầu năm', path: '/mam-non/bao-cao/thu-dau-nam', icon: <ChromeReaderModeIcon style={{ color: '#4db6ac' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Báo cáo tháng', path: '/mam-non/bao-cao/thang', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: MAM_NON_PACKAGE.PAYMENT },
    { title: 'Báo cáo năm', path: '/mam-non/bao-cao/nam', icon: <DescriptionIcon style={{ color: '#dce775' }} />, package: MAM_NON_PACKAGE.PAYMENT },
];

export const mamNonUserSubMenus = [
    { title: 'Điểm danh', path: '/mam-non/diem-danh', icon: <PlaylistAddCheckIcon style={{ color: '#1de9b6' }} />, package: MAM_NON_PACKAGE.STUDENT_INFO },
];

export const noiTruSubMenus = [
    { title: 'Thu nội trú', path: '/noi-tru/thu-chi/thu-noi-tru', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: NOI_TRU_PACKAGE.PAYMENT },
    { title: 'Chi nội trú', path: '/noi-tru/thu-chi/chi-noi-tru', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: NOI_TRU_PACKAGE.PAYMENT },
    { title: 'Báo cáo tháng', path: '/noi-tru/bao-cao/thang', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: NOI_TRU_PACKAGE.PAYMENT },
    { title: 'Báo cáo năm', path: '/noi-tru/bao-cao/nam', icon: <DescriptionIcon style={{ color: '#dce775' }} />, package: NOI_TRU_PACKAGE.PAYMENT },
    { title: 'Số lượng học sinh', path: '/noi-tru/students', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: NOI_TRU_PACKAGE.INFO },
    { title: 'Quản lí tài sản', path: '/noi-tru/quan-li-tai-san', icon: <WebAssetIcon style={{ color: '#ef9a9a' }} />, package: NOI_TRU_PACKAGE.INFO },
];

export const congTacPhucVuSubMenus = [
    { title: 'Danh mục CTPV', path: '/ctpv/danh-muc', icon: <FormatListBulletedIcon style={{ color: '#66bb6a' }} />, package: CTPV_PACKAGE.REPORT },
    { title: 'Thu chi CTPV', path: '/ctpv/thu-chi', icon: <LocalAtmIcon style={{ color: '#81d4fa' }} />, package: CTPV_PACKAGE.REPORT },
    { title: 'Báo cáo thu chi theo năm', path: '/ctpv/thu-chi/report', icon: <DescriptionIcon style={{ color: '#dce775' }} />, package: CTPV_PACKAGE.REPORT },
];

export const trungUongSubMenus = [
    { title: 'Danh sách cộng đoàn', path: '/trung-uong/cong-doan', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Tỉ lệ chuyển đổi tiền tệ', path: '/cong-doan/exchange-rate', icon: <AttachMoneyIcon style={{ color: '#81c784' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Tổng quan báo cáo cộng đoàn', path: '/cong-doan/report/overview', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Tổng quan dự thu/chi cộng đoàn', path: '/trung-uong/du-thu-chi/overview', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Mầm non báo cáo sai', path: '/trung-uong/mam-non/bao-cao-sai', icon: <DomainDisabledIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Cộng đoàn báo cáo sai', path: '/trung-uong/cong-doan/bao-cao-sai', icon: <DomainDisabledIcon style={{ color: '#4dd0e1' }} />, package: TRUNGUONG_PACKAGE.INFO },
    { title: 'Dự thu cộng đoàn', path: '/trung-uong/duthu', icon: <GroupIcon style={{ color: '#ef9a9a' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Dự chi cộng đoàn', path: '/trung-uong/duchi', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Công tác phục vụ', path: '/trung-uong/ctpv', icon: <ApartmentIcon style={{ color: '#c8e6c9' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi cộng đoàn theo tháng', path: '/trung-uong/congdoan/month', icon: <MoneyIcon style={{ color: '#4dd0e1' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi cộng đoàn theo năm', path: '/trung-uong/congdoan/annual', icon: <MoneyIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi mầm non theo tháng', path: '/trung-uong/mamnon/month', icon: <ChildCareIcon style={{ color: '#4dd0e1' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi mầm non theo năm', path: '/trung-uong/mamnon/annual', icon: <ChildCareIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi nội trú theo tháng', path: '/trung-uong/noitru/month', icon: <LocalHotelIcon style={{ color: '#4dd0e1' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi nội trú theo năm', path: '/trung-uong/noitru/annual', icon: <LocalHotelIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi vùng theo tháng', path: '/trung-uong/vung/month', icon: <GroupIcon style={{ color: '#4dd0e1' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Thu chi vùng theo năm', path: '/trung-uong/vung/annual', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.REPORT },
    { title: 'Tổng kết ban ngành', path: '/trung-uong/ban-nganh', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: TRUNGUONG_PACKAGE.REPORT },
];

export const vungSubMenus = [
    { title: 'Thu vùng', path: '/vung/thu-vung', icon: <GroupIcon style={{ color: '#1de9b6' }} />, package: VUNG_PACKAGE.PAYMENT },
    { title: 'Chi vùng', path: '/vung/chi-vung', icon: <PublishIcon style={{ color: '#ef9a9a' }} />, package: VUNG_PACKAGE.PAYMENT },
    { title: 'Tồn đầu tháng', path: '/vung/ton-dau-thang', icon: <MoveToInboxIcon style={{ color: '#90caf9' }} />, package: VUNG_PACKAGE.PAYMENT },
    { title: 'Báo cáo tháng', path: '/vung/monthly-report', icon: <DescriptionIcon style={{ color: '#81c784' }} />, package: VUNG_PACKAGE.REPORT },
    { title: 'Báo cáo năm', path: '/vung/annually-report', icon: <DescriptionIcon style={{ color: '#dce775' }} />, package: VUNG_PACKAGE.REPORT },
];

export const banNganhSubMenus = [{ title: 'Thu chi ban ngành', path: '/ban-nganh/thu-chi', icon: <MoneyIcon style={{ color: '#4dd0e1' }} />, package: BAN_NGANH_PACKAGE.REPORT }];

export const menus = [
    {
        title: 'Cộng Đoàn',
        path: '/cong-doan',
        icon: <AccountBalanceIcon style={{ fontSize: '4rem', color: '#90a4ae' }} />,
        subMenus: congDoanSubMenus,
        package: PACKAGES.CONG_DOAN,
    },
    {
        title: 'Mầm Non',
        path: '/mam-non',
        icon: <ChildCareIcon style={{ fontSize: '4rem', color: '#4dd0e1' }} />,
        subMenus: mamNonSubMenus,
        package: PACKAGES.MAM_NON,
    },
    {
        title: 'Mầm Non',
        path: '/mam-non-user',
        icon: <ChildCareIcon style={{ fontSize: '4rem', color: '#4dd0e1' }} />,
        subMenus: mamNonUserSubMenus,
        package: PACKAGES.MAM_NON_USER,
    },
    {
        title: 'Công tác phục vụ',
        path: '/cong-tac-phuc-vu',
        icon: <ApartmentIcon style={{ fontSize: '4rem', color: '#c8e6c9' }} />,
        subMenus: congTacPhucVuSubMenus,
        package: PACKAGES.CONG_TAC_PHUC_VU,
    },
    {
        title: 'Nội trú',
        path: '/noi-tru',
        icon: <LocalHotelIcon style={{ fontSize: '4rem', color: '#1de9b6' }} />,
        subMenus: noiTruSubMenus,
        package: PACKAGES.NOI_TRU,
    },
    {
        title: 'Trung ương',
        path: '/trung-uong',
        icon: <AccountBalanceIcon style={{ fontSize: '4rem', color: '#c8e6c9' }} />,
        subMenus: trungUongSubMenus,
        package: PACKAGES.TRUNG_UONG,
    },
    {
        title: 'Ban ngành',
        path: '/ban-nganh',
        icon: <ApartmentIcon style={{ fontSize: '4rem', color: '#c8e6c9' }} />,
        subMenus: banNganhSubMenus,
        package: PACKAGES.BAN_NGANH,
    },
    {
        title: 'Vùng',
        path: '/vung',
        icon: <AccountBalanceIcon style={{ fontSize: '4rem', color: '#c8e6c9' }} />,
        subMenus: vungSubMenus,
        package: PACKAGES.VUNG,
    },
];

const isDisableMenu = (roles, menuPackage) => {
    const role = getRolesFromMenuPackage(menuPackage);
    if (role === '') {
        return false;
    }
    return roles.indexOf(role) < 0;
};

const getRolesFromMenuPackage = (menuPackage) => {
    switch (menuPackage) {
        case PACKAGES.MAM_NON:
            return ROLES.ROLE_MAMNON_ADMIN;
        case PACKAGES.NOI_TRU:
            return ROLES.ROLE_NOITRU_ADMIN;
        case PACKAGES.CONG_TAC_PHUC_VU:
            return ROLES.ROLE_CONGDOAN_ADMIN;
        case PACKAGES.CONG_DOAN:
            return ROLES.ROLE_CONGDOAN_ADMIN;
        case PACKAGES.TRUNG_UONG:
            return ROLES.ROLE_TRUNGUONG_ADMIN;
        case PACKAGES.MAM_NON_USER:
            return ROLES.ROLE_MAMNON_USER;
        case PACKAGES.BAN_NGANH:
            return ROLES.ROLE_BANNGANH_ADMIN;
        case PACKAGES.VUNG:
            return ROLES.ROLE_VUNG_ADMIN;
        default:
            return '';
    }
};

export const getAuthorizedMenus = (roles) => {
    const authorizedMenus = menus.map((menu) => {
        return { ...menu, ...{ disabled: isDisableMenu(roles, menu.package) } };
    });
    return authorizedMenus;
};
