import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from '../../actions';
import { transport } from '../../../config/transport';

export default function* getAcademicYearFirstMonth(payload) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiGetAcademicYearFirstMonth, payload.payload.yearMonth);
        yield put({
            type: FMI_ACTIONS.ACADEMIC_YEAR__GET_FIRST_MONTH_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.ACADEMIC_YEAR__CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetAcademicYearFirstMonth(yearMonth) {
    return transport.get('/congdoan/academicYear/first-month', { params: { yearMonth } });
}
