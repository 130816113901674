import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import React from 'react';
import { useForm } from 'react-hook-form';
import { callApiCreateClass } from 'redux/saga/mam-non/info/class/createClass';
import { callApiUpdateClass } from 'redux/saga/mam-non/info/class/updateClass';

function ClazzDialog({ clazz, closeDialog, gradeId, open, onSuccess }) {
    const defaultValues = {
        name: clazz.className,
        accessCode: clazz.accessCode,
    };

    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = (data) => {
        const body = { ...data };
        if (clazz.id) {
            editClazz(body);
        } else {
            addClazz(body);
        }
    };

    const addClazz = (data) => {
        if (gradeId) {
            callApiCreateClass(gradeId, data)
                .then((res) => {
                    onSuccess(res.data);
                    reset(defaultValues);
                    toastHelper.success('Thêm lớp thành công.');
                })
                .catch((err) => {
                    messageHelper.showError(err);
                });
        }
    };

    const editClazz = (data) => {
        callApiUpdateClass(clazz.id, data)
            .then((res) => {
                onSuccess(res.data);
                closeDialog();
                toastHelper.success('Chỉnh sửa lớp thành công.');
            })
            .catch((err) => {
                toastHelper.error('Gặp lỗi khi chỉnh sửa lớp. Vui lòng thử lại sau.');
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">
                    <h3>{clazz.id ? 'Chỉnh sửa lớp' : 'Thêm lớp'}</h3>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="name">Tên lớp</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Tên lớp"
                                defaultValue={defaultValues.name}
                                id=" name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên lớp!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="accessCode">Mã điểm danh</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.accessCode && 'border-danger')}
                                placeholder="Mã điểm danh"
                                defaultValue={defaultValues.accessCode}
                                id="accessCode"
                                name="accessCode"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên lớp!',
                                    },
                                    maxLength: {
                                        value: 8,
                                        message: 'Mã điểm danh chỉ bao gồm tối đa 8 kí tự',
                                    },
                                })}
                                maxLength={8}
                            />
                            {errors.accessCode && <div className="text-danger pt-2">{errors.accessCode.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ClazzDialog;
