import { dateHelper } from 'helpers/dateHelper';
import React, { useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FMI_MONTH_YEAR_FORMAT } from 'config/constants/dateConstants';

const Styles = styled.div`
    padding: 1rem;

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }

            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
    }
`;

const EditableCell = ({
    value: initialValue,
    row,
    column,
    updateMyData, // This is a custom function that we supplied to our table instance
    academicYear,
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(row.index, column.id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    if (column.id === 'month') {
        return <span style={{ padding: '0.5rem' }}>{value}</span>;
    }
    if (column.id === 'tongTon') {
        return <span style={{ padding: '0.5rem' }}>{value}</span>;
    }
    if (row.values.month === 'Tồn đầu niên khoá' && (column.id === 'chiAmount' || column.id === 'thuAmount')) {
        return <span style={{ padding: '0.5rem' }}>{value}</span>;
    }
    if (row.values.month !== 'Tồn đầu niên khoá' && column.id === 'tonTienMat') {
        return <span style={{ padding: '0.5rem' }}>{value}</span>;
    }
    return <input style={{ padding: '0.5rem' }} value={value} onChange={onChange} onBlur={onBlur} type="number" />;
};

const defaultColumn = {
    Cell: EditableCell,
};

const defaultPropGetter = () => ({});

function ThuChiBanNganhTable({ thuchis, setData, academicYear, getColumnProps = defaultPropGetter, getHeaderProps = defaultPropGetter, getCellProps = defaultPropGetter }) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Tháng',
                columns: [
                    {
                        Header: '',
                        accessor: 'month',
                    },
                ],
                headerStyles: {
                    minWidth: '160px',
                },
                cellStyles: {
                    minWidth: '160px',
                },
            },
            {
                Header: 'Thu',
                columns: [
                    {
                        Header: '',
                        accessor: 'thuAmount',
                    },
                ],
            },
            {
                Header: 'Chi',
                columns: [
                    {
                        Header: '',
                        accessor: 'chiAmount',
                    },
                ],
            },
            {
                Header: 'Tồn',
                headerStyles: {
                    textAlign: 'center',
                },
                columns: [
                    {
                        Header: 'Tiền mặt',
                        accessor: 'tonTienMat',
                    },
                    {
                        Header: 'Ngân hàng/USD',
                        accessor: 'tonNganHang',
                    },
                ],
            },
            {
                Header: 'Tổng tồn',
                columns: [{ Header: '', accessor: 'tongTon' }],
                headerStyles: {
                    minWidth: '180px',
                },
                cellStyles: {
                    minWidth: '180px',
                },
            },
        ],
        []
    );

    const buildTonDauKhoa = (data) => {
        if (data) {
            return {
                thuAmount: '',
                chiAmount: '',
                tongTon: data.tongTon,
                month: 'Tồn đầu niên khoá',
                tonTienMat: data.tonTienMat,
                tonNganHang: data.tonNganHang,
                thuChiId: data.id,
                yearMonth: data.yearMonth,
            };
        }
        return {};
    };

    const buildRow = (data) => {
        if (data) {
            return {
                thuAmount: data.thuAmount,
                chiAmount: data.chiAmount,
                tongTon: data.tongTon,
                month: getFormatDate(data.yearMonth),
                tonTienMat: data.tonTienMat,
                tonNganHang: data.tonNganHang,
                thuChiId: data.id,
                yearMonth: data.yearMonth,
            };
        }
        return {};
    };

    const getFormatDate = (yearMonth) => {
        if (academicYear.congDoanNumOfMonth === 14 && dayjs(yearMonth).year() === +dateHelper.getYearsFromAcademicYear(academicYear.name)[1]) {
            return dateHelper.formatDate(yearMonth, FMI_MONTH_YEAR_FORMAT);
        }
        return dateHelper.formatDate(yearMonth, 'MM');
    };

    const makeData = (thuchis) => {
        const rows = [];
        const months = dateHelper.getMonthYearArrayByAcademicYear(academicYear);
        rows.push(buildTonDauKhoa(thuchis.find((item) => item.yearMonth === null)));
        months.forEach((month) => rows.push(buildRow(thuchis.find((item) => item.yearMonth === month))));
        return rows;
    };

    const [tableData, setTableData] = useState(() => makeData(thuchis));

    const updateMyData = (rowIndex, columnId, value) => {
        const newTableData = tableData.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...tableData[rowIndex],
                    [columnId]: value,
                };
            }
            return row;
        });
        // We also turn on the flag to not reset the page
        setTableData(newTableData);
        setData(newTableData);
    };

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
        columns,
        data: tableData,
        defaultColumn,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
        academicYear,
    });

    return (
        <Styles>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        {
                                            className: column.headerClassName,
                                            style: column.headerStyles,
                                        },
                                        getColumnProps(column),
                                        getHeaderProps(column)
                                    )}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps(
                                                {
                                                    className: cell.column.cellClassName,
                                                    style: { ...cell.column.cellStyles, padding: 0 },
                                                },
                                                getColumnProps(cell.column),
                                                getCellProps(cell)
                                            )}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Styles>
    );
}

export default ThuChiBanNganhTable;
