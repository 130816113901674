import { get } from 'lodash';

const { errorHelper, INTERNAL_SERVER_ERROR } = require('content/message/errorMessages');
const { toastHelper } = require('./toastHelper');

function showError(err) {
    const messageKey = get(err, 'response.data.messageKey', null);
    if (messageKey) {
        toastHelper.error(errorHelper.getErrorMessageByKey(messageKey));
    } else {
        toastHelper.error(errorHelper.getErrorMessageByKey(INTERNAL_SERVER_ERROR));
    }
}

export const messageHelper = {
    showError,
};
