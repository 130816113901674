import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import { numberHelper } from 'helpers/numberHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportNoiTruReportForAllCongDoan } from 'redux/saga/trung-uong/report/noitru/exportNoiTruAnnualReport';
import { callApiGetNoiTruReportForAllCongDoan } from 'redux/saga/trung-uong/report/noitru/getNoiTruReportForAllCongDoan';

function TWNoiTruReportAnnualPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const [pdfFile, setPdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getReport = useCallback(() => {
        dispatch(loading());
        callApiGetNoiTruReportForAllCongDoan(values.academicYear.value.name)
            .then((res) => {
                setReport(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.academicYear]);

    const exportReportPDF = useCallback(() => {
        const academicYear = get(values, 'academicYear.value.name');
        dispatch(loading());
        callApiExportNoiTruReportForAllCongDoan(academicYear, true)
            .then((res) => {
                const pdfURL = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
                setPdfFile(pdfURL);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            // getReport();
            exportReportPDF();
        }
    }, [values, watches, prevValues, getReport, exportReportPDF]);

    const buildTonRow = (data) => {
        const amount = data.amounts['2021-01'];
        return {
            code: '',
            name: data.name,
            amount: numberHelper.formatNumber(amount.amount),
            usdAmount: numberHelper.formatNumber(amount.usdAmount),
            eurAmount: numberHelper.formatNumber(amount.eurAmount),
        };
    };

    const buildRow = (data) => {
        const amount = data.amounts['2021-01'];
        return {
            code: data.code,
            name: data.name,
            amount: numberHelper.formatNumber(amount.amount),
            usdAmount: numberHelper.formatNumber(amount.usdAmount),
            eurAmount: numberHelper.formatNumber(amount.eurAmount),
        };
    };

    // const buildDataForThu = () => {
    //     const result = [];
    //     if (report) {
    //         report
    //             .filter((item) => item.code >= 1000)
    //             .forEach((item) => {
    //                 result.push(buildTonRow(item));
    //             });
    //         report
    //             .filter((item) => item.code < 1000 && item.loaiThuChi === 'THU')
    //             .forEach((item) => {
    //                 result.push(buildRow(item));
    //             });
    //     }
    //     return result;
    // };

    // const buildDataForChi = () => {
    //     const result = [];
    //     if (report) {
    //         report
    //             .filter((item) => item.code < 1000 && item.loaiThuChi === 'CHI')
    //             .forEach((item) => {
    //                 result.push(buildRow(item));
    //             });
    //     }
    //     return result;
    // };

    // const columns = useMemo(() => {
    //     const academicYear = get(values, 'academicYear.value');
    //     if (!academicYear) return [];
    //     const fixedColumns = [
    //         {
    //             Header: 'TM',
    //             accessor: 'code',
    //         },
    //         {
    //             Header: 'DIỄN GIẢI',
    //             accessor: 'name',
    //             style: {
    //                 minWidth: '500px',
    //                 textAlign: 'left',
    //             },
    //         },
    //         {
    //             Header: 'VND',
    //             accessor: 'amount',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //         {
    //             Header: 'USD',
    //             accessor: 'usdAmount',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //         {
    //             Header: 'EURO',
    //             accessor: 'eurAmount',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //     ];
    //     // const dynamicColumns = buildDynamicColumns(academicYear);
    //     return [
    //         ...fixedColumns,
    //         // ...dynamicColumns
    //     ];
    // }, [values]);

    const onExport = () => {
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiExportNoiTruReportForAllCongDoan(academicYear)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo thu chi tất cả nội trú ${academicYear}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo thu chi nội trú theo năm</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy báo cáo!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>

            {/* <div>
                {report && (
                    <>
                        <Box className="mt-3" p={theme.spacing(0, 3)}>
                            <Typography variant="h6">SỔ THU</Typography>
                        </Box>
                        <Table columns={columns} data={buildDataForThu()} />
                    </>
                )}
            </div>
            <div>
                {report && (
                    <>
                        <Divider></Divider>
                        <Box className="mt-3" p={theme.spacing(0, 3)}>
                            <Typography variant="h6">SỔ CHI</Typography>
                        </Box>
                        <Table columns={columns} data={buildDataForChi()} />
                    </>
                )}
            </div> */}
            {pdfFile && (
                <div className="align-pdf">
                    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={index} pageNumber={index + 1}></Page>
                        ))}
                    </Document>
                </div>
            )}
        </>
    );
}

export default TWNoiTruReportAnnualPage;
