import { call, put } from 'redux-saga/effects';
import FMI_ACTIONS from '../../actions';
import { transport } from '../../../config/transport';

export default function* getListNuns(action) {
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADING });
    // This comment will be remove whenever we handle the jwt login process
    try {
        let { data } = yield call(callApiGetListOfNuns, action.payload.data.congDoanId, action.payload.data.isActive);
        yield put({
            type: FMI_ACTIONS.NUN__GET_LIST_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: FMI_ACTIONS.NUN__CALL_FAIL,
        });
    }
    yield put({ type: FMI_ACTIONS.LOADING_STATUS__LOADED });
}

export function callApiGetListOfNuns(congDoanId, isActive) {
    return transport.get(`/congdoan/${congDoanId}/nun`, { params: { isActive } });
}
