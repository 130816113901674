import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { callApiGetPaymentSummaries } from 'redux/saga/mam-non/thu-chi/thu-tien/getPaymentSummaries';
import TraCuuThuTienTable from './TraCuuThuTienTable';

function MamNonTraCuuThuTienPage() {
    const { control, getValues, watch } = useForm();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [paymentSummaries, setPaymentSummaries] = useState();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    const getPaymentSummary = useCallback(
        (fromDate, toDate) => {
            setPaymentSummaries(null);
            dispatch(loading());
            callApiGetPaymentSummaries(fromDate, toDate)
                .then((res) => {
                    setPaymentSummaries(res.data);
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const fromDate = dateHelper.formatDate(values.fromDate, FMI_SERVER_DATE_FORMAT);
        const toDate = dateHelper.formatDate(values.toDate, FMI_SERVER_DATE_FORMAT);
        if (isDiff && values.fromDate && values.toDate) {
            getPaymentSummary(fromDate, toDate);
        }
    }, [values, watches, prevValues, getPaymentSummary]);

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Tra cứu tiền thu</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="fromDate">
                                        Từ:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="fromDate"
                                            name="fromDate"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn ngày"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="dd.MM.yyyy"
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="toDate">
                                        Đến:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="toDate"
                                            name="toDate"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn ngày"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="dd.MM.yyyy"
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {paymentSummaries && <TraCuuThuTienTable paymentSummaries={paymentSummaries}></TraCuuThuTienTable>}
        </>
    );
}

export default MamNonTraCuuThuTienPage;
