import FMI_ACTIONS from 'redux/actions';

export const number_nuns__get_list = (data) => {
    return {
        type: FMI_ACTIONS.NUN__GET_NUMBER_OF_NUNS,
        payload: { data },
    };
};

export const khan_dong__get_list = (data) => {
    return {
        type: FMI_ACTIONS.CONGDOAN__GET_ALL_KHAN_DONG,
        payload: { data },
    };
};
