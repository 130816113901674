import { dateHelper } from 'helpers/dateHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { useSelector } from 'react-redux';

const EmptyRowsView = () => {
    const message = 'Chưa có mục thu nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ThuCongDoanList({ dmThuCongDoans, thuCongDoans, data, setData, monthYear }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    useEffect(() => {
        const rows = [];
        if (dmThuCongDoans) {
            dmThuCongDoans.forEach((dm) => {
                const matchedThuCongDoan = thuCongDoans.find((thuCongDoan) => thuCongDoan.code === dm.code);
                const row = {
                    dmId: dm.id,
                    code: dm.code,
                    name: dm.name,
                    amount: matchedThuCongDoan ? matchedThuCongDoan.amount : '',
                    usdAmount: matchedThuCongDoan ? matchedThuCongDoan.usdAmount : '',
                    eurAmount: matchedThuCongDoan ? matchedThuCongDoan.eurAmount : '',
                    audAmount: matchedThuCongDoan ? matchedThuCongDoan.audAmount : '',
                    notes: matchedThuCongDoan ? matchedThuCongDoan.notes : '',
                    collectDate: matchedThuCongDoan ? dateHelper.formatDate(matchedThuCongDoan.collectDate) : '',
                    dm: matchedThuCongDoan,
                };
                rows.push(row);
            });
            setRows(rows);
        }
    }, [dmThuCongDoans, thuCongDoans]);

    useEffect(() => {
        // const readOnly = dateHelper.isAfterNextMonth10thDate(monthYear);
        const columns = [
            { key: 'code', name: 'Mã số', editable: false, width: 60 },
            { key: 'name', name: 'Diễn giải', editable: false, width: 360 },
            {
                key: 'amount',
                name: 'VND (ĐV: 1000đ)',
                // editable: !readOnly,
                editable: true,
                width: 180,
            },
            { key: 'usdAmount', name: 'USD', editable: true, width: 80 },
            { key: 'eurAmount', name: 'EUR', editable: true, width: 80 },
            { key: 'audAmount', name: 'AUD', editable: true, width: 80 },
            {
                key: 'notes',
                name: 'Ghi chú',
                editable: true,
                //  editable: !readOnly             ,
                width: 200,
            },
            { key: 'collectDate', name: 'Ngày ghi', editable: false, width: 200 },
        ].map((item) => {
            return {
                ...item,
                ...{
                    resizable: true,
                },
            };
        });
        setColumns(columns);
    }, [monthYear]);

    const onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
        const newData = new Map(data);
        const newRows = [...rows];
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
            if (get(newRows[i], 'dm.id', false) || updated[cellKey] !== '') {
                newData.set(i, newRows[i]);
            }
        }
        setRows(newRows);
        setData(newData);
    };

    const checkEditable = (props) => {
        if (congDoanInfo.abroad && props.row.code === 1) {
            return props.column.editable;
        }
        return props.column.editable && props.row.code !== 1 && props.row.code !== 6;
    };

    return (
        <div id="rdg">
            <ReactDataGrid
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                minHeight={tableHeight}
                enableCellSelect={true}
                onGridRowsUpdated={onGridRowsUpdated}
                emptyRowsView={EmptyRowsView}
                onCheckCellIsEditable={checkEditable}
            />
        </div>
    );
}

export default ThuCongDoanList;
