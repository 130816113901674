import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

function BankCardDialog({ bankCard, open, closeDialog, onSaveAsset }) {
    const { register, handleSubmit, errors } = useForm();

    const defaultValues = {
        id: bankCard.id,
        tenNganHang: bankCard.tenNganHang,
        soThe: bankCard.soThe,
        loaiThe: bankCard.loaiThe,
        thoiHan: bankCard.thoiHan,
        lienKetVoiSoTK: bankCard.lienKetVoiSoTK,
        tenChuThe: bankCard.tenChuThe,
        nguoiDuocChiaSeMK: bankCard.nguoiDuocChiaSeMK,
    };

    const onSubmit = (data) => {
        const body = { ...data };
        onSaveAsset(body);
    };
    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{bankCard.id ? 'Chỉnh sửa thẻ ngân hàng' : 'Thêm thẻ ngân hàng'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="soThe">Số thẻ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.soThe && 'border-danger')}
                                placeholder="Số thẻ"
                                defaultValue={defaultValues.soThe}
                                id="soThe"
                                name="soThe"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập số thẻ!',
                                    },
                                })}
                            />
                            {errors.soThe && <div className="text-danger pt-2">{errors.soThe.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="loaiThe">Loại thẻ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.loaiThe && 'border-danger')}
                                placeholder="Loại thẻ"
                                defaultValue={defaultValues.loaiThe}
                                id="loaiThe"
                                name="loaiThe"
                                ref={register({})}
                            />
                            {errors.loaiThe && <div className="text-danger pt-2">{errors.loaiThe.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="tenNganHang">Tên NH</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tenNganHang && 'border-danger')}
                                placeholder="Tên NH"
                                defaultValue={defaultValues.tenNganHang}
                                id="tenNganHang"
                                name="tenNganHang"
                                ref={register({})}
                            />
                            {errors.tenNganHang && <div className="text-danger pt-2">{errors.tenNganHang.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="thoiHan">Thời hạn</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.thoiHan && 'border-danger')}
                                placeholder="Thời hạn"
                                defaultValue={defaultValues.thoiHan}
                                id="thoiHan"
                                name="thoiHan"
                                ref={register({})}
                            />
                            {errors.thoiHan && <div className="text-danger pt-2">{errors.thoiHan.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="lienKetVoiSoTK">{`Liên kết với STK`}</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.lienKetVoiSoTK && 'border-danger')}
                                placeholder="Liên kết với STK"
                                defaultValue={defaultValues.lienKetVoiSoTK}
                                id="lienKetVoiSoTK"
                                name="lienKetVoiSoTK"
                                ref={register({})}
                            />
                            {errors.lienKetVoiSoTK && <div className="text-danger pt-2">{errors.lienKetVoiSoTK.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="tenChuThe">Tên chủ thẻ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tenChuThe && 'border-danger')}
                                placeholder="Tên chủ thẻ"
                                defaultValue={defaultValues.tenChuThe}
                                id="tenChuThe"
                                name="tenChuThe"
                                ref={register({})}
                            />
                            {errors.tenChuThe && <div className="text-danger pt-2">{errors.tenChuThe.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiDuocChiaSeMK">Người được chia sẻ MK</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiDuocChiaSeMK && 'border-danger')}
                                placeholder="Người được chia sẻ MK"
                                defaultValue={defaultValues.nguoiDuocChiaSeMK}
                                id="nguoiDuocChiaSeMK"
                                name="nguoiDuocChiaSeMK"
                                ref={register({})}
                            />
                            {errors.nguoiDuocChiaSeMK && <div className="text-danger pt-2">{errors.nguoiDuocChiaSeMK.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default BankCardDialog;
