import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiUpdateCongDoanInfo } from 'redux/saga/cong-doan/info/updateCongDoanInfo';
import { congdoan_get_info } from '../actions';

function UserInfoDialog({ open, closeDialog }) {
    const congDoanInfo = useSelector((state) => state.commonReducer.congDoanInfo, null);

    const defaultValues = useMemo(() => {
        return {
            name: get(congDoanInfo, 'name', ''),
            congDoanName: get(congDoanInfo, 'congDoanName', ''),
            address: get(congDoanInfo, 'address', ''),
            district: get(congDoanInfo, 'district', ''),
            city: get(congDoanInfo, 'city', ''),
            principalName: get(congDoanInfo, 'principalName', ''),
            accountantName: get(congDoanInfo, 'accountantName', ''),
        };
    }, [congDoanInfo]);

    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: defaultValues,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(congdoan_get_info());
    }, [dispatch]);

    useEffect(() => {
        setValue('name', defaultValues.name);
        setValue('congDoanName', defaultValues.congDoanName);
        setValue('address', defaultValues.address);
        setValue('district', defaultValues.district);
        setValue('city', defaultValues.city);
        setValue('principalName', defaultValues.principalName);
        setValue('accountantName', defaultValues.accountantName);
    }, [defaultValues, setValue]);

    const onSubmit = (data) => {
        dispatch(loading());
        callApiUpdateCongDoanInfo(data)
            .then((res) => {
                toastHelper.success('Lưu thành công!');
                dispatch(congdoan_get_info());
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle disableTypography id="form-dialog-title">
                    <h3>Thông tin cộng đoàn</h3>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="name">Tên trường</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.congDoanName && 'border-danger')}
                                placeholder="Tên trường"
                                id="name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên trường!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label htmlFor="congDoanName">Tên cộng đoàn</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.congDoanName && 'border-danger')}
                                placeholder="Tên cộng đoàn"
                                id="congDoanName"
                                name="congDoanName"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên cộng đoàn!',
                                    },
                                })}
                            />
                            {errors.congDoanName && <div className="text-danger pt-2">{errors.congDoanName.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="address">Địa chỉ</label>
                            <input type="text" className={clsx('form-control')} placeholder="Địa chỉ" id="address" name="address" ref={register()} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="district">Quận/Xã</label>
                            <input type="text" className={clsx('form-control')} placeholder="Quận / Xã" id="district" name="district" ref={register()} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="city">Tỉnh/Thành phố</label>
                            <input type="text" className={clsx('form-control')} placeholder="Tỉnh / Thành phố" id="city" name="city" ref={register()} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="principalName">Hiệu trưởng</label>
                            <input type="text" className={clsx('form-control')} placeholder="Hiệu trưởng" id="principalName" name="principalName" ref={register()} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="accountantName">Kế toán</label>
                            <input type="text" className={clsx('form-control')} placeholder="Kế toán" id="accountantName" name="accountantName" ref={register()} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="row m-0">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={closeDialog}>
                                Đóng
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Lưu
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default UserInfoDialog;
