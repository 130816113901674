// import environmentConstants from '../../config/constants/environmentConstants';
var jwtDecode = require('jwt-decode');

function getUserFromToken(token) {
    return jwtDecode(token);
}

function setRefreshToken(refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
}

function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}

function removeRefreshToken() {
    return localStorage.removeItem('refresh_token');
}

function getUserInfoAsObject() {
    return JSON.parse(sessionStorage.getItem('userInfo'));
}

function getAccessToken() {
    return sessionStorage.getItem('accessToken');
}

function removeAccessToken() {
    return sessionStorage.removeItem('accessToken');
}

function clearSession() {
    return sessionStorage.clear();
}

const jwtHelpers = {
    getUserFromToken,
    getRefreshToken,
    setRefreshToken,
    removeRefreshToken,
    getAccessToken,
    getUserInfoAsObject,
    removeAccessToken,
    clearSession,
};

export default jwtHelpers;
