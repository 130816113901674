import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import dayjs from 'dayjs';
import { messageHelper } from 'helpers/messageHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiGetChiCongDoanVung } from 'redux/saga/vung/chi/getChiCongDoanVung';
import { callApiSaveChiCongDoanVung } from 'redux/saga/vung/chi/saveChiCongDoanVung';
import { vung__get_list_danh_muc_chi_vung } from '../actions';
import ChiVungList from './ChiVungList';

function ChiVungPage() {
    const { control, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dmChiVungs = useSelector((state) => state.commonReducer.dmChiVungs, []);

    const theme = useTheme();

    const [data, setData] = useState(null);
    const [chiVungs, setChiVungs] = useState([]);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(vung__get_list_danh_muc_chi_vung());
    }, [dispatch]);

    const getChiVungs = useCallback(
        (month, year) => {
            setChiVungs([]);
            setData(null);
            if (userInfo.congDoanId) {
                callApiGetChiCongDoanVung(userInfo.congDoanId, month, year)
                    .then((res) => {
                        setChiVungs(res.data);
                    })
                    .catch((err) => {
                        messageHelper.showError(err);
                    });
            }
        },
        [userInfo]
    );

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.monthYear) {
            const month = dayjs(values.monthYear).month() + 1;
            const year = dayjs(values.monthYear).year();
            getChiVungs(month, year);
        }
    }, [values, watches, prevValues, getChiVungs]);

    const onSubmit = () => {
        if (data && userInfo.congDoanId && values.monthYear) {
            const body = buildBody(data);
            saveAllChiMamNon(body);
        }
    };

    const buildBody = (data) => {
        return [...data.values()].map((item) => {
            return {
                id: get(item, 'dm.id', undefined),
                amount: Number(item.amount),
                notes: item.notes,
                code: item.code,
                danhMucChiCongDoanVungId: item.dmId,
                eur: Number(item.eurAmount),
                usd: Number(item.usdAmount),
                aud: Number(item.audAmount),
            };
        });
    };

    const saveAllChiMamNon = (body) => {
        const month = dayjs(values.monthYear).month() + 1;
        const year = dayjs(values.monthYear).year();
        dispatch(loading());
        callApiSaveChiCongDoanVung(userInfo.congDoanId, month, year, body)
            .then((res) => {
                toastHelper.success('Lưu chi cộng đoàn thành công!');
                getChiVungs(month, year);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Chi vùng</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="monthYear">
                                        Tháng:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            control={control}
                                            id="monthYear"
                                            name="monthYear"
                                            render={(props) => (
                                                <ReactDatePicker
                                                    placeholderText="Chọn tháng"
                                                    onChange={(e) => props.onChange(e)}
                                                    className="form-control chi-mam-non-datepicker"
                                                    dateFormat="MM.yyyy"
                                                    showMonthYearPicker
                                                    locale="vi"
                                                    selected={props.value}
                                                    popperProps={{
                                                        positionFixed: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                <div className="row mx-0 w-100">
                                    <button
                                        type="button"
                                        className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })}
                                        disabled={data === null}
                                        onClick={onSubmit}
                                    >
                                        Lưu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {values.monthYear && <ChiVungList chiVungs={chiVungs} dmChiVungs={dmChiVungs} data={data} setData={setData} monthYear={values.monthYear} />}
        </>
    );
}

export default ChiVungPage;
