import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import usePrevious from 'helpers/usePrevious';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { callApiExportAnnualReportThuChiCTPV } from 'redux/saga/ctpv/thuchi/exportAnnualReportThuChiCTPV';
import { messageHelper } from 'helpers/messageHelper';
import _, { get } from 'lodash';
import { callApiGetReportThuChiCTPV } from 'redux/saga/ctpv/thuchi/getReportThuChiCTPV';
import Table from '../../shared/react-table/Table';
import { LOAI_CTPV } from 'config/constants/danhMucThuConstant';
import { numberHelper } from 'helpers/numberHelper';

function CongTacPhucVuBaoCaoNamPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const getReport = useCallback(() => {
        dispatch(loading());
        callApiGetReportThuChiCTPV(userInfo.congDoanId, values.academicYear.value)
            .then((res) => {
                setReport(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.academicYear, userInfo.congDoanId]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.academicYear) {
            getReport();
        }
    }, [values, watches, prevValues, getReport]);

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const onExport = () => {
        dispatch(loading());
        callApiExportAnnualReportThuChiCTPV(userInfo.congDoanId, values.academicYear.value, false)
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `Báo cáo CTPV năm ${get(values, 'academicYear.value', '')}.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                columns: [
                    {
                        Header: ' ',
                        columns: [
                            {
                                Header: '',
                                accessor: 'stt',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'CÁC CÔNG TÁC',
                columns: [
                    {
                        Header: '   ',
                        columns: [
                            {
                                Header: ' ',
                                accessor: 'danhMucName',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'TỒN ĐẦU NĂM',
                columns: [
                    {
                        Header: 'VNĐ',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tienmat_tondau',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'nganhang_tondau',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'usd_tondau',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'euro_tondau',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'THU',
                columns: [
                    {
                        Header: 'VNĐ',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tienmat_thu',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'nganhang_thu',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'usd_thu',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'euro_thu',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'CHI',
                columns: [
                    {
                        Header: 'VNĐ',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tienmat_chi',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'nganhang_chi',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'usd_chi',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'euro_chi',
                            },
                        ],
                    },
                ],
            },
            {
                Header: 'TỒN CUỐI NĂM',
                columns: [
                    {
                        Header: 'VNĐ',
                        columns: [
                            {
                                Header: 'Tiền mặt',
                                accessor: 'tienmat_toncuoi',
                            },
                            {
                                Header: 'Ngân hàng',
                                accessor: 'nganhang_toncuoi',
                            },
                        ],
                    },
                    {
                        Header: 'USD',
                        columns: [
                            {
                                Header: '',
                                accessor: 'usd_toncuoi',
                            },
                        ],
                    },
                    {
                        Header: 'EURO',
                        columns: [
                            {
                                Header: '',
                                accessor: 'euro_toncuoi',
                            },
                        ],
                    },
                ],
            },
        ],
        []
    );

    const buildRow = (row, loaiCTPV, thuchi) => {
        row['tienmat_' + getTableDataField(loaiCTPV)] = numberHelper.formatNumber(thuchi[0].tienMat);
        row['nganhang_' + getTableDataField(loaiCTPV)] = numberHelper.formatNumber(thuchi[0].nganHang);
        row['usd_' + getTableDataField(loaiCTPV)] = numberHelper.formatNumber(thuchi[0].usd);
        row['euro_' + getTableDataField(loaiCTPV)] = numberHelper.formatNumber(thuchi[0].euro);
        return row;
    };

    const buildData = () => {
        if (report) {
            const result = [];
            const reportMapped = _.groupBy(report, 'danhMucCongTacPhucVuId');
            Object.entries(reportMapped).forEach(([k, v], index) => {
                const row = {
                    stt: index + 1,
                    danhMucName: v[0].danhMucCongTacPhucVuName,
                };
                const thuchiMapped = _.groupBy(v, 'loaiCTPV');
                Object.entries(thuchiMapped).forEach(([k1, v1]) => {
                    buildRow(row, k1, v1);
                });
                result.push(row);
            });
            return result;
        }
    };

    const getTableDataField = (loaiCTPV) => {
        switch (loaiCTPV) {
            case LOAI_CTPV.THU:
                return 'thu';
            case LOAI_CTPV.CHI:
                return 'chi';
            case LOAI_CTPV.TONDAUTHANG:
                return 'tondau';
            case LOAI_CTPV.TONCUOI:
                return 'toncuoi';
            default:
                break;
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo năm công tác phục vụ</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy dự chi cá nhân!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mr-0 d-flex justify-content-end">
                                        <div className="col-md-12 p-0 d-flex justify-content-end">
                                            <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={onExport} title="Xuất file báo cáo năm">
                                                <AddIcon />
                                                Xuất file báo cáo năm
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {report && <Table columns={columns} data={buildData()} />}
        </>
    );
}

export default CongTacPhucVuBaoCaoNamPage;
