import React, { useCallback, useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import ThuDauNamDialog from './ThuDauNamDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import './styles.scss';
import { callApiDeleteThuDauNam } from 'redux/saga/mam-non/info/thu-dau-nam/deleteThuDauNam';
import { alert } from 'helpers/alertHelper';
import { getTableHeightFitContent } from 'helpers/lib/reactDataGridHelpers';

const EmptyRowsView = () => {
    const message = 'Chưa có mục thu đầu năm nào';
    return (
        <div style={{ textAlign: 'center', backgroundColor: '#ddd', padding: '100px', height: '100%' }}>
            <h3>{message}</h3>
        </div>
    );
};

function ThuDauNamList({ thuDauNams, onEditSuccess, onDeleteSuccess }) {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedThuDauNam, setSelectedThuDauNam] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [open, setOpen] = useState(false);

    const [tableHeight, setTableHeight] = useState();
    useEffect(() => {
        setTableHeight(getTableHeightFitContent());
    }, []);

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        const rows = [];
        thuDauNams.forEach((thuDauNam, index) => {
            const row = {
                index: index + 1,
                name: thuDauNam.name,
                amount: thuDauNam.amount,
                thuDauNam: thuDauNam,
            };
            rows.push(row);
        });
        setRows(rows);
    }, [thuDauNams]);

    const buildColumns = useCallback(() => {
        const fixedColumns = [
            { key: 'index', name: 'STT', frozen: true, width: 50 },
            { key: 'name', name: 'Mục thu', frozen: false, width: 250 },
            { key: 'amount', name: 'Số tiền', frozen: false, width: 250 },
            { key: 'actions', width: 80 },
        ];
        return fixedColumns.map((item) => {
            return { ...item, ...{ resizable: true } };
        });
    }, []);

    useEffect(() => {
        setColumns(buildColumns());
    }, [buildColumns]);

    const actionColumnActions = (row) => {
        return [
            {
                icon: <DeleteIcon />,
                callback: () => {
                    setSelectedThuDauNam(row.thuDauNam);
                    setSelectedIndex(row.index - 1);
                    showConfirmDialog(row);
                },
            },
            {
                icon: <EditIcon />,
                callback: () => {
                    setSelectedThuDauNam(row.thuDauNam);
                    setSelectedIndex(row.index - 1);
                    openDialog();
                },
            },
        ];
    };

    function getCellActions(column, row) {
        const cellActions = {
            actions: actionColumnActions(row),
        };
        return cellActions[column.key] || null;
    }

    const onEditStudentSuccess = (data) => {
        onEditSuccess(data, selectedIndex);
    };

    const showConfirmDialog = (selectedItem) => {
        alert({
            title: 'Bạn có chắc chắn muốn xoá mục thu đầu năm này?',
            showCancelButton: true,
            confirmButtonText: `Có`,
            cancelButtonText: 'Không',
        }).then((result) => {
            if (result.isConfirmed) {
                if (selectedItem.thuDauNam.id) {
                    callApiDeleteThuDauNam(selectedItem.thuDauNam.id)
                        .then((res) => {
                            onDeleteSuccess(selectedItem.index - 1);
                            alert({ icon: 'success', title: 'Xoá mục thu đầu năm thành công', showConfirmButton: false });
                        })
                        .catch((error) => {
                            alert({ icon: 'error', title: 'Lỗi', text: 'Có lỗi khi xoá mục thu đầu năm! Vui lòng thử lại sau.' });
                        });
                }
            }
        });
    };

    return (
        <div className="thu-dau-name-data-grid" id="rdg">
            <ReactDataGrid columns={columns} rowGetter={(i) => rows[i]} rowsCount={rows.length} minHeight={tableHeight} getCellActions={getCellActions} emptyRowsView={EmptyRowsView} />
            {selectedThuDauNam && <ThuDauNamDialog thuDauNam={selectedThuDauNam} open={open} closeDialog={closeDialog} onSuccess={onEditStudentSuccess} />}
        </div>
    );
}

export default ThuDauNamList;
