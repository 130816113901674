import { makeStyles, Paper, Typography } from '@material-ui/core';
import { monthsOfYear } from 'config/constants/monthsConstants';
import { dateHelper } from 'helpers/dateHelper';
import { toastHelper } from 'helpers/toastHelper';
import usePrevious from 'helpers/usePrevious';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiGetGradeHolidays } from 'redux/saga/mam-non/info/grade/getGradeHolidays';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { callApiSaveGradeHolidays } from 'redux/saga/mam-non/info/grade/saveGradeHolidays';
import { academic_year__get_list } from '../actions';
import Holidays from './holidays/Holidays';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

function MamNonHolidayPage() {
    const { control, setValue, getValues, watch } = useForm();
    const dispatch = useDispatch();
    const [grades, setGrades] = useState([]);
    const [academicYear, setAcademicYear] = useState();
    const [holidays, setHolidays] = useState(null);
    const [holidaysSubmitted, setHolidaySubmitted] = useState('');
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.month && values.grade.id) {
            getHolidayForGrade(values.grade.id, values.month);
        }
    }, [values, watches, prevValues]);

    const getHolidayForGrade = (gradeId, holidayMonth) => {
        setHolidays(null);
        callApiGetGradeHolidays(gradeId, { holidayMonth })
            .then((res) => {
                setHolidays(res.data.holidays);
                setHolidaySubmitted(res.data.holidays.join(','));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const monthOptions = monthsOfYear.map((item) => {
        return { value: item, label: 'Tháng ' + item };
    });

    const onAcademicYearChange = (selectedOption) => {
        setGrades([]);
        setAcademicYear(selectedOption.value);
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onMonthChange = (selectedOption) => {
        setValue('month', selectedOption.value);
    };

    const onGradeChange = (selectedOption) => {
        setValue('grade', selectedOption.value);
    };

    const classes = useStyles();

    const onChangeHoliday = (holidaysAsString) => {
        setHolidaySubmitted(holidaysAsString);
    };

    const saveGradeHolidays = () => {
        callApiSaveGradeHolidays(values.grade.id, { holidayMonth: values.month, dayOffs: holidaysSubmitted })
            .then((res) => {
                setHolidays(res.data.holidays);
                setHolidaySubmitted(res.data.holidays.join(','));
                toastHelper.success('Lưu ngày nghỉ thành công!');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const holidayStyles = {
        holidayFontSize: 20,
    };

    return (
        <>
            <div className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center" className="mb-5">
                        Thông tin ngày nghỉ
                    </Typography>
                    <form>
                        <div className="row mx-0 mb-2">
                            <div className="col-md-6">
                                <label className="form-label font-weight-bold" htmlFor="academicYear">
                                    Năm học:
                                </label>
                                <Select
                                    options={academicYearsOptions}
                                    id="academicYear"
                                    onChange={onAcademicYearChange}
                                    styles={{
                                        menu: (provided) => ({ ...provided, zIndex: 13 }),
                                    }}
                                ></Select>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label font-weight-bold" htmlFor="grade">
                                    Khối:
                                </label>
                                <Controller
                                    name="grade"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                        },
                                    }}
                                    render={({ value }) => {
                                        return (
                                            <Select
                                                onChange={onGradeChange}
                                                options={grades.map((item) => {
                                                    return { value: item, label: item.name };
                                                })}
                                                styles={{
                                                    menu: (provided) => ({ ...provided, zIndex: 13 }),
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mx-0 mb-2">
                            <div className="col-md-6">
                                <label className="form-label font-weight-bold" htmlFor="month">
                                    Tháng:
                                </label>
                                <Controller
                                    name="month"
                                    control={control}
                                    defaultValue={null}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Vui lòng nhập lớp để lấy danh sách điểm danh!',
                                        },
                                    }}
                                    render={() => {
                                        return (
                                            <Select
                                                onChange={onMonthChange}
                                                options={monthOptions}
                                                styles={{
                                                    menu: (provided) => ({ ...provided, zIndex: 13 }),
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    {holidays && (
                        <div className="mt-4">
                            <Holidays
                                holidays={holidays}
                                month={getValues()['month']}
                                year={
                                    dateHelper.isCongDoanSaiGonAndSpecialCaseFor2022(authenticationReducer.userInfo.congDoanId, academicYear, getValues()['month'])
                                        ? 2022
                                        : dateHelper.getYearFromAcademicYearAndMonth(academicYear, getValues()['month'])
                                }
                                onChangeHoliday={onChangeHoliday}
                                styles={holidayStyles}
                            />
                            <div className="text-right mt-3">
                                <button type="button" className="btn btn-primary px-5" onClick={saveGradeHolidays}>
                                    Lưu
                                </button>
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
        </>
    );
}

export default MamNonHolidayPage;
