import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import { academic_year__get_list } from '../actions';
import usePrevious from 'helpers/usePrevious';
import { callApiGetListOfGrades } from 'redux/saga/mam-non/info/grade/getListOfGrades';
import { callApiGetListOfClasses } from 'redux/saga/mam-non/info/class/getListOfClasses';
import ClazzList from './ClazzList';
import ClazzDialog from './ClazzDialog';
import { customSelectStyles } from 'config/constants/reactSelectConstant';

const defaultClazz = {
    name: '',
    amount: '',
};

function MamNonClazzPage() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [grades, setGrades] = useState([]);
    const [clazzes, setClazzes] = useState(null);
    const [clazz, setClazz] = useState(null);
    const [open, setOpen] = useState(false);
    const { control, errors, setValue, getValues, trigger, watch } = useForm();
    const authenticationReducer = useSelector((state) => state.authenticationReducer);
    const commonReducer = useSelector((state) => state.commonReducer);

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const getListClazzes = useCallback((gradeId) => {
        setClazzes(null);
        callApiGetListOfClasses(gradeId).then((res) => {
            setClazzes(res.data);
        });
    }, []);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        if (isDiff && values.grade && values.grade.id) {
            getListClazzes(values.grade.id);
        }
    }, [values, prevValues, watches, getListClazzes]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item.name, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('grade', '');
        setValue('academicYear', selectedOption.value);
        if (authenticationReducer.userInfo && authenticationReducer.userInfo.congDoanId) {
            callApiGetListOfGrades({ params: { congdoanId: authenticationReducer.userInfo.congDoanId, academicYear: selectedOption.value } })
                .then((res) => {
                    setGrades(res.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }
    };

    const onGradeChange = (selectedOption) => {
        setValue('grade', selectedOption.value);
        trigger('grade');
    };

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const addClazz = () => {
        setClazz(defaultClazz);
        openDialog();
    };

    const onAddClazzSuccess = (data) => {
        const newClazzes = [...clazzes];
        newClazzes.push(data);
        setClazzes(newClazzes);
        setClazz(defaultClazz);
    };

    const onEditClazzSuccess = (data, index) => {
        const newClazzes = [...clazzes];
        newClazzes[index] = data;
        setClazzes(newClazzes);
    };

    const onDeleteClazzSuccess = (index) => {
        const newClazzes = [...clazzes];
        newClazzes.splice(index, 1);
        setClazzes(newClazzes);
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Danh sách lớp</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="academicYear"
                                            control={control}
                                            defaultValue=""
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        options={academicYearsOptions}
                                                        id="academicYear"
                                                        onChange={onAcademicYearChange}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    ></Select>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label">Khối:</label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            name="grade"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập khối để lấy danh sách dự thu!',
                                                },
                                            }}
                                            render={({ value }) => {
                                                return (
                                                    <Select
                                                        onChange={onGradeChange}
                                                        options={grades.map((item) => {
                                                            return { value: item, label: item.name };
                                                        })}
                                                        value={value !== '' ? value.label : ''}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.clazz && <span className="text-danger">{errors.clazz.message}</span>}
                                    </div>
                                </div>
                            </div>
                            {values.grade && values.grade.id && (
                                <>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-3">
                                        <div className="row mr-0 d-flex justify-content-end">
                                            <div className="col-md-12 p-0 d-flex justify-content-end">
                                                <button type="button" className="export-button btn btn-primary fmi-toolbar-button" onClick={addClazz} title="Thêm lớp">
                                                    <AddIcon />
                                                    Thêm lớp
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </Box>
            </Paper>
            {clazzes && <ClazzList clazzes={clazzes} onEditSuccess={onEditClazzSuccess} onDeleteSuccess={onDeleteClazzSuccess} academicYear={values.academicYear} />}
            {clazz && <ClazzDialog clazz={clazz} open={open} closeDialog={closeDialog} gradeId={values.grade.id} onSuccess={onAddClazzSuccess} />}
        </>
    );
}

export default MamNonClazzPage;
