import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

function XeDialog({ xe, open, closeDialog, onSaveAsset }) {
    const { register, handleSubmit, errors } = useForm();

    const defaultValues = {
        id: xe.id,
        name: xe.name,
        loai: xe.loai,
        soXe: xe.soXe,
        nguoiSuDung: xe.nguoiSuDung,
        nguoiGiuGiayTo: xe.nguoiGiuGiayTo,
        triGia: xe.triGia,
        nguoiDungTen: xe.nguoiDungTen,
    };

    const onSubmit = (data) => {
        const body = { ...data };
        onSaveAsset(body);
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{xe.id ? 'Chỉnh sửa xe' : 'Thêm xe'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="name">Tên xe</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Tên xe"
                                defaultValue={defaultValues.name}
                                id="name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập Tên xe!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="loai">Loại xe</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.loai && 'border-danger')}
                                placeholder="Loại xe"
                                defaultValue={defaultValues.loai}
                                id="loai"
                                name="loai"
                                ref={register({})}
                            />
                            {errors.loai && <div className="text-danger pt-2">{errors.loai.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="soXe">Số xe</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.soXe && 'border-danger')}
                                placeholder="Số xe"
                                defaultValue={defaultValues.soXe}
                                id="soXe"
                                name="soXe"
                                ref={register({})}
                            />
                            {errors.soXe && <div className="text-danger pt-2">{errors.soXe.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="triGia">Trị giá</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.triGia && 'border-danger')}
                                placeholder="Trị giá"
                                defaultValue={defaultValues.triGia}
                                id="triGia"
                                name="triGia"
                                ref={register({})}
                            />
                            {errors.triGia && <div className="text-danger pt-2">{errors.triGia.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiDungTen">{`Người đứng tên xe`}</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiDungTen && 'border-danger')}
                                placeholder="Người đứng tên xe"
                                defaultValue={defaultValues.nguoiDungTen}
                                id="nguoiDungTen"
                                name="nguoiDungTen"
                                ref={register({})}
                            />
                            {errors.nguoiDungTen && <div className="text-danger pt-2">{errors.nguoiDungTen.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiSuDung">Người sử dụng</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiSuDung && 'border-danger')}
                                placeholder="Người sử dụng"
                                defaultValue={defaultValues.nguoiSuDung}
                                id="nguoiSuDung"
                                name="nguoiSuDung"
                                ref={register({})}
                            />
                            {errors.nguoiSuDung && <div className="text-danger pt-2">{errors.nguoiSuDung.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiGiuGiayTo">Người giữ giấy tờ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiGiuGiayTo && 'border-danger')}
                                placeholder="Người giữ giấy tờ"
                                defaultValue={defaultValues.nguoiGiuGiayTo}
                                id="nguoiGiuGiayTo"
                                name="nguoiGiuGiayTo"
                                ref={register({})}
                            />
                            {errors.nguoiGiuGiayTo && <div className="text-danger pt-2">{errors.nguoiGiuGiayTo.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default XeDialog;
