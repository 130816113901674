import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import Table from 'components/shared/react-table/Table';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportMamNonReportForAllCongDoan } from 'redux/saga/trung-uong/report/mamnon/exportMamNonAnnualReportForAll';
import { callApiGetMamNonReportForAllCongDoan } from 'redux/saga/trung-uong/report/mamnon/getMamNonReportForAllCongDoan';
import { numberHelper } from 'helpers/numberHelper';

function TWMamNonReportAnnualPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    // const getReport = useCallback(() => {
    //     dispatch(loading());
    //     callApiGetMamNonReportForAllCongDoan(values.academicYear.value.name)
    //         .then((res) => {
    //             setReport(res.data);
    //         })
    //         .catch((err) => {
    //             messageHelper.showError(err);
    //         })
    //         .finally(() => {
    //             dispatch(loaded());
    //         });
    // }, [dispatch, values.academicYear]);

    // useEffect(() => {
    //     const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
    //     const academicYear = get(values, 'academicYear.value.name');
    //     if (isDiff && academicYear) {
    //         getReport();
    //     }
    // }, [values, watches, prevValues, getReport]);

    // const buildRow = (data) => {
    //     const amount = data.amounts['2021-01'];
    //     return {
    //         code: data.code,
    //         name: data.name,
    //         thuHs: numberHelper.formatNumber(amount.thuHs),
    //         chiHs: numberHelper.formatNumber(amount.chiHs),
    //         chiCd: numberHelper.formatNumber(amount.chiCd),
    //     };
    // };

    // const buildDataForThu = () => {
    //     const result = [];
    //     if (report) {
    //         report.forEach((item) => {
    //             result.push(buildRow(item));
    //         });
    //     }
    //     return result;
    // };

    // const columns = useMemo(() => {
    //     const academicYear = get(values, 'academicYear.value');
    //     if (!academicYear) return [];
    //     const fixedColumns = [
    //         {
    //             Header: 'TM',
    //             accessor: 'code',
    //         },
    //         {
    //             Header: 'DIỄN GIẢI',
    //             accessor: 'name',
    //             style: {
    //                 minWidth: '400px',
    //                 textAlign: 'left',
    //             },
    //         },
    //         {
    //             Header: 'THU',
    //             accessor: 'thuHs',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //         {
    //             Header: 'CHI',
    //             accessor: 'chiHs',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //         {
    //             Header: 'CHI CĐ',
    //             accessor: 'chiCd',
    //             style: {
    //                 minWidth: '150px',
    //             },
    //         },
    //     ];
    //     // const dynamicColumns = buildDynamicColumns(academicYear);
    //     return [
    //         ...fixedColumns,
    //         // ...dynamicColumns
    //     ];
    // }, [values]);

    const onExport = () => {
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiExportMamNonReportForAllCongDoan(academicYear)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo thu chi tất cả mầm non ${academicYear}.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo thu chi mầm non theo năm</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy báo cáo!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
            {/* 
            <div>
                {report && (
                    <>
                        <Table columns={columns} data={buildDataForThu()} />
                    </>
                )}
            </div> */}
        </>
    );
}

export default TWMamNonReportAnnualPage;
