import { WEEK_DAYS } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import './styles.scss';

function Holidays({ month, year, onChangeHoliday, holidays, styles, readOnly = false }) {
    const { register, getValues } = useForm();
    const offsetFirstDay = dayjs(new Date(year, month - 1)).day();

    const daysInMonth = () => {
        const selectedMonth = dayjs(new Date(year, month - 1, 1));
        return [...Array.from(Array(dayjs(selectedMonth).daysInMonth()).keys(), (x) => x + 1)];
    };

    const handleChange = () => {
        onChangeHoliday(
            Object.values(getValues()['withIndex'])
                .filter((d) => Boolean(d) !== false)
                .join(',')
        );
    };

    return (
        <div className="holidays-wrapper">
            <fieldset
                className="holidays-fieldset"
                style={{
                    fontSize: styles.holidayFontSize,
                }}
                disabled={readOnly}
            >
                <div className="holidays-table">
                    {WEEK_DAYS.map((weekday) => (
                        <div className="holiday">{weekday}</div>
                    ))}
                </div>
                <div className="holidays-table">
                    {[...Array(offsetFirstDay)].map((item) => (
                        <div className="holiday" key={item}></div>
                    ))}
                    {daysInMonth().map((c, i) => (
                        <div className="holiday" key={c}>
                            <label>
                                <input
                                    className="mr-1"
                                    type="checkbox"
                                    value={c}
                                    defaultChecked={holidays.map((holiday) => Number(holiday)).includes(Number(c))}
                                    name={'withIndex.' + i}
                                    onChange={handleChange}
                                    ref={register}
                                />
                                {c}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
}

export default Holidays;
