import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiCreateBHYT } from 'redux/saga/cong-doan/info/bao-hiem-y-te/createBHYT';
import { callApiUpdateBHYT } from 'redux/saga/cong-doan/info/bao-hiem-y-te/updateBHYT';

function BaoHiemYTeDialog({ bhyt, open, closeDialog, onSuccess }) {
    const { register, handleSubmit, errors, control } = useForm();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dispatch = useDispatch();

    const defaultValues = {
        id: bhyt.id,
        maso: bhyt.maso,
        diaChiThe: bhyt.diaChiThe,
        noiDangKy: bhyt.noiDangKy,
        giaTriSuDung: bhyt.giaTriSuDung,
    };

    const onSubmit = (data) => {
        const body = { ...data, ...{ giaTriSuDung: dateHelper.formatDate(data.giaTriSuDung, FMI_SERVER_DATE_FORMAT) } };
        if (bhyt.id) {
            editBHYT(body);
        } else {
            addNun(body);
        }
    };

    const editBHYT = (data) => {
        dispatch(loading());
        callApiUpdateBHYT(userInfo.congDoanId, bhyt.nunId, bhyt.id, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    const addNun = (data) => {
        dispatch(loading());
        callApiCreateBHYT(userInfo.congDoanId, bhyt.nunId, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{bhyt.id ? 'Chỉnh sửa BHYT' : 'Thêm BHYT'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="maso">Mã số</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.maso && 'border-danger')}
                                placeholder="Mã số"
                                defaultValue={defaultValues.maso}
                                id="maso"
                                name="maso"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập mã số!',
                                    },
                                })}
                            />
                            {errors.maso && <div className="text-danger pt-2">{errors.maso.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="diaChiThe">Địa chỉ thẻ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.diaChiThe && 'border-danger')}
                                placeholder="Địa chỉ thẻ"
                                defaultValue={defaultValues.diaChiThe}
                                id="diaChiThe"
                                name="diaChiThe"
                                ref={register({})}
                            />
                            {errors.diaChiThe && <div className="text-danger pt-2">{errors.diaChiThe.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="noiDangKy">Nơi đăng ký</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.noiDangKy && 'border-danger')}
                                placeholder="Nơi đăng ký"
                                defaultValue={defaultValues.noiDangKy}
                                id="noiDangKy"
                                name="noiDangKy"
                                ref={register({})}
                            />
                            {errors.noiDangKy && <div className="text-danger pt-2">{errors.noiDangKy.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="giaTriSuDung">Giá trị sử dụng</label>
                            <Controller
                                control={control}
                                id="giaTriSuDung"
                                name="giaTriSuDung"
                                defaultValue={defaultValues.giaTriSuDung}
                                render={(props) => (
                                    <ReactDatePicker
                                        placeholderText="Chọn ngày"
                                        onChange={(e) => props.onChange(e)}
                                        className="form-control"
                                        value={new Date(defaultValues.giaTriSuDung)}
                                        selected={props.value ? new Date(props.value) : new Date()}
                                        dateFormat="dd.MM.yyyy"
                                        locale="vi"
                                        popperProps={{
                                            positionFixed: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.giaTriSuDung && <div className="text-danger pt-2">{errors.giaTriSuDung.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default BaoHiemYTeDialog;
