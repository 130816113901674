import FMI_ACTIONS from 'redux/actions';

export const vung__get_list_danh_muc_thu_vung = (data) => {
    return {
        type: FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_THU,
        payload: { ...data },
    };
};

export const vung__get_list_danh_muc_chi_vung = () => {
    return {
        type: FMI_ACTIONS.VUNG__GET_LIST_DANH_MUC_CHI,
    };
};
