import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading } from 'components/mam-non-page/actions';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { callApiCreateNun } from 'redux/saga/cong-doan/info/nun/createNun';
import { callApiUpdateNun } from 'redux/saga/cong-doan/info/nun/updateNun';
import Select from 'react-select';

function NunDialog({ nun, open, closeDialog, onSuccess }) {
    const { register, handleSubmit, errors, control, setValue, trigger } = useForm();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const dispatch = useDispatch();

    const defaultValues = {
        id: nun.id,
        fullName: nun.fullName,
        cmnd: nun.cmnd,
        phone: nun.phone,
        noiSinh: nun.noiSinh,
        namSinh: nun.namSinh,
        nguyenQuan: nun.nguyenQuan,
        hoKhau: nun.hoKhau,
        tamTru: nun.tamTru,
        noiCatGiuHoKhau: nun.noiCatGiuHoKhau,
        loaiNoiO: nun.loaiNoiO,
    };

    const onSubmit = (data) => {
        const body = {
            ...data,
            ...{
                namSinh: data.namSinh ? dateHelper.formatDate(data.namSinh, FMI_SERVER_DATE_FORMAT) : null,
                loaiNoiO: data.loaiNoiO ? data.loaiNoiO.value : null,
            },
        };
        if (nun.id) {
            editNun(body);
        } else {
            addNun(body);
        }
    };

    const editNun = (data) => {
        dispatch(loading());
        callApiUpdateNun(userInfo.congDoanId, nun.id, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    const addNun = (data) => {
        dispatch(loading());
        callApiCreateNun(userInfo.congDoanId, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    const loaiNoiOOptions = [
        { value: 'THUONGTRU', label: 'Thường trú' },
        { value: 'TAMTRU', label: 'Tạm trú' },
    ];

    const onLoaiNoiOChange = (selectedOption) => {
        setValue('loaiNoiO', selectedOption);
        trigger('loaiNoiO');
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{nun.id ? 'Chỉnh sửa sơ' : 'Thêm sơ'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="fullName">Tên sơ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.fullName && 'border-danger')}
                                placeholder="Tên sơ"
                                defaultValue={defaultValues.fullName}
                                id="fullName"
                                name="fullName"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên sơ!',
                                    },
                                })}
                            />
                            {errors.fullName && <div className="text-danger pt-2">{errors.fullName.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="cmnd">CMND</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.cmnd && 'border-danger')}
                                placeholder="CMND"
                                defaultValue={defaultValues.cmnd}
                                id="cmnd"
                                name="cmnd"
                                ref={register({})}
                            />
                            {errors.cmnd && <div className="text-danger pt-2">{errors.cmnd.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="phone">SĐT</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.phone && 'border-danger')}
                                placeholder="SĐT"
                                defaultValue={defaultValues.phone}
                                id="phone"
                                name="phone"
                                ref={register({})}
                            />
                            {errors.phone && <div className="text-danger pt-2">{errors.phone.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="noiSinh">Nơi Sinh</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.noiSinh && 'border-danger')}
                                placeholder="Nơi sinh"
                                defaultValue={defaultValues.noiSinh}
                                id="noiSinh"
                                name="noiSinh"
                                ref={register({})}
                            />
                            {errors.noiSinh && <div className="text-danger pt-2">{errors.noiSinh.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="namSinh">Ngày sinh</label>
                            <Controller
                                control={control}
                                id="namSinh"
                                name="namSinh"
                                defaultValue={defaultValues.namSinh}
                                render={(props) => {
                                    return (
                                        <ReactDatePicker
                                            placeholderText="Chọn năm"
                                            className="form-control"
                                            onChange={(e) => props.onChange(e)}
                                            dateFormat="dd.MM.yyyy"
                                            value={new Date(defaultValues.namSinh)}
                                            locale="vi"
                                            selected={props.value ? new Date(props.value) : new Date()}
                                            popperProps={{
                                                positionFixed: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.namSinh && <div className="text-danger pt-2">{errors.namSinh.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguyenQuan">Nguyên quán</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguyenQuan && 'border-danger')}
                                placeholder="Nguyên quán"
                                defaultValue={defaultValues.nguyenQuan}
                                id="nguyenQuan"
                                name="nguyenQuan"
                                ref={register({})}
                            />
                            {errors.nguyenQuan && <div className="text-danger pt-2">{errors.nguyenQuan.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="hoKhau">Hộ khẩu</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.hoKhau && 'border-danger')}
                                placeholder="Hộ khẩu"
                                defaultValue={defaultValues.hoKhau}
                                id="hoKhau"
                                name="hoKhau"
                                ref={register({})}
                            />
                            {errors.hoKhau && <div className="text-danger pt-2">{errors.hoKhau.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="tamTru">Tạm trú</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tamTru && 'border-danger')}
                                placeholder="Tạm trú"
                                defaultValue={defaultValues.tamTru}
                                id="tamTru"
                                name="tamTru"
                                ref={register({})}
                            />
                            {errors.tamTru && <div className="text-danger pt-2">{errors.tamTru.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="noiCatGiuHoKhau">Nơi cất giữ hộ khẩu</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.noiCatGiuHoKhau && 'border-danger')}
                                placeholder="Nơi cất giữ hộ khẩu"
                                defaultValue={defaultValues.noiCatGiuHoKhau}
                                id="noiCatGiuHoKhau"
                                name="noiCatGiuHoKhau"
                                ref={register({})}
                            />
                            {errors.noiCatGiuHoKhau && <div className="text-danger pt-2">{errors.noiCatGiuHoKhau.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="loaiNoiO">Loại nơi ở</label>
                            <Controller
                                name="loaiNoiO"
                                control={control}
                                defaultValue={loaiNoiOOptions.filter((item) => item.value === defaultValues.loaiNoiO)[0]}
                                rules={{}}
                                render={(props) => {
                                    return (
                                        <Select
                                            onChange={onLoaiNoiOChange}
                                            options={loaiNoiOOptions}
                                            menuPortalTarget={document.querySelector('body')}
                                            value={props.value}
                                            // menuIsOpen={true}
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                menu: (provided) => ({ ...provided, zIndex: 13 }),
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.loaiNoiO && <div className="text-danger pt-2">{errors.loaiNoiO.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default NunDialog;
