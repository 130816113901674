import { Box, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { academic_year__get_list, loaded, loading } from 'components/mam-non-page/actions';
import { customSelectStyles } from 'config/constants/reactSelectConstant';
import { messageHelper } from 'helpers/messageHelper';
// import { dateHelper } from 'helpers/dateHelper';
import usePrevious from 'helpers/usePrevious';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { callApiExportCongDoanReportForAllCongDoan } from 'redux/saga/trung-uong/report/congdoan/exportCongDoanReportForAllCongDoan';
import { callApiGetCongDoanReportForAllCongDoan } from 'redux/saga/trung-uong/report/congdoan/getCongDoanReportForAllCongDoan';
// import StickyTable from 'components/shared/react-table/StickyTable';
import Table from 'components/shared/react-table/Table';
import { numberHelper } from 'helpers/numberHelper';

function TrungUongCongDoanAnnualPage() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [report, setReport] = useState(null);
    const commonReducer = useSelector((state) => state.commonReducer);

    const { control, setValue, getValues, trigger, watch } = useForm();

    const watches = watch();
    const values = getValues();
    const prevValues = usePrevious(values);

    useEffect(() => {
        dispatch(academic_year__get_list());
    }, [dispatch]);

    const academicYearsOptions = commonReducer.academicYears
        ? commonReducer.academicYears.map((item) => {
              return { value: item, label: item.name };
          })
        : [];

    const onAcademicYearChange = (selectedOption) => {
        setValue('academicYear', selectedOption);
        trigger('academicYear');
    };

    const getReport = useCallback(() => {
        dispatch(loading());
        callApiGetCongDoanReportForAllCongDoan(values.academicYear.value.name)
            .then((res) => {
                setReport(res.data);
            })
            .catch((err) => {
                messageHelper.showError(err);
            })
            .finally(() => {
                dispatch(loaded());
            });
    }, [dispatch, values.academicYear]);

    useEffect(() => {
        const isDiff = JSON.stringify(values) !== JSON.stringify(prevValues);
        const academicYear = get(values, 'academicYear.value.name');
        if (isDiff && academicYear) {
            getReport();
        }
    }, [values, watches, prevValues, getReport]);

    const buildRow = (data) => {
        let totalVND = 0;
        let totalUSD = 0;
        let totalEURO = 0;
        let totalAUD = 0;
        Object.entries(data.amounts).forEach(([k, v]) => {
            totalVND += v.amount;
            totalUSD += v.usdAmount;
            totalEURO += v.eurAmount;
            totalAUD += v.audAmount;
        });
        return {
            code: data.code,
            name: data.name,
            totalVND: numberHelper.formatNumber(totalVND),
            totalUSD: numberHelper.formatNumber(totalUSD),
            totalEURO: numberHelper.formatNumber(totalEURO),
            totalAUD: numberHelper.formatNumber(totalAUD),
        };
    };

    const buildTonRow = (data) => {
        const amount = data.amounts['2021-01'];
        return {
            code: '',
            name: 'Kết tồn năm trước',
            totalVND: numberHelper.formatNumber(amount.amount),
            totalUSD: numberHelper.formatNumber(amount.usdAmount),
            totalEURO: numberHelper.formatNumber(amount.eurAmount),
            totalAUD: numberHelper.formatNumber(amount.audAmount),
        };
    };

    const buildDataForThu = () => {
        const result = [];
        if (report) {
            report
                .filter((item) => item.code === 1000)
                .forEach((item) => {
                    result.push(buildTonRow(item));
                });
            report
                .filter((item) => item.loaiThuChi === 'THU')
                .filter((item) => item.code !== 1000)
                .forEach((item) => {
                    result.push(buildRow(item));
                });
        }
        return result;
    };

    const buildDataForChi = () => {
        const result = [];
        if (report) {
            report
                .filter((item) => item.loaiThuChi === 'CHI')
                .forEach((item) => {
                    result.push(buildRow(item));
                });
        }
        return result;
    };

    // const buildColumnsFor1Month = (month) => {
    //     return [
    //         {
    //             Header: dateHelper.formatDate(month, '[T.]MM'),
    //             columns: [
    //                 {
    //                     Header: 'VND',
    //                     accessor: month + '_VND',
    //                 },
    //                 {
    //                     Header: 'USD',
    //                     accessor: month + '_USD',
    //                 },
    //                 {
    //                     Header: 'EURO',
    //                     accessor: month + '_EURO',
    //                 },
    //                 {
    //                     Header: 'AUD',
    //                     accessor: month + '_AUD',
    //                 },
    //             ],
    //         },
    //     ];
    // };

    // const buildDynamicColumns = useCallback((academicYear) => {
    //     if (academicYear) {
    //         const months = dateHelper.getMonthYearArrayByAcademicYear(academicYear);
    //         let result = [];
    //         months.forEach((month) => {
    //             result = [...result, ...buildColumnsFor1Month(month)];
    //         });
    //         return result;
    //     }
    // }, []);

    const columns = useMemo(() => {
        const academicYear = get(values, 'academicYear.value');
        if (!academicYear) return [];
        const fixedColumns = [
            {
                Header: 'TM',
                accessor: 'code',
            },
            {
                Header: 'DIỄN GIẢI',
                accessor: 'name',
                style: {
                    minWidth: '400px',
                    textAlign: 'left',
                },
            },
            {
                Header: 'VND',
                accessor: 'totalVND',
                style: {
                    minWidth: '150px',
                },
            },
            {
                Header: 'USD',
                accessor: 'totalUSD',
                style: {
                    minWidth: '150px',
                },
            },
            {
                Header: 'EURO',
                accessor: 'totalEURO',
                style: {
                    minWidth: '150px',
                },
            },
            {
                Header: 'AUD',
                accessor: 'totalAUD',
                style: {
                    minWidth: '150px',
                },
            },
        ];
        // const dynamicColumns = buildDynamicColumns(academicYear);
        return [
            ...fixedColumns,
            // ...dynamicColumns
        ];
    }, [values]);

    const onExport = () => {
        const academicYear = get(values, 'academicYear.value.name');
        if (academicYear) {
            dispatch(loading());
            callApiExportCongDoanReportForAllCongDoan(academicYear)
                .then((res) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `Báo cáo thu chi tất cả cộng đoàn ${academicYear}.xls`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch((err) => {
                    messageHelper.showError(err);
                })
                .finally(() => {
                    dispatch(loaded());
                });
        }
    };

    return (
        <>
            <Paper elevation={3}>
                <Box p={theme.spacing(0, 3)}>
                    <Typography variant="h6">Báo cáo thu chi cộng đoàn theo năm</Typography>
                </Box>
                <Divider></Divider>
                <Box p={theme.spacing(1, 1)}>
                    <form>
                        <div className="row m-0">
                            <div className="col-md-3 pr-0">
                                <div className="row mr-0">
                                    <label className="mb-0 col-md-4 fmi-label pr-0" htmlFor="academicYear">
                                        Năm học:
                                    </label>
                                    <div className="col-md-8 p-0">
                                        <Controller
                                            id="academicYear"
                                            name="academicYear"
                                            control={control}
                                            defaultValue={null}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Vui lòng nhập năm học để lấy báo cáo!',
                                                },
                                            }}
                                            render={() => {
                                                return (
                                                    <Select
                                                        onChange={onAcademicYearChange}
                                                        options={academicYearsOptions}
                                                        classNamePrefix="fmi-react-select"
                                                        styles={customSelectStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3"></div>
                            <div className="col-md-3">
                                {values.academicYear && (
                                    <div className="row mx-0 w-100">
                                        <button type="button" className={clsx({ 'export-button btn btn-primary mb-1 fmi-toolbar-button': true })} onClick={onExport}>
                                            Xuất báo cáo
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>

            <div>
                {report && (
                    <>
                        <Box className="mt-3" p={theme.spacing(0, 3)}>
                            <Typography variant="h6">SỔ THU</Typography>
                        </Box>
                        <Table columns={columns} data={buildDataForThu()} />
                    </>
                )}
            </div>

            <div>
                {report && (
                    <>
                        <Divider></Divider>
                        <Box className="mt-3" p={theme.spacing(0, 3)}>
                            <Typography variant="h6">SỔ CHI</Typography>
                        </Box>
                        <Table columns={columns} data={buildDataForChi()} />
                    </>
                )}
            </div>
        </>
    );
}

export default TrungUongCongDoanAnnualPage;
