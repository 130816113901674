import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { loaded, loading, nun__get_list } from 'components/mam-non-page/actions';
import { FMI_SERVER_DATE_FORMAT } from 'config/constants/dateConstants';
import dayjs from 'dayjs';
import { dateHelper } from 'helpers/dateHelper';
import { messageHelper } from 'helpers/messageHelper';
import React, { useEffect, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { callApiCreateKhanDong } from 'redux/saga/cong-doan/info/khandong/createKhanDong';
import { callApiUpdateKhanDong } from 'redux/saga/cong-doan/info/khandong/updateKhanDong';

function KhanDongDialog({ open, closeDialog, khanDong, onSuccess }) {
    const { register, handleSubmit, errors, control, setValue, trigger } = useForm();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});
    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userInfo.congDoanId) {
            dispatch(nun__get_list({ congDoanId: userInfo.congDoanId, isActive: true }));
        }
    }, [dispatch, userInfo.congDoanId]);

    const nunOptions = useMemo(() => {
        return commonReducer.nuns
            ? commonReducer.nuns.map((item) => {
                  return { value: item, label: item.fullName };
              })
            : [];
    }, [commonReducer.nuns]);

    useEffect(() => {
        setValue('nun', nunOptions.filter((item) => item.value.id === khanDong.nunId)[0]);
    }, [nunOptions, khanDong.nunId, setValue]);

    const defaultValues = {
        id: khanDong.id,
        giaoXu: khanDong.giaoXu,
        giaoPhan: khanDong.giaoPhan,
        namTienKhan: khanDong.namTienKhan ? dayjs(khanDong.namTienKhan, FMI_SERVER_DATE_FORMAT).toDate() : null,
        namVinhKhan: khanDong.namVinhKhan ? dayjs(khanDong.namVinhKhan, FMI_SERVER_DATE_FORMAT).toDate() : null,
        ngayDenCongDoan: khanDong.ngayDenCongDoan ? dayjs(khanDong.ngayDenCongDoan, FMI_SERVER_DATE_FORMAT).toDate() : null,
        congViec: khanDong.congViec,
        nunId: khanDong.nunId,
        nun: nunOptions.filter((item) => item.value.id === khanDong.nunId)[0],
    };

    const onNunChange = (selectedOption) => {
        setValue('nun', selectedOption);
        trigger('nun');
    };

    const onSubmit = (data) => {
        const body = {
            ...data,
            ...{
                namTienKhan: dayjs(data.namTienKhan).isValid() ? dateHelper.formatDate(data.namTienKhan, FMI_SERVER_DATE_FORMAT) : null,
                namVinhKhan: dayjs(data.namVinhKhan).isValid() ? dateHelper.formatDate(data.namVinhKhan, FMI_SERVER_DATE_FORMAT) : null,
                ngayDenCongDoan: dayjs(data.ngayDenCongDoan).isValid() ? dateHelper.formatDate(data.ngayDenCongDoan, FMI_SERVER_DATE_FORMAT) : null,
            },
        };
        console.log(body);
        if (khanDong.id) {
            editKhanDong(body.nun.value.id, khanDong.id, body);
        } else {
            addNun(data.nun.value.id, body);
        }
    };

    const editKhanDong = (nunId, id, data) => {
        dispatch(loading());
        callApiUpdateKhanDong(userInfo.congDoanId, khanDong.nunId, khanDong.id, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    const addNun = (nunId, data) => {
        dispatch(loading());
        callApiCreateKhanDong(userInfo.congDoanId, nunId, data)
            .then((res) => {
                onSuccess();
                closeDialog();
            })
            .catch((err) => messageHelper.showError(err))
            .finally(() => {
                dispatch(loaded());
            });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{khanDong.id ? 'Chỉnh sửa khấn dòng' : 'Thêm khấn dòng'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="nun">Sơ</label>
                            <Controller
                                id="nun"
                                name="nun"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Vui lòng chọn sơ!',
                                    },
                                }}
                                render={(props) => {
                                    return (
                                        <ReactSelect
                                            isClearable
                                            options={nunOptions}
                                            onChange={onNunChange}
                                            selected={props.value}
                                            value={props.value}
                                            isDisabled={khanDong.id ? true : false}
                                        />
                                    );
                                }}
                            />
                            {errors.nun && <div className="text-danger pt-2">{errors.nun.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="giaoXu">Giáo xứ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.giaoXu && 'border-danger')}
                                placeholder="Giáo xứ"
                                defaultValue={defaultValues.giaoXu}
                                id="giaoXu"
                                name="giaoXu"
                                ref={register({})}
                            />
                            {errors.giaoXu && <div className="text-danger pt-2">{errors.giaoXu.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="giaoPhan">Giáo phận</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.giaoPhan && 'border-danger')}
                                placeholder="Giáp phần"
                                defaultValue={defaultValues.giaoPhan}
                                id="giaoPhan"
                                name="giaoPhan"
                                ref={register({})}
                            />
                            {errors.giaoPhan && <div className="text-danger pt-2">{errors.giaoPhan.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="congViec">Công việc</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.congViec && 'border-danger')}
                                placeholder="Giáo xứ"
                                defaultValue={defaultValues.congViec}
                                id="congViec"
                                name="congViec"
                                ref={register({})}
                            />
                            {errors.congViec && <div className="text-danger pt-2">{errors.congViec.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="namTienKhan">Năm tiền khấn</label>
                            <Controller
                                control={control}
                                id="namTienKhan"
                                name="namTienKhan"
                                defaultValue={defaultValues.namTienKhan}
                                render={(props) => {
                                    return (
                                        <ReactDatePicker
                                            placeholderText="Chọn năm"
                                            onChange={(e) => props.onChange(e)}
                                            className="form-control"
                                            locale="vi"
                                            showYearPicker
                                            selected={props.value}
                                            dateFormat="yyyy"
                                            popperProps={{
                                                positionFixed: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.namTienKhan && <div className="text-danger pt-2">{errors.namTienKhan.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="namVinhKhan">Năm vinh khấn</label>
                            <Controller
                                control={control}
                                id="namVinhKhan"
                                name="namVinhKhan"
                                defaultValue={defaultValues.namVinhKhan}
                                render={(props) => (
                                    <ReactDatePicker
                                        placeholderText="Chọn năm"
                                        onChange={(e) => props.onChange(e)}
                                        className="form-control"
                                        dateFormat="yyyy"
                                        locale="vi"
                                        showYearPicker
                                        selected={props.value}
                                        popperProps={{
                                            positionFixed: true,
                                        }}
                                    />
                                )}
                            />
                            {errors.namVinhKhan && <div className="text-danger pt-2">{errors.namVinhKhan.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="ngayDenCongDoan">Ngày đến cộng đoàn</label>
                            <Controller
                                control={control}
                                id="ngayDenCongDoan"
                                name="ngayDenCongDoan"
                                defaultValue={defaultValues.ngayDenCongDoan}
                                render={(props) => {
                                    return (
                                        <ReactDatePicker
                                            placeholderText="Chọn ngày"
                                            className="form-control"
                                            onChange={(e) => props.onChange(e)}
                                            dateFormat="dd.MM.yyyy"
                                            locale="vi"
                                            selected={props.value}
                                            popperProps={{
                                                positionFixed: true,
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.ngayDenCongDoan && <div className="text-danger pt-2">{errors.ngayDenCongDoan.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default KhanDongDialog;
