import { ACADEMIC_YEAR_DELIMITER, FMI_CONG_DOAN_SAI_GON_IDS, FMI_DATE_FORMAT, FMI_SERVER_DATE_FORMAT, FMI_YEAR_MONTH_FORMAT, WEEK_DAYS } from 'config/constants/dateConstants';
import { monthsOfYearsHave10Months, monthsOfYearsHave12Months, monthsOfYearsHave14Months } from 'config/constants/monthsConstants';
import dayjs from 'dayjs';
import { get } from 'lodash';

function formatDate(dateString, format = FMI_DATE_FORMAT) {
    if (!dateString) return '';
    return dayjs(dateString).format(format);
}

function getWeekDayOfDateAsString(date) {
    const weekDayIndex = dayjs(date).day();
    return WEEK_DAYS[weekDayIndex];
}

function getWeekDayOfDate(date) {
    return dayjs(date).day();
}

function isWeekend(date) {
    return getWeekDayOfDate(date) === 6 || getWeekDayOfDate(date) === 0;
}

function isSaturday(date) {
    return getWeekDayOfDate(date) === 6;
}

function isSunday(date) {
    return getWeekDayOfDate(date) === 0;
}

function getStartDateOfDayInMonth(date) {
    return dayjs().startOf('month');
}

function isAfterNextMonth10thDate(monthYear, today = dayjs()) {
    const lastDayOfSelectedMonth = dayjs(monthYear).endOf('month');
    return today.isAfter(lastDayOfSelectedMonth.add(10, 'day'));
}

function getAllDaysOfMonth(monthYear, returnFormat = FMI_SERVER_DATE_FORMAT) {
    const result = [];
    const endDay = dayjs(monthYear).endOf('month');
    let day = dayjs(monthYear).startOf('month');
    while (day.isBefore(endDay) || day.isSame(endDay)) {
        result.push(day.format(returnFormat));
        day = day.add(1, 'day');
    }
    return result;
}

function getYearFromAcademicYearAndMonth(academicYear, month) {
    const yearAsArray = academicYear.split(ACADEMIC_YEAR_DELIMITER);
    if (month > 5) {
        return yearAsArray[0];
    } else {
        return yearAsArray[1];
    }
}

function getYearsFromAcademicYear(academicYear) {
    return academicYear.split(ACADEMIC_YEAR_DELIMITER);
}

function getMonthYearArrayByAcademicYear(academicYear) {
    const result = [];
    const numOfMonth = academicYear.congDoanNumOfMonth;
    const yearAsArray = academicYear.name.split(ACADEMIC_YEAR_DELIMITER);
    if (numOfMonth === 14) {
        monthsOfYearsHave14Months.forEach((month, index) => {
            if (index <= 8) {
                result.push(dayjs([yearAsArray[0], month]).format(FMI_YEAR_MONTH_FORMAT));
            } else {
                result.push(dayjs([yearAsArray[1], month]).format(FMI_YEAR_MONTH_FORMAT));
            }
        });
    } else if (numOfMonth === 12) {
        monthsOfYearsHave12Months.forEach((month, index) => {
            if (index <= 6) {
                result.push(dayjs([yearAsArray[0], month]).format(FMI_YEAR_MONTH_FORMAT));
            } else {
                result.push(dayjs([yearAsArray[1], month]).format(FMI_YEAR_MONTH_FORMAT));
            }
        });
    } else if (numOfMonth === 10) {
        monthsOfYearsHave10Months.forEach((month, index) => {
            if (index <= 6) {
                result.push(dayjs([yearAsArray[0], month]).format(FMI_YEAR_MONTH_FORMAT));
            } else {
                result.push(dayjs([yearAsArray[1], month]).format(FMI_YEAR_MONTH_FORMAT));
            }
        });
    }
    return result;
}

const getDuThuFieldMappedByMonth = () => {
    const result = new Map();
    result.set(1, 'janAmount');
    result.set(2, 'febAmount');
    result.set(3, 'marAmount');
    result.set(4, 'aprAmount');
    result.set(5, 'mayAmount');
    result.set(6, 'junAmount');
    result.set(7, 'julAmount');
    result.set(8, 'augAmount');
    result.set(9, 'sepAmount');
    result.set(10, 'octAmount');
    result.set(11, 'novAmount');
    result.set(12, 'decAmount');
    result.set(13, 'extraAmount1');
    result.set(14, 'extraAmount2');
    return result;
};

const getMonthArraysByAcademicYear = (academicYear) => {
    const numberOfMonths = get(academicYear, 'congDoanNumOfMonth');
    const startMonth = get(academicYear, 'congDoanStartMonth');
    const result = Array.from(Array(numberOfMonths).keys())
        .map((item) => item + startMonth)
        .map((item) => {
            return item - (item > 12 ? 12 : 0);
        });
    return result;
};

const isCongDoanSaiGonAndSpecialCaseFor2022 = (congDoanId, academicYear, month) => {
    return academicYear === '2021-2022' && [6, 7].includes(month) && FMI_CONG_DOAN_SAI_GON_IDS.includes(congDoanId);
};

export const dateHelper = {
    formatDate,
    getWeekDayOfDateAsString,
    getWeekDayOfDate,
    isWeekend,
    isSaturday,
    isSunday,
    getYearFromAcademicYearAndMonth,
    isAfterNextMonth10thDate,
    getAllDaysOfMonth,
    getMonthYearArrayByAcademicYear,
    duThuFieldMappedByMonth: getDuThuFieldMappedByMonth,
    getMonthArraysByAcademicYear,
    getYearsFromAcademicYear,
    isCongDoanSaiGonAndSpecialCaseFor2022,
    getStartDateOfDayInMonth,
};
