import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { callApiCreateDanhMucCTPV } from 'redux/saga/ctpv/danhmuc/createDanhMucCTPV';
import { callApiUpdateDanhMucCTPV } from 'redux/saga/ctpv/danhmuc/updateDanhMucCTPV';

function CTPVDanhMucDialog({ danhMuc, open, closeDialog, onSuccess }) {
    const { register, handleSubmit, errors } = useForm();
    const userInfo = useSelector((state) => state.authenticationReducer.userInfo, {});

    const defaultValues = {
        id: danhMuc.id,
        name: danhMuc.name,
    };

    const onSubmit = (data) => {
        const body = { ...data };
        if (danhMuc.id) {
            editDanhMuc(body);
        } else {
            addDanhMuc({ ...body, ...{ active: true } });
        }
    };

    const addDanhMuc = (data) => {
        callApiCreateDanhMucCTPV(userInfo.congDoanId, data).then((res) => {
            onSuccess();
            closeDialog();
        });
    };

    const editDanhMuc = (data) => {
        callApiUpdateDanhMucCTPV(danhMuc.id, data).then((res) => {
            onSuccess();
            closeDialog();
        });
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{danhMuc.id ? 'Chỉnh sửa danh mục thu' : 'Thêm danh mục'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label htmlFor="name">Tên danh mục</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.name && 'border-danger')}
                                placeholder="Tên danh mục"
                                defaultValue={defaultValues.name}
                                id="name"
                                name="name"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập tên danh mục!',
                                    },
                                })}
                            />
                            {errors.name && <div className="text-danger pt-2">{errors.name.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CTPVDanhMucDialog;
