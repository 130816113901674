import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import { BANK_ACCOUNT_TYPE } from 'config/constants/assetConstant';
import React from 'react';
import { useForm } from 'react-hook-form';

function BankAccountDialog({ bankAccount, open, closeDialog, onSaveAsset }) {
    const { register, handleSubmit, errors } = useForm();

    const defaultValues = {
        id: bankAccount.id,
        stk: bankAccount.stk,
        loaiTien: bankAccount.loaiTien,
        tenNganHang: bankAccount.tenNganHang,
        swift: bankAccount.swift,
        diaChiDangKyTK: bankAccount.diaChiDangKyTK,
        tenChuTK: bankAccount.tenChuTK,
        tenUyQuyenTK: bankAccount.tenUyQuyenTK,
    };

    const onSubmit = (data) => {
        const body = { ...data, ...{ accountType: BANK_ACCOUNT_TYPE.ACCOUNT } };
        onSaveAsset(body);
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{bankAccount.id ? 'Chỉnh sửa tài khoản ngân hàng' : 'Thêm tài khoản ngân hàng'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="stk">Số TK</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.stk && 'border-danger')}
                                placeholder="Số TK"
                                defaultValue={defaultValues.stk}
                                id="stk"
                                name="stk"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập số TK!',
                                    },
                                })}
                            />
                            {errors.stk && <div className="text-danger pt-2">{errors.stk.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="loaiTien">Loại tiền</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.loaiTien && 'border-danger')}
                                placeholder="Loại tiền"
                                defaultValue={defaultValues.loaiTien}
                                id="loaiTien"
                                name="loaiTien"
                                ref={register({})}
                            />
                            {errors.loaiTien && <div className="text-danger pt-2">{errors.loaiTien.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="tenNganHang">Tên NH</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tenNganHang && 'border-danger')}
                                placeholder="Tên NH"
                                defaultValue={defaultValues.tenNganHang}
                                id="tenNganHang"
                                name="tenNganHang"
                                ref={register({})}
                            />
                            {errors.tenNganHang && <div className="text-danger pt-2">{errors.tenNganHang.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="swift">Swift</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.swift && 'border-danger')}
                                placeholder="Swift"
                                defaultValue={defaultValues.swift}
                                id="swift"
                                name="swift"
                                ref={register({})}
                            />
                            {errors.swift && <div className="text-danger pt-2">{errors.swift.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="diaChiDangKyTK">{`Địa chỉ đăng ký TK`}</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.diaChiDangKyTK && 'border-danger')}
                                placeholder="Địa chỉ đăng ký TK"
                                defaultValue={defaultValues.diaChiDangKyTK}
                                id="diaChiDangKyTK"
                                name="diaChiDangKyTK"
                                ref={register({})}
                            />
                            {errors.diaChiDangKyTK && <div className="text-danger pt-2">{errors.diaChiDangKyTK.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="tenChuTK">Tên chủ tài khoản</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tenChuTK && 'border-danger')}
                                placeholder="Tên chủ tài khoản"
                                defaultValue={defaultValues.tenChuTK}
                                id="tenChuTK"
                                name="tenChuTK"
                                ref={register({})}
                            />
                            {errors.tenChuTK && <div className="text-danger pt-2">{errors.tenChuTK.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="tenUyQuyenTK">Tên uỷ quyền TK</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.tenUyQuyenTK && 'border-danger')}
                                placeholder="Tên uỷ quyền TK"
                                defaultValue={defaultValues.tenUyQuyenTK}
                                id="tenUyQuyenTK"
                                name="tenUyQuyenTK"
                                ref={register({})}
                            />
                            {errors.tenUyQuyenTK && <div className="text-danger pt-2">{errors.tenUyQuyenTK.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default BankAccountDialog;
