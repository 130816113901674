import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useForm } from 'react-hook-form';

function DatDaiDialog({ datdai, open, closeDialog, onSaveAsset }) {
    const { register, handleSubmit, errors } = useForm();

    const defaultValues = {
        id: datdai.id,
        soLuongDat: datdai.soLuongDat,
        dienTich: datdai.dienTich,
        soDo: datdai.soDo,
        soHong: datdai.soHong,
        triGia: datdai.triGia,
        noiCatGiu: datdai.noiCatGiu,
        nguoiDungTen: datdai.nguoiDungTen,
        nguoiDuocVietDiChuc: datdai.nguoiDuocVietDiChuc,
    };

    const onSubmit = (data) => {
        const body = { ...data };
        onSaveAsset(body);
    };

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">{datdai.id ? 'Chỉnh sửa đất đai' : 'Thêm đất đai'}</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="soLuongDat">Số lượng đất</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.soLuongDat && 'border-danger')}
                                placeholder="Số lượng đất"
                                defaultValue={defaultValues.soLuongDat}
                                id="soLuongDat"
                                name="soLuongDat"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Vui lòng nhập số lượng đất!',
                                    },
                                })}
                            />
                            {errors.soLuongDat && <div className="text-danger pt-2">{errors.soLuongDat.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="dienTich">Diện tích</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.dienTich && 'border-danger')}
                                placeholder="Diện tích"
                                defaultValue={defaultValues.dienTich}
                                id="dienTich"
                                name="dienTich"
                                ref={register({})}
                            />
                            {errors.dienTich && <div className="text-danger pt-2">{errors.dienTich.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="soDo">Sổ đỏ</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.soDo && 'border-danger')}
                                placeholder="Sổ đỏ"
                                defaultValue={defaultValues.soDo}
                                id="soDo"
                                name="soDo"
                                ref={register({})}
                            />
                            {errors.soDo && <div className="text-danger pt-2">{errors.soDo.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="soHong">Sổ Hồng</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.soHong && 'border-danger')}
                                placeholder="Sổ Hồng"
                                defaultValue={defaultValues.soHong}
                                id="soHong"
                                name="soHong"
                                ref={register({})}
                            />
                            {errors.soHong && <div className="text-danger pt-2">{errors.soHong.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="triGia">Trị giá</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.triGia && 'border-danger')}
                                placeholder="Trị giá"
                                defaultValue={defaultValues.triGia}
                                id="triGia"
                                name="triGia"
                                ref={register({})}
                            />
                            {errors.triGia && <div className="text-danger pt-2">{errors.triGia.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="noiCatGiu">{`TG sử dụng/Nơi lưu trữ (Tw, V, Cđ)`}</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.noiCatGiu && 'border-danger')}
                                placeholder="TG sử dụng/Nơi lưu trữ (Tw, V, Cđ)"
                                defaultValue={defaultValues.noiCatGiu}
                                id="noiCatGiu"
                                name="noiCatGiu"
                                ref={register({})}
                            />
                            {errors.noiCatGiu && <div className="text-danger pt-2">{errors.noiCatGiu.message}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiDungTen">Người đứng tên</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiDungTen && 'border-danger')}
                                placeholder="Người đứng tên"
                                defaultValue={defaultValues.nguoiDungTen}
                                id="nguoiDungTen"
                                name="nguoiDungTen"
                                ref={register({})}
                            />
                            {errors.nguoiDungTen && <div className="text-danger pt-2">{errors.nguoiDungTen.message}</div>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="nguoiDuocVietDiChuc">Người được viết di chúc</label>
                            <input
                                type="text"
                                className={clsx('form-control', errors.nguoiDuocVietDiChuc && 'border-danger')}
                                placeholder="Người được viết di chúc"
                                defaultValue={defaultValues.nguoiDuocVietDiChuc}
                                id="nguoiDuocVietDiChuc"
                                name="nguoiDuocVietDiChuc"
                                ref={register({})}
                            />
                            {errors.nguoiDuocVietDiChuc && <div className="text-danger pt-2">{errors.nguoiDuocVietDiChuc.message}</div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button type="button" className="btn btn-secondary" onClick={closeDialog}>
                        Đóng
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Lưu
                    </button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default DatDaiDialog;
